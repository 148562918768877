export const emitIterableEvent = async (eventName, variables) => {
  if (window._iaq) {
    try {
      await window._iaq.track(eventName, variables);

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(`[Iterable ${eventName}] event emitted ${variables ? `with variables: ${JSON.stringify(variables)}` : ''}`);
      }
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(err.message);
      }
    }
  }
};

export const setIterableUserProps = async ({ user, isMobileDevice }) => {
  if (window._iaq) {
    await window._iaq.identify(user.user_info.email, {
      firstName: user.user_info.first_name,
      userId: user.account_id,
      platform: !isMobileDevice ? 'web desktop' : 'web mobile',
    });
  }
};

export const unsetIterableUserProps = async () => {
  if (window._iaq) {
    await window._iaq.identify(null, {});
  }
};
