import Get from 'lodash.get';

export const emitSnapchatEvent = async (eventName, variables) => {
  if (window.snaptr) {
    try {
      if (variables) {
        window.snaptr('track', eventName, variables);
      } else {
        window.snaptr('track', eventName);
      }

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(`[Snapchat ${eventName}] event emitted ${variables ? `with variables: ${JSON.stringify(variables)}` : ''}`);
      }
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(err.message);
      }
    }
  }
};

export const startSnapchat = (user) => {
  if (window.snaptr) {
    try {
      window.snaptr('init', process.env.VUE_APP_SNAPCHAT_CLIENT_ID, {
        user_email: Get(user, 'user_info.email', ''),
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err.message);
    }
  }
};
