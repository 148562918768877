import router from '@/vue-router';

router.addRoute({ 
  component: () => import('@/views/Holdings'),
  name: 'holdings',
  path: '/holdings',
  meta: {
    bypassLoading: true,
    requiresAuth: true,
    template: 1,
  },
});

router.addRoute({ 
  component: () => import('@/views/Holdings'),
  name: 'holdings-type',
  path: '/holdings/:type',
  meta: {
    template: 1,
    bypassLoading: true,
    requiresAuth: true,
  }
});
