<template>
  <Card
    v-bind="cardProps"
    :class="cardClasses"
    @click.native="goToInternalPageGlobal(data.to)"
  >
    <div class="flex start">
      <SquareImage v-bind="assetImgProps" />
    </div>

    <!-- Asset Info -->
    <div class="asset-info">
      <div>
        <div class="asset-title">
          <h4 class="subheadline-small">
            {{ data.collection_name }}
          </h4>
        </div>
        <div class="asset-subtitle mt-s4">
          <h4 class="subheadline-x-small">
            {{ data.short_name }}
          </h4>
        </div>
      </div>

      <div
        v-if="!isLoading"
        class="flex flex-col gap-s8"
      >
        <div class="flex xs:flex-col flex-row justify-between gap-s16">
          <div class="flex flex-col">
            <h5 class="body-text-x-small text-text-inactive">
              {{ $t('trade.asset_price') }}
            </h5>

            <p class="subheadline-x-small">
              {{ data.readableValues.currentPrice }}
            </p>
          </div>

          <div
            v-if="data.rawValues.priceChangeDynamic.value !== 0"
            class="flex flex-col"
          >
            <h5 class="body-text-x-small text-text-inactive">
              {{ priceChangeTimeframe }}
            </h5>

            <p :class="priceChangeClasses">
              {{ priceChange.value }} 

              <span class="body-text-x-small">
                ({{ priceChange.percentage }})
              </span>
            </p>
          </div>
        </div>

        <CardLadderDescription
          v-if="hasCardLadder"
          :value="cardLadderValue"
          descriptionText="drops.card_ladder_avg_price_small"
        />
      </div>

      <div class="flex">
        <ButtonV2 
          :label="$tc('asset.buy_fraction')" 
          testId="btn-buy-fractions"
          size="small"
        />
      </div>
    </div>
  </Card>
</template>

<script>
import {
  ButtonV2,
  Card,
  CardLadderDescription,
  SquareImage,
} from '@/components/misc';

export default {
  name: 'AssetCard',

  components: {
    ButtonV2,
    Card,
    CardLadderDescription,
    SquareImage,
  },

  props: {
    ...Card.props,

    data: Object,

    background: {
      default: 'bg-background-page',
    },

    skeletonLoading: {
      type: Object,
      default: () => ({
        height: '218px',
        width: '100%',
      }),
    },
  },

  computed: {
    priceChangeTimeframe() {
      const { timeframe } = this.priceChange;

      return this.$t(`market.change_timeframes.change${ timeframe }`);
    },

    cardClasses() {
      return {
        'flex flex-row gap-s16 cursor-pointer w-full': true,
        'asset-card': true,
        'asset-card--desktop py-s24 px-s20': !this.isMobileDevice,
        'asset-card--mobile py-s24 px-s12': this.isMobileDevice,
      };
    },

    cardProps() {
      if (this.$props) {
        const cardPropsExpected = [
          'background',
          'isLoading',
          'skeletonLoading'
        ];
        const props = Object
          .entries(this.$props)
          .filter((item) => cardPropsExpected.includes(item[0]));

        return Object.fromEntries(props);
      }

      return {};
    },

    cardLadderValue() {
      return !this.isLoading && this.data.cardladder.value;
    },

    hasCardLadder() {
      return !this.isLoading && this.data.cardladder.active;
    },

    priceChange() {
      return !this.isLoading 
        && this.data.readableValues.priceChangeDynamic;
    },

    priceChangeClasses() {
      return (
        !this.isLoading && {
          'body-text-x-medium': true,
          [`text-text-${this.priceChange.type}`]: true,
        }
      );
    },

    assetImgProps() {
      return {
        image: this.data.image,
        imageResizeProps: {
          alt: this.data.img_alt,
          assetType: 'home_asset_card',
        }
      };
    },
  },
};
</script>

<style scoped>
.asset-card {
  @apply grid;
  grid-template-columns: 100px 1fr;
}

.asset-info {
  @apply grid h-full gap-s8;
  grid-template-rows: min-content 1fr min-content;
}

.asset-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.asset-subtitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 40px;
}

.asset-card--mobile {
  max-height: 212px;
  max-width: 335px;
}

.asset-card--desktop {
  height: 218px;
  max-width: 385px;
}
</style>
