import store from '@/store';
import { onLogin, onLogout } from '@/vue-apollo';
import AUTH_WITH_APPLE from '@/graphql/mutations/AuthWithApple.gql';
import AUTH_WITH_GOOGLE from '@/graphql/mutations/AuthWithGoogle.gql';

const auth = {
  data: {
    captchaSiteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
  },
  methods: {
    isAuthenticated: function () {
      return store.state.api.isAuthenticated;
    },

    handleLogout: async function () {
      await onLogout(this.$apollo);
      this.$store.commit('api/unsetLoginData');
      await this.$store.dispatch('banner/hideAllBanners');
      await this.$store.dispatch('user/resetStore');
      this.switchTheme('light');
      localStorage.removeItem('hideKycGemToggle');
    },

    handleLogin: async function (data, twofa_enabled = false) {
      this.$store.commit('ui/setState', { stateName: 'initialLoading', stateData: true });
      await this.$store.dispatch('events/setUserProps', {
        user: { ...data.user, user_info: { email: data.user.email, twofa_enabled } },
        isMobileDevice: this.isMobileDevice,
      });
      await onLogin(this.$apollo, data.token);
      this.$store.commit('api/setLoginData', { data });
      this.$router.push(this.$store.state.ui.lastValidPage || '/').catch(() => { });
      await this.$store.dispatch('user/requiresKycComplete');
    },

    handleRegister: async function (data, invite) {
      this.$store.commit('ui/setState', { stateName: 'initialLoading', stateData: true });
      await this.$store.dispatch('events/setUserProps', {
        user: { ...data.user, user_info: { email: data.user.email } },
        isMobileDevice: this.isMobileDevice,
      });
      await onLogin(this.$apollo, data.token);
      this.$store.commit('api/setLoginData', { data });
      await this.$store.dispatch('events/track', {
        event: 'BRANCH_SIGN_UP_SUCCEEDED',
        variables: {
          invite_code: invite,
        },
      });
      this.$router.push(this.$store.state.ui.lastValidPage || '/').catch(() => { });
      await this.$store.dispatch('user/requiresKycComplete');
    },

    ssoAuth: async function (authToken, origin, twofa = '', agreed_to_tos = false) {
      const ref = this.$store.state.api.referralCode;
      const mutation = origin === 'apple' ? AUTH_WITH_APPLE : AUTH_WITH_GOOGLE;
      const response = await this.apolloApiCall({
        mutation,
        variables: {
          auth_token: authToken,
          keep_me: true,
          twofa,
          agreed_to_tos,
          referral_code: ref ? ref : '',
        }
      });
      return response.data[`_auth_with_${origin}`];
    },

    ssoGetAuthToken: async function (origin) {
      try {
        if (origin === 'apple') {
          const appleAuthData = await window.AppleID.auth.signIn();
          return appleAuthData.authorization.id_token;
        }
        if (origin === 'google') {
          const googleUser = await this.$gAuth.signIn();
          return googleUser.getAuthResponse().id_token;
        }
      } catch (err) {
        await this.showError(err);
        return null;
      }
    },

    tosDisagree() {
      this.$store.commit('ui/showPrompt', {
        eventName: 'confirm-disagree-tos',
        title: this.$t('user_agreement.disagree_title'),
        message: this.$t('user_agreement.disagree_description'),
        customConfirm: this.$t('disagree'),
      });
    }
  },
};

export default auth;
