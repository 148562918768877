<template>
  <section class="markdown-component" v-html="formatedContent" />
</template>

<script>
import showdown from 'showdown';
const converter = new showdown.Converter({
  simpleLineBreaks: true,
  openLinksInNewWindow: true,
  tables: true,
  strikethrough: true,
  ghCodeBlocks: true,
});
export default {
  name: 'Markdown',
  props: {
    content: String,
  },
  computed: {
    formatedContent() {
      if (!this.content) { return; }
      return converter.makeHtml(this.content);
    },
  },
};
</script>

<style>
/* Typography  */
.markdown-component {
  @apply text-n-md font-normal;
}
.markdown-component a {
  @apply text-text-active-2;
}


/* Titles */
.markdown-component h1,
.markdown-component h2,
.markdown-component h3,
.markdown-component h4,
.markdown-component h5,
.markdown-component h6 {
  @apply font-bold mt-s20 mb-s12;
}
.markdown-component h1 {
  @apply text-n-3xl leading-7;
}
.markdown-component h2 {
  @apply text-n-2xl leading-6;
}
.markdown-component h3 {
  @apply text-n-2xl leading-6;
}
.markdown-component h4,
.markdown-component h5,
.markdown-component h6 {
  @apply text-n-xl leading-5;
}

.markdown-component p {
  @apply body-text-medium;
}


/* Lists */
.markdown-component ul,
.markdown-component ol {
  @apply list-disc pl-s16;
}
.markdown-component li {
  @apply pl-s2;
}
.markdown-component ol                         { list-style-type: decimal; }
.markdown-component ol ol                      { list-style-type: lower-alpha; }
.markdown-component ol ol ol                   { list-style-type: lower-roman; }
.markdown-component ol ol ol ol                { list-style-type: decimal; }
.markdown-component ol ol ol ol ol             { list-style-type: lower-alpha; }
.markdown-component ol ol ol ol ol ol          { list-style-type: lower-roman; }
.markdown-component ol ol ol ol ol ol ol       { list-style-type: decimal; }
.markdown-component ol ol ol ol ol ol ol ol    { list-style-type: lower-alpha; }
.markdown-component ol ol ol ol ol ol ol ol ol { list-style-type: lower-roman; }
.markdown-component ul                         { list-style-type: disc; }
.markdown-component ul ul                      { list-style-type: circle; }
.markdown-component ul ul ul                   { list-style-type: square; }
.markdown-component ul ul ul ul                { list-style-type: disc; }
.markdown-component ul ul ul ul ul             { list-style-type: circle; }
.markdown-component ul ul ul ul ul ul          { list-style-type: square; }
.markdown-component ul ul ul ul ul ul ul       { list-style-type: disc; }
.markdown-component ul ul ul ul ul ul ul ul    { list-style-type: circle; }
.markdown-component ul ul ul ul ul ul ul ul ul { list-style-type: square; }

.markdown-component table {
  @apply table-auto border-collapse;
}
.markdown-component td,
.markdown-component th {
  @apply 
    border 
    border-border 
    px-s8 
    py-s4 
    text-left
  ;
}
.markdown-component th {
  @apply bg-background-positive-soft;
}

.markdown-component blockquote {
  @apply 
    bg-background-positive-soft
    border
    border-background-positive-dark
    rounded-4
    p-s12
    mt-s12
  ;
}
</style>
