import Get from 'lodash.get';

import i18n from '@/translations/i18n';

const mutedErrorMessages = ['user_not_kyced'];

const isApiError = (err) => {
  return Get(err, '__typename', '') === 'DibbsError';
};

const _isGraphQlError = (err) => {
  if (!err) {
    return null;
  }

  return typeof err.graphQLErrors !== 'undefined' && err.graphQLErrors.length;
};

const _isMuted = (err) => {
  return mutedErrorMessages.findIndex(message => message === getErrorLabel(err)) !== -1;
};

const _isNetworkError = (err) => {
  if (!err) {
    return null;
  }

  return err.networkError && err.networkError.result && err.networkError.result.errors && err.networkError.result.errors.length;
};

const getErrorLabel = (err) => {
  if (!err) {
    return null;
  }

  /*
  * If you need to implement a new error handling scenario,
  * abstract the logic to a private function and then add it here.
  * @see _isGraphQlError
  **/

  if (isApiError(err)) {
    return err['error_code'];
  }

  if (_isNetworkError(err)) {
    return err.networkError.result.errors[0].message;
  }

  if (_isGraphQlError(err)) {
    return err.graphQLErrors[0].message;
  }

  if (typeof err.message !== 'undefined') {
    return err.message;
  }

  return err;
};

const getErrorData = (err) => {
  if (!err) {
    return null;
  }

  if (isApiError(err)) {
    return err['error_info'];
  }

  if (_isGraphQlError(err)) {
    return err.graphQLErrors[0].data;
  }

  return null;
};

const getErrorMessage = (err = '') => {
  if (err !== null) {
    const errorData = getErrorData(err);
    const errorMessage = i18n.t(`errors.${getErrorLabel(err)}`, { ...errorData });

    if (typeof errorMessage === 'object') {
      return `${errorMessage.title}.<br>${errorMessage.description}`;
    }

    const noTranslatableString = !errorMessage || errorMessage.includes('errors.');

    if (noTranslatableString) {
      return i18n.t('errors.generic');
    }

    return errorMessage;
  }

  return err ? err : i18n.t('errors.generic');
};

const showError = async (dispatch, err) => {
  if (!err || !dispatch || _isMuted(err)) {
    return null;
  }

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.error('SHOW ERROR: ', err);
  }

  const errorMessage = getErrorMessage(err);

  await dispatch('ui/showToast', {
    toastData: {
      type: 'error',
      message: errorMessage,
    },
  }, { root: true });

  await dispatch('events/track', {
    event: 'ERROR_ALERT_VIEWED',
    variables: {
      message: err && err.graphQLErrors,
    },
  }, { root: true });
};

export {
  isApiError,
  getErrorData,
  getErrorLabel,
  getErrorMessage,
  showError,
};
