export default {
  items: [
    {
      id: '0cf8ea93-0872-4242-8f90-0d7fbb1c4a0c',
      color_glow_hex: '#CC3299',
      cost: '100.0000000000000000',
      img: 'https://img.dibbscdn.com/5f8a3df4-decc-4808-8587-4a80e5480381',
      stock: 10,
      title: 'Pack 1',
      type: 'fracpack',
      value: '5.0000000000000000',
      __typename: 'RewardsShopItem',
    },
    {
      id: '330a0765-1f8f-494e-9a47-61b48277f8f0',
      color_glow_hex: '#ffcc00',
      cost: '2000.0000000000000000',
      img: 'https://img.dibbscdn.com/5f8a3df4-decc-4808-8587-4a80e5480381',
      stock: 20,
      title: 'Pack 2',
      type: 'fracpack',
      value: '10.0000000000000000',
      __typename: 'RewardsShopItem',
    },
    {
      id: 'c81d1842-d621-4dac-bb4e-45bb40aff20d',
      color_glow_hex: '#ff00ea',
      cost: '1.0000000000000000',
      img: 'https://img.dibbscdn.com/5f8a3df4-decc-4808-8587-4a80e5480381',
      stock: 1985,
      title: 'Cheap Pack',
      type: 'fracpack',
      value: '5.0000000000000000',
      __typename: 'RewardsShopItem',
    },
    {
      id: 'c8175842-d621-4dac-bb4e-45bb40aff20d',
      color_glow_hex: '#5555a0',
      cost: '10.0000000000000000',
      img: 'https://img.dibbscdn.com/5f8a3df4-decc-4808-8587-4a80e5480381',
      stock: 1985,
      title: 'Fake Pack',
      type: 'fracpack',
      value: '20.0000000000000000',
      __typename: 'RewardsShopItem',
    },
  ],
  disclaimer:
    'Only available to existing Dibbs Users meeting certain eligibility requirements, including Dibbs Balance purchases, Purchases, and Sales. Frac Packs of the following types are intended to contain Fractional Tokens of the following approximate values: Pack 1 - $5; Pack 2 - $10; Cheap Pack - $5. While Dibbs makes best efforts to match Intended Value, you understand that real-time variations in Item prices may result in your Frac Pack having a value higher or lower than Intended Value. Gems are not transferable or usable outside of the Dibbs Platform. Subject to the Dibbs User Agreement (available at https://dibbsmarketplace.com/user-agreement), including Section 9(n). Capitalized terms not otherwise defined have the meanings given to them in the User Agreement.',
  __typename: 'RewardsShop',
};
