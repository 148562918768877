<template>
  <div class="relative inline-block" v-click-outside="onCloseDropdown">
    <div @click="onToggle">
      <slot name="dropdown-button">
        <ButtonV2
          class="cursor-pointer"
          version="tertiary"
          :testId="'btn__'+selectedDropdown.label"
          :label="selectedDropdown.label"
          :icon="iconProps.icon"
          :iconPosition="iconProps.position"
          :iconRotation="iconProps.rotation"
          size="small"
        />
      </slot>
    </div>

    <div class="dropdown-container mt-s8" :class="dropdownClasses">
      <slot />
    </div>
  </div>
</template>

<script>
import { ButtonV2 } from '@/components/misc';

export default {
  name: 'BaseDropdown',
  props: {
    alignLeft: Boolean,
    hideArrow: Boolean,
    isOpen: Boolean,
    selectedDropdown: {
      type: Object,
      default: () => ({
        label: '',
      }),
    },
  },
  components: {
    ButtonV2,
  },

  methods: {
    onDropdownAction(item) {
      this.$emit('onDropdownAction', item);
    },

    onToggle() {
      this.$emit('onToggle');
    },

    onCloseDropdown() {
      this.$emit('onCloseDropdown');
    },
  },

  computed: {
    dropdownClasses() {
      return {
        'open-dropdown': this.isOpen,
        'right-0': !this.alignLeft,
        'left-0': this.alignLeft,
      };
    },

    iconProps() {
      return {
        icon: !this.hideArrow ? 'caret-down' : this.selectedDropdown.faIcon,
        position: 'right',
        rotation: !this.hideArrow && this.isOpen ? 'rotate-180' : 'rotate-0',
      };
    },
  }
};
</script>

<style scoped>
.dropdown-container {
  @apply absolute opacity-0 invisible;
  z-index: -1;
  transform: translate3d(0, -20px, 0);
  border-radius: 15px;
  box-shadow: 3px 3px 10px #0000009a;
  transition: opacity 200ms ease, transform 200ms ease;
}

.open-dropdown {
  @apply opacity-100 visible z-overlay;
  transform: none;
}
</style>
