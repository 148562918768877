
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserOrderReceipt"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserOrderReceipt"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pair"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"base"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"short_name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"associated_players"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"is_buy"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"submitted_time"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"filled_amount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"total_amount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"quote_amount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"average_price"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"order_status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"base_amount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"transaction_id"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":290}};
    doc.loc.source = {"body":"fragment UserOrderReceipt on UserOrderReceipt {\n  pair {\n    base {\n      name\n      short_name\n      associated_players {\n        name\n      }\n    }\n  }\n  is_buy\n  submitted_time\n  filled_amount\n  total_amount\n  quote_amount\n  average_price\n  order_status\n  base_amount\n  transaction_id\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
