import Big from 'big.js';

import mountProfitLossObject from './mountProfitLossObject';

export default function mountProfitLoss(firstValue, lastValue){
  const profit = lastValue > 0 ? lastValue : firstValue;
  const value = firstValue ? Big(firstValue).minus(profit).toNumber() : 0;
  const percentage = firstValue ? Big(firstValue).times(100).div(profit).minus(100).toNumber() : 0;

  return mountProfitLossObject(value, percentage);
}
