import Big from 'big.js';
import Get from 'lodash.get';

import { setDropStatus } from '@/modules/drops/utils/setDropStatus';
import formatters from '@/mixins/formatters';
import { formatCardladder } from '@/utils/formatters';

export const formatDrop = (item, timeNow) => {
  const cardLadderValue = item.asset.cardladder
    ? formatCardladder(
        item.asset.cardladder.cl_average_price,
        item.asset_value_usd
      )
    : 0;

  const price = formatters.methods.numberFormat(
    item.asset_value_usd,
    2,
    false,
    true
  );

  const amountSold = Big(1).minus(item.supply_left).times(100).round(2);
  const supply = amountSold < 0 ? 0 : amountSold;

  const times = {
    start_time: item.start_time * 1000,
    end_time: item.end_time * 1000,
  };

  const status = setDropStatus(
    {
      supply: Big(supply).toNumber(),
      ...times,
    },
    timeNow
  );

  return {
    ...times,
    id: item.id,
    link: `/drop/${item.id}`,
    name: Get(item, 'asset.name'),
    img_front: Get(item, 'asset.img_front'),
    img_alt: Get(item, 'asset.img_alt'),
    price,
    rawPrice: item.asset_value_usd,
    short_name: Get(item, 'asset.short_name'),
    long_name: Get(item, 'asset.long_name'),
    player: Get(item, 'asset.associated_players[0]'),
    associated_players: Get(item, 'asset.associated_players'),
    cardladder: {
      active: cardLadderValue >= 5,
      value: cardLadderValue,
    },
    supply: Big(supply).toNumber(),
    status: status,
  };
};
