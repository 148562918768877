<template>
  <button
    :type="type"
    class="autocomplete-button body-text-x-medium focus:bg-background-inverted hover:bg-background-inverted bg-opacity-10"
    @click="$emit('click')">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'AutocompletionItem',
  props: {
    type: {
      type: String,
      required: false,
      default: 'button'
    }
  }
};
</script>

<style scoped>
.autocomplete-button {
  @apply py-s12 px-s16 text-text-body text-left cursor-pointer;
  outline: 0;
}
</style>
