import router from '@/vue-router';
import DropQuery from '@/graphql/queries/Drop.gql';

router.addRoute({ 
  component: () => import('@/views/Drop'),
  name: 'drop-v2',
  path: '/drop/:drop_id',
  meta: {
    query: DropQuery,
    template: 5,
    variable: 'drop_id',
  }
});

router.addRoute({
  component: () => import('@/views/Drop'),
  name: 'drop-v2-action',
  path: '/drop/:drop_id/:action',
  meta: {
    query: DropQuery,
    template: 5,
    variable: 'drop_id',
  }
});
