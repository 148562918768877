<template>
  <CentralizedSection class="drop-section">
    <template slot="header">
      <div class="flex justify-center mb-s64 lg:mb-s32">
        <div class="drop-header w-4/5 lg:w-full">
          <h2 :class="titleClasses">
            {{$t('drop_section.title')}}
          </h2>
          <p class="body-text-x-space">
            {{$t('drop_section.description')}}
          </p>
        </div>
      </div>
    </template>

    <div class="max-w-1109 self-center w-full">
      <CarouselV2 :carousel-settings="carouselSettings">
          <Slide  v-for="( chunk, index ) in chunks" :key="index" class="flex flex-row justify-center gap-s32" :data-testid="`drop-${index}`">
              <div :key="index" v-for="( drop, index ) in chunk" class="flex justify-center h-full flex-1">
                <DropsCard
                  :isLoading="isLoading"
                  :data="drop"
                  :background="background"
                  :timeNow="timeNow"
                  show-button
                />
              </div>
          </Slide>
      </CarouselV2>
    </div>

  </CentralizedSection>
</template>
<script>
import { Slide } from 'vue-carousel';

import { CarouselV2, CentralizedSection } from '@/components/misc';
import DropsCard from '@/modules/drops/DropsCard';

export default {
  name: 'DropsSection',
  components: {
    DropsCard,
    CarouselV2,
    CentralizedSection,
    Slide
  },
  props: {
    background: {
      type: String,
      default: 'bg-background-page',
    },
    data: {
      type: Array,
      default: () => ([])
    },
    getCurrentTime: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    carouselSettings(){
      return {
        slideAmount: this.chunks.length,
        perPage: 1,
      };
    },
    chunks(){
      if (this.isLoading) {
        return this.loadingList;
      }

      return this.nested;
    },
    loadingList(){
      if (this.isSmallMobileDevice) {
        return [Array(1).fill({})]; 
      } else if (this.isMobileDevice) {
        return [Array(2).fill({})]; 
      } else {
        return [Array(3).fill({})]; 
      }
    },
    nested(){
      return this.data.reduce((acc, item, index) => { 
        let nestedArray;
        if (this.isSmallMobileDevice) {
         nestedArray = Math.floor(index / 1);
        } else if (this.isMobileDevice) {
         nestedArray = Math.floor(index / 2);
        } else {
         nestedArray = Math.floor(index / 3);
        }

        if(!acc[nestedArray]) {
          acc[nestedArray] = [];
        }

        acc[nestedArray].push({
          ...item,
          backgroundType: index % 3
        });

        return acc;
      }, []);
    },
    timeNow(){
      return this.getCurrentTime;
    },
    titleClasses(){
      return {
        'text-center': true,
        'headline-large': !this.isMobileDevice,
        'headline-small': this.isMobileDevice,
      };
    },
  }
};
</script>
<style scoped>
.drop-header {
  @apply gap-s16 flex flex-col items-center text-justify;
}

.max-w-1109 {
  max-width: 1109px;
}
</style>
