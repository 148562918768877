export { default as ToggleList } from './ToggleList.vue';

export const DEFAULT_TYPES = {
  'trade-preset-values': {
    header_styles: [
      'text-n-xs text-left',
      'text-n-xs text-right',
      'text-n-xs text-right',
    ],
    cols_styles: [
      'font-bold text-n-xl justify-start',
      'font-normal text-n-md justify-end',
      'font-normal text-n-md justify-end',
    ]   
  }
};

export const SAMPLE_DATA = {
  items: [
    ['$ 5.00', '0.30%', '$ 1445.00'],
    ['$ 10.00', '0.60%', '$ 1455.00'],
    ['$ 20.00', '1.20%', '$ 1495.00'],
    ['$ 50.00', '2.50%', '$ 1520.00'],
    ['$ 100.00', '5.00%', '$ 1735.00'],
  ],
  headers: ['Purchase Amount', 'Card Quantity', 'Card Price'],
  type: DEFAULT_TYPES[0],
  headerStyles: [
    'font-bold text-n-2xl text-red-500', 
    'font-bold text-right text-n-2xl text-blue-500', 
    'font-bold text-right text-n-2xl text-orange-500'
  ],
  colStyles: [
    'font-normal text-n-md justify-start text-red-500', 
    'font-normal text-n-md justify-center text-blue-500', 
    'font-normal text-n-md justify-end text-orange-500'
  ],
  showRadio: true
}; 
