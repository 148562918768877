<template>
  <div
    v-if="hasItems"
    class="custom-select"
    :class="{ 'is-open': open }"
    :tabindex="tabindex"
    @blur="onBlur"
    :data-testid="testId"
  >
    <div
      class="selected-item"
      :class="{ 'is-open': open }"
      :style="borderWidth"
      @click="onClickSelected"
      ref="custom-select-item"
    >
      <span :data-testid="`${testId}__selected-item`">
        {{ selected }}
      </span>
      <IconDropdownArrow class="icon" :data-testid="`${testId}__icon`" />
    </div>
    <div
      class="items"
      :class="{ 'is-hidden': !open }"
      :data-testid="`${testId}__items-list`"
    >
      <div class="overflow-container" :style="overflowContainerStyles">
        <div
          class="item is-placeholder"
          :style="borderWidth"
          v-if="placeholder"
          ref="custom-select-placeholder"
          :data-testid="`${testId}__placeholder`"
        >
          {{ placeholder }}
        </div>

        <div
          class="item"
          v-for="(item, i) of items"
          :key="i"
          @click="onClickItem(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconDropdownArrow } from '@/assets/icons';

export default {
  name: 'CustomSelect',

  components: {
    IconDropdownArrow,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    tabindex: {
      type: Number,
      default: 0,
    },

    isOpen: {
      type: Boolean,
      default: false,
    },

    selectedItem: {
      type: String,
    },

    visibleItems: {
      type: Number,
    },

    placeholder: {
      type: String,
      default: ''
    },

    testId: {
      type: String,
      default: 'unamed',
    },
  },

  data() {
    return {
      selected: null,
      open: false,
      itemsContainerHeight: 300,
      itemBorderWidth: 1,
      hasOverflow: this.visibleItems,
    };
  },

  computed: {
    hasItems() {
      return !!this.items.length;
    },

    borderWidth() {
      return { 'border-width': `${this.itemBorderWidth}px` };
    },

    overflowContainerStyles() {
      return {
        'max-height': `${this.itemsContainerHeight}px`,
        'overflow-y': this.hasOverflow ? 'scroll' : 'hidden',
      };
    },
  },

  watch: {
    isOpen(bool) {
      this.open = bool;
    },

    selectedItem(str) {
      const itemExists = this.items.includes(str);
      this.selected = itemExists ? str : this.placeholder;
    },

    visibleItems() {
      this.setItemsContainerMaxHeight();
    },
  },

  methods: {
    onBlur() {
      this.open = false;
    },

    onClickSelected() {
      this.open = !this.open;
    },

    onClickItem(item) {
      this.selected = item;
      this.open = false;

      this.$emit('onSelectItem', item);
    },

    setSelected() {
      if (this.hasItems) {
        if (this.selectedItem) {
          this.selected = this.selectedItem;
          return;
        }

        if (this.placeholder) {
          this.selected = this.placeholder;
          return;
        }

        this.selected = this.items[0];
        return;
      }

      this.selected = null;
    },

    updateParent() {
      const payload = !this.placeholder ? this.selected : '';

      if (payload) {
        this.$emit('onSelectItem', payload);
      }
    },

    setItemsContainerMaxHeight() {
      const itemHeight = this.getRefHeight('custom-select-item');
      const placeholderHeight = this.getPlaceholderHeight();
      const itemsLength = this.visibleItems
        ? this.visibleItems
        : this.items.length;
      const borderHeight = itemsLength * this.itemBorderWidth;

      const itemsWrapperHeight =
        itemsLength * itemHeight + borderHeight + placeholderHeight;

      if (!this.visibleItems) {
        this.hasOverflow = itemsWrapperHeight > this.itemsContainerHeight;
        return;
      }

      this.itemsContainerHeight = itemsWrapperHeight;
    },

    getPlaceholderHeight() {
      if (!this.placeholder) {
        return 0;
      }

      const borderHeigth = this.itemBorderWidth * 1;

      return this.getRefHeight('custom-select-placeholder') + borderHeigth;
    },

    getRefHeight(ref) {
      if (!this.$refs[ref]) {
        return 0;
      }

      const item = this.$refs[ref];
      const itemHeight = item.clientHeight;
      const itemBorder = parseInt(
        item.attributes.style.value.replace(/[^0-9.]/g, '')
      );

      return itemHeight + itemBorder;
    },
  },

  created() {
    this.open = this.isOpen;
  },

  mounted() {
    this.setSelected();
    this.setItemsContainerMaxHeight();
    this.updateParent();
  },
};
</script>

<style scoped>
.custom-select {
  @apply
    flex
    body-text-large
    bg-background-primary
    border-border
    box-border
    cursor-pointer
    font-semibold
    outline-none
    relative
    rounded-16
    text-text-body
    w-full
  ;
}

.custom-select.is-open {
  border: none;
  border-radius: 16px 16px 0px 0px;
}

.selected-item {
  @apply
    flex
    justify-start
    items-center
    border-border
    box-border
    pl-s16
    relative
    rounded-16
    select-none
    text-text-body
    w-full
  ;
  height: 48px;
}

.selected-item.is-open {
  border-radius: 16px 16px 0px 0px;
}

.selected-item:after {
  border: 1px solid transparent;
  content: "";
  height: 0;
  width: 0;
}

.selected-item .icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.1s ease-in;
}

.selected-item .icon path {
  fill: var(--colors-text-body);
}

.selected-item.is-open .icon {
  transform: rotate(180deg);
}

.items {
  @apply
    absolute
    bg-background-primary
    border-border
    left-0
    overflow-hidden
    right-0
    rounded-16
    text-text-body
  ;
  border: 1px solid var(--colors-border-default);
  border-radius: 0 0 16px 16px;
  top: 48px;
  z-index: 21;
  border-top: none;
}

.items .overflow-container {
  @apply overflow-y-scroll;
  max-height: 300px;
}

.items .overflow-container .item {
  @apply 
    border-l-0
    border-r-0
  ;
}

.item {
  @apply
    flex
    justify-start
    items-center
    border-border
    cursor-pointer
    pl-s16
    select-none
    text-text-body
  ;
  transition: background-color 0.2s ease-in-out;
  height: 48px;
}

.item:last-child {
  border-bottom: none;
}

.item.is-placeholder {
  @apply bg-background-page text-text-inactive cursor-default;
  border-top: none;
}

.item:hover {
  background-color: var(--colors-background-page);
}

.is-hidden {
  display: none;
}
</style>
