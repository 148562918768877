<template>
  <div class="container">
    <!-- Lower layer - video -->
    <div data-testid="media-container" role="media" class="lower-layer" data-cy="div_dontdreamit">
      <video :key="src" autoplay muted loop playsinline style="pointer-events: none; border: 0; outline: none;">
        <source :src="src" type="video/mp4">
      </video>
    </div>

    <!-- Upper layer - text/buttons -->
    <div class="upper-layer">
      <div class="text-and-buttons">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesktopVersion',

  computed: {
    src() {
      return this.currentTheme === 'dark' ? 'https://img.dibbscdn.com/homepageanimationdark.mp4' : 'https://img.dibbscdn.com/homepageanimation.mp4';
    }
  }
};
</script>

<style scoped>
  .container {
    @apply relative;
    height: 620px;
    width: 1294px;
    max-width: 100%;
  }

  .lower-layer {
    @apply w-full h-full flex items-center;
  }

  .upper-layer {
    @apply w-full h-full absolute top-0 flex justify-end items-center;
    padding-right: 8%;
  }

  .text-and-buttons {
    @apply w-full flex flex-col;
    width: 490px;
  }
</style>
