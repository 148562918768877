import Vue from 'vue';
import VueMoment from 'vue-moment';
import Big from 'big.js';
import i18n from '@/translations/i18n';

Big.RM = 0;
Vue.use(VueMoment);
const vue = new Vue({ i18n });

export function formatMoney(value = 0, fixed = 2, style = 'currency', currency = 'USD') {
  const amount = Big(value).toFixed(fixed);
  const formatter = new Intl.NumberFormat('en-US', { style, currency });
  return formatter.format(amount);
}

export function formatPercentage(value = 0) {
  const formatter = new Intl.NumberFormat('en-US', { style: 'percent' });
  return formatter.format(value);
}

export function formatDecimals(value = 0) {
  const formatter = new Intl.NumberFormat('en-US', { style: 'decimal' });
  return formatter.format(value);
}

export function formatFraction(value = 0, fixed = 2, signal = false) {
  const amount = Big(value).times(100).toFixed(fixed);

  if (signal && amount > 0) {
    return `+${amount}%`;
  }

  return `${amount}%`;
}

export function formatBig(value = 0) {
  return Big(value).toNumber();
}

export function toKebabCase(str) {
  return str && str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');
}

export function toSnakeCase(str) {
  return str && str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('_');
}

export function capitalizeWord(str) {
  return str && str.replace(/^\w/, s => s.toUpperCase());
}

export function formatCardladder(avgCardladder, assetPrice) {
  const difference = Big(avgCardladder).minus(assetPrice).div(avgCardladder).times(100).toNumber();

  return Big(Big(difference).toFixed(1)).toNumber();
}

export function timeRemaining(startDate, endDate, result = 'days') {
  if (!startDate || !endDate) { return; }
  const start = vue.$moment(startDate);
  const end = vue.$moment(endDate);
  return end.diff(start, result);
}

export function inputToPath(input) {
  if (input.indexOf('https://') === 0 || input.indexOf('http://') === 0) {
    input = new URL(input).pathname;
  }
  const regexp = new RegExp(/^\/cdn-cgi\/image\/(.*?)\/(.*)/g);
  const match = regexp.exec(input);
  if (match) {
    return match[2];
  } else {
    return input.replace(/^\/|\/$/g, '');
  }
}

export function getCdnImage(file, { width, height, format }) {
  if(!file) { return; }
  const w = width ? `,width=${width}` : '';
  const h = height ? `,height=${height}`: '';
  const f = format ? `,format=${format}`: ',format=webp';
  const n = inputToPath(file);
  return `https://img.dibbscdn.com/cdn-cgi/image/${w}${h}${f}/${n}`;
}
