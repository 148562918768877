import Get from 'lodash.get';

import PackState from '@/enums/PackState';
import Theme from '@/enums/Theme';

import PACK_OPEN from '@/graphql/mutations/PackOpen.gql';
import PACK_FINALIZE from '@/graphql/mutations/PackFinalize.gql';

const mutation = {
  SET_PACK_FINALIZE: 'SET_PACK_FINALIZE',
  SET_PACK_VIDEO_URL: 'SET_PACK_VIDEO_URL',
  SET_BYPASS_VIDEO: 'SET_BYPASS_VIDEO',
};

const ROOT = { root: true };

export default {
  namespaced: true,
  
  state: {
    packFinalize: null,
    openedPackVideo: null,
    bypassPackVideo: false,
  },

  actions: {
    async openPack({ dispatch, commit, rootState }, pack) {
      try {
        // Opens a pack
        if (pack.state === PackState.PENDING) {
          const openedPack = await dispatch('api/apolloClient', {
            type: 'mutate',
            options: {
              mutation: PACK_OPEN,
              variables: {
                pack_id: pack.id,
                theme: Theme[rootState.ui.currentTheme],
              },
            }
          }, ROOT);
          const packVideoUrl = Get(openedPack, 'data.pack_open.video', null);
          if (packVideoUrl) {
            commit(mutation.SET_PACK_VIDEO_URL, packVideoUrl);
          } else {
            commit(mutation.SET_BYPASS_VIDEO, true);
          }
        }

        // Finalize a pack
        const packFinalize = await dispatch('api/apolloClient', {
          type: 'mutate',
          options: {
            mutation: PACK_FINALIZE,
            variables: {
              pack_id: pack.id,
            },
          }
        }, ROOT);
        commit(mutation.SET_PACK_FINALIZE, Get(packFinalize, 'data.pack_finalize', {}));

        // Updates the pack state
        await dispatch('asset/updatePackState', {
          packId: pack.id,
          packState: PackState.ROLLED,
        }, ROOT);
      } catch (err) {
        throw err;
      }
    },
    
    resetPack({commit}) {
      commit(mutation.SET_PACK_FINALIZE, null);
      commit(mutation.SET_PACK_VIDEO_URL, null);
      commit(mutation.SET_BYPASS_VIDEO, false);
    },
  },

  mutations: {
    [ mutation.SET_PACK_FINALIZE ] (state, payload) {
      state.packFinalize = payload;
    },
    [ mutation.SET_PACK_VIDEO_URL ](state, url) {
      state.openedPackVideo = url;
    },
    [ mutation.SET_BYPASS_VIDEO ](state, bypassVideo) {
      state.bypassPackVideo = bypassVideo;
    },
  },

};
