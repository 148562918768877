<template>
  <div class="ribbon body-text-x-large xl:hidden" @click="$emit('click')">
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'Ribbon',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
  .ribbon {
    @apply
      absolute
      bg-background-inverted
      text-text-body-inverted
      left-0
      pb-s8
      pt-s16
      px-s24
      rounded-t-2xl
      cursor-pointer;
    transform-origin: left;
    transform: rotate(90deg)translateY(-50%)translateX(-50%);
  }
</style>
