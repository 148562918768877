<template>
  <Card
    v-bind="cardProps"
    :class="cardClasses"
    data-testid="drops-card"
    @click.native="goToInternalPageGlobal(data.link)" 
  >
    <div slot="image">
      <SquareImage v-bind="assetImgProps" />
    </div>

    <div
      class="drop-header"
      slot="header"
    >
      <div class="subheadline-x-medium">
        {{ playerName }}
      </div>

      <div class="body-text-large-normal">
        {{ data.short_name || data.name }}
      </div>
    </div>

    <div class="drop-card__bottom">
      <div class="asset-area">
        <div class="drop-card__side">
          <span>{{ $t('drop.asset_price') }}</span>

          {{ data.price }}
        </div>

        <div :class="['drop-card__side', timeColor]">
          <span>{{ timeRemainingLabel }}</span>

          {{ formatedTimeRemaining }}
        </div>
      </div>

      <div class="percent-area">
        <CardLadderDescription
          v-if="hasCardLadder"
          :value="cardLadderValue"
          :descriptionText="cardLadderDescription"
          class="mb-s16"
        />

        <PercentageBar
          :percentage="percentageSold"
          :text="percentageText"
        />
      </div>

      <ButtonV2
        v-if="showButton"
        :inactive="!isDropLive"
        :label="$tc('buy_now')"
        class="mt-auto"
        testId="btn-buyNow"
        size="medium"
      />
    </div>
  </Card>
</template>

<script>
import { DateTime } from 'luxon';
import DROP_STATUS from '@/enums/DropStatus';
import {
  Card,
  CardLadderDescription,
  SquareImage,
  PercentageBar,
  ButtonV2,
} from '@/components/misc';

export default {
  name: 'DropsCard',

  components: {
    ButtonV2,
    Card,
    CardLadderDescription,
    SquareImage,
    PercentageBar,
  },

  props: {
    ...Card.props,

    background: {
      type: String,
      default: 'bg-background-page',
    },

    data: {
      type: Object,
      required: true,
    },

    showButton: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },

    timeNow: {
      type: Number,
      required: true,
    },
  },

  computed: {
    cardLadderDescription() {
      const modifier = this.small ? '_small' : '';

      return `drops.card_ladder_avg_price${ modifier }`;
    },

    cardLadderValue() {
      return this.lodashGet(this.data, 'cardladder.value', 0);
    },

    hasCardLadder() {
      return this.lodashGet(this.data, 'cardladder.active', false);
    },

    currentTime() {
      return DateTime.fromMillis(this.timeNow || 0);
    },

    startTime() {
      return DateTime.fromMillis(this.data.start_time || 0);
    },

    endTime() {
      return DateTime.fromMillis(this.data.end_time || 0);
    },

    playerName() {
      return this.data?.player?.name;
    },

    dateReadable() {
      return (DateTimeA, DateTimeB) => {
        const duration = DateTimeA.diff(DateTimeB, [
          'days',
          'hours',
          'minutes',
          'seconds',
        ]);

        if (duration.days < 1) {
          return duration.toFormat('hh:mm:ss');
        }

        if (duration.days === 1) {
          return this.$t('drops.a_day');
        }

        return DateTimeA.toRelative({
          base: DateTimeB,
          locale: 'en-US',
        }).replace(/in/g, '');
      };
    },

    endDateReadable() {
      return this.endTime.toFormat('MM/dd');
    },

    timeColor() {
      switch (this.data.status) {
        case DROP_STATUS.LIVE:
          return 'text-text-positive';
        case DROP_STATUS.LIVE_IN:
          return 'text-text-body';
        case DROP_STATUS.SOLD_OUT:
          return 'text-text-negative';
        default:
          return 'text-text-negative';
      }
    },

    timeRemainingLabel() {
      switch (this.data.status) {
        case DROP_STATUS.LIVE:
          return this.$t('drops.ends_in');
        case DROP_STATUS.LIVE_IN:
          return this.$t('drops.live_in');
        case DROP_STATUS.SOLD_OUT:
          return this.$t('asset.drop_status');
        default:
          return this.$t('drops.ended');
      }
    },

    formatedTimeRemaining() {
      switch (this.data.status) {
        case DROP_STATUS.LIVE:
          return this.dateReadable(this.endTime, this.currentTime);
        case DROP_STATUS.LIVE_IN:
          return this.dateReadable(this.startTime, this.currentTime);
        case DROP_STATUS.SOLD_OUT:
          return this.$t('drops.sold_out');
        default:
          return this.endDateReadable;
      }
    },

    percentageSold() {
      return this.data.status === DROP_STATUS.LIVE_IN
        ? 0 
        : this.data.supply;
    },

    percentageText() {
      return this.percentageSold !== 100
        ? `${this.percentageSold}% ${this.$t('drops.sold')}`
        : this.$t('drops.sold_out');
    },

    cardClasses() {
      return {
        'flex flex-col justify-between overflow-hidden w-full': true,
        'drop-card': true,
        'cursor-pointer': true,
        'drop-card__small': this.small,
      };
    },

    cardProps() {
      if (this.$props) {
        const cardPropsExpected = ['background', 'isLoading'];
        const props = Object.entries(this.$props).filter((item) =>
          cardPropsExpected.includes(item[0])
        );
        const propsObject = Object.fromEntries(props);

        propsObject.skeletonLoading = this.skeletonLoadingSize;

        return propsObject;
      }

      return {};
    },

    isDropLive() {
      return this.data.status === DROP_STATUS.LIVE;
    },

    dropBackgroundClasses() {
      return {
        'flex justify-center pointer-events-none relative': true,
        'drop-background': true,
        'drop-background__small': this.small,
        'drop-background__one': this.data.backgroundType === 0,
        'drop-background__two': this.data.backgroundType === 1,
        'drop-background__three': this.data.backgroundType === 2,
      };
    },

    dropSupTitleClasses() {
      return {
        'text-text-inactive-2': !this.isDropLive,
        'text-text-positive': this.isDropLive,
        'body-text-x-large': !this.small,
        'body-text-x-medium': this.small,
      };
    },

    dropValueClasses() {
      return {
        'text-text-positive': true,
        'headline-small': !this.small,
        'subheadline-large': this.small,
      };
    },

    dropNameClasses() {
      return {
        'drop-name leading-xl': true,
        'subheadline-medium': !this.small,
        'subheadline-small': this.small,
      };
    },

    skeletonLoadingSize() {
      const skeletonLoadingSize = {
        height: '653px',
        width: '340px',
      };

      if (!this.showButton && !this.small) {
        skeletonLoadingSize.height = '593px';
      }

      if (!this.showButton && this.small) {
        skeletonLoadingSize.height = '546px';
      }

      if (this.showButton && this.small) {
        skeletonLoadingSize.height = '606px';
      }

      return skeletonLoadingSize;
    },

    assetImgProps() {
      const assetType = `home_drop_card${this.small ? '_small' : ''}`;

      return {
        coverClasses: 'rounded-bl-none rounded-br-none',
        image: this.data.img_front,
        imageResizeProps: {
          alt: this.data.img_alt,
          assetType,
        }
      };
    }
  },
};
</script>

<style scoped>
.drop-card {
  max-width: 340px;
  --z-index-image: 20;
}

.drop-card__small {
  max-width: 285px;
}

.drop-background {
  @apply flex justify-center items-center overflow-hidden;
  height: 385px;
}

.drop-background__small {
  height: 348px;
}

.drop-background__one {
  background: url(~@/assets/img/drops-bg1.webp);
}

.drop-background__two {
  background: url(~@/assets/img/drops-bg2.webp);
}

.drop-background__three {
  background: url(~@/assets/img/drops-bg3.webp);
}

.drop-header {
  @apply flex flex-col gap-s12 p-s16 mt-s12 items-center text-center;
}

.drop-name {
  @apply overflow-hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.percent-area {
  @apply flex flex-col justify-end;
  min-height: 52px;
}

.drop-card__bottom {
  @apply flex flex-col justify-end flex-1 gap-s16 pb-s16 px-s16;
}

.drop-card__bottom .asset-area {
  @apply flex w-full items-center justify-between;
}

.drop-card__side {
  @apply flex flex-col text-n-lg leading-5 font-medium text-center;
}

.drop-card__side span {
  @apply body-text-medium text-text-inactive;
}
</style>
