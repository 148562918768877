import router from '@/vue-router';

router.addRoute({ 
  component: () => import ('@/views/Rewards'),
  name: 'rewards',
  path: '/rewards',
  meta: {
    bypassLoading: true,
    requiresAuth: true,
    template: 1,
  },
});
