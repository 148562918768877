export const PAIRS_TRENDING = 'PAIRS_TRENDING';
export const PAIRS_NEW_ARRIVALS = 'PAIRS_NEW_ARRIVALS';
export const PAIRS_CARDLADDER_DISCOUNTED = 'PAIRS_CARDLADDER_DISCOUNTED';
export const PAIRS_WATCHLIST = 'PAIRS_WATCHLIST';

export const assetSettings = {
  data: [],
  tab: '',
  isLoading: false,
  options: [],
  onTabChange: () => {}
};
