<template>
  <Collapse :customClasses="collapseClasses">
    <div
      class="text-left headline-small"
      slot="title"
    >
      {{ $t('trade.order_book') }}
    </div>

    <div
      class="flex justify-end relative"
      v-click-outside="closeDropdown"
    >
      <Button
        @click.native="toggleDropdown"
        customClasses="px-s8 py-s2"
        icon="IconTriangle"
        iconAfter
        :iconClasses="iconClasses"
        :label="`$${dropdownSelected}`"
        version="v3"
      />
      <div
        v-if="isOpen"
        class="dropdown-content"
      >
        <div
          v-for="(option) in dropdownOptions"
          @click="dropdownSelect(option)"
          :key="`dropdown_option_${option}`"
          :class="dropdownClasses(option)"
        >
          ${{ option }}
        </div>
      </div>
    </div>

    <div class="flex w-full gap-s8 mt-s16 mb-s8">
      <BaseText
        class="w-1/3"
        :size="headerSize"
      >
        {{ $t('trade.price_usd') }}
      </BaseText>
      <BaseText
        class="w-1/3"
        position="text-center"
        :size="headerSize"
      >
        {{ $t('trade.amount') }}
      </BaseText>
      <BaseText
        class="w-1/3"
        position="text-right"
        :size="headerSize"
      >
        {{ $t('trade.total_usd') }}
      </BaseText>
    </div>

    <div
      v-for="(sell, index) in sells"
      :key="`sell-${index}`"
      class="order-list text-text-negative"
    >
      <BaseText
        class="w-1/3"
        :isLoading="isLoading"
      >
        {{ numberFormat(sell.price_rounded) }}
      </BaseText>
      <BaseText
        class="w-1/3"
        :isLoading="isLoading"
        position="text-center"
      >
        {{ numberFormat(sell.amount, 6) }}
      </BaseText>
      <BaseText
        class="w-1/3"
        :isLoading="isLoading"
        position="text-right"
      >
        {{ numberFormat(sell.total) }}
      </BaseText>
    </div>

    <div class="flex w-full my-s14">
      <BaseText
        class="w-1/2"
        position="text-left"
        :size="headerSize"
      >
        {{ $t('trade.last_price') }}
      </BaseText>
      <BaseText
        class="w-1/2 font-monospace"
        :isLoading="isLoading"
        position="text-right"
        :size="headerSize"
      >
        {{ numberFormat(lastPrice, 2, false, true) }}
      </BaseText>
    </div>

    <div
      v-for="(buy, index) in buys"
      :key="`buy-${index}`"
      class="order-list text-text-positive"
    >
      <BaseText
        class="w-1/3"
        :isLoading="isLoading"
      >
        {{ numberFormat(buy.price_rounded) }}
      </BaseText>
      <BaseText
        class="w-1/3"
        :isLoading="isLoading"
        position="text-center"
      >
        {{ numberFormat(buy.amount, 6) }}
      </BaseText>
      <BaseText
        class="w-1/3"
        :isLoading="isLoading"
        position="text-right"
      >
        {{ numberFormat(buy.total) }}
      </BaseText>
    </div>
  </Collapse>
</template>

<script>
import { BaseText, Collapse } from '@/components/misc';
import Button from '@/components/misc/Button';

export default {
  name: 'TradeOrderbookLayout',
  components: {
    BaseText,
    Button,
    Collapse,
  },
  props: {
    buys: Array,
    dropdownSelected: Number,
    dropdownOptions: Array,
    isLoading: Boolean,
    lastPrice: Number,
    sells: Array,
  },

  data() {
    return {
      collapseClasses: {
        button: 'border-b pb-s20',
      },
      headerSize: 'body-text-x-large',
      isOpen: false,
    };
  },

  computed: {
    iconClasses() {
      return `default-transition w-s12 h-s12 ml-s4 text-text-body ${this.isOpen ? 'rotate-180' : ''}`;
    },
  },

  methods: {
    closeDropdown() {
      this.isOpen = false;
    },

    dropdownClasses(option) {
      return `px-s8 py-s4 hover:bg-background-primary hover:text-text-inactive ${this.dropdownSelected === option ? 'font-bold' : 'font-medium'}`;
    },

    dropdownSelect(option) {
      this.$emit('onDropdownSelect', option);
      this.closeDropdown();
    },

    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
  .orderbook {
    @apply font-semibold;
  }

  .dropdown-content {
    @apply absolute mt-8 bg-background-primary border border-border rounded text-sm12 cursor-pointer z-10;
  }

  .order-list {
    @apply flex w-full gap-s8 font-monospace mt-s4;
  }

  .my-s14 {
    margin: 14px 0;
  }
</style>
