<template>
  <div class="flex gap-s12">
    <template v-for="(item, index) in tabList">
      <SecondaryTabsButton 
        :key="index"
        :data-cy="`secondary-tab-button-${index}`"
        :data-testid="`secondary-tab-button-${index}`"
        :active="currentTab === getItemTab(item)"
        @click="$emit('tabChange', item)">
        {{ getItemLabel(item) }}
      </SecondaryTabsButton>
    </template>
  </div>
</template>

<script>
import SecondaryTabsButton from './SecondaryTabsButton';

export default {
  name: 'SecondaryTabs',

  components: {
    SecondaryTabsButton,
  },

  props: {
    currentTab: String,
    tabList: Array,
  },

  methods: {
    getItemLabel(item) {
      return this.lodashGet(item, 'label', item);
    },

    getItemTab(item) {
      return this.lodashGet(item, 'tab', item);
    }
  },
};
</script>
