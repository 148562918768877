export default {
  getPagination(state) {
    return state.pagination;
  },

  getAssets(state) {
    return state.assets;
  },

  getPacks(state) {
    return state.packs;
  },

  getWatchlist(state) {
    return state.watchlist;
  }
};
