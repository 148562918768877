<template>
  <Card v-bind="cardProps" :class="cardClasses">
    <div class="flex flex-row flex-wrap justify-between gap-y-s32">
      <div v-for="content in contentTypes" :key="content.title" class="flex flex-col w-6/12">
        <p class="body-text-x-medium text-text-inactive uppercase">
          {{ content.title }}
        </p>
        <p :class="content.classes">
          {{ content.value }}
        </p>
      </div>
      <div class="flex flex-col w-6/12">
        <p class="body-text-x-medium text-text-inactive uppercase">
          {{ $t('wallet.profit_loss') }}  ({{inventoryTimeframe}})
        </p>
        <p :class="profitLossClasses">
          {{ changeValue }} <span class="body-text-x-large">({{ changePercentage }})</span>
        </p>
      </div>
    </div>

    <div class="flex flex-row gap-s16">
      <ButtonV2
        @onClick="addFunds"
        :label="$t('wallet.add_funds')"
        testId="btn-add-funds"
        :size="!isNanoMobileDevice ? 'medium' : 'small'"
        wide
        data-cy="btn_addfunds"
      />

      <ButtonV2
        @onClick="() => goToInternalPageGlobal('/orders/active')"
        :label="$tc('wallet.view_orders', this.ordersLength, { count: this.ordersLength })"
        testId="btn-orders"
        :size="!isNanoMobileDevice ? 'medium' : 'small'"
        wide
      />
    </div>
  </Card>
</template>

<script>
import { ButtonV2, Card } from '@/components/misc';
import { mapGetters } from 'vuex';

export default {
  name: 'WalletCard',
  components: {
    ButtonV2,
    Card,
  },

  props: {
    ...Card.props,
    background: {
      default: 'bg-background-page',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    skeletonLoading: {
      type: Object,
      default: () => ({
        height: '248px',
        width: '100%',
      }),
    },
    netCost: {
      type: String,
      required: true,
    },
    buyingPower: {
      type: String,
      required: true,
    },
    holdings: {
      type: String,
      required: true,
    },
    ordersLength: {
      type: Number,
      default: 5,
    },
    changeValue: {
      type: String,
      required: true,
    },
    changePercentage: {
      type: String,
      required: true,
    },
    changeType: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('wallet', ['getInventoryChange']),
    cardClasses() {
      return {
        'flex flex-col gap-y-s32': true,
        'wallet-card--desktop p-s24': !this.isMediumMobileDevice,
        'wallet-card--mobile p-s16': this.isMediumMobileDevice,
      };
    },
    cardProps() {
      if (this.$props) {
        const cardPropsExpected = ['background', 'isLoading', 'skeletonLoading'];
        const props = Object.entries(this.$props).filter((item) =>
          cardPropsExpected.includes(item[0])
        );

        return Object.fromEntries(props);
      }
      return {};
    },
    profitLossClasses() {
      return `subheadline-x-medium text-text-${this.changeType}`;
    },
    inventoryTimeframe() {
      return this.$t(`timeframes.${[this.getInventoryChange?.timeframe || '_1W']}.long`) || '';
    },
    contentTypes() {
      return [
        {
          classes: 'subheadline-large',
          title: this.$t('wallet.net_cost'),
          value: this.netCost,
        },
        {
          classes: 'subheadline-large',
          title: this.$t('wallet.buying_power'),
          value: this.buyingPower,
        },
        {
          classes: 'subheadline-large',
          title: this.$t('wallet.holdings'),
          value: this.holdings,
        },
      ];
    },
  },
  methods: {
    addFunds() {
      this.showModal('PaymentInterface', { isDeposit: true });
    },
  },
};
</script>

<style scoped>
.wallet-card--mobile {
  width: 100%;
}
.wallet-card--desktop {
  width: 100%;
  max-width: 405px;
}
</style>
