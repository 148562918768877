<template>
  <div class="flex flex-col items-center w-full">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseLayout',
};
</script>
