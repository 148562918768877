<template>
  <transition name="fade">
    <section v-if="promptOpened" class="prompt-component" :class="promptClasses">
      
      <div @click="closePrompt" class="prompt-overlay"></div>
      
      <div class="prompt-content">
        <div class="prompt-window">
          <div class="prompt-title headline-small md:subheadline-large">
            <h2>{{ promptContent.title }}</h2>
          </div>
          <div class="prompt-message mb-s40 md:mb-s24" v-html="promptContent.message">
          </div>
          <div v-if="tooltipSize > 1" class="flex justify-between subheadline-small">
            <div
              v-if="previousTooltip !== nextTooltip"
              @click="showPreviousTooltip"
              class="flex justify-start items-center cursor-pointer"
            >
              <IconChevronLeft class="h-s14 w-s8 fill-current mr-s8" />
              {{tooltips[previousTooltip].title_short}}
            </div>
            <div
              @click="showNextTooltip"
              class="flex justify-start items-center ml-auto cursor-pointer"
            >
              {{tooltips[nextTooltip].title_short}}
              <IconChevronLeft class="h-s14 w-s8 fill-current rotate-180 ml-s8" />
            </div>
          </div>
          <div v-if="promptContent.type === 'success' || promptContent.type === 'tooltip'" class="prompt-actions">
            <ButtonV2
              :label="$t('navigation.close')"
              testId="btn-close"
              size="medium"
              wide
              @onClick="handleClose"
            />
          </div>
          <div v-else-if="promptContent.type === 'single-action'" class="prompt-actions">
            <ButtonV2
              :label="$t('confirm')"
              version="primary" 
              testId="btn-confirm1"
              size="medium"
              wide
              @onClick="handleSubmit"
            />
          </div>
          <div v-else class="prompt-actions flex-col">
            <ButtonV2
              :label="promptContent.customConfirm ? promptContent.customConfirm : $t('confirm')"
              :version="promptContent.type === 'destructive' ? 'destructive' : 'primary'"
              size="medium"
              testId="btn-confirm"
              @onClick="handleSubmit"
              wide
            />
            <ButtonV2
              class="mt-s12"
              testId="btn-cancel"
              :label="promptContent.customClose ? promptContent.customClose : $t('cancel')"
              version="secondary"
              size="medium"
              @onClick="handleClose"
              wide
            />
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import { IconChevronLeft } from '@/assets/icons';
import { mapState } from 'vuex';

export const DEFAULT_TYPES = {
  destructive: 'destructive',
  single_action: 'single-action',
  success: 'success',
  tooltip: 'tooltip',
};

export default {
  name: 'Prompt',
  components: {
    ButtonV2,
    IconChevronLeft,
  },

  computed: {
    ...mapState('ui', [
      'promptOpened', 
      'promptContent',
      'tooltips',
    ]),

    tooltipSize() {
      if (this.promptContent.type !== DEFAULT_TYPES.tooltip) {
        return;
      }

      return this.tooltips.length;
    },

    nextTooltip() {
      if (this.promptContent.type !== DEFAULT_TYPES.tooltip) {
        return;
      }

      if (this.promptContent.tooltipIndex + 1 >= this.tooltipSize) {
        return 0;
      }

      return this.promptContent.tooltipIndex + 1;
    },

    previousTooltip() {
      if (this.promptContent.type !== DEFAULT_TYPES.tooltip) {
        return;
      }

      if (this.promptContent.tooltipIndex - 1 < 0) {
        return this.tooltipSize - 1;
      }

      return this.promptContent.tooltipIndex - 1;
    },

    promptClasses() {
      const type = this.promptContent ? this.promptContent.type : '';
      const has_type = this.validateType(type);
      return {
        'destructive': has_type && type === DEFAULT_TYPES.destructive,
        'single-action': has_type && type === DEFAULT_TYPES.single_action,
        'success': has_type && type === DEFAULT_TYPES.success,
      };
    },
  },

  methods: {
    handleClose(){
      return this.lodashGet(this.promptContent, 'actions.close', this.closePrompt)();
    },

    handleSubmit(){
      return this.lodashGet(this.promptContent, 'actions.submit', this.submit)();
    },

    closePrompt() {
      if(!this.promptContent.preventClose){
        this.$root.$emit('ui/hidePrompt');
        this.$store.commit('ui/hidePrompt');
      }
    },

    showNextTooltip() {
      this.$store.commit('ui/showPrompt', {
        type: 'tooltip',
        tooltipIndex: this.nextTooltip,
        title: this.tooltips[this.nextTooltip].title,
        message: this.tooltips[this.nextTooltip].body,
      });
    },

    showPreviousTooltip() {
      this.$store.commit('ui/showPrompt', {
        type: 'tooltip',
        tooltipIndex: this.previousTooltip,
        title: this.tooltips[this.previousTooltip].title,
        message: this.tooltips[this.previousTooltip].body,
      });
    },

    async submit() {
      try {
        if (this.promptContent.eventName) {
          this.$root.$emit(this.promptContent.eventName);
        } else if (this.promptContent.action) {
          const payload = this.promptContent.payload || {};
          const request = await this.$store.commit(this.promptContent.action, payload);  
          return {
            result: !!request,
            error: null
          };
        }
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
        return {
          result: null,
          error: err
        };
      } finally {
        this.$store.commit('ui/hidePrompt');
      }
    },

    validateType(type_name) {
      return DEFAULT_TYPES.hasOwnProperty(type_name);
    },
  },
};
</script>

<style>
.prompt-component {
  @apply flex justify-center items-center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 40;
}
.prompt-overlay {
  @apply fixed w-screen h-screen bg-background-page opacity-80;
  z-index: 41;
}
.prompt-content {
  z-index: 42;
}
.prompt-window {
  @apply bg-background-page border border-border rounded-16 py-s40 px-s24;
  width: 480px;
  max-width: 100%;
  box-shadow: 0 0 20px 2px #00000020;
}
.single-action .prompt-window {
  @apply bg-background-page;
  box-shadow: 0 4px 20px #00000030;
}

.dark .prompt-window {
  box-shadow: 0 4px 20px 2px #ffffff20;
}

.prompt-message {
  @apply mt-s24 text-n-md font-normal leading-md;
}
.prompt-message h4 {
  @apply text-n-lg font-bold;
}
.prompt-message p {
  @apply mb-s24;
}
.prompt-actions {
  @apply flex justify-between mt-s24;
}
@media (max-width: 767px) {
   .prompt-window{
     @apply px-s24 py-s24;
     width: 350px;
   }
}


</style>
