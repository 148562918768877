<template>
  <div class="button-container">
    <button :disabled="isLoading || isDisabled" :class="`button flex justify-center relative ${version} ${customClasses}`" @click="handleClick" :type="type">
      <div :class="`flex items-center ${loadingClass} ${iconAfter ? 'flex-row-reverse' : ''}`">
        <component v-if="icon" class="fill-current" :class="iconClasses" :is="icon"></component>
        <font-awesome-icon v-else-if="iconFa" class="fill-current" :class="iconClasses" :icon="iconFa"/>
        <span>{{ label }}</span>
      </div>
      <Loading v-if="isLoading" :smallLoading="smallLoading"/>
    </button>
  </div>
</template>

<script>
import IconArrowDown from '@/assets/icons/arrow_down.svg';
import IconArrowUp from '@/assets/icons/arrow_up.svg';
import IconPlayAlt from '@/assets/icons/play_alt.svg';
import IconShare from '@/assets/icons/share.svg';
import IconTriangle from '@/assets/icons/triangle.svg';
import IconClose from '@/assets/icons/close.svg';
import IconHistory from '@/assets/icons/history.svg';
import { Loading } from '@/components/misc';

export default {
  name: 'Button',
  components: {
    IconArrowDown,
    IconArrowUp,
    IconPlayAlt,
    IconShare,
    IconTriangle,
    IconClose,
    IconHistory,
    Loading,
  },

  props: {
    label: String,
    type: {
      type: String,
      default: 'button',
    },
    version: {
      type: String,
      default: '',
    },
    customClasses: {
      type: String,
      default: '',
    },
    iconClasses: {
      type: String,
      default: '',
    },
    icon: String,
    iconFa: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    smallLoading: {
      type: Boolean,
      default: false,
    },
    iconAfter: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    loadingClass() {
      return this.isLoading ? 'hide-label' : '';
    },
  },

  methods: {
    handleClick() {
      this.$emit('buttonAction');
    }
  }
};
</script>

<style scoped>
  .button-container {
    @apply flex flex-col justify-center;
  }
  .button:hover:not([disabled]) {
    opacity: .85;
  }
  button:disabled {
    cursor: not-allowed;
    opacity: .4;
    filter: grayscale(32%);
  }
  .hide-label {
    opacity: 0;
  }
  .v1 {
    @apply font-medium text-text-body;
  }
  .v2 {
    @apply font-semibold bg-background-primary-button text-text-body-inverted;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 25px;
  }
  .v2-rounded {
    @apply text-white bg-background-primary-button text-text-body-inverted;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 100px;
  }
  .v3 {
    @apply bg-background-primary text-sm12 font-semibold border border-border;
    border-radius: 8px;
  }
  .v4-primary {
    @apply bg-background-positive-text text-text-positive text-xl20 font-semibold;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 15px;
  }
  .v4-primary-small {
    @apply bg-background-positive-text text-text-positive text-sm14 font-semibold;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 10px;
  }
  .v4-secondary {
    @apply bg-background-icon text-text-positive text-xl20 font-semibold;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 15px;
  }
  .v4-secondary-small {
    @apply bg-background-icon text-text-positive text-sm14 font-semibold;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 10px;
  }
  .v4-tertiary {
    @apply bg-background-negative-text text-text-negative text-xl20 font-semibold;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 15px;
  }
  .v4-tertiary-small {
    @apply bg-background-negative-text text-text-negative text-sm14 font-semibold;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 10px;
  }
  .v5 {
    @apply bg-background-primary;
    border-radius: 15px;
  }
  .v6 {
    @apply text-xl20 font-bold bg-background-primary-button text-text-body-inverted rounded-16;
    box-shadow: 0px 3px 10px #00000029;
  }
  .v6-red {
    @apply text-xl20 font-bold text-text-negative bg-background-negative-text rounded-16;
  }
  .v7 {
    @apply text-n-lg font-semibold border border-border bg-background-primary py-2;
    box-shadow: 0px 3px 20px #00000033;
    border-radius: 10px;
  }
  .v8-red {
    @apply rounded-16 text-xl20 font-bold text-text-negative bg-background-negative-text py-4-5;
  }
  .v8-green {
    @apply rounded-16 text-xl20 font-bold text-text-positive bg-background-positive-text py-4-5;
  }
  .v8-purple {
    @apply rounded-16 text-xl20 font-bold text-text-positive border-2 bg-border-active-nav py-4-5;
    border-radius: 15px;
  }
  .v9 {
    @apply font-semibold border border-border py-1;
    border-radius: 10px;
  }
  .v10 {
    @apply bg-background-icon text-text-positive;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 15px;
  }
  .with-box-shadow {
    box-shadow: 0px 3px 10px #00000029;
  }
  .rounded-15 {
    border-radius: 15px;
  }
  .with-border {
    @apply border border-border;
  }
  .rounded {
    border-radius: 100px;
  }
</style>
