<template>
  <div class="w-full">
    <h1 class="shop-item-title subheadline-large">{{ $t('rewards.title') }}</h1>
    <CarouselV2 :carousel-settings="carouselSettings">
      <Slide
        v-for="( chunk, chunkIndex ) in currentShopList"
        :key="chunkIndex"
        :class="slideClasses"
        :data-testid="`chunk-${chunkIndex}`"
      >
        <RewardShopItem
          v-for="(shopItem, index) in chunk"
          data-testid="test-shop-item"
          @onBuyReward="handleBuyReward"
          :key="`chunk-${chunkIndex}-shop-item-${index}`"
          :id="shopItem.id"
          :balance="balance"
          :color="shopItem.color_glow_hex"
          :cost="shopItem.cost"
          :image="shopItem.img"
          :isComponentLoading="isComponentLoading"
          :title="shopItem.title"
          :type="shopItem.type"
        />
      </Slide>
    </CarouselV2>
  </div>
</template>

<script>
import { Slide } from 'vue-carousel';
import { CarouselV2 } from '@/components/misc';
import RewardShopItem from '@/modules/rewards/RewardShopItem';
import shopItems from '@/mocks/shop-items';

export default {
  name: 'RewardShopList',

  components: {
    CarouselV2,
    RewardShopItem,
    Slide,
  },

  props: {
    balance: {
      type: Number,
      required: true,
    },
    isComponentLoading: {
      type: Boolean,
      default: false,
    },
    shopList: {
      type: Array,
      required: true,
    },
  },

  methods: {
    handleBuyReward(shopItem) {
      this.$emit('onBuyReward', shopItem);
    },
  },

  computed: {
    carouselSettings() {
      return {
        slideAmount: this.nestedshopList.length,
        isLoading: this.isComponentLoading,
        perPage: 1
      };
    },

    currentShopList() {
      if (this.isComponentLoading) {
        return this.loadingList;
      }
      return this.nestedshopList; 
    },

    loadingList() {
      const item = shopItems.items[0];

      if (this.isSmallMobileDevice) {
        return [...Array(3).fill([item, item])];
      }
      return [...Array(3).fill([item, item, item, item])];
    },

    nestedshopList() {
      return this.shopList.reduce((acc, item, index) => {
        let nestedArray;
        if (this.isSmallMobileDevice) {
         nestedArray = Math.floor(index / 2);
        } else {
         nestedArray = Math.floor(index / 4);
        }

        if(!acc[nestedArray]) {
          acc[nestedArray] = [];
        }

        acc[nestedArray].push(item);

        return acc;
      }, []);
    },

    slideClasses() {
      const lessItems = this.currentShopList[0].length <= 4;
      return {
        'shop-list-slide gap-s20 mt-s24 start-md:gap-s24': true,
        'flex justify-start': lessItems
      };
    },
  }
};
</script>
