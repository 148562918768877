<template>
    <div class="image-browser__inner">
      <!-- Close button -->
      <div class="image-browser__top-controls">
        <div
          class="image-browser__button close"
          data-testid="image-browser-close"
          @click="$emit('close')"
        >
          <font-awesome-icon class="icon" icon="times"/>
        </div>
      </div>

      <!-- Carousel area -->
      <div class="image-browser__carousel overflow-hidden">

        <!-- Chevron buttons -->
        <div class="image-browser__mid-controls left" data-testid="image-browser-mid-controls">
          <div
            class="image-browser__button chevron"
            :class="{ hide: currentImageIndex === 0 }"
            @click="selectImage(currentImageIndex - 1)"
          >
            <font-awesome-icon class="icon" icon="chevron-left"/>
          </div>
        </div>
        <div class="image-browser__mid-controls right">
          <div
            class="image-browser__button chevron"
            :class="{ hide: currentImageIndex === slideAmount - 1 }"
            @click="selectImage(currentImageIndex + 1)"
          >
            <font-awesome-icon class="icon" icon="chevron-right"/>
          </div>
        </div>

        <Carousel
          key="image-browser-carousel"
          v-model="currentImageIndex"
          v-bind="settings"
        >
          <Slide
            v-for="(image, index) in imageList"
            :key="`image-browser-slide-${index}`"
            class="flex justify-center items-center"
            data-testid="image-browser-slide"
          >
            <ImageResize
              :alt="`Image browser slide ${index}`"
              :src="image"
              :width="1200"
              :height="1200"
              class="max-w-full max-h-full"
              bypass-img-tag-size-attributes
              start-hidden
            />
          </Slide>
        </Carousel>
      </div>

      <!-- Image list -->
      <div class="image-browser__list">
        <div
          @click="selectImage(index)"
          v-for="(image, index) in imageList"
          :key="`image-browser-list-thumb-${index}`"
          class="flex-shrink-0"
          :class="{ 'opacity-50': currentImageIndex !== index }"
          data-testid="image-browser-thumbnail"
        >
          <ImageResize
            :alt="`Image browser list thumbnail ${index}`"
            :src="image"
            :width="120"
            :height="120"
            class="max-w-full max-h-full mr-s8 border-2 border-transparent"
            :class="{
              'cursor-pointer': currentImageIndex !== index,
              'border-border-active-nav': currentImageIndex === index,
            }"
            bypass-img-tag-size-attributes
            start-hidden
          />
        </div>
      </div>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import { ImageResize } from '@/components/misc/ImageResize';

export default {
  name: 'DesktopVersion',
  components: {
    Carousel,
    ImageResize,
    Slide,
  },

  data() {
    return {
      currentImageIndex: 0,
    };
  },

  props: {
    imageList: {
      type: Array,
      required: true,
    },
  },

  computed: {
    settings() {
      return {
        navigateTo: this.currentImageIndex,
        paginationEnabled: false,
        slideAmount: this.slideAmount,
        perPage: 1,
      };
    },

    slideAmount() {
      return this.imageList.length;
    }
  },

  methods: {
    selectImage(index) {
      if (index < 0) {
        index = 0;
      }

      if (index >= this.slideAmount) {
        index = this.slideAmount - 1;
      }

      this.currentImageIndex = index;
    }
  },

  mounted() {
    document.documentElement.style.overflow = 'hidden';
  },

  beforeDestroy() {
    document.documentElement.style.overflow = 'unset';
  },
};
</script>

<style scoped>
  .image-browser__inner {
    @apply w-full h-full relative flex flex-col items-center justify-between;
  }

  .image-browser__top-controls {
    @apply mt-s40 mb-s12 w-full px-s16 flex justify-end;
    height: 40px;
    max-width: var(--navbar-width);
  }

  .image-browser__button {
    @apply rounded-full bg-border flex items-center justify-center cursor-pointer;
  }

  .image-browser__button .icon {
    width: 20px;
    height: 20px;
  }

  .image-browser__button.close {
    width: 40px;
    height: 40px;
  }

  .image-browser__button.chevron .icon {
    width: 16px;
    height: 16px;
  }

  .image-browser__button.chevron {
    width: 32px;
    height: 32px;
  }

  .image-browser__carousel {
    @apply relative h-full w-full flex object-contain;
    max-width: var(--navbar-width);
  }

  .image-browser__mid-controls {
    @apply absolute;
    z-index: calc(theme('zIndex.component-overlay') + 1);
    top: calc(50% - 32px);
  }

  .image-browser__mid-controls.left {
    @apply absolute;
    z-index: calc(theme('zIndex.component-overlay') + 1);
    left: 10%;
    top: calc(50% - 32px);
  }

  .image-browser__mid-controls.right {
    @apply absolute;
    z-index: calc(theme('zIndex.component-overlay') + 1);
    right: 10%;
    top: calc(50% - 32px);
  }

  .image-browser__list {
    @apply mt-s32 mb-s24 px-s40 py-s8 flex flex-shrink-0 overflow-scroll;
    min-height: 144px;
  }

  .image-browser__list::-webkit-scrollbar {
    height: 4px;
    width: 0;
  }
</style>

<style>
  .VueCarousel {
    width: 100%;
    height: 100%;
  }
  .VueCarousel-inner {
    height: 100% !important;
  }
</style>
