
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserKycState"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserKycState"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"account_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"current_level_value"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"required_actions_to_reach_this_level"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"pending_actions_to_reach_this_level"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"next_level_value"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"required_actions_to_reach_this_level"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"pending_actions_to_reach_this_level"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"kyc_level_status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"contact_customer_support_reason"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"reject_message"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":397}};
    doc.loc.source = {"body":"fragment UserKycState on UserKycState {\n  id\n  account_id\n  current_level_value {\n    value\n    description\n    required_actions_to_reach_this_level\n    pending_actions_to_reach_this_level\n  }\n  next_level_value {\n    value\n    description\n    required_actions_to_reach_this_level\n    pending_actions_to_reach_this_level\n  }\n  kyc_level_status\n  contact_customer_support_reason\n  reject_message\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
