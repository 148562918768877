<template>
  <CentralizedSection class="wallet-section">
    <div class="flex flex-col not-mobile:flex-row not-mobile:gap-s48 lg:gap-y-s24">
      <WalletChart class="w-full" v-bind="chartSettings" @changeTab="(value) => $emit('changeTab', value)" />
      <WalletCard v-bind="cardSettings" :background="background" />
    </div>
  </CentralizedSection>
</template>

<script>
import { CentralizedSection } from '@/components/misc';
import { WalletCard, WalletChart } from '@/modules/walletv2';

export default {
  name: 'WalletSection',
  components: {
    CentralizedSection,
    WalletCard,
    WalletChart,
  },
  props: {
    background: {
      type: String,
      default: 'bg-background-page',
    },
    chartSettings: Object,
    cardSettings: Object,
  }
};
</script>

<style scoped></style>
