<template>
  <DropsInfoLayout
    @onShareEvent="$emit('onShareEvent')"
    @onToggleFavorite="$emit('onToggleFavorite')"
    :assetDetailContent="assetDetailContent"
    :detailedDescription="detailedDescription"
    :dropName="dropName"
    :isLoading="isLoading"
    :isOnFavoritesList="isOnFavoritesList"
    :percentageSold="percentageSold"
    :percentageText="percentageText"
    :playerName="playerName"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';

import DropsInfoLayout from './layout';

import { formatDrop } from '@/modules/drops/utils/formatDrop';
import { formatMoney } from '@/utils/formatters';
import DROP_STATUS from '@/enums/DropStatus';

export default {
  name: 'DropsInfo',
  props: {
    drop: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isOnFavoritesList: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    DropsInfoLayout,
  },
  computed: {
    ...mapGetters('drops', ['getCurrentTime']),

    assetDetailContent() {
      return [
        {
          class: 'asset-price-font',
          label: this.$t('drop.asset_price'),
          value: formatMoney(this.drop.asset_value_usd),
        },
        {
          class: ['time-remaining-font', this.timeColor],
          label: this.timeRemainingLabel,
          value: this.formatedTimeRemaining,
        },
        {
          class: 'usd-remaining-font',
          label: this.$t('asset.usd_remaining'),
          value: this.formatedUsdRemaining,
        },
      ];
    },

    currentDrop() {
      return formatDrop(this.drop, this.currentTime);
    },

    currentTime() {
      return DateTime.fromMillis(this.getCurrentTime || 0);
    },

    dateReadable() {
      return (DateTimeA, DateTimeB) => {
        const duration = DateTimeA.diff(DateTimeB, [
          'days',
          'hours',
          'minutes',
          'seconds',
        ]);

        if (duration.days < 1) {
          return duration.toFormat('hh:mm:ss');
        }

        if (duration.days === 1) {
          return this.$t('drops.a_day');
        }

        return DateTimeA.toRelative({
          base: DateTimeB,
          locale: 'en-US',
        }).replace(/in/g, '');
      };
    },

    detailedDescription() {
      return this.lodashGet(this.drop, 'asset.detailed_description', '');
    },

    playerName() {
      return this.lodashGet(this.drop, 'asset.associated_players[0].name', '');
    },

    dropName() {
      return `${this.drop?.asset?.long_name || this.drop?.asset?.short_name || this.drop?.asset?.name} | DIBBS`;
    },

    endDateReadable() {
      return this.endTime.toFormat('MM/dd');
    },

    endTime() {
      return DateTime.fromMillis(this.drop.end_time * 1000 || 0);
    },

    formatedTimeRemaining() {
      switch (this.currentDrop.status) {
        case DROP_STATUS.LIVE:
          return this.dateReadable(this.endTime, this.currentTime);
        case DROP_STATUS.LIVE_IN:
          return this.dateReadable(this.startTime, this.currentTime);
        case DROP_STATUS.SOLD_OUT:
          return this.$t('drops.sold_out');
        default:
          return this.endDateReadable;
      }
    },

    formatedUsdRemaining() {
      return formatMoney(this.usdRemaining);
    },

    maxBuy() {
      const maxBuy = this.drop['max_current_user_can_spend'];
      if (maxBuy > this.usdRemaining) {
        return this.usdRemaining;
      }
      return maxBuy;
    },

    percentageSold() {
      return this.currentDrop.status === DROP_STATUS.LIVE_IN ? 0 : this.currentDrop.supply;
    },

    percentageText() {
      return this.percentageSold !== 100
        ? `${this.percentageSold}% ${this.$t('drops.sold')}`
        : this.$t('drops.sold_out');
    },

    startTime() {
      return DateTime.fromMillis(this.drop.start_time * 1000 || 0);
    },

    timeColor() {
      switch (this.currentDrop.status) {
        case DROP_STATUS.LIVE:
          return 'text-text-positive';
        case DROP_STATUS.LIVE_IN:
          return 'text-text-body';
        case DROP_STATUS.SOLD_OUT:
          return 'text-text-negative';
        default:
          return 'text-text-negative';
      }
    },

    timeRemainingLabel() {
      switch (this.currentDrop.status) {
        case DROP_STATUS.LIVE:
          return this.$t('asset.time_remaining');
        case DROP_STATUS.LIVE_IN:
          return this.$t('asset.drop_starts');
        case DROP_STATUS.SOLD_OUT:
          return this.$t('asset.drop_status');
        default:
          return this.$t('drops.ended');
      }
    },

    usdRemaining() {
      if (!this.drop.asset_value_usd && !this.drop.supply_left) {
        return;
      }
      return this.$big(this.drop.asset_value_usd).times(this.drop.supply_left);
    },
  },
};
</script>

<style scoped>
  .grid-container {
    @apply w-full grid grid-cols-3 gap-s24 items-center mt-s24;
  }

  .asset-price-font {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
  }
  .time-remaining-font {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }
  .usd-remaining-font {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
  }

  @screen md {
    .grid-container {
      @apply gap-s8 text-center;
      grid-template-columns: auto auto auto;
    }

    .asset-price-font {
      font-size: 22px;
      line-height: 30px;
      font-weight: 500;
    }
    .time-remaining-font {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
    }
    .usd-remaining-font {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
    }
  }
</style>
