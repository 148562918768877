export const emitRedditEvent = (eventName, variables) => {
  if (window.rdt) {
    try {
      if (variables) {
        window.rdt('track', eventName, variables);
      } else {
        window.rdt('track', eventName);
      }

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(`[Reddit ${eventName}] event emitted ${variables ? `with variables: ${JSON.stringify(variables)}` : ''}`);
      }
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(err.message);
      }
    }
  }
};
