<template>
  <button
    class="secondary-tabs-button"
    :class="classes"
    role="button"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'SecondaryTabsButton',
  props: {
    active: Boolean,
  },
  computed: {
    classes(){
      return this.active 
        ? '--active bg-background-inverted text-text-body-inverted'
        : 'buttontext-text-body border-background-inverted';
    }
  }
};
</script>

<style scoped>
.secondary-tabs-button {
  @apply flex items-center justify-center body-text-large p-s8 cursor-pointer border rounded-24 outline-none;
  height: 24px;
  min-width: 40px;
  color: rgb(var(--colors-text-inactive));
  border-color: rgb(var(--colors-text-inactive));
}
.secondary-tabs-button.--active {
  color: var(--colors-text-body-inverted);
}
</style>
