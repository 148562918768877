<template>
  <BaseDropdown
    :alignLeft="alignLeft"
    :selectedDropdown="selectedDropdown"
    :hideArrow="hideArrow"
    :isOpen="isOpen"
    @onCloseDropdown="onCloseDropdown"
    @onToggle="onToggle"
  >
    <slot name="dropdown-button" slot="dropdown-button"></slot>
    <div class="dropdown-container" :class="[dropdownListClasses, customClasses]">
      <DropdownItem
        @click.native="onCloseDropdown"
        @onDropdownAction="onDropdownAction"
        v-for="(item, index) in dropdownItems"
        v-bind:key="`item.label-${index}`"
        :dropdownItem="item"
        :selectedItem="selectedDropdown"
      />
      <slot name="footer" />
    </div>
  </BaseDropdown>
</template>

<script>
import BaseDropdown from '@/components/dropdown/BaseDropdown';
import DropdownItem from '@/components/dropdown/DropdownItem';

export default {
  name: 'DropdownList',
  props: {
    alignLeft: Boolean,
    customClasses: String,
    dropdownItems: Array,
    selectedDropdown: Object,
    hideArrow: Boolean,
  },
  components: {
    BaseDropdown,
    DropdownItem,
  },

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    onToggle() {
      this.isOpen = !this.isOpen;
    },

    onDropdownAction(payload) {
      this.$emit('onDropdownAction', payload);
    },

    onCloseDropdown() {
      this.isOpen = false;
    },
  },

  computed: {
    dropdownListClasses() {
      return {
        'open-dropdown': this.isOpen,
        'right-0': !this.alignLeft,
        'left-0': this.alignLeft,
      };
    },
  },
};
</script>

<style scoped>
.dropdown-container {
  @apply absolute px-s24 py-s12 bg-background-primary border border-border opacity-0 invisible;
  z-index: -1;
  min-width: 143px;
  transform: translate3d(0, -20px, 0);
  border-radius: 15px;
  box-shadow: 3px 3px 10px #0000009a;
  transition: opacity 200ms ease, transform 200ms ease;
}

.navbar-dropdown {
  top: 12px;
}

.open-dropdown {
  @apply 
    opacity-100 
    visible
    z-overlay
  ;
  transform: none;
}
</style>
