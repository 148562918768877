<template>
  <!-- Desktop version -->
  <div v-if="desktopVersion">
    <!-- Header -->
    <div class="table-header justify-between">
      <div class="w-1/12">{{ $t('asset.label') }}</div>
      <div class="w-7/12">{{ $t('asset.name') }}</div>
      <div class="w-2/12">{{ $t('market.total_value') }}</div>
      <div class="w-1/12 whitespace-nowrap">{{ $t('market.change_24h') }}</div>
      <div class="w-1/12"></div>
    </div>

    <!-- Content -->
    <div v-for="(n, index) in filteredData" v-bind:key="index" class="table-row py-n-3xl px-n-4xl text-n-md">
      <div class="w-1/12">
        <router-link
          role="link"
          class="flex justify-between w-11/12 items-center"
          :to="`/trade/${getPairData(filteredData[index]).base.ticker}-${getPairData(filteredData[index]).quote.ticker}`"
        >
          <ImageResize class="watchlist-asset-image mx-n-md" :src="getPairData(filteredData[index]).img" :alt="getPairData(filteredData[index]).base.name" type="market_list_asset" />
        </router-link>
      </div>

      <div class="w-7/12 watchlist-asset-name">
        <router-link
          role="link"
          class="flex justify-between w-11/12 items-center"
          :to="`/trade/${getPairData(filteredData[index]).base.ticker}-${getPairData(filteredData[index]).quote.ticker}`"
        >
          {{ getPairData(filteredData[index]).base.name }}
        </router-link>
      </div>

      <div class="w-2/12">
        {{ numberFormat(getPairData(filteredData[index]).price_per_token, 2, false, true) }}
      </div>

      <div class="w-1/12 flex">
        <div
            class="whitespace-nowrap text-n-sm px-n-md py-n-xs rounded-8"
            :class="getPairData(filteredData[index]).price_change_24h_pct >= 0 ? 'bg-background-positive-text text-text-positive' : 'bg-background-negative-text text-text-negative'"
        >
          {{ numberFormat(getPairData(filteredData[index]).price_change_24h_pct) }}%
        </div>
      </div>

      <div class="w-1/12 text-right">
        <span @click="removeFromWatchlist(getPairData(filteredData[index]).base.id)">
          <font-awesome-icon class="watchlist-icon" role="icon" :icon="['fas', 'star']"/>
        </span>
      </div>
    </div>
  </div>

  <!-- Mobile version -->
  <div v-else class="pt-n-2xl">
    <div v-for="(n, index) in filteredData" v-bind:key="index" class="watchlist-mobile-row">
      <router-link
        role="link"
        class="flex justify-between w-11/12 items-center"
        :to="`/trade/${getPairData(filteredData[index]).base.ticker}-${getPairData(filteredData[index]).quote.ticker}`"
      >
        <div class="w-1/12">
          <ImageResize class="watchlist-asset-image" :src="getPairData(filteredData[index]).img" :alt="getPairData(filteredData[index]).base.name" type="market_list_asset" />
        </div>

        <div class="w-7/12 watchlist-asset-name">
          {{ getPairData(filteredData[index]).base.name }}
        </div>

        <div class="w-2/12 flex flex-col items-center">
          <div>
            {{ numberFormat(getPairData(filteredData[index]).price_per_token, 2, false, true) }}
          </div>
          <div
              class="whitespace-nowrap text-n-sm px-n-md py-n-xs rounded-8 mt-n-xs"
              :class="getPairData(filteredData[index]).price_change_24h_pct >= 0 ? 'bg-background-positive-text text-text-positive' : 'bg-background-negative-text text-text-negative'"
          >
            {{ numberFormat(getPairData(filteredData[index]).price_change_24h_pct) }}%
          </div>
        </div>
      </router-link>

      <div class="w-1/12 text-right ml-n-sm">
        <span @click="removeFromWatchlist(getPairData(filteredData[index]).base.id)">
          <font-awesome-icon class="watchlist-icon" role="icon" :icon="['fas', 'star']"/>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ImageResize } from '@/components/misc';

export default {
  name: 'WalletWatchlist',
  components: {
    ImageResize,
  },

  computed: {
    filteredData() {
      // zero-delay removal
      const filteredData = this.data;
      return filteredData.filter(asset => {
        return !this.removedIds.includes(this.getPairData(asset).base.id);
      });
    }
  },

  data() {
    return {
      removedIds: []
    };
  },

  methods: {
    getPairData(rawData) {
      return this.lodashGet(rawData.base, 'pairs[0]', {});
    },

    removeFromWatchlist(id) {
      this.removedIds.push(id);
      this.$emit('removeFromWatchlist', { id });
    }
  },

  props: {
    data: Array,
    desktopVersion: {
      type: Boolean,
      default: true,
    }
  },
};
</script>

<style scoped>
  .watchlist-asset-image {
    min-width: 35px;
    width: 35px;
    height: 60px;
    object-fit: contain;
  }

  .watchlist-asset-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .watchlist-mobile-row {
    @apply
      flex
      justify-between
      p-n-xl
      text-left
      items-center
      font-semibold
      text-n-sm
      bg-background-primary
      border
      border-border
      rounded-16
      mb-n-md;
  }

  .watchlist-icon {
    @apply cursor-pointer text-text-active-2;
    width: 18px;
    height: 18px;
  }
</style>
