export const emitTiktokEvent = async (eventName, variables) => {
  if (window.ttq) {
    try {
      if (variables) {
        window.ttq.track(eventName, variables);
      } else {
        window.ttq.track(eventName);
      }

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(`[Tiktok ${eventName}] event emitted ${variables ? `with variables: ${JSON.stringify(variables)}` : ''}`);
      }
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(err.message);
      }
    }
  }
};

export const startTiktok = () => {
  if (window.ttq) {
    try {
      window.ttq.load(process.env.VUE_APP_TIKTOK_CLIENT_ID);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err.message);
    }
  }
};
