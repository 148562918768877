<template>
  <div>
    <div class="flex flex-1 flex-row justify-between items-center w-full">
      <BaseText
        class="w-full md:text-center"
        tag="h1"
        testId="title-asset-player"
        size="headline-large"
        loadingHeight="36px"
        loadingWidth="95%"
        :isLoading="isLoading"
      >
        {{ details.name }}
      </BaseText>
      <div class="flex items-center lg:hidden">
        <SkeletonLoading
          v-if="isLoading"
          width="20px"
          height="20px"
          borderRadius="24px"
        />
        <Share
          v-else
          @amplitudeEvent="$emit('onShareEvent')"
          :shareMessage="$t('share.check_out', { name: details.name })"
        />
        <SkeletonLoading
          v-if="isLoading"
          class="ml-s16"
          width="20px"
          height="20px"
          borderRadius="24px"
        />
        <IconButton
          v-else
          @onClick="$emit('onToggleFavorite')"
          class="h-s20 w-s20 ml-s16"
          data-cy="favorite-icon"
          data-testid="favorite-icon"
          icon="star"
          :iconStyle="isOnFavoritesList ? 'fas' : 'far'"
          variation="plain"
        />
      </div>
    </div>

    <div class="pt-s12">
      <BaseText
        class="md:text-center md:w-full"
        tag="h3"
        testId="title-asset-player"
        loadingHeight="22px"
        size="subheadline-medium"
        :isLoading="isLoading"
        style="font-weight: 400"
      >
        {{ details.assetName }}
      </BaseText>
    </div>

    <div class="flex flex-row pt-s16 md:justify-center">
      <div class="asset-price">
        <BaseText
          color="text-text-inactive"
          :isLoading="isLoading"
          loadingHeight="24px"
          loadingWidth="76px"
          size="body-text-x-space"
          tag="label"
        >
          {{ $t('asset_detail.asset_price') }}
        </BaseText>
        <BaseText
          class="w-full"
          :isLoading="isLoading"
          loadingHeight="24px"
          size="subheadline-x-medium"
        >
          {{ details.assetPrice }}
        </BaseText>
      </div>
      <div class="flex flex-col ml-s16">
        <BaseText
          color="text-text-inactive"
          :isLoading="isLoading"
          loadingHeight="24px"
          loadingWidth="90px"
          size="body-text-x-space"
          tag="label"
        >
          {{ timeframeLabel }}
        </BaseText>
        <div>
          <BaseText 
            :color="priceChangeColor"
            :isLoading="isLoading"
            loadingHeight="24px"
            loadingWidth="230px"
            size="subheadline-x-medium"
          >
            {{ details.assetChange }}
          </BaseText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseText, IconButton, Share } from '@/components/misc';
import mountProfitLossObject from '@/modules/walletv2/utils/mountProfitLossObject';

export default {
  name: 'AssetInfo',
  props: {
    asset: Object,
    isLoading: Boolean,
    isOnFavoritesList: Boolean,
  },
  components: {
    BaseText,
    IconButton,
    Share,
  },
  computed: {
    details() {
      return {
        name: this.lodashGet(this.asset, 'base.associated_players[0].name', ''),
        assetName: `${this.asset?.base?.long_name || this.asset?.base?.short_name || this.asset?.base?.name} | DIBBS`,
        assetPrice: this.numberFormat(this.lodashGet(this.asset, 'price_per_token', 0), 2, false, true),
        assetChange: `${this.numberFormat(this.profitLoss.value, 2, true, true)} (${this.numberFormat(this.profitLoss.percentage, 2, true)}%)`,
      };
    },

    priceChange() {
      return this.lodashGet(this.asset, 'price_change.change_amount', 0);
    },

    priceChangeColor() {
      return `text-text-${this.profitLoss.type}`;
    },

    priceChangePct() {
      return this.lodashGet(this.asset, 'price_change.change_pct', 0);
    },

    profitLoss() {
      return mountProfitLossObject(this.priceChange, this.priceChangePct);
    },

    timeframe() {
      return this.lodashGet(this.asset, 'price_change.timeframe', '_1D');
    },

    timeframeLabel() {
      return this.$t(`timeframes.${[this.timeframe || '_1W']}.change`);
    },
  },
};
</script>

<style scoped>
  .asset-price {
    @apply flex flex-col;
    width: 175px;
  }
</style>
