<template>
  <Collapse
    v-if="orders.length"
    :customClasses="collapseClasses"
  >
    <div
      class="text-left headline-small"
      slot="title"
    >
      {{ $t('trade.recent_orders') }}
    </div>

    <div class="w-full flex mt-s40 mb-s8 body-text-x-large lg:mt-s20">
      <div class="w-1/3">
        {{ $t('trade.price_usd') }}
      </div>
      <div class="w-1/3 text-center">
        {{ $t('trade.amount') }}
      </div>
      <div class="w-1/3 text-right">
        {{ $t('trade.total_usd') }}
      </div>
    </div>

    <div
      v-for="(trade, i) in orders"
      :key="i"
      :class="['order-list', orderColor(trade.type)]"
    >
      <div class="w-1/3">
        {{ numberFormat(trade.price) }}
      </div>
      <div class="w-1/3 text-center">
        {{ numberFormat(trade.amount, 6) }}
      </div>
      <div class="w-1/3 text-right">
        {{ numberFormat($big(trade.price).times(trade.amount)) }}
      </div>
    </div>
  </Collapse>
</template>

<script>
import Collapse from '@/components/misc/Collapse/Collapse';

export default {
  name: 'TradeRecentOrders',
  components: {
    Collapse
  },
  props: {
    orders: Array,
  },
  data() {
    return {
      collapseClasses: {
        button: 'border-b pb-s20',
      },
    };
  },
  methods: {
    orderColor(type) {
      return {
        'text-text-positive': (type === 'orderbuy' || type === 'ammbuy'),
        'text-text-negative': (type === 'ordersell' || type === 'ammsell'),
      };
    },
  },
};
</script>

<style scoped>
  .order-list {
    @apply flex flex-row w-full font-monospace body-text-medium mt-s4;
  }
</style>
