
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FracpackDefinition"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FracpackDefinition"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"id_hr"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"image"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"color_glow_hex"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cards_per_pack"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fraction_per_card"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"initial_supply"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"remaining_supply"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"price"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sale_start_time"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sale_end_time"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"possible_assets"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FracpackDefinitionAsset"},"directives":[]}]}}]}}],"loc":{"start":0,"end":328}};
    doc.loc.source = {"body":"#import \"@/graphql/fragments/FracpackDefinitionAsset.gql\"\n\nfragment FracpackDefinition on FracpackDefinition {\n  id\n  id_hr\n  name\n  image\n  color_glow_hex\n  cards_per_pack\n  fraction_per_card\n  initial_supply\n  remaining_supply\n  price\n  sale_start_time\n  sale_end_time\n  possible_assets {\n    ...FracpackDefinitionAsset\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/FracpackDefinitionAsset.gql").definitions));


      module.exports = doc;
    
