import cloneDeep from 'lodash.clonedeep';
import Get from 'lodash.get';
import Big from 'big.js';

Big.RM = 0;

import ACKNOWLEDGE_USER_REWARD_NOTIFICATION from '@/graphql/mutations/AcknowledgeUserRewardNotification.gql';
import DAILY_REWARD_CLAIM from '@/graphql/mutations/DailyRewardClaim.gql';
import GET_REWARDS_ACTION_CLAIMS from '@/graphql/queries/GetRewardsActionClaims.gql';
import GET_REWARDS_HISTORY from '@/graphql/queries/GetRewardsHistory.gql';
import GET_REWARDS_PENDING_NOTIFICATIONS from '@/graphql/queries/GetRewardsPendingNotifications.gql';
import GET_REWARDS_SHOP_ITEMS from '@/graphql/queries/GetRewardsShopItems.gql';
import REWARDS_SHOP_BUY from '@/graphql/mutations/RewardsShopBuy.gql';
import REWARDS_STATE from '@/graphql/queries/RewardsState.gql';
import RewardState from '@/enums/RewardState';

const SET_ACTION_CLAIMS = 'SET_ACTION_CLAIMS';
const SET_BALANCE = 'SET_BALANCE';
const SET_CURRENT_CLAIM_DAY = 'SET_CURRENT_CLAIM_DAY';
const SET_DAILY_CLAIMS = 'SET_DAILY_CLAIMS';
const SET_DISCLAIMER = 'SET_DISCLAIMER';
const SET_HAS_REWARD = 'SET_HAS_REWARD';
const SET_HISTORY = 'SET_HISTORY';
const SET_IS_REWARDS_STATE_LOADING = 'SET_IS_REWARDS_STATE_LOADING';
const SET_IS_REWARDS_ACTION_CLAIMS_LOADING = 'SET_IS_REWARDS_ACTION_CLAIMS_LOADING';
const SET_IS_REWARDS_HISTORY_LOADING = 'SET_IS_REWARDS_HISTORY_LOADING';
const SET_IS_REWARDS_SHOP_LIST_LOADING = 'SET_IS_REWARDS_SHOP_LIST_LOADING';
const SET_NEVER_CLAIMED_BEFORE = 'SET_NEVER_CLAIMED_BEFORE';
const SET_IS_KYC_LITE_COMPLETE = 'SET_IS_KYC_LITE_COMPLETE';
const SET_REWARDS_PENDING_NOTIFICATIONS = 'SET_REWARDS_PENDING_NOTIFICATIONS';
const SET_SHOP_ITEMS = 'SET_SHOP_ITEMS';

const ROOT = { root: true };

export default {
  namespaced: true,
  state: {
    actionClaims: [],
    balance: 0,
    currentClaimDay: 0,
    days: [],
    disclaimer: '',
    hasReward: false,
    history: [],
    inStreak: false,
    isRewardsActionClaimsLoading: false,
    isRewardsHistoryLoading: false,
    isRewardsShopListLoading: false,
    isRewardsStateLoading: false,
    neverClaimedBefore: true,
    nextClaimEnd: 0,
    nextClaimStart: 0,
    pendingNotifications: [],
    isKycLiteComplete: false,
    shopItems: [],
  },
  mutations: {
    [ SET_ACTION_CLAIMS ] (state, payload) {
      state.actionClaims = payload;
    },
    [ SET_BALANCE ] (state, payload) {
      state.balance = payload;
    },
    [ SET_CURRENT_CLAIM_DAY ] (state, payload) {
      state.currentClaimDay = payload;
    },
    [ SET_DAILY_CLAIMS ] (state, payload) {
      state.days = Get(payload, 'days', state.days);
      state.inStreak = Get(payload, 'in_streak', state.inStreak);
      state.neverClaimedBefore = Get(payload, 'never_claimed_before', state.neverClaimedBefore);
      state.nextClaimStart = Get(payload, 'next_claim_start', state.nextClaimStart);
      state.nextClaimEnd = Get(payload, 'next_claim_end', state.nextClaimEnd);
    },
    [ SET_DISCLAIMER ] (state, payload) {
      state.disclaimer = payload;
    },
    [ SET_HAS_REWARD ] (state, payload) {
      state.hasReward = !!payload;
    },
    [ SET_HISTORY ] (state, payload) {
      state.history = payload;
    },
    [ SET_IS_KYC_LITE_COMPLETE ] (state, payload) {
      state.isKycLiteComplete = !!payload;
    },
    [ SET_IS_REWARDS_ACTION_CLAIMS_LOADING ] (state, payload) {
      state.isRewardsActionClaimsLoading = !!payload;
    },
    [ SET_IS_REWARDS_HISTORY_LOADING ] (state, payload) {
      state.isRewardsHistoryLoading = !!payload;
    },
    [ SET_IS_REWARDS_SHOP_LIST_LOADING ] (state, payload) {
      state.isRewardsShopListLoading = !!payload;
    },
    [ SET_IS_REWARDS_STATE_LOADING ] (state, payload) {
      state.isRewardsStateLoading = !!payload;
    },
    [ SET_NEVER_CLAIMED_BEFORE ] (state, payload) {
      state.neverClaimedBefore = !!payload;
    },
    [ SET_REWARDS_PENDING_NOTIFICATIONS ] (state, payload) {
      state.pendingNotifications = payload;
    },
    [ SET_SHOP_ITEMS ] (state, payload) {
      state.shopItems = payload;
    },
  },
  getters: {
  },
  actions: {
    async acknowledgeUserRewardNotification({ dispatch }, id) {
      await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: ACKNOWLEDGE_USER_REWARD_NOTIFICATION,
          variables: {
            id,
          },
        }
      }, ROOT);
    },

    async dailyRewardClaim({ dispatch }) {
      const response = await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: DAILY_REWARD_CLAIM,
        }
      }, ROOT);

      const rewardsState = Get(response, 'data.daily_reward_claim.rewards_state', {
        balance: 0,
        daily_claims: {
          days: [],
        },
        never_claimed_before: false,
      });

      dispatch('setRewardsState', rewardsState);
    },

    async rewardsShopBuy({ commit, dispatch, state }, { id, cost }) {
      const response = await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: REWARDS_SHOP_BUY,
          variables: {
            id,
          }
        }
      }, ROOT);

      const newBalance = Big(state.balance).minus(cost).toNumber();

      commit(SET_BALANCE, newBalance);

      return Get(response, 'data.rewards_shop_buy.result', '');
    },

    async getRewardsActionClaims({ commit, dispatch }) {
      try {
        commit(SET_IS_REWARDS_ACTION_CLAIMS_LOADING, true);

        const response = await dispatch('api/apolloClient', {
          type: 'query',
          options: {
            query: GET_REWARDS_ACTION_CLAIMS,
            fetchPolicy: 'no-cache',
          }
        }, ROOT);
        
        const actionClaims = Get(response, 'data.current_user.rewards_state.action_claims', {});

        const notCompletedActions = actionClaims.filter(action => !action.completed);

        const kycLiteIndex = notCompletedActions.findIndex(action => action.trigger.type === 'kyc_lite');

        const kycLite = kycLiteIndex > -1 ? notCompletedActions.splice(kycLiteIndex, 1) : [];

        const actions = [...kycLite, ...notCompletedActions];

        commit(SET_ACTION_CLAIMS, actions);
        commit(SET_IS_KYC_LITE_COMPLETE, kycLiteIndex === -1);
      } finally {
        commit(SET_IS_REWARDS_ACTION_CLAIMS_LOADING, false);
      }
    },

    async getRewardsHistory({ commit, dispatch }) {
      try {
        commit(SET_IS_REWARDS_HISTORY_LOADING, true);

        
        const response = await dispatch('api/apolloClient', {
          type: 'query',
          options: {
            query: GET_REWARDS_HISTORY,
            fetchPolicy: 'no-cache',
          }
        }, ROOT);
        
        const history = Get(response, 'data.current_user.rewards_state.history', []);
        
        commit(SET_HISTORY, history);
      } finally {
        commit(SET_IS_REWARDS_HISTORY_LOADING, false);
      }
    },

    async getRewardsPendingNotifications({ commit, dispatch }) {
      const response = await dispatch('api/apolloClient', {
        type: 'query',
        options: {
          query: GET_REWARDS_PENDING_NOTIFICATIONS,
          fetchPolicy: 'no-cache',
        }
      }, ROOT);

      const pendingNotifications = Get(response, 'data.current_user.rewards_state.pending_notifications', []);

      commit(SET_REWARDS_PENDING_NOTIFICATIONS, pendingNotifications);

      if (pendingNotifications.length) {
        commit('ui/showModal', {
          currentModal:'RewardGemsEarned',
          currentModalProps: {
            pendingNotifications: pendingNotifications,
          },
        }, ROOT);
      }
    },

    async getRewardsShopItems({ commit, dispatch }) {
      try {
        commit(SET_IS_REWARDS_SHOP_LIST_LOADING, true);

        const response = await dispatch('api/apolloClient', {
          type: 'query',
          options: {
            query: GET_REWARDS_SHOP_ITEMS,
            fetchPolicy: 'no-cache',
          }
        }, ROOT);
        
        const shopItems = Get(response, 'data.get_rewards_shop_items', {
          items: [],
          disclaimer: '',
        });

        commit(SET_DISCLAIMER, shopItems.disclaimer);
        commit(SET_SHOP_ITEMS, shopItems.items);
      } finally {
        commit(SET_IS_REWARDS_SHOP_LIST_LOADING, false);
      }
    },

    async getRewardsState({ commit, dispatch }) {
      try {
        commit(SET_IS_REWARDS_STATE_LOADING, true);

        const response = await dispatch('api/apolloClient', {
          type: 'query',
          options: {
            query: REWARDS_STATE,
            fetchPolicy: 'no-cache',
          }
        }, ROOT);
        
        const rewardsState = Get(response, 'data.current_user.rewards_state', {
          balance: 0,
          daily_claims: {
            days: [],
          },
          never_claimed_before: true,
        });

        dispatch('setRewardsState', rewardsState);
      } finally {
        commit(SET_IS_REWARDS_STATE_LOADING, false);
      }
    },

    setIsRewardsStateLoading({ commit }, payload) {
      commit(SET_IS_REWARDS_STATE_LOADING, payload);
    },

    setRewardsState({ commit, rootGetters }, payload) {
      commit(SET_BALANCE, Big(payload.balance).toNumber());
      commit(SET_DAILY_CLAIMS, cloneDeep(payload.daily_claims));
      commit(SET_NEVER_CLAIMED_BEFORE, cloneDeep(payload.never_claimed_before));
      
      const days = Get(payload, 'daily_claims.days', []);
      
      const canBeClaimedIndex = days.findIndex(day => day.state === RewardState.CAN_BE_CLAIMED);
      const claimedIndex = days.map((day) => day.state).lastIndexOf(RewardState.CLAIMED);

      const isKycComplete = rootGetters['user/isKycComplete'];
      
      commit(SET_HAS_REWARD, canBeClaimedIndex > -1 && isKycComplete);
      commit(SET_CURRENT_CLAIM_DAY, canBeClaimedIndex > -1 ? canBeClaimedIndex : claimedIndex);
    }
  }
};
