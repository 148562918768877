<template>
  <CentralizedSection class="asset-section">
    <template slot="header">
      <div class="flex justify-center border-b border-border mb-s32">
        <PrimaryTabs
          class="subheadline-small start-xl:headline-small"
          :currentTab="currentTab"
          :tabList="options"
          @tabChange="tabChange" />
      </div>
    </template>
    <CarouselV2 :carousel-settings="carouselSettings">
      <Slide
        v-for="( chunk, chunkIndex ) in assets"
        :key="chunkIndex"
        :class="slideClasses"
        :data-testid="`chunk-${chunkIndex}`">
        <AssetCard
          v-for="(asset, assetIndex) in chunk"
          class="not-mobile:odd:justify-self-end"
          :data-testid="`chunk-${chunkIndex}-asset-${assetIndex}`"
          :key="asset.id"
          :data="asset"
          :background="background"
          :isLoading="isLoading"
        />
      </Slide>
    </CarouselV2>
  </CentralizedSection>
</template>
<script>
import { Slide } from 'vue-carousel';

import { CarouselV2, CentralizedSection, PrimaryTabs } from '@/components/misc';
import AssetCard from '@/modules/asset/AssetCard';

export default {
  name: 'AssetSection',
  components: {
    AssetCard,
    CarouselV2,
    CentralizedSection,
    PrimaryTabs,
    Slide
  },
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    background: {
      type: String,
      default: 'bg-background-page',
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => ([])
    },
    onTabChange: {
      type: Function,
      default: () => {}
    },
    tab: {
      type: String,
      default: ''
    },
  },
  computed: {
    carouselSettings(){
      return {
        slideAmount: this.assets.length,
        perPage: 1
      };
    },
    currentTab(){
      return this.options.find(item => item.type === this.tab).label;
    },
    loadingList(){
      if (this.isSmallMobileDevice) {
        return [...Array(3).fill([{},{}])]; 
      }
      return [...Array(3).fill([{},{},{},{},{},{}])]; 
    },
    nestedAssetCards(){
      return this.data.reduce((acc, item, index) => { 
        let nestedArray;
        if (this.isSmallMobileDevice) {
         nestedArray = Math.floor(index / 2);
        } else {
         nestedArray = Math.floor(index / 6);
        }

        if(!acc[nestedArray]) {
          acc[nestedArray] = [];
        }

        acc[nestedArray].push(item);

        return acc;
      }, []);
    },
    assets(){
      if (this.isLoading) {
        return this.loadingList; 
      }
      return this.nestedAssetCards; 
    },
    slideClasses(){
      const lessItems = this.assets[0].length <= 2;
      return {
        'asset-slide gap-s24': true,
        'asset-slide--small justify-items-center': this.isSmallMobileDevice,
        'asset-slide--medium': !this.isSmallMobileDevice && this.isMobileDevice && !lessItems,
        'asset-slide--normal': !this.isMobileDevice && !lessItems,
        'flex justify-center': lessItems
      };
    }
  },
  methods: {
    tabChange(tab){
      const type = this.options.find(item => item.label === tab.label).type;
      return this.onTabChange(type);
    }
  },
};
</script>
<style scoped>
.asset-slide {
  display: grid;
}
.asset-slide--small {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.asset-slide--medium {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.asset-slide--normal {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
</style>
