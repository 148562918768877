import IconSellCard from '@/assets/icons/sell_card.svg';

const SET_BANNER = 'SET_BANNER';
const HIDE_ALL_BANNERS = 'HIDE_ALL_BANNERS';

const findBannerAndChangeActiveState = (state, name, isActive) => {
  return state.map(banner => {
    if (banner.name === name) {
      return {...banner, isActive};
    }
    return banner;
  });
};

const findModalAndChangeActionUrl = (state, name, actionUrl) => {
  return state.map(banner => {
    if (banner.name === name) {
      return {...banner, actionUrl};
    }
    return banner;
  });
};

export default {
  namespaced: true,
  state: {
    banners: [
      {
        name: 'sell_card_banner',
        actionLabel: 'sell_card_banner.learnmore',
        actionUrl: 'https://pages.dibbs.io/sellwithdibbs',
        icon: IconSellCard,
        isActive: false,
        title: 'sell_card_banner.title',
        subTitle: 'sell_card_banner.description',
        amplitudeEvent: 'SELLERS_LANDING_PAGE_VIEWED',
        routeNames: ['home'],
        weight: 10
      },
    ]
  },
  getters: {
    currentPageBanners: (state) => (routeName = 'home') => {
      return state.banners.filter(banner => banner.isActive && banner.routeNames.includes(routeName));
    },
  },
  mutations: {
    [SET_BANNER](state, payload){
      state.banners = payload.data;
    },
    [HIDE_ALL_BANNERS](state){
      state.banners = state.banners.map(banner => ({...banner, isActive: false}));
    }
  },
  actions: {
    setBannerActive({state, commit}, payload) {
      const isActive = !localStorage.getItem(`hide_${payload.name}`) && payload.featureFlag;
      const newState = findBannerAndChangeActiveState(state.banners, payload.name, isActive);
      commit(SET_BANNER, { data: newState });
    },

    setBannerInactive({state, commit}, name) {
      localStorage.setItem(`hide_${name}`, 'true');
      const newState = findBannerAndChangeActiveState(state.banners, name, false);
      commit(SET_BANNER, { data: newState });
    },

    updateActionUrl({state, commit}, payload) {
      const newState = findModalAndChangeActionUrl(state.banners, payload.name, payload.actionUrl);
      commit(SET_BANNER, { data: newState });
    },
    hideAllBanners({commit}) {
      commit(HIDE_ALL_BANNERS);
    }
  }
};
