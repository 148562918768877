export default {
  PAIRS_TRENDING: [],
  PAIRS_NEW_ARRIVALS: [],
  PAIRS_CARDLADDER_DISCOUNTED: [],
  currentPair: {},
  assets: [],
  packs: [],
  fracpacks: [],
  watchlist: [],
};
