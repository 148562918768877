<template>
  <section :class="sectionClasses" role="contentinfo">
    <div class="container" :style="{ maxWidth }">
      <slot name="header" />
      <slot />
    </div>
  </section>
</template>
<script>
export default {
  name: 'CentralizedSection',
  computed: {
    sectionClasses(){
      return {
        'centralized-section': true,
        'centralized-section--desktop': !this.isMobileDevice,
        'centralized-section--mobile': this.isMobileDevice,
      };
    }
  },
  props: {
    maxWidth: {
      type: String,
      default: '1239px',
    },
  },
};
</script>
<style scoped>
.centralized-section {
  @apply flex justify-center;
}

.centralized-section--desktop {
  padding: 80px 0;
}

.centralized-section--mobile {
  padding: 40px 0;
}

.container {
  @apply flex flex-col justify-center px-s16;
  width: 100%;
}
</style>
