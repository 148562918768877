export const emitFacebookEvent = (eventName, variables) => {
  if(window.fbq) {
    try {
      if (variables) {
        window.fbq('track', eventName, _formatFacebookVariables(variables));
      } else {
        window.fbq('track', eventName);
      }
      
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(`[Facebook ${eventName}] event emitted`);
      }
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(err.message);
      } 
    }
  }
};

export const emitFacebookPageView = () => {
  if(window.fbq) {
    try {
      window.fbq('track', 'PageView');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err.message);
    }
  }
};

const _formatFacebookVariables = (variables) => {
  if(!variables) {return;}
  
  const formattedVariables = {};
  
  Object.entries(variables).forEach(item => {
    switch(item[0]) {
      case 'amount': 
      case 'max_amount': 
      case 'quote_amount': 
        formattedVariables['value'] = item[1];
        formattedVariables['currency'] = 'USD';
        return;
      default: return;
    }
  });

  return formattedVariables;
};

export const startFacebookPixel = () => {
  if(window.fbq) {
    try {
      window.fbq('init', process.env.VUE_APP_FACEBOOK_ID);          
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err.message);
    }
  }
};
