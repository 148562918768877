<template>
  <div>
    <!-- Header -->
    <div class="flex justify-between items-center">
      <SkeletonLoading v-if="globalIsLoading" height="36px" width="140px" borderRadius="0"/>
      <div v-else class="text-n-3xl font-semibold">
        {{ assetsInCollectionDisplay }}
      </div>

      <SkeletonLoading v-if="globalIsLoading" height="34px" width="132px" borderRadius="10px"/>
      <div v-else class="flex items-center">
        <DropdownList
          :selectedDropdown="selectedSort"
          :dropdownItems="dropdownItems"
          @onDropdownAction="onDropdownAction"
        />
      </div>
    </div>

    <!-- List -->
    <div class="player-cards-container">
      <AssetCardV2
        v-for="(n, index) in displayCards"
        v-bind:key="index"
        :isLoading="globalIsLoading"
        :skeletonLoading="skeletonLoading"
        :data="{
          ...displayCards[index],
          collectionName: player.name,
        }"
      />
    </div>
  </div>
</template>

<script>
import { AssetCardV2 } from '@/modules/asset';
import { DropdownList } from '@/components/dropdown';
import { mountAssetList } from '@/modules/asset/utils';

export default {
  name: 'CollectionAssetsList',
  components: {
    AssetCardV2,
    DropdownList,
  },

  computed: {
    assetsInCollectionDisplay() {
      return this.$tc('player.assets', this.numberOfCards);
    },

    displayCards() {
      return !this.globalIsLoading ? mountAssetList(this.allPlayerCards) : new Array(12).fill({});
    },

    numberOfCards() {
      return this.lodashGet(this.allPlayerCards, 'length', 0);
    },
  },

  data: function () {
    return {
      allPlayerCards: [],
      dropdownItems: [
        {label: this.$t('filter.highest_value'), action: 'highest_value'},
        {label: this.$t('filter.lowest_value'), action: 'lowest_value'},
        {label: this.$t('filter.price_up'), action: 'price_up'},
        {label: this.$t('filter.price_down'), action: 'price_down'},
      ],
      imagesLoaded: 0,
      isOpen: false,
      selectedSort: {label: this.$t('filter.highest_value'), action: 'highest_value'},
      skeletonLoading: {
        height: '487px',
        width: '100%',
      }
    };
  },

  methods: {
    closeDropdown() {
      this.isOpen = false;
    },

    onDropdownAction(item) {
      if (item.action === this.selectedSort.action) {
        return;
      }

      switch (item.action) {
        case 'highest_value':
          this.allPlayerCards = this.sortArrayOnProperty({ array: this.allPlayerCards, property: 'price_per_token', ascending: false });
          this.selectedSort = item;
          break;
        case 'lowest_value':
          this.allPlayerCards = this.sortArrayOnProperty({ array: this.allPlayerCards, property: 'price_per_token '});
          this.selectedSort = item;
          break;
        case 'price_up':
          this.allPlayerCards = this.sortArrayOnProperty({ array: this.allPlayerCards, property: 'price_change_24h', ascending: false });
          this.selectedSort = item;
          break;
        case 'price_down':
          this.allPlayerCards = this.sortArrayOnProperty({ array: this.allPlayerCards, property: 'price_change_24h '});
          this.selectedSort = item;
          break;
      }
    },

    loaded() {
      this.allPlayerCards = this.sortArrayOnProperty({ array: this.lodashGet(this.player, 'pairs', []), property: 'price_per_token', ascending: false });
    },

    toggle() {
      this.isOpen = !this.isOpen;
    },
  },

  mounted() {
    if (!this.globalIsLoading) {
      // if page is already loaded when mounted, it means this is the initial loading (i.e. page refresh)
      this.loaded();
    }
  },

  props: {
    player: {
      type: Object,
      required: true,
    },
  },

  watch: {
    globalIsLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.loaded();
        });
      }
    },
  },
};
</script>

<style scoped>
  .player-cards-container {
    @apply grid grid-cols-1 gap-s16 mt-s24;
  }

  @screen start-sm {
    .player-cards-container {
      @apply grid-cols-3;
    }
  }

  @screen start-md {
    .player-cards-container {
      @apply grid-cols-4;
    }
  }

  @screen start-xl {
    .player-cards-container {
      @apply grid-cols-5;
    }
  }
</style>
