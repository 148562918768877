export const claimDayListAlreadyClaimed = [
  {
    amount: 10,
    state: 'claimed',
  },
  {
    amount: 20,
    state: 'claimed',
  },
  {
    amount: 30,
    state: 'claimed',
  },
  {
    amount: 40,
    state: 'upcoming',
  },
  {
    amount: 50,
    state: 'upcoming',
  },
  {
    amount: 60,
    state: 'upcoming',
  },
  {
    amount: 100,
    state: 'upcoming',
  },
];

export const claimDayListCanBeClaimed = [
  {
    amount: 10,
    state: 'can_be_claimed',
  },
  {
    amount: 20,
    state: 'upcoming',
  },
  {
    amount: 30,
    state: 'upcoming',
  },
  {
    amount: 40,
    state: 'upcoming',
  },
  {
    amount: 50,
    state: 'upcoming',
  },
  {
    amount: 60,
    state: 'upcoming',
  },
  {
    amount: 100,
    state: 'upcoming',
  },
];

export const rewardShopItem = {
  id: '0cf8ea93-0872-4242-8f90-0d7fbb1c4a0c',
  balance: 70,
  color: '#CC3299',
  cost: '10.0000000000000000',
  image: 'https://img.dibbs.io/frac_pack_std.png',
  title: 'Pack 1',
  type: 'fracpack',
};

export const rewardActionList = [{
  trigger: {
    id: '0cf8aa93-0872-4242-8f90-0d7fbb1c4a0c',
    title: 'Make Your First Deposit',
    gems_amount: '9000',
    target: 'https://staging.hovuu.com/?utm_reward=make_your_first_deposit'
  },
}, {
  trigger: {
    id: '0cfaea93-0872-4242-8f90-0d7fbb1c4a0c',
    title: 'Invite a Friend',
    gems_amount: '11000',
    target: 'https://staging.hovuu.com/?utm_reward=invite_a_friend'
  }
}, {
  trigger: {
    id: '0af8ea93-0872-4242-8f90-0d7fbb1c4a0c',
    title: 'Every $1 You Spend',
    gems_amount: '30',
    target: 'https://staging.hovuu.com/?utm_reward=every_1_you_spend'
  }
}, {
  trigger: {
    id: '0cf8ea93-087a-4242-8f90-0d7fbb1c4a0c',
    title: 'Another Reward ...',
    gems_amount: '1000',
    target: 'https://staging.hovuu.com/?utm_reward=another_reward'
  }
}, {
  trigger: {
    id: '0cf8ea93-0872-4242-8f90-0d7fab1c4a0c',
    title: 'Lorem ipsum ...',
    gems_amount: '100000',
    target: 'https://staging.hovuu.com/?utm_reward=lorem_ipsum'
  }
}, ];
