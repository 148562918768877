<template>
  <BaseText
    @click.native="handleShowRewardDisclaimer"
    :class="disclaimerClasses"
    color="text-text-positive"
    data-cy="terms-and-conditions"
    data-testid="terms-and-conditions"
    :isLoading="isComponentLoading"
    loadingHeight="18px"
    loadingWidth="200px"
    position="text-center"
    size="body-text-medium"
  >
    {{ $t('rewards.view_terms_and_conditions') }}
  </BaseText>
</template>

<script>
import { BaseText } from '@/components/misc';

export default {
  name: 'RewardDisclaimer',

  components: {
    BaseText,
  },

  props: {
    isComponentLoading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleShowRewardDisclaimer() {
      this.$emit('onShowRewardDisclaimer');
    },
  },

  computed: {
    disclaimerClasses() {
      return this.isComponentLoading ? 'flex justify-center items-center' : 'cursor-pointer';
    },
  }
};
</script>
