const mountProfitLossObject = (value, percentage, timeframe = '_1D') => {
  return {
    type: value >= 0 ? 'positive' : 'negative',
    value,
    percentage,
    timeframe,
  };
};

export default mountProfitLossObject;
