import Vue from 'vue';
import Get from 'lodash.get';
import isEmpty from 'lodash.isempty';

import TRACKING_EVENTS from '@/graphql/queries/TrackingEvents.gql';

import {
  emitAmplitudeEvent,
  setAmplitudeGuestProps,
  setAmplitudeUserProps,
  unsetAmplitudeUserProps,
  emitBranchEvent,
  setBranchUserProps,
  unsetBranchUserProps,
  emitFacebookEvent,
  emitFacebookPageView,
  startFacebookPixel,
  emitIterableEvent,
  setIterableUserProps,
  unsetIterableUserProps,
  emitRedditEvent,
  emitSegmentEvent,
  setSegmentUserProps,
  emitSnapchatEvent,
  startSnapchat,
  emitTiktokEvent,
  startTiktok,
  emitTwitterEvent,
  startTwitter,
} from './utils';

const vue = new Vue();

export const SET_EVENT_PROPERTIES = 'SET_EVENT_PROPERTIES';
export const SET_TRACKING_EVENTS = 'SET_TRACKING_EVENTS';

export default {
  namespaced: true,

  state: {
    amplitude: {},
    branch: {},
    facebook: {},
    iterable: {},
    reddit: {},
    segment: {},
    snapchat: {},
    tiktok: {},
    eventProperties: {},
  },

  actions: {
    async fetchEvents({ commit, dispatch }) {
      const response = await dispatch('api/apolloClient', {
        type: 'query',
        options: {
          query: TRACKING_EVENTS,
        },
      }, { root: true });

      const amplitude = JSON.parse(Get(response, 'data.amplitude_events', '{}'));
      const branch = JSON.parse(Get(response, 'data.branch_events', '{}'));
      const facebook = JSON.parse(Get(response, 'data.facebook_events', '{}'));
      const iterable = JSON.parse(Get(response, 'data.iterable_events', '{}'));
      const reddit = JSON.parse(Get(response, 'data.reddit_events', '{}'));
      const segment = JSON.parse(Get(response, 'data.segment_events', '{}'));
      const snapchat = JSON.parse(Get(response, 'data.snapchat_events', '{}'));
      const tiktok = JSON.parse(Get(response, 'data.tiktok_events', '{}'));
      const eventProperties = JSON.parse(Get(response, 'data.segment_event_properties', '{}'));

      commit(SET_TRACKING_EVENTS, {
        amplitude,
        branch,
        facebook,
        iterable,
        reddit,
        segment,
        snapchat,
        tiktok,
      });

      commit(SET_EVENT_PROPERTIES, {
        eventProperties,
      });
    },
    formatVariables({ state }, variables) {
      if (!variables) {
        return;
      }

      const { eventProperties } = state;

      const newVariables = {};

      Object.keys(variables).forEach(key => {
        if (eventProperties.hasOwnProperty(key.toUpperCase())) {
          newVariables[eventProperties[key.toUpperCase()]] = variables[key];
        }
      });

      if (isEmpty(newVariables)) {
        return;
      }

      return newVariables;
    },
    pageView() {
      // Update Sift _trackPageview
      vue.$sift.setPageview();

      // Update FB Pixel Pageview
      emitFacebookPageView();

      // Update Reddit Pixel ViewContent
      emitRedditEvent('ViewContent');

      // Update Snapchat PAGE_VIEW
      emitSnapchatEvent('PAGE_VIEW');

      // Update Tiktok ViewContent
      emitTiktokEvent('ViewContent');

      // Update Twitter PageView
      emitTwitterEvent('PageView');
    },
    setGuestUserProps({ rootState, state }, isMobileDevice) {
      try {
        const { currentTheme } = rootState.ui;
        const { eventProperties } = state;

        setAmplitudeGuestProps({ currentTheme, eventProperties, isMobileDevice });
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(err.message);
        }
      }
    },
    async setUserProps({ rootState, state }, { user, isMobileDevice }) {
      try {
        const { currentTheme } = rootState.ui;
        const { eventProperties } = state;

        [
          setAmplitudeUserProps({ eventProperties, user, isMobileDevice, currentTheme }),
          await setBranchUserProps({ user }),
          await setIterableUserProps({ user, isMobileDevice }),
          setSegmentUserProps({ eventProperties, user, isMobileDevice, currentTheme }),
        ];
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(err.message);
        }
      }
    },
    startTracking(_, user) {
      vue.$sift.start();
      startFacebookPixel();
      startSnapchat(user);
      startTiktok();
      startTwitter();

      vue.$sift.setPageview();
      emitSnapchatEvent('PAGE_VIEW');
      emitTwitterEvent('PageView');
    },
    async track({ dispatch, state }, { event, variables }) {
      try {
        const events = [];

        const formattedVariables = await dispatch('formatVariables', variables);

        if (state.amplitude.hasOwnProperty(event)) {
          events.push(async () => await emitAmplitudeEvent(state.amplitude[event], formattedVariables));
        }

        if (state.branch.hasOwnProperty(event)) {
          events.push(async () => await emitBranchEvent(state.branch[event], formattedVariables));
        }

        if (state.facebook.hasOwnProperty(event)) {
          events.push(async () => await emitFacebookEvent(state.facebook[event], formattedVariables));
        }

        if (state.iterable.hasOwnProperty(event)) {
          events.push(async () => await emitIterableEvent(state.iterable[event], formattedVariables));
        }

        if (state.reddit.hasOwnProperty(event)) {
          events.push(async () => await emitRedditEvent(state.reddit[event], formattedVariables));
        }

        if (state.segment.hasOwnProperty(event)) {
          events.push(async () => await emitSegmentEvent(state.segment[event], formattedVariables));
        }

        if (state.snapchat.hasOwnProperty(event)) {
          events.push(async () => await emitSnapchatEvent(state.snapchat[event], formattedVariables));
        }

        if (state.tiktok.hasOwnProperty(event)) {
          events.push(async () => await emitTiktokEvent(state.tiktok[event], formattedVariables));
        }

        if (events.length) {
          events.map(async (emitEvent) => await emitEvent());
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(err.message);
        }
      }
    },
    async unsetUserProps() {
      try {
        [
          unsetAmplitudeUserProps(),
          await unsetBranchUserProps(),
          await unsetIterableUserProps(),
        ];
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(err.message);
        }
      }
    },
  },

  mutations: {
    [ SET_EVENT_PROPERTIES ] (state, { eventProperties }) {
      state.eventProperties = eventProperties;
    },
    [ SET_TRACKING_EVENTS ] (state, payload) {
      state.amplitude = payload.amplitude;
      state.branch = payload.branch;
      state.facebook = payload.facebook;
      state.iterable = payload.iterable;
      state.reddit = payload.reddit;
      state.segment = payload.segment;
      state.snapchat = payload.snapchat;
      state.tiktok = payload.tiktok;
    },
  },
};
