<template>
  <router-link v-if="linkToHome" to="/">
    <MainLogoDark v-if="darkMode" :class="classes" class="main-logo"/>
    <MainLogoLight v-else :class="classes" class="main-logo"/>
  </router-link>
  <div v-else>
    <MainLogoDark v-if="darkMode" :class="classes" class="main-logo"/>
    <MainLogoLight v-else :class="classes" class="main-logo"/>
  </div>
</template>

<script>
import MainLogoDark from '@/assets/img/logo_main.svg';
import MainLogoLight from '@/assets/img/logo_main_light.svg';

export default {
  name: 'MainLogo',
  components: {
    MainLogoDark,
    MainLogoLight,
  },

  computed: {
    classes() {
      return {
        ['cursor-pointer']: this.linkToHome,
        ['default-transition']: this.linkToHome,
        ['hover:opacity-80']: this.linkToHome,
        ['main-logo']: this.linkToHome,
      };
    },
  },

  props: {
    linkToHome: {
      type: Boolean,
      default: true,
    },
    darkMode: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style scoped>
.main-logo {
  @apply object-contain w-auto;
  height: 45px;
}
</style>
