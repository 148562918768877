<template>
  <div class="font-bold">
    <SkeletonLoading v-if="globalIsLoading" height="36px" width="240px" borderRadius="0"/>
    <div v-else class="text-n-5xl leading-3xl">
      {{ name }}
    </div>

    <SkeletonLoading v-if="globalIsLoading" height="40px" borderRadius="0" class="mt-n-2xl"/>
    <div v-else class="mt-n-2xl font-medium text-n-md leading-md">
      {{ $t('frac_pack.description', { cardsPerPack }) }}
    </div>

    <SkeletonLoading v-if="globalIsLoading" height="39px" width="240px" borderRadius="0" class="mt-n-2xl"/>
    <div v-else class="mt-n-2xl text-text-inactive font-semibold text-n-lg leading-2xl">
      <i18n path="frac_pack.price" tag="span">
        <template v-slot:price>
          <span class="text-text-body font-bold text-n-4xl">{{ numberFormat(price, 2, false, true) }}</span>
        </template>
      </i18n>
    </div>

    <SkeletonLoading v-if="globalIsLoading" height="34px" borderRadius="0" class="mt-n-2xl"/>
    <div v-else class="mt-n-2xl font-semibold text-n-2xl leading-2xl">
      {{ hasSupply ? $t('frac_pack.supply_available', { supplyTotal, supplyRemaining }) : this.$t('frac_pack.sold_out') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FracPackDescription',

  props: {
    cardsPerPack: Number,
    name: String,
    price: Number,
    supplyTotal: Number,
    supplyRemaining: Number,
  },

  computed: {
    hasSupply() {
      return this.supplyRemaining > 0;
    },
  },
};
</script>
