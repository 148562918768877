import api from './api';
import array from './array';
import auth from './auth';
import formatters from './formatters';
import loading from './loading';
import lodash from './lodash';
import messages from './messages';
import modals from './modals';
import ui from './ui';

const mixins = {
  data() {
    return {
      ...auth.data,
      ...formatters.data,
      ...ui.data,
    };
  },

  computed: {
    ...loading.computed,
    ...ui.computed,
  },

  methods: {
    ...api.methods,
    ...array.methods,
    ...auth.methods,
    ...formatters.methods,
    ...loading.methods,
    ...lodash.methods,
    ...messages.methods,
    ...modals.methods,
    ...ui.methods,
  }
};

export default mixins;
