export const emitTwitterEvent = async (eventName, variables) => {
  if (window.twq) {
    try {
      if (variables) {
        window.twq('track', eventName, variables);
      } else {
        window.twq('track', eventName);
      }

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(`[Twitter ${eventName}] event emitted ${variables ? `with variables: ${JSON.stringify(variables)}` : ''}`);
      }
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(err.message);
      }
    }
  }
};

export const startTwitter = () => {
  if (window.twq) {
    try {
      window.twq('init', process.env.VUE_APP_TWITTER_CLIENT_ID);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err.message);
    }
  }
};
