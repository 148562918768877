import {
  createMessage,
  encrypt,
  readKey
} from 'openpgp';

export default async function (rawPublicKey, dataToEncrypt) {
  const armoredKey = Buffer.from(rawPublicKey, 'base64').toString('ascii');
  const publicKey = await readKey({
    armoredKey
  });
  const encrypted = await encrypt({
    message: await createMessage({
      text: JSON.stringify(dataToEncrypt)
    }),
    encryptionKeys: publicKey,
  });

  return Buffer.from(encrypted).toString('base64');
}
