<template>
  <div class="flex flex-col justify-center items-center">
    <div class="claim-day-container" ref="claim-day-container">
      <RewardClaimDayButton
        v-for="(day, index) in currentDays"
        @onDateClick="onDateClick"
        :key="index"
        :day="day"
        :dayNumber="index + 1"
        :isComponentLoading="isComponentLoading"
        :isKycComplete="isKycComplete"
      />
    </div>

    <div :class="buttonClasses">
      <SkeletonLoading v-if="isComponentLoading"
        height="44px"
        borderRadius="25px"
      />
      <ButtonV2
        v-else
        @onClick="onDateClick"
        :inactive="!hasReward"
        :loading="isLoading"
        :label="$t('rewards.check_in')"
        testId="btn-CheckIn"
        size="medium"
        wide
      />
    </div>
  </div>
</template>

<script>
import { ButtonV2 } from '@/components/misc';
import RewardClaimDayButton from '@/modules/rewards/RewardClaimDayButton';
import { claimDayListCanBeClaimed } from '@/mocks/rewards';

export default {
  name: 'RewardClaimDayList',
  components: {
    ButtonV2,
    RewardClaimDayButton,
  },
  props: {
    days: {
      type: Array,
      required: true,
    },
    hasReward: {
      type: Boolean,
      required: true,
    },
    isComponentLoading: {
      type: Boolean,
      default: false,
    },
    isKycComplete: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    currentClaimDay: {
      type: Number,
      required: true,
    },
  },

  mounted() {
    this.scrollToDay();
  },

  methods: {
    onDateClick() {
      this.$emit('onDateClick');
    },

    scrollToDay() {
      this.$nextTick(() => {
        this.$refs['claim-day-container'].scrollLeft = this.scrollAmount;
      });
    }
  },

  computed: {
    buttonClasses() {
      return {
        'button-container': !this.isTinyMobileDevice,
        'w-full px-s20': this.isTinyMobileDevice,
      };
    },

    currentDays() {
      return this.isComponentLoading ? claimDayListCanBeClaimed : this.days;
    },

    scrollAmount() {
      const scroll = this.currentClaimDay > 2 ? this.currentClaimDay : 0;

      return 108 * scroll;
    },
  },

  watch: {
    hasReward() {
      this.scrollToDay();
    },

    isComponentLoading(isLoading) {
      if (!isLoading) {
        this.scrollToDay();
      }
    },
  },
};
</script>

<style scoped>
.claim-day-container {
  @apply flex gap-s12 overflow-x-auto mb-s40 px-s20;
  max-width: calc(100vw);
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.claim-day-container::-webkit-scrollbar {
  display: none;
}

.button-container {
  @apply w-full mb-s2;
  max-width: 335px;
}
</style>
