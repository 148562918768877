<template>
  <SkeletonLoading
    v-if="isLoading"
    height="280px"
  />
  <div v-else class="flex flex-col">
    <div class="price-container">
      <h2
        class="headline-small"
        v-text="$t('asset_detail.price_history')" 
      />
    </div>
    <TradeChartContainer
      @onUpdateChartRange="handleUpdateChartRange"
      :chartOptions="{ height: 135 }"
      :data="asset"
    />
  </div>
</template>

<script>
import TradeChartContainer from '@/modules/trade/TradeChartContainer';

export default {
  name: 'TradePriceHistory',
  props: {
    asset: Object,
    isLoading: Boolean,
  },

  components: {
    TradeChartContainer,
  },

  methods: {
    handleUpdateChartRange(chartOption) {
      this.$emit('onUpdateChartRange', chartOption);
    },
  },
};
</script>

<style scoped>
  .price-container {
    @apply flex flex-row justify-between pb-s20 mb-s20 border-b border-border;
  }
</style>
