import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import mixins from './mixins';
import router from './vue-router';
import store from './store';
import i18n from './translations/i18n';
import { apolloProvider } from './vue-apollo';
import VueToast from 'vue-toast-notification';
import VueMoment from 'vue-moment';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import amplitude from 'amplitude-js';
import VueInputAutoWidth from 'vue-input-autowidth';
import VueIntercom from 'vue-intercom';
import VueSegmentAnalytics from 'vue-segment-analytics';
import SkeletonLoading from '@/stories/misc/SkeletonLoading';
import VueDragscroll from 'vue-dragscroll';
import Big from 'big.js';
import VueSift from '@/utils/vue-sift';
import CountryFlag from 'vue-country-flag';
import GAuth from 'vue-google-oauth2';
import VueGtag from 'vue-gtag';
import '@/utils/faIcons';
import 'vue-slider-component/theme/default.css';

/* New routes  */
import './routesV2';

Big.RM = 0;
Object.defineProperty(Vue.prototype, '$big', { value: Big });

amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_KEY, null, {
  includeGclid: true,
  includeUtm: true,
}, (vueAmplitude) => {
  Object.defineProperty(Vue.prototype, '$amplitude', { value: vueAmplitude });
});

if (window._iaq) {
  window._iaq.account(process.env.VUE_APP_ITERABLE_KEY);
}

Vue.component(VueQrcode.name, VueQrcode);

Vue.config.productionTip = false;

// Router
Vue.use(VueRouter);

// Toast notifications
Vue.use(VueToast, { position: 'top-right', duration: 6000 });

// Moments
Vue.use(VueMoment);

// Input auto-width
Vue.use(VueInputAutoWidth);

// Intercom
Vue.use(VueIntercom, {
  appId: process.env.VUE_APP_INTERCOM_ID,
});

// Skeleton Loading
Vue.component('SkeletonLoading', SkeletonLoading);

// Drag scroll
Vue.use(VueDragscroll);

// Vue Sift
Vue.use(VueSift);

// Google Analytics / Google Ads
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_CLIENT_ID },
  includes: [
    { id: process.env.VUE_APP_GOOGLE_ADS_CLIENT_ID },
  ]
}, router);

// Country flag
Vue.component('country-flag', CountryFlag);

// Apple Auth
window.AppleID.auth.init({
  clientId: process.env.VUE_APP_APPLE_AUTH_CLIENT_ID,
  scope: 'name email',
  redirectURI: process.env.VUE_APP_APPLE_AUTH_REDIRECT_URI,
  state: 'dibbs-apple-sso',
  usePopup: true
});

// GAuth
const gauthOption = {
  clientId: process.env.VUE_APP_GAUTH_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account'
};
Vue.use(GAuth, gauthOption);

// Segment.io
const segmentOptions = {
  id: process.env.VUE_APP_SEGMENT_ID,
  router,
};
Vue.use(VueSegmentAnalytics, segmentOptions);

let pt = {
  purchaseProtection: null,
  pt: null
};

Vue.prototype.getPt = () => {
  return pt;
};

window.onPurchaseProtectionReady = function (purchaseProtection) {
  pt.purchaseProtection = purchaseProtection;
};

window.primeTrustReady = function (_pt) {
  pt.pt = _pt;
};

// Branch
(function (b, r, a, n, c, h, _, s, d, k) { if (!b[n] || !b[n]._q) { while (s < _.length) { c(h, _[s++]); } d = r.createElement(a); d.onerror = (err) => { window.branch = undefined; throw new URIError(`The script ${err.target.src} didn't load correctly.`); }; d.onload = () => { window.branch.init(process.env.VUE_APP_BRANCH_KEY); }; d.async = 1; d.src = 'https://cdn.branch.io/branch-latest.min.js'; k = r.getElementsByTagName(a)[0]; k.parentNode.insertBefore(d, k); b[n] = h; } })(window, document, 'script', 'branch', function (b, r) { b[r] = function () { b._q.push([r, arguments]); }; }, { _q: [], _v: 1 }, 'addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking'.split(' '), 0);

// Check clicks outside component
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

String.prototype.hashCode = function () {
  let hash = 0;
  if (this.length === 0) {
    return hash;
  }
  for (let i = 0; i < this.length; i++) {
    let char = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

Vue.mixin(mixins);

new Vue({
  router,
  store,
  i18n,
  apolloProvider,
  render: h => h(App)
}).$mount('#app');
