import { SET_CATEGORIES } from './types';


export default {
  namespaced: true,
  state: {
    api: [],
  },
  mutations: {
    [SET_CATEGORIES](state, payload){
      state.api = payload;
    }
  },
  actions: {
    setCategories({ commit }, payload){
      commit(SET_CATEGORIES, payload);
    }
  },
  getters: {
    getCategoriesList(state){
      return state.api.map(item => ({
        id: item.id,
        name: item.name,
        link: `market/${item.id_hr}/collections/`,
        img: item.img,
      }));
    }
  },
};
