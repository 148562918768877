import { formatMoney, capitalizeWord } from '@/utils/formatters';

export default {
  name: 'TransactionsListItemMixin',

  computed: {
    isActiveOrder() {
      return !!this.listItem.order_status && this.listItem.order_status === 'active';
    },

    transaction() {
      const transactionTypes = {
        UserOrderReceipt: this.getUserOrderReceiptTransaction(),
        DropReceipt: this.getDropReceiptTransaction(),
        FracPackReceipt: this.getFrackPackReceiptTransaction(),
        CirclePaymentReceipt: this.getCirclePaymentTransaction(),
        CircleWithdrawalReceipt: this.getCircleWithdrawalTransaction(),
      };

      return transactionTypes[this.listItem.__typename];
    },

    singleTransactionLink() {
      return this.listItem.__typename !== 'FracPackReceipt'
        ? `/transaction/${this.transaction.transactionId}`
        : '';
    },

    isTransactionReady() {
      return !!this.lodashGet(this.transaction, 'isValid', false);
    },
  },

  methods: {
    // __typename formatters

    getUserOrderReceiptTransaction() {
      const isValid = !!this.listItem.pair && !!this.listItem.total_amount;
      let transaction = { isValid };

      if (transaction.isValid) {
        const hasName = this.listItem.pair && this.listItem.pair.base.associated_players.length;
        const hasDescription = !!this.listItem.pair && !!this.listItem.pair.base;
        const name = hasName ? this.listItem.pair.base.associated_players[0].name : '-';
        const description = hasDescription ? this.listItem.pair?.base?.short_name || this.listItem.pair.base.name : '';

        const transactionPrice = this.getOrderTransactionPrice(this.listItem.average_price);

        transaction = {
          ...transaction,
          transactionId: this.listItem.transaction_id,
          name,
          description,
          type: this.getItemType(this.listItem.__typename),
          date: this.getItemDate(this.listItem.__typename),
          assetPrice: this.getItemAveragePrice(this.listItem.average_price),
          status: this.getItemStatus(this.listItem.__typename),
          transaction: this.getItemTransaction(transactionPrice),
        };
      }

      return transaction;
    },

    getDropReceiptTransaction() {

      const isValid = !!this.listItem.drop && !!this.listItem.quote_amount;
      let transaction = { isValid };

      if (transaction.isValid) {
        const hasName = this.listItem.drop && this.listItem.drop.asset.associated_players.length;
        const hasDescription = this.listItem.drop && !!this.listItem.drop.asset;
        const name = hasName ? this.listItem.drop.asset.associated_players[0].name : '-';
        const description = hasDescription ? this.listItem.drop.asset.name : null;

        transaction = {
          ...transaction,
          transactionId: this.listItem.transaction_id,
          name,
          description,
          type: this.getItemType(this.listItem.__typename),
          date: this.getItemDate(this.listItem.__typename),
          assetPrice: '-',
          status: this.getItemStatus(this.listItem.__typename),
          transaction: this.getItemTransaction(this.listItem.quote_amount),
        };
      }

      return transaction;
    },

    getFrackPackReceiptTransaction() {
      const isValid = !!this.listItem.drop && !!this.listItem.base_amount;
      let transaction = { isValid };

      if (transaction.isValid) {
        transaction = {
          ...transaction,
          transactionId: this.listItem.transaction_id,
          name: 'Frac Pack',
          description: null,
          type: this.getItemType(this.listItem.__typename),
          date: this.getItemDate(this.listItem.__typename),
          assetPrice: '-',
          status: this.getItemStatus(this.listItem.__typename),
          transaction: 'Free'
        };
      }

      return transaction;
    },

    getCirclePaymentTransaction() {
      const isValid = !!this.listItem.payment_amount;
      let transaction = { isValid };

      if (transaction.isValid) {
        const { payment_description, payment_type } = this.listItem;

        transaction = {
          ...transaction,
          transactionId: this.listItem.payment_transaction_id,
          name: this.getTransferName(payment_description, payment_type),
          description: null,
          type: this.getItemType(this.listItem.__typename),
          date: this.getItemDate(this.listItem.__typename),
          assetPrice: '-',
          status: this.getItemStatus(this.listItem.__typename),
          transaction: this.getItemTransaction(this.listItem.payment_amount),
        };
      }

      return transaction;
    },

    getCircleWithdrawalTransaction() {
      const isValid = !!this.listItem.withdrawal_amount;
      let transaction = { isValid };

      if (transaction.isValid) {
        const { withdrawal_description, withdrawal_type } = this.listItem;

        transaction = {
          ...transaction,
          transactionId: this.listItem.withdrawal_transaction_id,
          name: this.getTransferName(withdrawal_description, withdrawal_type),
          description: null,
          type: this.getItemType(this.listItem.__typename),
          date: this.getItemDate(this.listItem.__typename),
          assetPrice: '-',
          status: this.getItemStatus(this.listItem.__typename),
          transaction: this.getItemTransaction(this.listItem.withdrawal_amount),
        };
      }

      return transaction;
    },

    // grid item getters

    getItemType(type) {
      let result;

      switch (type) {
        case 'UserOrderReceipt':
          result = this.listItem.is_buy
            ? { label: 'Buy', class: 'text-text-positive' }
            : { label: 'Sell', class: 'text-text-negative' };
          break;
        case 'DropReceipt':
          result = { label: 'Drop', class: 'text-text-blue' };
          break;
        case 'FracPackReceipt':
          result = { label: 'Reward', class: 'text-text-blue' };
          break;
        case 'CirclePaymentReceipt':
          result = { label: 'Deposit', class: 'text-text-positive' };
          break;
        case 'CircleWithdrawalReceipt':
          result = { label: 'Withdrawal', class: 'text-text-negative' };
          break;
      }

      return result;
    },

    getItemDate(type) {
      let date;

      switch (type) {
        case 'UserOrderReceipt':
          date = this.listItem.submitted_time;
          break;
        case 'DropReceipt':
          date = this.listItem.submitted_time;
          break;
        case 'FracPackReceipt':
          date = this.listItem.received_time;
          break;
        case 'CirclePaymentReceipt':
          date = this.$moment(this.listItem.payment_created_at).unix();
          break;
        case 'CircleWithdrawalReceipt':
          date = this.$moment(this.listItem.withdrawal_created_at).unix();
          break;
      }

      return this.$moment(date * 1000).format('MM/DD hh:mm A');
    },

    getItemAveragePrice(price) {
      const isNan = Number.isNaN(parseFloat(price));

      if (this.listItem.__typename === 'UserOrderReceipt') {
        return price && !isNan ? formatMoney(price, 0) : formatMoney(0, 0);
      }

      return '';
    },

    getItemStatus(type) {
      let status = 0;
      let isPlainText = true;
      let label = '';

      switch (type) {
        case 'UserOrderReceipt':
          if (this.isActiveOrder) {
            const { total_amount, filled_amount } = this.listItem;
            status = this.getOrdersStatus(total_amount, filled_amount);
            label = `${status}% ${this.$t('wallet.history.transaction.status.filled')}`;
            isPlainText = false;
            break;
          }

          label = capitalizeWord(this.listItem.order_status);
          break;
        case 'DropReceipt':
          label = this.listItem.drop.running ? 'Running' : 'Completed';
          break;
        case 'FracPackReceipt':
          label = capitalizeWord(this.listItem.frack_pack_status);
          break;
        case 'CirclePaymentReceipt':
          label = capitalizeWord(this.listItem.payment_status);
          break;
        case 'CircleWithdrawalReceipt':
          label = this.listItem.withdrawal_status !== 'requires_email_authorization'
            ? capitalizeWord(this.listItem.withdrawal_status)
            : 'Pending';
          break;
      }

      return {
        isPlainText,
        percentage: this.$big(status).toNumber(),
        label,
      };
    },

    getItemTransaction(amount) {
      let sign;

      switch (this.listItem.__typename) {
        case 'UserOrderReceipt':
          sign = '';
          break;
        case 'DropReceipt':
          sign = '';
          break;
        case 'FracPackReceipt':
          sign = '';
          break;
        case 'CirclePaymentReceipt':
          sign = '+';
          break;
        case 'CircleWithdrawalReceipt':
          sign = '-';
          break;
      }

      return `${sign} ${formatMoney(amount, 2)}`;
    },

    // general helpers

    getOrdersStatus(totalAmount, quoteAmount) {
      if (!totalAmount) {
        return 0.00;
      }

      const total = this.$big(totalAmount).toNumber();
      const quote = quoteAmount
        ? this.$big(quoteAmount).times(100).toNumber()
        : 0;

      return this.$big(quote).div(total).toFixed(0);
    },

    getOrderTransactionPrice(price) {
      const isNan = Number.isNaN(parseFloat(price));
      const averagePrice = price && !isNan
        ? this.$big(price).toNumber()
        : 0.0;

      const isOlderOrder = averagePrice <= 0.0001;
      const isSell = !this.listItem.is_buy;

      return isOlderOrder && isSell
        ? this.listItem.base_amount
        : this.listItem.quote_amount;
    },

    getTransferName(name, type) {
      if (!name) {
        const namesPerType = {
          ach: 'ACH',
          card: 'Credit/Debit Card',
          blockchain: 'Blockchain',
          wire: 'Wire',
        };

        name = namesPerType[type];
      }

      return name;
    },
  }
};