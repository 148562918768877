<template>
  <DesktopVersion v-if="!isMobileDevice">
    <slot />
  </DesktopVersion>
  <MobileVersion v-else>
    <slot />
  </MobileVersion>
</template>

<script>
import DesktopVersion from '@/components/misc/IntroVideoSection/DesktopVersion';
import MobileVersion from '@/components/misc/IntroVideoSection/MobileVersion';

export default {
  name: 'IntroVideoSection',
  components: {
    DesktopVersion,
    MobileVersion
  },
};
</script>

<style scoped>
</style>
