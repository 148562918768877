<template>
  <div>
    <button
      @click="toggleContent()"
      :class="['collapse-button', collapseButtonClasses]"
      data-cy="collapse-button"
      data-testid="collapse-button"
      role="button"
    >
      <div class="w-full">
        <slot name="title" />
      </div>
      <font-awesome-icon 
        icon="chevron-down"
        :class="iconClasses"
      />
    </button>

    <div
      :class="collapsibleContentClasses"
      :data-testid="`content--${active ? 'active' : 'inactive'}`"
      v-bind:style="`--height: ${height + 40}px;`"
    >
      <div ref="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: 'Collapse',
  data: () => ({
    active: false,
    height: 0,
  }),
  props: {
    customClasses: {
      type: Object,
    },
  },
  computed: {
    iconClasses(){
      return {
        'fa-lg': true,
        'rotate-180': this.active
      };
    },
    collapseButtonClasses(){
      return {
        'border-transparent': this.active,
        [this.lodashGet(this.customClasses, 'button', '')]: true,
      };
    },
    collapsibleContentClasses(){
      return {
        'collapse-content': true,
        'collapse-content--active': this.active,
        [this.lodashGet(this.customClasses, 'content', '')]: true,
        [this.lodashGet(this.customClasses, 'contentActive', '')]: this.active,
      };
    },
  },
  methods: {
    toggleContent(){
      this.active = !this.active;
      this.height = this.$refs.content.offsetHeight;
    }
  },
  mounted(){
    this.height = this.$refs.content.offsetHeight;
  }
};
</script>

<style scoped>
  .collapse-button {
    @apply flex items-center justify-between w-full;
    outline: 0;
    transition: border 500ms;
  }

  .collapse-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 500ms;
  }

  .collapse-content--active {
    max-height: var(--height);
  }
</style>
