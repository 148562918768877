<template>
  <BaseLayout class="justify-between min-h-screen">
    <slot/>
    <Footer/>
  </BaseLayout>
</template>

<script>
import BaseLayout from './BaseLayout.vue';
import Footer from '@/components/footer/Footer.vue';

export default {
  name: 'LayoutWithoutPadding',
  components: {
    BaseLayout,
    Footer,
  }
};
</script>
