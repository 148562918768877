<template>
  <div class="review-order">
    <div class="text-left text-n-4xl font-bold leading-2xl">
      {{ $t('review_order') }}
    </div>

    <div class="mt-n-4xl">
      <div
        v-for="(n, index) in reviewOrder.length"
        v-bind:key="index"
        class="review-order-content"
      >
        <div class="review-order-label">
          {{ $t(reviewOrder[index].label) }}:

          <div
            v-if="reviewOrder[index].tooltip"
            @click="$emit('onTooltipOpen', reviewOrder[index].tooltip)"
            role="tooltip"
          >
            <IconInfo
              class="w-s16 h-s16 ml-s8 fill-current cursor-pointer default-transition hover:opacity-60"
            />
          </div>
        </div>
        <div class="review-order-value">{{ formattedValue(index) }}</div>
      </div>
    </div>

    <div class="flex justify-between mt-n-md">
      <div class="text-n-lg font-medium">
        {{ $t(total.label) }}:
      </div>
      <div class="text-n-2xl font-bold">
        {{ numberFormat(total.value, 2, false, true) }}
      </div>
    </div>

    <ButtonV2
      @onClick="$emit('onContinue')"
      class="mt-n-4xl"
      :inactive="isLoading"
      :label="$t(continueLabel || 'continue')"
      testId="btn-continue"
      size="medium"
      wide
    />

    <ButtonV2
      @onClick="$emit('onCancel')"
      class="mt-n-lg"
      :label="$t(cancelLabel || 'cancel')"
      testId="btn-cancel"
      size="medium"
      version="secondary"
      wide
    />
  </div>
</template>

<script>
import { IconInfo } from '@/assets/icons';
import ButtonV2 from '@/stories/misc/ButtonV2';

export default {
  name: 'ReviewOrder',

  components: {
    ButtonV2,
    IconInfo,
  },

  methods: {
    formattedValue(index) {
      switch (this.reviewOrder[index].type) {
        case 'currency':
          return this.numberFormat(this.reviewOrder[index].value, 2, false, true);
        case 'fraction':
          return `~${this.numberFormat(this.$big(this.reviewOrder[index].value).times(100))}%`;
        default:
          return this.reviewOrder[index].value;
      }
    },
  },

  props: {
    cancelLabel: String,
    continueLabel: String,
    isLoading: Boolean,
    reviewOrder: Array,
    total: Object,
  },

};
</script>

<style scoped>
.review-order {
  @apply flex flex-col w-full;
}

.review-order-content {
  @apply flex justify-between items-center leading-md mb-n-xl;
}

.review-order-content:last-of-type {
  @apply mb-n-0 pb-n-md border-border border-b;
}

.review-order-label {
  @apply flex justify-start items-center text-n-md font-medium;
}

.review-order-value {
  @apply text-n-lg font-bold;
}
</style>
