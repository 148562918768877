import Big from 'big.js';

const formatters = {
  data: {
    numberFormatters: {},
  },
  methods: {
    formatDollarPercent: function (dollar, percent) {
      if (this.$big(dollar).gte(0)) {
        const numberPart = this.$big(dollar).toFixed(2);
        const percentagePart = this.$big(percent).toFixed(2);

        return `+$${numberPart} (+${percentagePart}%)`;
      } else {
        const numberPart = this.$big(-dollar).toFixed(2);
        const percentagePart = this.$big(-percent).toFixed(2);

        return `-$${numberPart} (-${percentagePart}%)`;
      }
    },
    formatVariables: function (variables) {
      const formattedVariables = {};

      Object.keys(variables).forEach(key => {
        const newKey = key.replaceAll('_', ' ').toLowerCase();
        formattedVariables[newKey] = typeof variables[key] === 'string' ? variables[key].replaceAll('_', ' ').toLowerCase() : variables[key];
      });

      return formattedVariables;
    },
    numberFormat: function (number, decimalPlaces = 2, signal = false, currencySymbol = false) {
      if (!this.numberFormatters) {
        this.numberFormatters = {};
      }

      if (!this.numberFormatters[decimalPlaces]) {
        this.numberFormatters[decimalPlaces] = new Intl.NumberFormat(
          'en-US',
          { maximumFractionDigits: decimalPlaces, minimumFractionDigits: decimalPlaces }
        );
      }

      let bigNumber;
      let isPositive;
      try {
        bigNumber = Big(number).toFixed(decimalPlaces);
        isPositive = Big(number).gte(0);
      } catch (err) {
        bigNumber = Big(0);
        isPositive = true;
      }

      let numberString = this.numberFormatters[decimalPlaces].format(bigNumber);

      if (signal && number >= 0) {
        numberString = `+${numberString}`;
      }

      if (currencySymbol) {
        if (signal || !isPositive) {
          const signal = numberString.substr(0, 1);
          numberString = `${signal}$${numberString.substr(1)}`;
        } else {
          numberString = `$${numberString}`;
        }
      }

      return numberString;
    },
    preventNonNumericalInput(event) {
      event = event || window.event;
      const charCode = (typeof event.which == 'undefined') ? event.keyCode : event.which;
      const charStr = String.fromCharCode(charCode);

      if (!charStr.match(/^[0-9.]+$/)) {
        event.preventDefault();
      }
    },
    sanitizeChartData(array, timeLabel = 'time', valueLabel = 'close') {
      const params = {
        array: array.filter(el => el),
        property: timeLabel,
      };

      const sortedChart = this.sortArrayOnProperty({ ...params });

      const firstValue = sortedChart
        .findIndex(data => data[valueLabel] !== 0);

      let chartValues;

      if (firstValue !== -1) {
        chartValues = sortedChart.slice(firstValue, sortedChart.length);
      }

      return chartValues || [...sortedChart];
    },
  },
};

export default formatters;
