<template>
  <div>
    <!-- Graphics -->
    <div data-testid="media-container" role="media" class="top-section-image">
      <img class="mx-auto" src="@/assets/img/top-section-mobile.webp" alt="card-fraction"/>
    </div>

    <!-- Text/Buttons -->
    <div class="px-s20 mt-s32">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileVersion',
  components: {
  }
};
</script>

<style scoped>
  .top-section-image {
    background: url('~@/assets/img/bg-top-section.webp') no-repeat center;
  }
</style>
