import state from './state.js';
import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export const WalletModule = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export { default as WalletCard } from './WalletCard';
export { default as WalletChart } from './WalletChart';
export { default as WalletSection } from './WalletSection';
export { default as WalletBalanceHeader } from './WalletBalanceHeader';
export { default as WalletTransactions } from './WalletTransactions';
