<template>
  <div class="font-bold">
    <SkeletonLoading v-if="globalIsLoading" height="24px" width="150px" borderRadius="0"/>
    <div v-else class="text-n-2xl leading-xl">
      {{ $t('frac_pack.stats') }}
    </div>

    <div class="mt-n-2xl font-medium text-n-md leading-md">
      <SkeletonLoading v-if="globalIsLoading" height="40px" borderRadius="0"/>
      <div v-else>
        {{ $t('frac_pack.stats_description_1', { fractionPerCard: fractionPerCardPercent, cardsPerPack }) }}
      </div>

      <SkeletonLoading v-if="globalIsLoading" height="20px" borderRadius="0" class="mt-n-2xl"/>
      <div v-else class="mt-n-2xl">
        {{ $t('frac_pack.stats_description_2') }}
      </div>
    </div>

    <!-- Top Card -->
    <div class="mt-n-4xl">
      <SkeletonLoading v-if="globalIsLoading" height="24px" width="150px" borderRadius="0"/>
      <div v-else class="text-n-2xl leading-xl">
        {{ $t('frac_pack.top_card') }}
      </div>

      <router-link :to="`/trade/${getTicker(topCard)}`" class="flex mt-n-4xl">
        <div class="flex-shrink-0">
          <SkeletonLoading v-if="globalIsLoading" height="143px" width="90px" borderRadius="0"/>
          <ImageResize v-else class="top-card-image" :alt="topCard.asset.name" :src="topCard.asset.img_front" type="asset_card"/>
        </div>

        <div class="ml-n-4xl font-medium text-n-md leading-md w-full">
          <SkeletonLoading v-if="globalIsLoading" height="40px" borderRadius="0"/>
          <div v-else>
            {{ topCard.asset.name }}
          </div>

          <SkeletonLoading v-if="globalIsLoading" height="20px" borderRadius="0" class="mt-n-4xl"/>
          <div v-else class="mt-n-4xl">
            {{ $t('frac_pack.currently_trading_for') }}:
          </div>

          <SkeletonLoading v-if="globalIsLoading" height="20px" borderRadius="0"/>
          <div v-else class="font-bold text-n-2xl">
            {{ numberFormat(topCard.asset['price_usd'], 2, false, true) }}
          </div>
        </div>
      </router-link>
    </div>

    <!-- Complete Pack List -->
    <SkeletonLoading v-if="globalIsLoading" height="24px" width="240px" borderRadius="0" class="mt-n-4xl"/>
    <div v-else class="pack-list" :style="listMaxHeight">
      <div class="mb-n-4xl text-n-2xl leading-xl cursor-pointer" @click="toggleList()">
        {{ $t('frac_pack.possible_assets') }}
        <font-awesome-icon
          icon="chevron-down"
          class="pack-list-icon"
          :class="{ 'rotate-180': listIsOpen }"
        />
      </div>

      <div
        v-for="(n, index) in possibleAssets.length"
        v-bind:key="index"
        class="pb-n-md border-b border-border my-n-3xl"
      >
        <router-link :to="`/trade/${getTicker(possibleAssets[index])}`" class="flex">
          <div class="flex-shrink-0">
            <ImageResize
              class="asset-list-image"
              :alt="possibleAssets[index].asset['name']"
              :src="possibleAssets[index].asset['img_front']"
              type="asset_card_extra_small"
            />
          </div>
          <div class="ml-n-md pr-n-5xl font-medium text-n-md leading-md">
            {{ possibleAssets[index].asset['name'] }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ImageResize } from '@/components/misc';
import { mapGetters } from 'vuex';

export default {
  name: 'FracPackStats',
  components: {
    ImageResize,
  },

  computed: {
    ...mapGetters('fracpack', [
      'fracpackTrackingData'
    ]),

    fractionPerCardPercent() {
      return `${this.fractionPerCard * 100}%`;
    },

    listMaxHeight() {
      return {
        'max-height': this.listIsOpen ? `${89 * this.possibleAssets.length}px` : '24px',
      };
    },

    topCard() {
      return this.lodashGet(this, 'possibleAssets[0]', {});
    },
  },

  data() {
    return {
      listIsOpen: false,
    };
  },

  methods: {
    getTicker(card) {
      const ticker =  this.lodashGet(card, 'asset.ticker', null);
      return ticker ? `${ticker}-USD` : '';
    },

    async toggleList() {
      this.listIsOpen = !this.listIsOpen;

      if (this.listIsOpen) {
        await this.$store.dispatch('events/track', {
          event: 'FRAC_PACK_LIST_VIEWED',
          variables: {
            ...this.fracpackTrackingData,
          },
        });
      }
    }
  },

  props: {
    cardsPerPack: Number,
    fractionPerCard: Number,
    possibleAssets: Array,
  }
};
</script>

<style scoped>
  .asset-list-image {
    @apply object-contain;
    width: 35px;
    height: 60px;
  }

  .pack-list {
    @apply mt-n-4xl overflow-hidden;
    transition: all 700ms;
  }

  .pack-list-icon {
    @apply ml-n-lg;
    transition: all 500ms;
  }

  .top-card-image {
    @apply object-contain;
    width: 90px;
    height: 143px;
  }
</style>
