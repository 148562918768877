<template>
  <div class="image-browser" :class="navbarAdjustNeeded ? 'navbar-adjust' : 'no-navbar-adjust'">
    <MobileVersion
      v-if="isMediumMobileDevice"
      :image-list="imageList"
      @close="$emit('close')"
    />
    <DesktopVersion
      v-else
      :image-list="imageList"
      @close="$emit('close')"
    />
  </div>
</template>

<script>
import DesktopVersion from './DesktopVersion';
import MobileVersion from './MobileVersion';

export default {
  name: 'ImageBrowser',
  components: {
    DesktopVersion,
    MobileVersion,
  },

  props: {
    imageList: {
      type: Array,
      required: true,
    },
  },

  computed: {
    navbarAdjustNeeded() {
      const template = this.$route.meta.template;

      // Template 5 has no navbar on mobile
      if (template === 5 && this.isMediumMobileDevice) {
        return false;
      }

      // Template 4 has no navbar
      return template !== 4;
    }
  },
};
</script>

<style scoped>
  .image-browser {
    @apply fixed left-0 bg-background-page w-screen z-component-overlay;
  }

  .navbar-adjust {
    top: var(--navbar-height);
    height: calc(var(--vh, 1vh) * 100 - var(--navbar-height));
  }

  .no-navbar-adjust {
    top: 0;
    height: calc(var(--vh, 1vh) * 100);
  }
</style>
