<template>
  <div class="frac-pack-image-container">
    <div class="absolute w-full h-full" :style="glowStyle"></div>
    <div v-if="globalIsLoading">
      <SkeletonLoading :height="`${imageHeight}px`" :width="`${imageWidth}px`" borderRadius="0"/>
    </div>
    <ImageResize v-else
      class="z-10"
      alt="Pack Image"
      :src="image"
      :width="imageWidth"
      :height="imageHeight"
    />
  </div>
</template>

<script>
import { ImageResize } from '@/components/misc';

export default {
  name: 'FracPackImage',
  components: {
    ImageResize,
  },

  computed: {
    glowStyle() {
      return {
        background: this.glowColor,
        filter: `blur(${this.glowBlur}px)`,
        width: `${this.glowWidth}px`,
        height: `${this.glowHeight}px`
      };
    }
  },

  props: {
    glowBlur: {
      type: Number,
      default: 80,
    },

    glowColor: {
      type: String,
      default: '#74DBB5',
    },

    glowHeight: {
      type: Number,
      default: 100,
    },

    glowWidth: {
      type: Number,
      default: 160,
    },

    image: {
      type: String,
      default: 'https://img.dibbs.io/frac_pack_std.png'
    },

    imageHeight: {
      type: Number,
      default: 236,
    },

    imageWidth: {
      type: Number,
      default: 162,
    },
  }
};
</script>

<style scoped>
  .frac-pack-image-container {
    @apply relative flex justify-center items-center;
  }
</style>
