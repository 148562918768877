import Big from 'big.js';
import Get from 'lodash.get';
import mountProfitLossObject from '@/modules/walletv2/utils/mountProfitLossObject';

import formatters from '@/mixins/formatters';
import { formatCardladder, formatFraction, formatPercentage } from '@/utils/formatters';

const _toNumber = (value) => new Big(value).toNumber();

const _getValues = (item, isDrop) => {
  const rawCurrentPrice = !isDrop ? Get(item, 'base.pair.price_per_token', 0) : Get(item, 'base.drops[0].asset_value_usd', 0);
  const supply_left = _toNumber(Get(item, 'base.drops[0].supply_left', '0'));
  const to = !isDrop ? `/trade/${Get(item, 'base.pair.id_hr', '')}` : `/drop/${Get(item, 'base.drops[0].id')}`;

  return {
    rawCurrentPrice,
    to,
    supply_left 
  };
};

const mountAssetList = (list) => {
  return list.map(item => {
    const { rawCurrentPrice, to, supply_left } = _getValues(item, item.type === 'HoldingDrop');

    const hasCardLadder = item.base && item.base.cardladder;
    const ticker = Get(item, 'ticker', '');

    const rawHoldingAmount = _toNumber(Get(item, 'amount', 0));
    const rawCurrentHolding = new Big(rawHoldingAmount).times(rawCurrentPrice).toNumber();

    // Price change 24h (legacy)
    const rawPriceChangeValue24h = Get(item, 'base.pair.price_change_24h', 0);
    const rawPriceChangePercentage24h = new Big(Get(item, 'base.pair.price_change_24h_pct', 0)).div(100).toNumber();
    const rawHoldingChangeValue24h = new Big(rawCurrentHolding).times(rawPriceChangePercentage24h).toNumber();
    const rawHoldingChangePercentage24h = rawPriceChangePercentage24h;
    const priceChange24h = mountProfitLossObject(rawPriceChangeValue24h, rawPriceChangePercentage24h);
    const holdingChange24h = mountProfitLossObject(rawHoldingChangeValue24h, rawHoldingChangePercentage24h);

    // Price change with dynamic timeframe
    const rawPriceChangeValue = Get(item, 'base.pair.price_change.change_amount', 0);
    const rawPriceChangePercentage = new Big(Get(item, 'base.pair.price_change.change_pct', 0)).div(100).toNumber();
    const rawPriceChangeTimeframe = Get(item, 'base.pair.price_change.timeframe', '_1D');
    const rawHoldingChangeValue = new Big(rawCurrentHolding).times(rawPriceChangePercentage).toNumber();
    const rawHoldingChangePercentage = rawPriceChangePercentage;
    const priceChangeDynamic = mountProfitLossObject(rawPriceChangeValue, rawPriceChangePercentage, rawPriceChangeTimeframe);
    const holdingChangeDynamic = mountProfitLossObject(rawHoldingChangeValue, rawHoldingChangePercentage, rawPriceChangeTimeframe);

    const cardLadderValue = hasCardLadder ? formatCardladder(item.base.cardladder.cl_average_price, rawCurrentPrice) : 0;
    const supplyValue = supply_left > 0 ? new Big(rawHoldingAmount).times(supply_left).toNumber() : 0;
    const supplyPercent = supply_left > 0 ? new Big(supply_left).times(100).div(supply_left).toNumber() : 0;
    return {
      type: item.type,
      id: item.base.id,
      ticker,
      to,
      image: item.base.img_front,
      image_alt: item.base.img_alt,
      title: item.base.name,
      short_name: item.base.short_name,
      collection_name: Get(item.base, 'associated_players[0].name',  ''),
      cardladder: {
        active: cardLadderValue >= 5,
        value: cardLadderValue
      },
      rawValues: {
        currentPrice: rawCurrentPrice,
        currentHolding: rawCurrentHolding,
        currentHoldingAmount: rawHoldingAmount,
        priceChange24h,
        holdingChange24h,
        priceChangeDynamic,
        holdingChangeDynamic,
        supplyValue, 
        supplyPercent, 
      },
      readableValues: {
        currentPrice: formatters.methods.numberFormat(rawCurrentPrice, 2, false, true),
        currentHolding: formatters.methods.numberFormat(rawCurrentHolding, 2, false, true),
        currentHoldingAmount: formatFraction(rawHoldingAmount, 2),
        priceChange24h: {
          ...priceChange24h,
          value: formatters.methods.numberFormat(priceChange24h.value, 2, true, true),
          percentage: formatFraction(priceChange24h.percentage, 2, true),
        },
        holdingChange24h: {
          ...holdingChange24h,
          value: formatters.methods.numberFormat(holdingChange24h.value, 2, true, true),
          percentage: formatFraction(holdingChange24h.percentage, 2, true),
        },
        priceChangeDynamic: {
          ...priceChangeDynamic,
          value: formatters.methods.numberFormat(priceChangeDynamic.value, 2, true, true),
          percentage: formatFraction(priceChangeDynamic.percentage, 2, true),
        },
        holdingChangeDynamic: {
          ...holdingChangeDynamic,
          value: formatters.methods.numberFormat(holdingChangeDynamic.value, 2, true, true),
          percentage: formatFraction(holdingChangeDynamic.percentage, 2, true),
        },
        supplyValue: formatters.methods.numberFormat(supplyValue, 2, false, true), 
        supplyPercent: formatPercentage(supplyPercent), 
      },
    };
  });
};

export default mountAssetList;
