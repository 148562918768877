<template>
  <CentralizedSection class="categories-section">
    <template slot="header">
      <div class="flex justify-center border-b border-border lg:mb-s32 mb-s64">
        <PrimaryTabs
          class="subheadline-small start-xl:headline-small"
          :currentTab="currentTab"
          :tabList="options"
          @tabChange="tabChange" />
      </div>
    </template>
    <CarouselV2 :carousel-settings="carouselSettings">
      <template v-for="( chunk, chunkIndex ) in collectionList">
        <Slide :key="chunkIndex" :class="slideClasses" :data-testid="`chunk-${chunkIndex}`">
          <template v-for="(collection, collectionIndex) in chunk">
            <CollectionsCard
              :key="collection.id"
              :data-testid="`chunk-${chunkIndex}-collection-${collectionIndex}`"
              :data="collection"
              :background="background"
              :isLoading="isLoading"
            />
          </template>
        </Slide>
      </template>
    </CarouselV2>
  </CentralizedSection>
</template>

<script>
import { Slide } from 'vue-carousel';

import { CarouselV2, CentralizedSection, PrimaryTabs } from '@/components/misc';
import { CollectionsCard } from '@/modules/collections';

export default {
  name: 'CollectionsSection',
  components: {
    CarouselV2,
    CentralizedSection,
    CollectionsCard,
    PrimaryTabs,
    Slide
  },
  props: {
    background: {
      type: String,
      default: 'bg-background-page'
    },
    data: {
      type: Array,
      default: () => ([])
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => ([])
    },
    onTabChange: {
      type: Function,
      default: () => {}
    },
    tab: {
      type: String,
      default: ''
    },
  },
  computed: {
    currentTab(){
      return this.options.find(item => item.type === this.tab).label;
    },
    loadingList(){
      if (this.isSmallMobileDevice) {
        return [Array(1).fill({})]; 
      } else if (this.isMobileDevice) {
        return [Array(3).fill({})]; 
      } else {
        return [Array(4).fill({})]; 
      }
    },
    nestedList(){
      return this.data.reduce((acc, item, index) => { 
        let nestedArray;
        if (this.isSmallMobileDevice) {
         nestedArray = Math.floor(index / 1);
        } else if (this.isMobileDevice) {
          nestedArray = Math.floor(index / 3);
        } else {
          nestedArray = Math.floor(index / 4);
        }

        if(!acc[nestedArray]) {
          acc[nestedArray] = [];
        }

        acc[nestedArray].push(item);

        return acc;
      }, []);
    },
    collectionList(){
      if (this.isLoading) {
        return this.loadingList; 
      }
      return this.nestedList; 
    },
    carouselSettings(){
      return {
        slideAmount: this.collectionList.length,
        perPage: 1,
      };
    },
    slideClasses(){
      return {
        'flex justify-center gap-s24 min-w-full': true,
      };
    }
  },
  methods: {
    tabChange(tab){
      const type = this.options.find(item => item.label === tab.label).type;
      return this.onTabChange(type);
    }
  }
};
</script>

<style scoped></style>
