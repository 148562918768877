import store from '@/store';

const loading = {
  computed: {
    globalIsLoading() {
      return store.state.ui.loading;
    },
  },
  methods: {
    imagesLoading: function () {
      return false; // disabling this function for now
      //return !data || imagesLoaded < data.length;
    },
  },
};

export default loading;
