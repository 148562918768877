import Vue from 'vue';
import Get from 'lodash.get';

const UPDATE_TIME = 'UPDATE_TIME';

import { formatDrop } from '@/modules/drops/utils/formatDrop';

export default {
  namespaced: true,
  state: {
    timeNow: new Date().getTime(),
  },
  mutations: {
    [UPDATE_TIME](state, payload) {
      Vue.set(state, 'timeNow', payload);
    },
  },
  actions: {
    updateCurrentTime({ commit }, payload) {
      commit(UPDATE_TIME, payload);
    },
  },
  getters: {
    getDropAssetImages(_state, _, rootState) {
      const drop = rootState.api.drop;
      if (!Get(drop, 'asset.individual_assets', []).length) {
        return [
          {
            img_front: Get(drop, 'asset.img_front', ''),
            img_back: Get(drop, 'asset.img_back', ''),
          },
        ];
      }
      return Get(drop, 'asset.individual_assets', []);
    },
    getCurrentTime(state) {
      return state.timeNow;
    },
    getDrops(state, _getters, rootState) {
      return rootState.api.drops.map((item) => formatDrop(item, state.timeNow));
    },
  },
};
