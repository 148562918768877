import router from '@/vue-router';

router.addRoute({
  path: '/upcoming-user-agreement',
  redirect: () => {
    return {
      path: '/user-agreement'
    };
  },
});

router.addRoute({
  component: () => import('@/views/UserAgreement'),
  name: 'user-agreement',
  path: '/user-agreement',
  meta: {
    template: 4
  },
});
