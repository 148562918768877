<template>
  <LayoutCentralized class="pt-s48 xl:pt-s32">
    <slot />
  </LayoutCentralized>
</template>

<script>
import LayoutCentralized from './LayoutCentralized.vue';

export default {
  name: 'LayoutMainPages',
  components: {
    LayoutCentralized,
  }
};
</script>

<style scoped></style>
