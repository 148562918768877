export default {
  name: 'InfiniteScrollMixin',

  mounted() {
    this.setInfiniteScrollTrigger();

    window.addEventListener('scroll', this.onScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },

  data() {
    return {
      scrollTop: 0,
      refHeight: null,
      infiniteScroll: {
        active: false,
        trigger: null,
        threshold: 0.8,
        loading: false,
      }
    };
  },

  computed: {
    needManualTrigger() {
      return this.refHeight <= this.infiniteScroll.trigger;
    },

    isInfiniteScrollActive() {
      return !!this.infiniteScroll.active;
    },

    isInfiniteScrollLoading() {
      return !!this.infiniteScroll.loading;
    },

    infiniteScrollTrigger() {
      return this.infiniteScroll.trigger * this.infiniteScroll.threshold;
    },

    userScrolledOverTrigger() {
      const passedTrigger = this.scrollTop + this.windowHeight >= this.infiniteScrollTrigger;

      return this.isInfiniteScrollActive
        && !this.isInfiniteScrollLoading
        && passedTrigger;
    },
  },

  methods: {
    onScroll(e) {
      this.scrollTop = e.target.documentElement.scrollTop;
    },

    setInfiniteScrollStatus(status) {
      this.infiniteScroll.active = !!status;
    },

    setInfiniteScrollLoading(isLoading) {
      this.infiniteScroll.loading = !!isLoading;
    },

    setInfiniteScrollTrigger() {
      this.infiniteScroll.trigger = document.documentElement.offsetHeight;
    },

    setInfiniteScrollRefHeight(height) {
      this.refHeight = height;
    }
  },

  watch: {
    userScrolledOverTrigger(isOverTrigger) {
      if (isOverTrigger) {
        this.$nextTick(() => {
          this.setInfiniteScrollTrigger();
        });
      }
    },

    isInfiniteScrollActive(isActive) {
      if (isActive) {
        this.$nextTick(() => {
          this.setInfiniteScrollTrigger();
        });
      }
    },
  },
};
