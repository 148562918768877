<template>
  <div :class="componentClasses" :style="componentStyles"></div>
</template>

<script>
export default {
  name: 'BackgroundGlow',
  props: {
    background: {
      type: String,
      default: 'bg-text-positive'
    },
    filter: {
      type: String,
      default: 'blur(60px)'
    },
    height: {
      type: String,
      default: '100%'
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  computed: {
    componentClasses(){
      return ['background-glow z-5', this.background];
    },
    componentStyles(){
      return {filter: this.filter, height: this.height, width: this.width };
    }
  }
}; 
</script>

<style>
.background-glow {
  @apply absolute;
}
</style>
