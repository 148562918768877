<template>
  <Card class="asset-details" v-if="hasDescription || hasSlotAssetData">
      
    <!-- Slot: header -->
    <div class="asset-details__header" slot="header">
      <div class="headline-medium">
        {{ title || $t('asset.details_title') }}
      </div>
      <div class="flex items-center subheadline-x-small" v-if="!isMobileDevice">
        {{$t('drop.dropped_by')}} <Checkmark class="ml-s8" />
      </div>
    </div>

    <!-- Slot: Default -->
    <div class="asset-detail__content">
      
      <!-- Asset data Slot-->
      <div v-if="hasSlotAssetData" class="asset-detail__data md:flex-col">
        <slot name="asset-data" />
      </div>
      
      <!-- Markdown content -->
      <label class="body-text-x-large" v-text="$t('asset.detail')" v-if="!noLabel && hasDescription" />
      <Markdown :content="details" />
      
    </div>
  </Card>
</template>

<script>
import { Card, Markdown } from '@/components/misc';
import { Checkmark } from '@/assets/icons';

export default {
  name: 'AssetDetails',
  components: { 
    Card,
    Markdown,
    Checkmark
  },
  props: {
    title: String,
    details: String,
    noLabel: Boolean,
    
  },
  computed: {
    hasSlotAssetData() {
      return this.$slots['asset-data'];
    },
    hasDescription() {
      return this.details && this.details.length > 0;
    },
  },
};
</script>

<style scoped>
.asset-details {
  @apply p-s24;
}
.asset-details__header {
  @apply 
    flex 
    justify-between 
    items-center
    pb-s16
    mb-s16
    border-b
    border-border
  ;
}
.asset-detail__content {
  @apply flex flex-col;
}
.asset-detail__data {
  @apply 
    flex 
    justify-between
    mb-s16
  ;
}
</style>
