<template>
  <RewardNavbarLayout
    @onRewardClick="onRewardClick"
    :hasReward="hasReward"
  />
</template>

<script>
import { mapState } from 'vuex';
import RewardNavbarLayout from './layout';

export default {
  name: 'RewardNavbar',
  components: {
    RewardNavbarLayout,
  },

  methods: {
    async onRewardClick() {
      if (!this.isAuthenticated()) {
        return this.goToInternalPageGlobal('/login');
      }
      this.goToInternalPageGlobal('/rewards');
    },
  },

  computed: {
    ...mapState('rewards', ['hasReward']),
  },
};
</script>
