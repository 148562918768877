import { DEFAULT_STATE } from './state';

export default {
  setAssets(state, payload) {
    state.assets = payload;
  },

  setPacks(state, payload) {
    state.packs = payload;
  },

  setWatchlist(state, payload) {
    state.watchlist = payload;
  },

  setPaginationFlag(state, payload) {
    state.pagination.endOfList = payload;
  },

  clear: state => Object.assign(state, DEFAULT_STATE),

  setPaginationOffset(state, payload) {
    const pagination = {
      ...state.pagination,
      offset: payload,
    };

    state.pagination = pagination;
  },
};
