<template>
  <div>
    <div class="flex flex-1 flex-row justify-between items-center w-full">
      <BaseText
        class="w-full md:text-center"
        tag="h1"
        testId="title-drop-player"
        size="headline-large"
        loadingHeight="36px"
        loadingWidth="95%"
        :isLoading="isLoading"
      >
        {{ playerName }}
      </BaseText>

      <div class="flex items-center lg:hidden">
        <SkeletonLoading
          v-if="isLoading"
          width="20px"
          height="20px"
          borderRadius="24px"
        />
        <Share
          v-else
          @amplitudeEvent="$emit('onShareEvent')"
          :shareMessage="$tc('share.check_out', { name: playerName })"
        />
      </div>
    </div>

    <div class="pt-s12">
      <BaseText
        class="md:text-center md:w-full"
        tag="h3"
        testId="title-drop-player"
        loadingHeight="22px"
        size="subheadline-medium"
        :isLoading="isLoading"
        style="font-weight: 400"
      >
        {{ dropName }}
      </BaseText>
    </div>

    <Markdown
      :content="detailedDescription"
    />

    <PercentageBar
      class="mt-s24"
      height="28px"
      :percentage="percentageSold"
      :text="percentageText"
    />

    <div class="grid-container">
      <div v-for="(item, key) in assetDetailContent" :key="key">
        <label
          class="body-text-x-space text-text-inactive"
          v-text="item.label"
        />
        <div
          :class="item.class"
          v-text="item.value"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseText,
  Markdown, 
  PercentageBar,
  Share,
} from '@/components/misc';

export default {
  name: 'DropsInfoLayout',
  props: {
    assetDetailContent: {
      type: Array,
      required: true,
    },
    detailedDescription: {
      type: String,
    },
    dropName: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isOnFavoritesList: {
      type: Boolean,
      required: true,
    },
    percentageSold: {
      type: Number,
      required: true,
    },
    percentageText: {
      type: String,
      required: true,
    },
    playerName: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseText,
    Markdown,
    PercentageBar,
    Share,
  },
};
</script>

<style scoped>
  .grid-container {
    @apply w-full grid grid-cols-3 gap-s24 items-center mt-s24;
  }

  .asset-price-font {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
  }
  .time-remaining-font {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }
  .usd-remaining-font {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
  }

  @screen md {
    .grid-container {
      @apply gap-s8 text-center;
      grid-template-columns: auto auto auto;
    }

    .asset-price-font {
      font-size: 22px;
      line-height: 30px;
      font-weight: 500;
    }
    .time-remaining-font {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
    }
    .usd-remaining-font {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
    }
  }
</style>
