<template>
  <div data-testid="has-cardladder" class="flex items-center text-text-body text-n-sm">
    <IconStarDollar class="cardladder--icon-star text-text-positive fill-current" /> 

    <slot>
      <p :class="textClasses">
        <span class="text-text-positive">{{value}}%</span> {{$t(descriptionText)}}
      </p>
    </slot>

    <IconCardLadder class="fill-current cardladder--icon-cardladder" />
  </div>
</template>
<script>
import IconCardLadder from '@/assets/icons/cardladder.svg';
import IconStarDollar from '@/assets/icons/star_dollar.svg';

export default {
  name: 'CardLadderDescription',
  components: {
    IconCardLadder,
    IconStarDollar,
  },
  props: {
    descriptionText: {
      type: String,
      default: 'drops.card_ladder_avg_price'
    },
    value: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    textClasses(){
      return {
        'whitespace-nowrap not-mobile:pl-s8 pl-s4 pr-s4': true,
        'body-text-x-small': this.isMobileDevice,
        'body-text-x-medium': !this.isMobileDevice,
      };
    },
  }
};
</script>
<style scoped>
.cardladder--icon-star {
  height: 16px;
  width: 16px;
}

.cardladder--icon-cardladder {
  height: 12px;
  width: 32px;
}
</style>
