<template>
  <section class="toggle-list">
      
    <div class="toggle-list__header">
      
      <template v-if="has_slot_header">
        <slot name="header"></slot>
      </template>
      <template v-else>
        <div 
          class="toggle-list__header-col"
          v-for="(item, i) in headers"
          :key="i"
          :class="header_styles[i]"
        >
          {{item}}
        </div>
      </template>
    </div>

    <ul class="toggle-list__items">
      <li class="toggle-list__row" 
        role="item"
        :data-testid="`item-${i}`"
        v-for="(row, i) in items" 
        :key="i"
        :class="{'--selected': isEqual(row, selected_item)}"  
        @click="selectItem(i)"
      >
        <div 
          class="toggle-list__col" 
          v-for="(col, j) in row" 
          :class="col_styles[j]"
          :key="j"
        >
          <template v-if="showRadio && j === 0">
            <div 
              role="check"
              class="toggle-list__radio" 
              :class="{'--checked': isEqual(row, selected_item)}"
            >
              <font-awesome-icon 
                role="check-selected"
                class="toggle-list__radio-icon" 
                icon="check-circle" 
                v-if="isEqual(row, selected_item)" 
              />
            </div>
          </template>
          <span>{{col}}</span>
        </div>
      </li>
    </ul>

  </section>
</template>


<script>
import { DEFAULT_TYPES } from './index';

export default {
  name: 'toggle-list',
  props: {
    
    type: {
      type: String,
      default: 'trade-preset-values',
      validator: (item) => Object.keys(DEFAULT_TYPES).includes(item)
    },
    
    items: {
      type: Array,
      required: true,
      validator: (arr) => arr.every(item => Array.isArray(item))
    },

    initialSelect: {
      type: Array
    },
    
    showRadio: {
      type: Boolean,
      default: true
    },

    headers: {
      type: Array,
    },

    headerStyles: {
      type: Array,
      validator: (arr) => arr.every(item => typeof item === 'string')
    },

    colStyles: {
      type: Array,
      validator: (arr) => arr.every(item => typeof item === 'string')
    },
    
  },
  
  data() {
    return {
      selected_item: this.initialSelect || null,
    };
  },
  
  computed: {
    type_config() {
      return DEFAULT_TYPES[this.type];
    },
    header_styles() {
      return this.headerStyles || this.type_config.header_styles;
    },
    col_styles() {
      return this.colStyles || this.type_config.cols_styles;
    },
    has_slot_header() {
      return !!this.$slots.header;
    },
    is_selected() {
      return !!this.selected_item;
    },
  },
  
  watch: {
    initialSelect(val, oldVal) {
      if(val !== oldVal) {
        this.selected_item = val;
      }
    },
  },
  methods: {
    isEqual(arr1, arr2) {
      if(arr1 && arr2) {
        return arr1.toString() === arr2.toString();
      }
    },
    selectItem(i) {
      const isDifferent = this.items[i] !== this.selected_item;
      this.selected_item = isDifferent ? this.items[i] : null;
      if(isDifferent) {
        this.$emit('itemSelected', this.items[i]);
        this.$emit('itemSelectedIndex', i);
      } else {
        this.$emit('itemDeselected', null);
      }
    }
  },
};
</script>

<style scoped>
.toggle-list {
  @apply flex-col;
}
.toggle-list__header {
  @apply flex justify-between px-s8;
}
.toggle-list__header-col {
  @apply flex-1 mb-s4;
}
.toggle-list__items {
  @apply list-none;
}
.toggle-list__row {
  @apply 
    flex 
    flex-row 
    items-center 
    justify-between 
    bg-background-primary 
    border border-border 
    rounded-10 
    mt-s8
    cursor-pointer
  ;
  height: 54px;
  padding: 0 14px;
}
.toggle-list__row.--selected {
  border: solid 2px var(--colors-text-active-2);
  padding: 0 13px;
}
.toggle-list__col {
  @apply flex flex-row flex-1;
  user-select: none;
}
.toggle-list__col span{
  white-space: nowrap;
}
.toggle-list__radio {
  @apply relative flex-auto justify-start;
  width: 20px;
  margin-right: 14px;
}
.toggle-list__radio-icon {
  position: absolute;
  left: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  color: var(--colors-text-active-2);
  z-index: 2;
}
.toggle-list__radio:before {
  @apply absolute left-0 rounded-full border-solid border-2;
  content: '';
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-color: rgb(var(--colors-text-inactive));
  z-index: 1;
}
.toggle-list__radio.--checked:before {
  border-color: var(--colors-text-active-2);
}
</style>
