import Vue from 'vue';
import Get from 'lodash.get';

const vue = new Vue();

export const emitSegmentEvent = async (eventName, variables) => {
  if (vue.$segment) {
    try {
      vue.$segment.track(eventName, variables);

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(`[Segment ${eventName}] event emitted ${variables ? `with variables: ${JSON.stringify(variables)}` : ''}`);
      }
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(err.message);
      }
    }
  }
};

export const setSegmentUserProps = ({ eventProperties, user, isMobileDevice, currentTheme }) => {
  const props = {};

  vue.$segment.identify(user.account_id, {
    [eventProperties.IS_TWO_FACTOR_AUTH_ENABLED]: Get(user, 'user_info.twofa_enabled', false),
    [eventProperties.REFERRAL_CODE]: Get(user, 'ref_status.ref_code'),
    [eventProperties.NUMBER_OF_REFERRALS]: Get(user, 'ref_status.num_referred'),
    [eventProperties.REFERRAL_LEVEL]: Get(user, 'ref_status.rank'),
    [eventProperties.PLATFORM]: !isMobileDevice ? 'web desktop' : 'web mobile',
    [eventProperties.COLOR_THEME]: currentTheme,
    ...props,
  });
};
