<template>
  <li v-if="isTransactionReady" class="transaction last:border-b-0">
    <router-link :to="singleTransactionLink">    
      <div class="transaction-description">
        <h4 class="pb-s8 subheadline-small">
          {{ transaction.name }}
        </h4>
        <p>
          {{ transaction.description }}
        </p>
      </div>

      <div class="absolute top-16-px right-16-px">
        <p class="text-text-inactive">
          {{ transaction.date }}
        </p> 
      </div>

      <section class="transaction-details" :class="[transactionDetailsClass]">
        <div>
          <p class="text-text-inactive">
            {{ headers.type }}
          </p>

          <p class="body-text-x-large" :class="[transaction.type.class]">
            {{ transaction.type.label }}
          </p> 
        </div>

        <div>
          <p class="text-text-inactive">
            {{ headers.transaction }}
          </p>

          <p class="body-text-x-large">
            {{ transaction.transaction }}
          </p> 
        </div>

        <template>
          <div>
            <p class="text-text-inactive">
              {{ headers.asset_price }}
            </p>

            <p class="body-text-x-large">
              {{ transaction.assetPrice }}
            </p> 
          </div>

          <div v-if="!showPercentageBar">
            <p class="text-text-inactive">
              {{ headers.status }}
            </p>

            <p class="body-text-x-large">
              {{ transaction.status.label }}
            </p>
          </div>
        
        </template>
      </section>

      <div v-if="showPercentageBar" class="pb-s8">
        <PercentageBar
          :percentage="transaction.status.percentage"
          :text="transaction.status.label"
          height="32px"
        />
      </div>
    </router-link>
  </li>
</template>

<script>
import { PercentageBar } from '@/components/misc';
import TransactionsListItemMixin from '../transactions-list-item.mixin.js';

export default {
  name: 'TransactionListItemCard',

  mixins: [TransactionsListItemMixin],

  components: { PercentageBar },

  computed: {
    showPercentageBar() {
      return this.isActiveOrder && !this.transaction.status.isPlainText;
    },

    transactionDetailsClass() {
      return !this.isActiveOrder ? 'transaction-details--not-orders' : '';
    }
  },

  props: {
    listItem: {
      type: Object,
      required: true,
    },

    itemType: {
      type: String,
      required: true,
    },

    headers: {
      type: Object,
      required: true,
    }
  }
};
</script>

<style scoped>
.transaction {
  @apply list-none body-text-x-medium p-s16 relative border-b border-border;
}

.transaction-details {
  @apply py-s16 gap-n-md grid items-center;
  grid-template-columns: 1.5fr 1.5fr 1fr;
}

.transaction-details--not-orders {
  grid-template-columns: 1fr auto;
}

@media(min-width: 450px) {
  .transaction-details--not-orders {
    grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr;
  }
}

.transaction-description {
  max-width: 65%;
}
</style>
