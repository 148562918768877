<template>
  <VueSlider
    :lazy="true"
    :adsorb="adsorb"
    :tooltip="tooltip"
    :data="data"
    :dotSize="dotSize"
    :processStyle="processStyle"
    :railStyle="railStyle"
    :value="currentValue"
    @change="onChangeCurrentValue"
  />
</template>

<script>
import VueSlider from 'vue-slider-component';

export default {
  name: 'Slider',
  components: {
    VueSlider
  },
  props: {
    active: Boolean,
    adsorb: {
      type: Boolean,
      default: true
    },
    tooltip: {
      type: String,
      default: 'none'
    },
    data: {
      type: Array,
      required: true
    },
    value: {
      type: Number,
      required: true
    }
  },
  computed: {
    currentValue: {
      get(){
        return this.value;
      },
      set(value){
        this.$emit('input', value);
      }
    },
    dotSize(){
      return 28;
    },
    processStyle(){
      return {
        backgroundColor: this.active ? '#00FFAA' : '#999999',
      };
    },
    railStyle(){
      return {
        backgroundColor: this.active ? '#00FFAA' : '#999999',
      };
    },
  },
  methods: {
    onChangeCurrentValue(newValue){
      this.currentValue = newValue;
    }
  }
};
</script>

<style scoped>
.vue-slider-process {
  @apply bg-border-active-nav;
}
.vue-slider-rail {
  @apply bg-border-active-nav;
}
</style>
