<template>
  <button
    role="button"
    class="button-v2 flex"
    :class="classes"
    :data-testid="testId"
    :data-cy="testId"
    :style="style"
    :type="type"
    :disabled="inactive"
    :title="label"
    @click="handleClick"
  >
    <!-- All the rest -->
    <span class="flex justify-center items-center" :class="{ 'opacity-0': loading }">
      <span
        class="button-v2-icon"
        :class="[iconColor, iconPadding, iconSize]"
        v-if="icon && iconLeft"
      >
        <font-awesome-icon class="fa-icon" :class="[iconRotation]" role="icon" :icon="[iconStyle, icon]"/>
      </span>
      <span class="button-v2-label">
        {{ label }}
      </span>
      <span
        class="button-v2-icon"
        :class="[iconColor, iconPadding, iconSize]"
        v-if="icon && !iconLeft"
      >
        <font-awesome-icon class="fa-icon" :class="[iconRotation]" role="icon" :icon="[iconStyle, icon]"/>
      </span>
    </span>

    <!-- Loading state -->
    <span class="absolute" :class="{ 'opacity-0': !loading }">
      <Loading version="v4" :class="loadingSpinner"/>
    </span>
  </button>
</template>

<script>
import Loading from '@/components/misc/Loading/index.vue';

export const AVAILABLE_SIZES = ['small', 'medium', 'large'];
export const AVAILABLE_VERSIONS = ['primary', 'secondary', 'tertiary', 'destructive'];
export const AVAILABLE_ICON_POSITIONS = ['left', 'right'];
export const AVAILABLE_STYLES = ['far', 'fas', 'fab'];

// Icons must be imported at src/utils/faIcons.js
export const AVAILABLE_ICONS = [
  'apple',
  'caret-down',
  'check-circle',
  'check',
  'chevron-down',
  'discord',
  'edit',
  'envelope',
  'eye',
  'facebook',
  'google',
  'info-circle',
  'long-arrow-alt-down',
  'long-arrow-alt-up',
  'search-plus',
  'sync',
  'trash-alt',
  'twitter',
];

export default {
  name: 'ButtonV2',
  components: {
    Loading
  },

  props: {
    icon: {
      type: String,
      validator: (value) => AVAILABLE_ICONS.includes(value),
    },

    iconPosition: {
      type: String,
      default: 'left',
      validator: (value) => AVAILABLE_ICON_POSITIONS.includes(value),
    },

    iconRotation: {
      type: String,
      default: 'rotate-0',
    },

    iconStyle: {
      type: String,
      default: 'fas',
      validator: (value) => AVAILABLE_STYLES.includes(value),
    },

    inactive: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: 'large',
      validator: (value) => AVAILABLE_SIZES.includes(value)
    },

    submit: {
      type: Boolean,
      default: false,
    },
    
    testId: String,

    version: {
      type: String,
      default: 'primary',
      validator: (value) => AVAILABLE_VERSIONS.includes(value)
    },

    wide: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    type() {
      return this.submit ? 'submit' : 'button';
    },
    classes() {
      return {
        [this.backgroundColor]: true,
        [this.borderColor]: true,
        [this.borderRadius]: true,
        [this.buttonHeight]: true,
        [this.cursor]: true,
        [this.hoverTextColor]: true,
        [this.opacity]: true,
        [this.paddingHorizontal]: true,
        [this.textColor]: true,
        [this.textSize]: true,
        [this.textWeight]: true,
        ['border']: true,
        ['default-transition']: true,
        ['w-full']: this.wide,
      };
    },
    borderColor() {
      switch (this.version) {
        case 'primary': return 'border-background-primary-button';
        case 'secondary': return 'border-text-body';
        case 'tertiary': return 'border-background-primary';
        case 'destructive': return 'border-text-negative';
        default: return '';
      }
    },
    borderRadius() {
      switch (this.version) {
        case 'primary': return 'rounded-24';
        case 'secondary': return 'rounded-24';
        case 'tertiary': return 'rounded-10';
        case 'destructive': return 'rounded-24';
        default: return '';
      }
    },
    backgroundColor() {
      switch (this.version) {
        case 'primary': return 'bg-background-primary-button';
        case 'secondary':  return 'bg-transparent';
        case 'tertiary': return 'bg-background-primary';
        case 'destructive': return 'bg-text-negative';
        default: return '';
      }
    },
    buttonHeight() {
      if (this.version === 'tertiary') {
        return 'button-height-tertiary';
      }

      switch (this.size) {
        case 'small': return 'button-height-sm';
        case 'medium': return 'button-height-md';
        case 'large': return 'button-height-lg';
        default: return 'button-height-md';
      }
    },
    cursor() {
      if (this.inactive) {
        return 'cursor-not-allowed';
      }
      return this.loading ? 'cursor-default' : 'cursor-pointer';
    },
    hoverTextColor() {
      return (this.inactive || this.loading) ? '' : `hover-${this.version}`;
    },
    iconColor() {
      switch (this.version) {
        case 'primary': return 'text-text-body-inverted'; // waiting for design system update
        case 'secondary': return 'text-text-body';
        case 'tertiary': return 'text-text-body';
        case 'destructive': return 'hidden'; // waiting for design system update
        default: return '';
      }
    },
    iconLeft() {
      return this.iconPosition === 'left';
    },
    iconPadding() {
      switch (this.size) {
        case 'small': return this.iconLeft ? 'pr-s4' : 'pl-s4';
        case 'medium': return this.iconLeft ? 'pr-s8' : 'pl-s8';
        case 'large': return this.iconLeft ? 'pr-s8' : 'pl-s8';
        default: return '';
      }
    },
    iconSize() {
      switch (this.size) {
        case 'small': return 'text-n-sm';
        case 'medium': return 'text-n-2xl';
        case 'large': return 'text-n-3xl';
        default: return '';
      }
    },
    loadingSpinner() {
      switch (this.size) {
        case 'small': return 'w-s16 h-s16';
        case 'medium': return 'w-s24 h-s24';
        case 'large': return 'w-s32 h-s32';
        default: return '';
      }
    },
    opacity() {
      return this.inactive ? 'inactive-opacity' : '';
    },
    paddingHorizontal() {
      switch (this.size) {
        case 'small': return this.version === 'tertiary' ? 'px-s16' :  'px-n-xl';
        case 'medium': return this.version === 'tertiary' ? 'px-s16' :  'px-s24';
        case 'large': return this.version === 'tertiary' ? 'px-s24' : 'px-n-5xl';
        default: return '';
      }
    },
    style() {
      const customStyles = {};
      if (this.version === 'tertiary') {
        customStyles['box-shadow'] = '0px 4px 10px rgba(35, 42, 101, 0.2)';
      }
      return customStyles;
    },
    textColor() {
      switch (this.version) {
        case 'primary': return 'text-text-body-inverted';
        case 'secondary': return 'text-text-body';
        case 'tertiary': return 'text-text-body';
        case 'destructive': return 'text-text-body-inverted dark:text-text-body';
        default: return '';
      }
    },
    textSize() {
      switch (this.size) {
        case 'small': return 'text-n-sm';
        case 'medium': return 'text-n-md';
        case 'large': return 'text-n-xl';
        default: return 'text-n-md';
      }
    },
    textWeight() {
      switch (this.version) {
        case 'primary': return 'font-bold';
        case 'secondary': return 'font-bold';
        case 'tertiary': return 'font-semibold';
        case 'destructive': return 'font-bold';
        default: return '';
      }
    },
  },

  methods: {
    handleClick() {
      if (!this.inactive && !this.loading) {
        this.$emit('onClick');
      }
    }
  },

};
</script>

<style scoped>
  .button-v2 {
    @apply justify-center items-center;
  }
  .button-v2-icon {
    @apply inline-block;
    transition: color 250ms linear;
    user-select: none;
  }
  .fa-icon {
    transition: transform 250ms linear;
  }
  .button-v2-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
  }
  .button-height-sm {
    height: 28px;
  }
  .button-height-md {
    height: 44px;
  }
  .button-height-lg {
    height: 54px;
  }
  .button-height-tertiary {
    height: 34px;
  }
  .hover-primary:hover,
  .hover-primary:hover .button-v2-icon {
    @apply text-text-active;
  }
  .hover-secondary:hover,
  .hover-secondary:hover .button-v2-icon {
    @apply text-text-active-2;
  }
  .hover-tertiary:hover,
  .hover-tertiary:hover .button-v2-icon {
    @apply text-text-active-2;
  }
  .hover-destructive:hover,
  .hover-destructive:hover .button-v2-icon { 
    @apply text-border;
  }
  .inactive-opacity {
    opacity: var(--inactive-button-opacity);
  }
</style>
