<template>
  <transition name="fade">
    <div
      v-if="currentModal"
      class="modal-outer real-100vh"
    >
      <div
        class="modal-overlay"
        :class="{ 'mt-s16': withNavbar }"
        @click="handleClose"
      />

      <ToastMessage
        class="modal-toast-message"
        :open="showToast"
        :type="toastData.type"
        :message="toastData.message"
        :component="toastData.component"
        :dismiss="toastData.dismiss"
      />

      <div
        ref="modalContainer"
        class="modal-container rounded-24 xl:w-screen xl:rounded-none"
        :class="[backgroundClass, paddingClass, heightClass]"
      >
        <div
          v-if="!hideCloseButton"
          class="flex justify-end bg-transparent z-20 md:mt-s4 md:ml-s4 md:mr-s4"
          :class="{ 'absolute': floatingCloseButton }"
        >
          <font-awesome-icon
            role="icon"
            icon="times"
            class="close-icon"
            :class="closeButtonClass"
            @click="handleClose"
          />
        </div>

        <div
          v-if="showPreviousButton"
          class="flex justify-left mb-s20"
        >
          <IconChevronLeft
            class="cursor-pointer fill-current h-s20 w-s12 text-text-body"
            @click="handlePrevious"
          />
        </div>

        <div
          class="modal-inner"
          ref="modalInner"
        >
          <component
            :is="currentModal"
            :ref="currentModalId"
            v-bind="currentModalProps"
            v-bind:hideCloseButton="hideCloseButton"
            v-bind:handleClose="handleClose"
            v-bind:dark="currentTheme === 'dark'"
            @modalLoaded="handleScrollLock"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { IconChevronLeft } from '@/assets/icons';
import { ToastMessage } from '@/components/misc';
import { mapState } from 'vuex';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  name: 'Modal',
  components: {
    ToastMessage,
    IconChevronLeft,
    // dynamic imports
    'AboutInviteCodes': () => import('@/components/modal/AboutInviteCodes'),
    'AchOverview': () => import('@/modules/walletv2/WalletModals/AchOverview/index.vue'),
    'AddCreditCardMethod': () => import('@/modules/walletv2/WalletModals/AddCreditCardMethod/index.vue'),
    'AnnouncementModal': () => import('@/components/modal/AnnouncementModal'),
    'AssetLevel': () => import('@/components/modal/AssetLevel/AssetLevel'),
    'AssetViewer': () => import('@/components/modal/AssetViewer'),
    'BuyDropConfirmation': () => import('@/components/modal/BuyDropConfirmation'),
    'BuyPlayer': () => import('@/components/modal/BuyPlayer.vue'),
    'BuyPlayerConfirmation': () => import('@/components/modal/BuyPlayerConfirmation.vue'),
    'Card3ds': () => import('@/modules/walletv2/WalletModals/Card3ds'),
    'CardCcvConfirmation': () => import('@/modules/walletv2/WalletModals/CardCcvConfirmation'),
    'CardLadderHelp': () => import('@/components/modal/CardLadderHelp'),
    'ConfirmEmail': () => import('@/components/modal/ConfirmEmail.vue'),
    'ConfirmLogout': () => import('@/components/modal/ConfirmLogout.vue'),
    'CountryNotAllowed': () => import('@/components/modal/CountryNotAllowed'),
    'Custody': () => import('@/components/modal/Custody/Custody'),
    'DeleteAccountRequire2FA': () => import('@/components/modal/UserDeleteAccount/RequireTwoFactorAuthenticator/index.vue'),
    'DeleteAccountStepOneContactSupport': () => import('@/components/modal/UserDeleteAccount/StepOneContactSupport/index.vue'),
    'DeleteAccountStepTwoEmailConfirmation': () => import('@/components/modal/UserDeleteAccount/StepTwoEmailConfirmation/index.vue'),
    'DepositUsdc': () => import('@/modules/walletv2/WalletModals/DepositUsdc/index.vue'),
    'DepositUsdcConfirmation': () => import('@/modules/walletv2/WalletModals/DepositUsdcConfirmation/index.vue'),
    'DisableTwoFactorAuthentication': () => import('@/components/modal/twofa/DisableTwoFactorAuthentication.vue'),
    'DropBuy': () => import('@/modules/drops/modals/DropBuy'),
    'DropConfirmed': () => import('@/components/modal/DropConfirmed'),
    'EmailNotVerified': () => import('@/components/modal/EmailNotVerified.vue'),
    'EmailTokenExpired': () => import('@/components/modal/EmailTokenExpired.vue'),
    'EnableTwoFactorAuthentication': () => import('@/components/modal/twofa/EnableTwoFactorAuthentication.vue'),
    'ForgotPassword': () => import('@/components/modal/ForgotPassword.vue'),
    'FracPack': () => import('@/components/modal/FracPack'),
    'GlobalSearch': () => import('@/components/modal/GlobalSearch'),
    'KycAddress': () => import('@/modules/user/Modals/KycAddress/index.vue'),
    'KycBasicInfo': () => import('@/modules/user/Modals/KycBasicInfo/index.vue'),
    'KycCountry': () => import('@/modules/user/Modals/KycCountry'),
    'KycDocuments': () => import('@/modules/user/Modals/KycDocuments/index.vue'),
    'KycProofOfAddress': () => import('@/modules/user/Modals/KycProofOfAddress/KycProofOfAddress.vue'),
    'KycProofOfBank': () => import('@/modules/user/Modals/KycProofOfBank/KycProofOfBank.vue'),
    'KycSSN': () => import('@/modules/user/Modals/KycSSN/index.vue'),
    'KycStatus': () => import('@/modules/user/Modals/KycStatus/index.vue'),
    'MethodRemovalConfirmation': () => import('@/modules/walletv2/WalletModals/MethodRemovalConfirmation'),
    'MobileMenu': () => import('@/components/modal/MobileMenu'),
    'Onboard': () => import('@/components/modal/Onboard/Onboard.vue'),
    'OpenPackModal': () => import('@/components/fracpack/OpenPackModal'),
    'PackOpen': () => import('@/components/modal/PackOpen'),
    'PaymentInterface': () => import('@/modules/walletv2/WalletModals/PaymentInterface'),
    'ResetPassword': () => import('@/components/modal/ResetPassword.vue'),
    'ReviewFracpackModal': () => import('@/components/fracpack/ReviewFracpackModal'),
    'RewardDisclaimer': () => import('@/modules/rewards/modals/RewardDisclaimer'),
    'RewardGemsEarned': () => import('@/modules/rewards/modals/RewardGemsEarned/index.vue'),
    'RewardGemsKyc': () => import('@/modules/rewards/modals/RewardGemsKyc/index.vue'),
    'RewardOrderConfirmed': () => import('@/modules/rewards/modals/RewardOrderConfirmed'),
    'RewardReviewOrder': () => import('@/modules/rewards/modals/RewardReviewOrder'),
    'SellPlayer': () => import('@/components/modal/SellPlayer.vue'),
    'SellPlayerConfirmation': () => import('@/components/modal/SellPlayerConfirmation.vue'),
    'Share': () => import('@/components/modal/Share/Share'),
    'SubmitFeedback': () => import('@/components/modal/SubmitFeedback'),
    'TakeCustodyModal': () => import('@/views/TakeCustody/TakeCustodyModal.vue'),
    'TradeWidget': () => import('@/modules/trade/modals/TradeWidget'),
    'TwoFactorAuthenticationBackupCode': () => import('@/components/modal/twofa/TwoFactorAuthenticationBackupCode.vue'),
    'TwoFactorAuthenticationDisabled': () => import('@/components/modal/twofa/TwoFactorAuthenticationDisabled.vue'),
    'TwoFactorAuthenticationEmailVerification': () => import('@/components/modal/twofa/TwoFactorAuthenticationEmailVerification.vue'),
    'TwoFactorAuthenticationEnabled': () => import('@/components/modal/twofa/TwoFactorAuthenticationEnabled.vue'),
    'TwoFactorAuthenticationReset': () => import('@/components/modal/twofa/TwoFactorAuthenticationReset.vue'),
    'TwoFactorAuthenticationVerification': () => import('@/components/modal/twofa/TwoFactorAuthenticationVerification.vue'),
    'UserAgreement': () => import('@/components/modal/UserAgreement/UserAgreement.vue'),
    'VerifyAuthentication': () => import('@/components/modal/twofa/VerifyAuthentication.vue'),
    'Video': () => import('@/components/modal/Video.vue'),
    'WalletAddTransferMethod': () => import('@/modules/walletv2/WalletModals/AddTransferMethod/index.vue'),
    'WalletAddWireTransferDetails': () => import('@/modules/walletv2/WalletModals/AddWireTransferDetails/index.vue'),
    'WalletResendWithdrawalConfirmation': () => import('@/modules/walletv2/WalletModals/ResendWithdrawalConfirmation/index.vue'),
    'WalletTransactionCancelOrder': () => import('@/modules/walletv2/WalletTransactions/Transaction/CancelOrder/index.vue'),
    'WalletTransferLimit': () => import('@/modules/walletv2/WalletModals/TransferLimit/index.vue'),
    'WalletTransferSuccess': () => import('@/modules/walletv2/WalletModals/TransferSuccess/index.vue'),
    'WalletWireTransferDetails': () => import('@/modules/walletv2/WalletModals/WireTransferDetails/index.vue'),
    'WebRequiresUserFlag': () => import('@/components/modal/WebRequiresUserFlag'),
    'WithdrawUsdc': () => import('@/modules/walletv2/WalletModals/WithdrawUsdc/index.vue'),
    'WithdrawalConfirmation': () => import('@/modules/walletv2/WalletModals/WithdrawalConfirmation/index.vue'),
    'YourSecureKey': () => import('@/components/modal/twofa/YourSecureKey.vue'),
  },

  props: {
    withNavbar: Boolean,
  },

  beforeDestroy() {
    clearAllBodyScrollLocks();
  },

  computed: {
    ...mapState('ui', [
      'toastData',
      'toastOpen',
      'currentModal',
      'currentModalProps',
    ]),

    backgroundClass() {
      return this.lodashGet(this.currentModalProps, 'customBackground', 'bg-background-page');
    },

    currentModalId() {
      return `current-modal-${this.currentModal}`;
    },

    customPadding() {
      return !!this.lodashGet(this.currentModalProps, 'noPadding', false);
    },

    floatingCloseButton() {
      return !!this.lodashGet(
        this.currentModalProps,
        'floatingCloseButton',
        false
      );
    },

    hideCloseButton() {
      return !this.lodashGet(this.currentModalProps, 'isClosable', true);
    },

    heightClass() {
      return this.isMobileDevice ? 'real-100vh' : 'modal-container-height-desktop';
    },

    paddingClass() {
      return this.customPadding ? 'p-s0' : 'p-s20';
    },

    closeButtonClass() {
      return this.customPadding ? 'pt-s20 pr-s20' : 'p-s0';
    },

    showPreviousButton() {
      if (this.currentModalProps) {
        const { hasPrevious } = this.currentModalProps;
        return !!hasPrevious;
      }

      return false;
    },

    showToast() {
      return this.toastOpen && !!this.toastData.component === false;
    },
  },

  methods: {
    handleClose() {
      if (this.currentModalProps) {
        const { customClose } = this.currentModalProps;

        if (this.hideCloseButton && !this.isMobileDevice) {
          return;
        }

        if (customClose) {
          return customClose();
        }
      }

      this.hideModal();
    },

    handlePrevious() {
      const { handlePrevious } = this.currentModalProps;
      return handlePrevious();
    },

    async triggerMethod() {
      try {
        const request = await this.$store.dispatch('ui/modalMethod', {
          component: this.currentModalProps.component,
          method: this.currentModalProps.method,
        });
        return {
          result: request,
          error: null,
        };
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
        return {
          result: null,
          error: err,
        };
      }
    },

    handleScrollLock() {
      // No open modal --> no lock
      if (!this.currentModal) {
        clearAllBodyScrollLocks();
        return;
      }

      // Make sure it is already rendered
      let modalElement = this.$refs[this.currentModalId];
      modalElement = modalElement ? modalElement.$el : null;
      if (!modalElement) {
        setTimeout(() => {
          return this.handleScrollLock();
        }, 250); // keep re-trying until there is an element to lock
        return;
      }

      // There is an open modal --> try to acquire element to lock
      const targetElements = document.getElementsByClassName('scrollable-modal-element');
      const targetElement = Array.from(targetElements).find(el => !el.classList.contains('modal-container'));
      const modalContainer = this.lodashGet(this.$refs, 'modalContainer', null);
      const modalInner = this.lodashGet(this.$refs, 'modalInner', null);

      // If there is a custom element to apply the lock on, do it
      if (targetElement) {
        modalInner.classList.add('overflow-hidden');
        modalContainer.classList.remove('scrollable-modal-element');
        clearAllBodyScrollLocks();
        disableBodyScroll(targetElement);
        return;
      }

      // Otherwise, just apply lock on modal root
      if (modalContainer) {
        modalContainer.classList.add('scrollable-modal-element');
        modalInner.classList.remove('overflow-hidden');
        clearAllBodyScrollLocks();
        disableBodyScroll(modalContainer);
      }
    }
  },

  watch: {
    currentModal() {
      this.handleScrollLock();
    },
  },
};
</script>

<style scoped>
.modal-outer {
  @apply fixed w-screen z-modal;
}

.modal-container {
  @apply flex flex-col border border-border fixed z-modal top-0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  scrollbar-width: none;
}

.modal-container::-webkit-scrollbar {
  width: 0;
}

.modal-container-height-desktop {
  max-height: calc(90%);
}

.modal-inner {
  @apply flex flex-1 justify-center;
}

.modal-overlay {
  @apply fixed w-screen h-screen bg-background-page opacity-80 z-modal top-0 left-0;
}

.close-icon {
  @apply text-text-inactive cursor-pointer box-content;
  width: 25px;
  height: 25px;
}

.modal-toast-message {
  @apply fixed top-0 left-0 right-0 z-modal-toast;
}
</style>




