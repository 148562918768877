import router from '@/vue-router';
import DefaultQuery from '@/graphql/queries/DefaultQuery.gql';

router.addRoute({ 
  component: () => import('@/views/AboutUs'),
  name: 'about-us',
  path: '/about-us',
  meta: {
    bypassLoading: true,
    template: 2,
    query: DefaultQuery
  }
});
