<template>
  <div role="progressbar" class="skeleton-loading"
    :style="!hasCustomClass && {
      width: type === 'circle' ? currentHeight : currentWidth,
      height: currentHeight,
      borderRadius: type === 'circle' ? '100%' : borderRadius,
    }">
  </div>
</template>

<script>
export default {
  name: 'SkeletonLoading',

  props: {
    hasCustomClass: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number],
      default: '100%'
    },
    height: {
      type: [String, Number],
      default: '100%'
    },
    type: {
      type: String,
      default: 'rectangle'
    },
    borderRadius: {
      type: String,
      default: '15px'
    }
  },

  computed: {
    currentHeight(){
      return typeof this.height === 'string' ? this.height : `${this.height}px`;
    },
    currentWidth(){
      return typeof this.width === 'string' ? this.width : `${this.width}px`;
    },
  }
};
</script>

<style scoped>
.skeleton-loading {
  @apply overflow-hidden cursor-wait;
  background: var(--skeleton);
  background-size: 200%;
  animation: gradient 1s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}
</style>
