import router from '@/vue-router';
import WalletQuery from '@/graphql/queries/Wallet.gql';

router.addRoute({
  component: () => import('@/views/Wallet'),
  name: 'wallet',
  path: '/wallet',
  meta: {
    bypassLoading: true,
    query: WalletQuery,
    requiresAuth: true,
    template: 1,
  },
});

router.addRoute({
  component: () => import('@/views/Wallet'),
  name: 'wallet-tab',
  path: '/wallet/:tab',
  meta: {
    template: 1,
    query: WalletQuery,
    requiresAuth: true,
  }
});

router.addRoute({
  component: () => import('@/views/Wallet'),
  name: 'deposit',
  path: '/deposit',
  meta: {
    bypassLoading: true,
    query: WalletQuery,
    requiresAuth: true,
    template: 1,
  },
});

router.addRoute({ 
  name: 'orders',
  path: '/orders',
  redirect: '/wallet',
});

router.addRoute({ 
  name: 'orders-type',
  path: '/orders/:type',
  redirect: '/wallet',
});
