<template>
  <img
    @error="onError"
    @load="onLoad"
    :alt="alt"
    class="default-transition"
    :class="{'opacity-0': hideImage}"
    :src="source"
    v-bind="imgTagSizeAttributes"
  />
</template>

<script>

export const IMAGE_SIZES = {
  about_us_careers: { width: 292, height: 385 },
  about_us_careers_mobile: { width: 292, height: 385 },
  about_us_investors: { width: 120, height: 120 },
  about_us_investors_mobile: { width: 120, height: 120 },
  about_us_press: { width: 260, height: 173 },
  about_us_press_mobile: { width: 260, height: 173 },
  about_us_press_logo: { width: 76, height: 20 },
  about_us_press_logo_mobile: { width: 76, height: 20 },
  asset_card: { width: 139, height: 230 },
  asset_card_mobile: { width: 139, height: 230 },
  asset_card_small: { width: 103, height: 176 },
  asset_card_small_mobile: { width: 89, height: 152 },
  asset_card_extra_small: { width: 70, height: 120 },
  asset_card_extra_small_mobile: { width: 70, height: 120 },
  asset_detail: { width: 330, height: 558 },
  asset_detail_mobile: { width: 290, height: 485 },
  collection_asset_card: { width: 230, height: 340 },
  collection_asset_card_mobile: { width: 180, height: 300 },
  collection_info: { width: 180, height: 150 },
  collection_info_mobile: { width: 375, height: 400 },
  drop: { width: 305, height: 544 },
  drop_mobile: { width: 192, height: 325 },
  drop_banner: { width: 124, height: 210 },
  drop_banner_mobile: { width: 124, height: 210 },
  drop_banner_background: { width: 451, height: 765 },
  drop_banner_background_mobile: { width: 451, height: 765 },
  drop_banner_background_small: { width: 393, height: 666 },
  drop_banner_background_small_mobile: { width: 451, height: 765 },
  drop_banner_small: { width: 95, height: 160 },
  drop_banner_small_mobile: { width: 95, height: 160 },
  educational_article: { width: 408, height: 228 },
  educational_article_mobile: { width: 408, height: 228 },
  footer: { width: 396, height: 174 },
  footer_mobile: { width: 396, height: 174 },
  frac_pack: { width: 195, height: 300 },
  frac_pack_mobile: { width: 175, height: 271 },
  frac_pack_asset: { width: 176, height: 298 },
  frac_pack_asset_mobile: { width: 176, height: 298 },
  home_banner: { width: 75, height: 112 },
  home_banner_mobile: { width: 53, height: 84 },
  home_carousel: { width: 916, height: 350 },
  home_carousel_mobile: { width: 916, height: 350 },
  home_asset_card: { width: 100, height: 170 },
  home_asset_card_mobile: { width: 100, height: 170 },
  home_blog_author: { width: 24, height: 24 },
  home_blog_author_mobile: { width: 24, height: 24 },
  home_blog_card: { width: 380, height: 200 },
  home_blog_card_mobile: { width: 380, height: 200 },
  home_category_card: { width: 378, height: 251 },
  home_category_card_mobile: { width: 378, height: 251 },
  home_collection_card: { width: 200, height: 150 },
  home_collection_card_mobile: { width: 200, height: 150 },
  home_drop_card: { width: 207, height: 354 },
  home_drop_card_mobile: { width: 207, height: 354 },
  home_drop_card_small: { width: 178, height: 300 },
  home_drop_card_small_mobile: { width: 178, height: 300 },
  invite_frac_pack: { width: 92, height: 150 },
  invite_frac_pack_mobile: { width: 92, height: 150 },
  market_list_asset: { width: 60, height: 60 },
  market_list_asset_mobile: { width: 60, height: 60 },
  market_list_player: { width: 120, height: 120 },
  market_list_player_mobile: { width: 120, height: 120 },
  news: { width: 168, height: 168 },
  news_mobile: { width: 84, height: 84 },
  onboard: { width: 500, height: 500 },
  onboard_mobile: { width: 500, height: 500 },
  player_card: { width: 250, height: 199 },
  player_card_mobile: { width: 250, height: 199 },
  player_cards_list: { width: 100, height: 100 },
  player_cards_list_mobile: { width: 100, height: 100 },
  player_info: { width: 632, height: 480 },
  player_info_mobile: { width: 632, height: 480 },
  player_item: { width: 58, height: 98 },
  player_item_mobile: { width: 58, height: 98 },
  player_modal: { width: 446, height: 208 },
  player_modal_mobile: { width: 446, height: 208 },
  sport_background: { width: 64, height: 64 },
  sport_background_mobile: { width: 64, height: 64 },
  trade_widget: { width: 206, height: 345 },
  trade_widget_mobile: { width: 160, height: 270 },
  trending_player: { width: 300, height: 300 },
  trending_player_mobile: { width: 160, height: 160 },
};

export default {
  name: 'ImageResize',

  data() {
    return {
      hideImage: false,
    };
  },

  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type:String,
      default: '',
    },
    onImageLoad: {
      type: Function,
    },
    type: {
      type: String,
      default: 'asset_card',
      validate: (value) => IMAGE_SIZES.includes(value),
    },
    width: Number,
    height: Number,
    bypassImgTagSizeAttributes: {
      type: Boolean,
      default: false,
    },
    startHidden: {
      type: Boolean,
      default: false,
    }
  },

  created() {
    if (this.startHidden) {
      this.hideImage = true;
    }
  },

  computed: {
    imgWidth() {
      return this.width || IMAGE_SIZES[this.responsiveType].width;
    },
    imgHeight() {
      return this.height || IMAGE_SIZES[this.responsiveType].height;
    },
    responsiveType() {
      return this.isSmallMobileDevice && !this.type.includes('_mobile') ? `${this.type}_mobile` : this.type;
    },
    source() {
      const validCdns = /(https:\/\/img.dibbscdn.com\/|https:\/\/img.dibbs.io\/)/g;
      const cdnResize = /cdn-cgi\/image\/width=\d*,height=\d*\//g;
      if (this.src && validCdns.test(this.src)) {
        const [, cdn, file] = this.src.split(validCdns);
        const fileWithoutResize = file.split(cdnResize);
        const filePath = fileWithoutResize[fileWithoutResize.length - 1];
        return `${cdn}cdn-cgi/image/width=${this.adjustSizeWithDpi(this.imgWidth)},height=${this.adjustSizeWithDpi(this.imgHeight)},format=webp/${filePath}`;
      }
      return this.src;
    },
    imgTagSizeAttributes() {
      const sizeAttributes = {
        height: this.imgHeight,
        width: this.imgWidth,
      };

      return this.bypassImgTagSizeAttributes ? {} : sizeAttributes;
    }
  },

  methods: {
    adjustSizeWithDpi(size) {
      if(!size) { return; }
      const dpi = window.devicePixelRatio;
      return Math.round(size * dpi);
    },

    onError() {
      this.hideImage = true;
    },

    onLoad() {
      this.hideImage = false;
      this.onImageLoad && this.onImageLoad();
    },
  }
};
</script>
