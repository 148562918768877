<template>
  <TradeOrderbookLayout
    @onDropdownSelect="dropdownSelect"
    :buys="buys"
    :dropdownOptions="dropdownOptions"
    :dropdownSelected="dropdownSelected"
    :isLoading="isLoading"
    :lastPrice="lastPrice"
    :sells="sells"
  />
</template>

<script>
import { mapActions } from 'vuex';
import TradeOrderbookLayout from './layout';

export default {
  name: 'TradeOrderbook',
  components: {
    TradeOrderbookLayout,
  },
  props: {
    orderbook: Object,
    lastPrice: Number,
  },

  data() {
    const largeBuy = this.orderbook && this.orderbook.buys.length && this.orderbook.buys[0].price_rounded >= 10000;

    return {
      dropdownSelected: 10,
      dropdownOptions: largeBuy ? [1, 5, 10, 50, 100, 1000] : [1, 5, 10, 50, 100],
      isLoading: false,
      numRows: 5,
    };
  },

  computed: {
    sells() {
      const sells = [...this.orderbook.sells];
      const length = sells.length;

      return sells.reverse().splice(length - this.numRows, length).map(x => {
        const price_rounded = x.price_rounded;
        const amount = x.amount_base;
        return {
          ...x,
          price_rounded,
          amount,
          total: x.amount_quote,
        };
      });
    },

    buys() {
      const buys = [...this.orderbook.buys];

      return buys.splice(0, this.numRows).map(x => {
        const price_rounded = x.price_rounded;
        const amount = x.amount_base;
        return {
          ...x,
          price_rounded,
          amount,
          total: x.amount_quote,
        };
      });
    },
  },

  methods: {
    ...mapActions('trade', ['getOrderbook']),

    async dropdownSelect(option) {
      const lastDropdownValue = this.dropdownSelected;

      try {
        this.isLoading = true;

        this.dropdownSelected = option;
        await this.getOrderbook({
          pair: this.$route.params.pair,
          option,
        });
      } catch (err) {
        this.dropdownSelected = lastDropdownValue;
        this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
  .orderbook {
    @apply font-semibold;
  }

  .dropdown-content {
    @apply absolute mt-8 bg-background-primary border border-border rounded text-sm12 cursor-pointer z-10;
  }

  .my-s14 {
    margin: 14px 0;
  }
</style>
