<template>
  <div
    id="app"
    class="font-main bg-background-page text-emphasis"
    :class="responsiveClasses"
    :style="appStyle"
  >
    <div v-if="isOverlayLoading">
      <div class="loading-overlay-bg"></div>
      <div class="loading-overlay">
        <Loading version="v3" />
      </div>
    </div>


    <!-- Full screen loading for first page load -->
    <div v-if="isInitialLoading && template">
      <Navbar v-if="template !== 4" />
      <ToastMessage
        :open="isToastOpen"
        :type="toastData.type"
        :message="toastData.message"
        :component="toastData.component"
        :dismiss="toastData.dismiss"
      />
      <Loading version="v3" />
    </div>

    <template v-else-if="!debugMode || isDebugging || isAuthenticated()">

      <!-- Layout #1 - with navbar, sidebar, footer and content -->
      <template v-if="(template === 1 || template === 2)">
        <Navbar />
        <Banner
          v-if="showBanner"
          :banners="currentPageBanners($route.name)"
          @closeBanner="setBannerInactive"
          @closeAllBanners="dismissAllBanners"
        />
        <ToastMessage
          :open="isToastOpen"
          :type="toastData.type"
          :message="toastData.message"
          :component="toastData.component"
          :dismiss="toastData.dismiss"
        />
        <div
          class="page-container flex justify-center overflow-y-auto overflow-x-hidden"
          ref="scrolled"
        >
          <div class="flex justify-between w-full xxl:justify-center">
            <div
              class="view-default"
              v-if="bypassLoading"
            >
              <router-view :key="$route.fullPath" />
            </div>
            <div
              class="view-default"
              v-else-if="!isLoading"
              :class="visibility"
            >
              <router-view :key="$route.fullPath" />
            </div>
          </div>
        </div>
        <MobileBottomMenu v-if="isMediumMobileDevice" />
      </template>

      <!-- Layout #3 - just navbar and content -->
      <template v-else-if="template === 3">
        <Navbar />
        <ToastMessage
          :open="isToastOpen"
          :type="toastData.type"
          :message="toastData.message"
          :component="toastData.component"
          :dismiss="toastData.dismiss"
        />
        <div
          class="page-container overflow-y-auto"
          v-if="!isLoading"
        >
          <router-view :key="$route.fullPath" />
        </div>
      </template>

      <!-- Layout #4 - just content -->
      <template v-else-if="template === 4">
        <div
          class="h-screen overflow-y-auto"
          v-if="!isLoading"
        >
          <router-view :key="$route.fullPath" />
        </div>
      </template>

      <!-- Layout #5 - no top navbar on mobile -->
      <template v-if="template === 5">
        <Navbar v-if="!isMediumMobileDevice" />
        <Banner
          v-if="showBanner"
          :banners="currentPageBanners($route.name)"
          @closeBanner="setBannerInactive"
          @closeAllBanners="dismissAllBanners"
        />
        <ToastMessage
          :open="isToastOpen"
          :type="toastData.type"
          :message="toastData.message"
          :component="toastData.component"
          :dismiss="toastData.dismiss"
        />
        <div
          class="page-container flex justify-center overflow-y-auto overflow-x-hidden"
          ref="scrolled"
        >
          <div class="flex justify-between w-full xxl:justify-center">
            <div
              class="view-default"
              v-if="bypassLoading"
            >
              <router-view :key="$route.fullPath" />
            </div>
            <div
              class="view-default"
              v-else-if="!isLoading"
              :class="visibility"
            >
              <router-view :key="$route.fullPath" />
            </div>
          </div>
        </div>
      </template>
    </template>

    <div
      v-else-if="!isInitialLoading"
      class="flex flex-col justify-center items-center w-full h-full text-xl20 font-bold px-5"
    >
      {{ $t('errors.please_use_the_app') }}

      <ButtonV2
        @onClick="redirectTo"
        class="max-w-sm"
        testId="btn__open-app"
        :label="$t('open_app')"
        wide
      />
    </div>
    <Modal
      v-if="!debugMode || isDebugging || isAuthenticated()"
      :withNavbar="false"
    />
    <div
      id="captcha"
      class="captcha-container"
    ></div>
    <Prompt />
  </div>
</template>

<script>
import { debounce } from 'vue-debounce';
import Navbar from '@/components/navbar/Navbar.vue';
import MobileBottomMenu from '@/components/navbar/MobileBottomMenu';
import Modal from '@/components/modal/Index.vue';
import { Banner, Loading, Prompt, ToastMessage } from '@/components/misc';
import ButtonV2 from '@/stories/misc/ButtonV2.vue';
import DefaultQuery from '@/graphql/queries/DefaultQuery.gql';
import ConfirmEmail from '@/graphql/mutations/ConfirmEmail.gql';
import TWO_FACTOR_AUTH_RESET_STEP_TWO from '@/graphql/mutations/TwoFactorAuthResetStepTwo.gql';
import TWO_FACTOR_AUTH_SETUP_STEP_TWO from '@/graphql/mutations/TwoFactorAuthSetupStepTwo.gql';
import CIRCLE_AUTHORIZE_WITHDRAWAL from '@/graphql/mutations/CircleAuthorizeWithdrawal.gql';
import ANNOUNCEMENT_MODAL from '@/graphql/queries/Announcements.gql';
import store from './store';
import { onLogin, getTokenName } from '@/vue-apollo';
import { mapState, mapGetters, mapActions } from 'vuex';
import { navbarList } from '@/components/navbar/Menu';
import ExternalUrl from '@/enums/ExternalUrl';

function whitelistedPages() {
  if (
    window.location.pathname === '/privacy-policy' ||
    window.location.pathname === '/consignment-agreement' ||
    window.location.pathname === '/user-agreement' ||
    window.location.pathname === '/fee-schedule' ||
    window.location.pathname.includes('/deposit')
  ) { return true; }
  return false;
}

export default {
  name: 'app',
  store,
  components: {
    Banner,
    ButtonV2,
    Loading,
    MobileBottomMenu,
    Modal,
    Navbar,
    Prompt,
    ToastMessage,
  },

  data() {
    const dontDebugArray = ['', 'null', 'undefined', 'false'];
    const dontDebugIndex = dontDebugArray.indexOf(process.env.VUE_APP_DEBUG_MODE);

    return {
      debugMode: dontDebugIndex === -1,
      invalidPages: ['/login', '/sign-up', '/reset-password', 'invite=', '/reset-2fa'],
      isDebugging: (!!localStorage.getItem('debug') || whitelistedPages()),
      navbarLinks: navbarList.map((item) => item.to),
      possibleLoginToken: localStorage.getItem(getTokenName()) || '',
      scrollPositions: {},
      visibility: 'hide',
      announcementUrl: '',
    };
  },

  async created() {
    // Setting theme
    const theme = localStorage.getItem('theme');
    document.documentElement.classList.add(theme);
    store.commit('ui/setState', { stateName: 'currentTheme', stateData: theme });

    // Handle window-resize related stuff
    this.handleWindowResize();
    const windowResizeDebounce = debounce(() => {
      this.handleWindowResize();
    }, 300);
    window.addEventListener('resize', function () {
      windowResizeDebounce();
    });

    // Setting scroll position
    this.$router.beforeEach((to, from, next) => {
      if (to === from) { return; }
      store.dispatch('ui/hideToast');
      next();
    });

    this.$router.afterEach((to, from) => {
      this.scrollPositions[from.name] = this.lodashGet(this.$refs, 'scrolled.scrollTop', 0);
      this.scrollPositions[from.name] = this.lodashGet(this.$refs, 'scrolled.scrollTop', 0);
      this.$nextTick(() => {
        const scroll = this.scrollPositions.hasOwnProperty(to.name) && to.meta.fromHistory ? this.scrollPositions[to.name] : 0;
        if (this.$refs.scrolled) {
          this.$refs.scrolled.scrollTop = scroll;
        }
      });


      this.getAnnouncements();
    });
  },

  async mounted() {
    await onLogin(this.$apollo, this.possibleLoginToken);
    await this.$store.dispatch('events/fetchEvents');
  },

  computed: {
    ...mapGetters('user', ['getUserPermissions']),
    ...mapGetters('banner', ['currentPageBanners']),
    ...mapState('ui', ['currentModal', 'is_mobile', 'isOverlayLoading', 'hasSeenAnnouncement', 'toastData', 'toastOpen', 'windowWidth']),

    appStyle() {
      return !this.isHomePage && ({ minHeight: '100vh' });
    },
    bypassLoading() {
      return this.$route.meta.bypassLoading;
    },
    isHomePage() {
      if (this.$router.history.current.name) {
        return ['home'].includes(this.$router.history.current.name);
      }
      return false;
    },
    isInitialLoading() {
      return this.$store.state.ui.initialLoading;
    },
    isLoading() {
      return this.globalIsLoading;
    },
    isToastOpen() {
      return this.toastOpen && !this.currentModal;
    },
    isUserAuthenticated() {
      return this.$store.state.api.isAuthenticated;
    },
    responsiveClasses() {
      const size = {
        xs: 375,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200
      };

      return {
        'is-device-mobile': this.is_mobile.any,
        'is-device-phone': this.is_mobile.phone,
        'is-device-tablet': this.is_mobile.tablet,
        'is-device-desktop': !this.is_mobile.any,

        'is-screen-xs-down': this.windowWidth < size.xs,
        'is-screen-xs-only': this.windowWidth < size.xs,
        'is-screen-xs-up': this.windowWidth >= size.xs,

        'is-screen-sm-down': this.windowWidth < size.xs,
        'is-screen-sm-only': this.windowWidth >= size.xs && this.windowWidth < size.sm,
        'is-screen-sm-up': this.windowWidth >= size.sm,

        'is-screen-md-down': this.windowWidth < size.md,
        'is-screen-md-only': this.windowWidth >= size.md && this.windowWidth < size.lg,
        'is-screen-md-up': this.windowWidth >= size.md,

        'is-screen-lg-down': this.windowWidth < size.lg,
        'is-screen-lg-only': this.windowWidth >= size.lg && this.windowWidth < size.xl,
        'is-screen-lg-up': this.windowWidth >= size.lg,

        'is-screen-xl-down': this.windowWidth < size.xl,
        'is-screen-xl-only': this.windowWidth >= size.lg && this.windowWidth < size.xl,
        'is-screen-xl-up': this.windowWidth >= size.xl,
      };
    },
    showBanner() {
      return this.isUserAuthenticated;
    },
    template() {
      return this.$route.meta.template;
    },
    user() {
      return this.$store.state.api.user;
    },
  },

  methods: {
    ...mapActions('user', ['loadUserPermissions']),
    ...mapActions('banner', ['setBannerInactive', 'setBannerActive', 'updateActionUrl', 'hideAllBanners']),
    ...mapActions('api', ['updateUserEmailStatus']),
    ...mapActions('rewards', ['getRewardsState', 'setIsRewardsStateLoading']),
    ...mapActions('ui', ['showToast']),

    async getAnnouncements() {
      if (!this.hasSeenAnnouncement) {
        await this.apolloApiCall({
          query: ANNOUNCEMENT_MODAL
        })
          .then(({ data: { features: { announcementUrl } } }) => this.announcementUrl = announcementUrl);
      }
    },

    showAnnouncementModal() {
      const notAllowedRoutes = ['reset-password', 'home-redirect', 'registration/status'];
      const isAllowedRoute = !notAllowedRoutes.includes(this.$route.name);

      if (!this.hasSeenAnnouncement && !!this.announcementUrl && !!isAllowedRoute) {
        this.showModal('AnnouncementModal', { announcementUrl: this.announcementUrl });
      }
    },

    handleWindowResize() {
      store.commit('ui/setWindowDimensions', { windowHeight: window.innerHeight, windowWidth: window.innerWidth, });
      this.setWindowNavigator();
      // Have real vh value to use on mobile devices -- more info here :https://css-tricks.com/the-trick-to-viewport-units-on-mobile
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    },

    async checkSpecialRoutes(data) {
      switch (this.$route.path) {
        case '/confirm-email':
          try {
            if (!this.lodashGet(data, 'current_user.user_info.email_verified', false)) {
              await this.apolloApiCall({
                mutation: ConfirmEmail,
                variables: {
                  secret: this.lodashGet(this.$route, 'query.token', ''),
                }
              });

              await this.updateUserEmailStatus(true);

              this.showModal('ConfirmEmail', {
                isClosable: false,
                noPadding: true,
              });
            }
          } catch (err) {
            await this.showError(err);
          }
          break;
        case '/registration/status':
          if (this.isAuthenticated()) {
            this.showModal('KycStatus');
          } else {
            this.goToInternalPageGlobal('/login');
          }
          break;
        case '/reset-2fa':
          try {
            await this.apolloApiCall({
              mutation: TWO_FACTOR_AUTH_RESET_STEP_TWO,
              variables: {
                secret: this.lodashGet(this.$route, 'query.token', ''),
              },
            });

            this.showModal('TwoFactorAuthenticationReset');
          } catch (err) {
            await this.showError(err);
          }
          break;
        case '/reset-password':
          this.showModal('ResetPassword');
          break;
        case '/setup-2fa':
          try {
            const response = await this.apolloApiCall({
              mutation: TWO_FACTOR_AUTH_SETUP_STEP_TWO,
              variables: {
                secret: this.lodashGet(this.$route, 'query.token', ''),
              },
            });

            this.showModal('EnableTwoFactorAuthentication', response.data._twofa_setup_step2);
          } catch (err) {
            await this.showError(err);
          }
          break;
        case '/withdraw/authorize':
          try {
            await this.apolloApiCall({
              mutation: CIRCLE_AUTHORIZE_WITHDRAWAL,
              variables: {
                withdrawalId: this.lodashGet(this.$route, 'query.id', ''),
              },
            });

            this.showModal('WithdrawalConfirmation', {
              isClosable: false,
              noPadding: true,
            });
          } catch (err) {
            this.showModal('WithdrawalConfirmation', {
              success: false,
              isClosable: false,
              noPadding: true,
            });
          }
          break;
      }
    },

    dismissAllBanners() {
      this.currentPageBanners(this.$route.name).map((banner) => {
        this.setBannerInactive(banner.name);
      });
    },

    async managerBanners() {
      await this.loadUserPermissions();
      const { withdraw, deposit } = this.getUserPermissions;
      const isHomePage = this.$route.name === 'home';

      if (isHomePage && (!withdraw || !deposit)) {
        this.showToast({
          toastData: {
            component: 'KycStatusBanner',
            dismiss: true,
            type: 'success',
          },
          waitInDays: 30,
        });
      } else if (withdraw) {
        this.updateActionUrl({
          name: 'sell_card_banner',
          actionUrl: ExternalUrl.SELL_WITH_DIBBS,
        });
        this.setBannerActive({
          name: 'sell_card_banner',
          featureFlag: withdraw,
        });
      } else {
        this.hideAllBanners();
      }
    },

    // On page load, and in a single graphql query, load all the initial data needed
    async loadPageData() {
      this.isDebugging = (!!localStorage.getItem('debug') || whitelistedPages());

      if (this.debugMode && !this.isDebugging) {
        await this.handleLogout();
        localStorage.clear();
      }

      this.$store.commit('ui/setState', { stateName: 'loading', stateData: true });

      this.setIsRewardsStateLoading(true);

      try {
        const query = typeof (this.$route.meta.query) !== 'undefined' ? this.$route.meta.query : DefaultQuery;

        const response = await this.$apollo.query({
          query: query,
          variables: {
            chartEndTimeSeconds: parseInt(new Date().getTime() / 1000),
            [this.$route.meta.variable]: isNaN(this.$route.params[this.$route.meta.variable]) ? this.$route.params[this.$route.meta.variable] : parseFloat(this.$route.params[this.$route.meta.variable]),
          }
        });

        // If user data is available, user token is valid - auto-login
        if (response.data.current_user) {
          const data = {};
          data.token = this.possibleLoginToken;
          data.user = response.data.current_user;

          await this.$store.dispatch('events/setUserProps', {
            user: data.user,
            isMobileDevice: this.isMobileDevice,
          });
          // TODO: Separate the data by module
          this.$store.dispatch('user/updateModule', data.user);
          this.$store.dispatch('user/updateKycStatus', this.lodashGet(data.user, 'permissions.deposit', false));

          if (response.data.current_user.inventory_chart) {
            this.$store.dispatch('wallet/setProfitLoss', response.data.current_user.inventory_chart);
          }

          await this.getRewardsState();
          this.$store.commit('api/setLoginData', { data });

          await this.managerBanners(response.data.current_user.permissions);
        } else if (response.data.current_user === null) {
          await this.handleLogout();
          await this.$store.dispatch('events/unsetUserProps');
        }

        // Set state with the initial data
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log('----------------------------------------');
        }
        for (let [key, value] of Object.entries(response.data)) {
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.log('STATE LOADED', key, value);
          }
          this.$store.commit('api/setState', { stateName: key, stateData: value });
        }

        // TODO: Separate the data by module
        if (response.data.pair) {
          await this.$store.dispatch('asset/setCurrentPair', response.data.pair);
        }

        await this.checkSpecialRoutes(response.data);
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log('Error loading data - ', err.message);
        }
        // Upon not_authorized error, logout user
        if (err.graphQLErrors && err.graphQLErrors.length && err.graphQLErrors[0].message === 'not_authorized') {
          await this.handleLogout();
        }
      }
      await this.privateRouteCheck(this.$route);

      if (!this.isAuthenticated()) {
        // Set props for tracking/analytics
        await this.$store.dispatch('events/setGuestUserProps', this.isMobileDevice);

        // Handle referral onboarding
        if (this.$route.query.referralCode) {
          // Referral flow
          this.$store.commit('api/setState', {
            stateName: 'referralCode',
            stateData: this.$route.query.referralCode,
          });
        }
      }

      if (this.isInitialLoading) {
        this.$store.commit('ui/setState', { stateName: 'initialLoading', stateData: false });
        await this.$store.dispatch('events/startTracking', this.user);
      }

      this.$nextTick(() => {
        this.$store.commit('ui/setState', { stateName: 'loading', stateData: false });
      });
    },

    // Check if user can access this route
    async privateRouteCheck(to) {
      if (!this.isAuthenticated() && to.meta.requiresAuth) {
        await this.handleLogout();
        this.goToInternalPageGlobal('/login');
      } else if (this.isAuthenticated()) {
        const isInvalidPage = this.invalidPages.some((page) => to.path.includes(page));

        if (isInvalidPage) {
          this.goToInternalPageGlobal('/');
        }
      }
    },

    // Open url on Dibbs app
    redirectTo() {
      window.open(window.location.href.replace(window.location.origin, 'dibbs:/'));
    },

    // Depending on which page was the last one before the user is logged out, set which page it'll go back to once logged in
    setLastValidPage(lastValidPage) {
      const isInvalidPage = this.invalidPages.some((page) => lastValidPage.includes(page));

      if (isInvalidPage) {
        return;
      }

      this.$store.commit('ui/setState', { stateName: 'lastValidPage', stateData: lastValidPage });
    },

    // Depending on which page is being loaded, set which icon on the sidebar is highlighted
    setSidebarSelectedItem(sidebarSelectedItem) {
      this.$store.commit('ui/setState', { stateName: 'sidebarSelectedItem', stateData: sidebarSelectedItem });
    },

    setWindowNavigator(e) {
      const user_agent = e ? e.currentTarget.navigator : window.navigator;
      this.$store.dispatch('ui/setIsMobile', user_agent);
    },
  },

  watch: {
    isUserAuthenticated() {
      // Set Sift _userId
      const user = this.lodashGet(this.user, 'account_id', '');
      this.$sift.setUser(user);
    },

    $route(to, from) {
      if (from.name !== to.name || !this.lodashIsEqual(from.params, to.params)) {
        this.setLastValidPage(from.fullPath);
        this.setSidebarSelectedItem(to.name);
        this.loadPageData();
        this.$store.dispatch('events/pageView');
      }
    },

    isLoading() {
      if (!this.isLoading) {
        setTimeout(() => {
          this.visibility = 'show';

          this.showAnnouncementModal();
        }, 10); // nextTick was not doing the job, so I used a 10ms timeout to trigger the fade animation
      } else {
        this.visibility = 'hide';
      }
    },

    announcementUrl() {
      if (!this.isLoading) {
        this.showAnnouncementModal();
      }
    }
  },
};
</script>

<style src="./assets/css/font.css"></style>
<style src="./assets/css/style.css"></style>
<style src="./assets/css/notifications.css"></style>
<style src="./assets/css/countries.css"></style>
