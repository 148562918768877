export const sellWithDibbsSection = {
  title: 'sell_with_dibbs.title',
  subtitle: 'sell_with_dibbs.subtitle',
  buttonLabel: 'sell_with_dibbs.button',
  topics: [
    {
      description: 'sell_with_dibbs.topic_1.description',
      icon: 'IconShipInsureVault',
      title: 'sell_with_dibbs.topic_1.title',
    },
    {
      description: 'sell_with_dibbs.topic_2.description',
      icon: 'IconMonetization',
      title: 'sell_with_dibbs.topic_2.title',
    },
    {
      description: 'sell_with_dibbs.topic_3.description',
      icon: 'IconFeeStructure',
      title: 'sell_with_dibbs.topic_3.title',
    },
    {
      description: 'sell_with_dibbs.topic_4.description',
      icon: 'IconMarketingSupport',
      title: 'sell_with_dibbs.topic_4.title',
    },
  ],
};

export const valuePropositionSection = {
  title: 'value_proposition.title',
  subtitle: 'value_proposition.subtitle',
  topics: [
    {
      description: 'value_proposition.topic_1.description',
      icon: 'IconTokenize',
      title: 'value_proposition.topic_1.title',
    },
    {
      description: 'value_proposition.topic_2.description',
      icon: 'IconVault',
      title: 'value_proposition.topic_2.title',
    },
    {
      description: 'value_proposition.topic_3.description',
      icon: 'IconFractionalize',
      title: 'value_proposition.topic_3.title',
    },
    {
      description: 'value_proposition.topic_4.description',
      icon: 'IconMoney',
      title: 'value_proposition.topic_4.title',
    },
  ],
};
