import TRANSACTION_HISTORY_ACTIVE_ORDERS from '@/graphql/queries/TransactionHistory/ActiveOrders.gql';
import TRANSACTION_HISTORY_ALL from '@/graphql/queries/TransactionHistory/TransactionHistory.gql';
import { SET_TRANSACTIONS_HISTORY_ACTIVE_ORDERS } from '@/modules/walletv2/mutations';
import { SET_TRANSACTIONS_HISTORY_ALL } from '@/modules/walletv2/mutations';

export default {
  ALL: {
    query: TRANSACTION_HISTORY_ALL,
    mutation: SET_TRANSACTIONS_HISTORY_ALL,
  },
  ACTIVE_ORDERS: {
    query: TRANSACTION_HISTORY_ACTIVE_ORDERS,
    mutation: SET_TRANSACTIONS_HISTORY_ACTIVE_ORDERS,
  },
};
