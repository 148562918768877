import router from '@/vue-router';
import CollectionQuery from '@/graphql/queries/Collection.gql';

router.addRoute({
  component: () => import ('@/views/Collection'),
  name: 'collection',
  path: '/collection/:id_hr',
  meta: { template: 1, query: CollectionQuery, variable: 'id_hr', bypassLoading: true, },
});

router.addRoute({
  name: 'collection-action',
  path: '/collection/:id_hr/:action',
  redirect: '/collection/:id_hr',
});

router.addRoute({
  name: 'player',
  path: '/player/:id_hr',
  redirect: '/collection/:id_hr',
});

router.addRoute({
  name: 'player-action',
  path: '/player/:id_hr/:action',
  redirect: '/collection/:id_hr',
});
