<template>
  <div class="balance-resume">
    <div class="flex w-full">
      <div class="flex flex-col">
        <div class="uppercase font-bold flex text-n-sm text-text-inactive" @click="showTotalBalanceInfo()">
          {{ $t('current_balance.total_balance') }}
          <IconInfo class="w-s12 h-s12 fill-current cursor-pointer default-transition hover:opacity-60" />
        </div>
        <div class="text-n-3xl font-bold mt-2px md:text-n-xl">
          <AnimatedNumber :number="totalBalance" currency />
        </div>

        <div 
          data-testid="balance-change"
          :class="`text-n-md font-semibold mt-2px only-md:text-n-sm only-sm:text-n-sm xs:text-n-xs ${isBalancePositive ? 'text-text-positive' : 'text-text-negative'}`">
          <AnimatedNumber :number="balanceChange" signal currency/>&nbsp;
          <span class="text-n-sm" v-if="percentageChange !== null">
            (<AnimatedNumber :number="percentageChange" signal />%)
          </span>&nbsp;
          <span class="text-text-body text-emphasis">{{ balanceDateDescription }}</span>
        </div>
      </div>

      <div class="flex flex-col ml-n-4xl xl:hidden">
        <div class="uppercase font-bold flex text-n-sm text-text-inactive">{{ $t('current_balance.buying_power') }}</div>
        <div class="text-n-3xl font-bold mt-2px md:text-n-xl">
          {{ formattedBuyingPower }}
        </div>
      </div>

      <div 
        v-if="hasActiveOrders"
        class="xl:hidden flex flex-col ml-n-4xl">
        <div class="uppercase font-bold flex text-n-sm text-text-inactive">{{ $t('balance_short.active_orders') }}</div>
        <div class="text-n-3xl font-bold mt-2px md:text-n-xl">
          {{formattedActiveOrders}}
        </div>
      </div>
    </div>

    <div 
      class="flex items-center gap-n-lg">
      <ButtonV2 
        class="xl:hidden flex"
        :label="$t('balance_short.view_orders')"
        size="medium"
        @onClick="goToMyOrdersPage"
        testId="btn-view_orders"
        version="primary" 
      />

      <ButtonV2 
        :label="$t('wallet.add_funds')"
        :size="isMobileDevice ? 'small': 'medium'"
        testId="btn-funds"
        @onClick="goToDepositPage"
        version="primary" 
      />
    </div>
    
  </div>
</template>
<script>
import { IconInfo } from '@/assets/icons';
import AnimatedNumber from '@/components/misc/AnimatedNumber';
import ButtonV2 from '@/stories/misc/ButtonV2';

export default {
  name: 'BalanceResume',
  components: {
    AnimatedNumber,
    ButtonV2,
    IconInfo,
  },
  props: {
    activeOrdersLength: {
      type: Number,
      default: 0
    },
    balanceChange: {
      type: Number,
      default: 0
    },
    balanceDateDescription: String,
    buyingPower: {
      type: Number,
      default: 0
    },
    percentageChange: {
      type: Number,
      default: 0
    },
    totalBalance: {
      type: Number,
      default: 0
    },
  },
  computed: {
    isBalancePositive() {
      return this.balanceChange >= 0;
    },
    hasActiveOrders(){
      return !!this.activeOrdersLength;
    },
    formattedBuyingPower(){
      return this.numberFormat(this.buyingPower, 2, false, true);
    },
    formattedActiveOrders(){
      return this.activeOrdersLength >= 11 ? '10+' : this.activeOrdersLength;
    },
  },
  methods: {
    goToMyOrdersPage() {
      this.goToInternalPageGlobal('/orders/active');
    },
    goToDepositPage() {
      this.showModal('PaymentInterface', { isDeposit: true });
    },
  }
};
</script>
<style scoped>
  .balance-resume {
    @apply flex;
    min-height: 75px;
  }
</style>
