<template>
  <section>
    <Card>
      <template v-if="isMobileDevice">
        <TransactionListItemCard
          v-for="(item, index) in listResults" :key="index"
          :listItem="item"
          :itemType="type"
          :headers="listHeaders"
        />
      </template>

      <ul v-else>
        <li class="transaction-grid subheadline-small p-s20 border-b">
          <p v-for="(header,i) in headers" :key="i">
            {{ header }}
          </p>
        </li>

        <TransactionListItemRow
          v-for="(item, index) in listResults" :key="index"
          :listItem="item"
          :itemType="type"
        />
      </ul>
    </Card>
  </section>
</template>

<script>
import { Card } from '@/components/misc';
import TransactionListItemRow from '../TransactionsListItem/Row/index.vue';
import TransactionListItemCard from '../TransactionsListItem/Card/index.vue';

export default {
  name: 'TransactionsList',

  components: {
    Card,
    TransactionListItemRow,
    TransactionListItemCard,
  },

  props: {
    listHeaders: {
      type: Object,
      required: true,
    },

    listResults: {
      type: Array,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    trasactionItemStyle() {
      return `transaction-grid--${this.type}`;
    },

    headers() {
      return Object.values(this.listHeaders).filter(header => !!header);
    }
  }
};
</script>

<style>
/**
* Not scoped for DRY reasons
* TransactionsList -> TransactionsListItem && Storybook
*/
.transaction-grid {
  @apply gap-n-md grid;
  grid-template-columns: 4.25fr 0.75fr repeat(4, 1.25fr);
}
</style>