<template>
  <div class="flex flex-col">
    <component :is="icon" class="self-center w-s64 h-s64" />

    <h3 class="headline-medium topic-title" :class="{ 'whitespace-pre': $t(title).includes('\n') }">
      {{ $t(title) }}
    </h3>

    <p class="body-text-x-space mt-s12">
      {{ $t(description) }}
    </p>
  </div>
</template>

<script>
import {
  IconVault,
  IconTokenize,
  IconShipInsureVault,
  IconMoney,
  IconMonetization,
  IconMarketingSupport,
  IconFractionalize,
  IconFeeStructure,
} from '@/assets/icons';

export default {
  name: 'TopicCard',

  components: {
    IconFeeStructure,
    IconFractionalize,
    IconMarketingSupport,
    IconMonetization,
    IconMoney,
    IconShipInsureVault,
    IconTokenize,
    IconVault,
  },

  props: {
    description: String,
    icon: String,
    title: String,
  },

};
</script>

<style scoped>
  .topic-title {
    @apply text-text-active-2 text-center mt-s40 flex justify-center items-center;
    min-height: 68px;
  }
</style>
