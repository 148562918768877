<template>
  <section class="primary-tabs gap-s24 start-lg:gap-s40" :class="[classes]">
    <button
      v-for="(item, id) in tabListWithActive"
      class="tab-button border-b-4 border-border-active-nav whitespace-no-wrap capitalize pb-s12 start-md:pb-s14"
      :class="item.active ? 'tab-button--selected' : 'tab-button--inactive'"
      :key="id"
      tabindex="0"
      @click="$emit('tabChange', item)">
      {{ getItemLabel(item) }}
    </button>
  </section>
</template>

<script>
export default {
  name: 'PrimaryTabs',

  props: {
    classes: String,

    currentTab: {
      type: String,
      required: true
    },

    tabList: {
      type: Array,
      required: true,
      default: () => ([
        {
          tab: '',
          label: '',
        }
      ])
    }
  },

  computed: {
    tabListWithActive(){
      return this.tabList.map(item => {
        return {
          ...item,
          active: Object.values(item).includes(this.currentTab),
        };
      });
    }
  },

  methods: {
    getItemLabel(item) {
      return this.lodashGet(item, 'label', item);
    },
  },
};
</script>

<style scoped>
.primary-tabs {
  @apply flex overflow-x-auto;
}

.primary-tabs-divider {
  @apply border-b; 
}

.primary-tabs::-webkit-scrollbar {
  display: none;
}

.tab-button {
  outline: 0;
  transition: all 500ms ease-in-out;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

.tab-button:focus,
.tab-button:hover,
.tab-button--selected {
  @apply text-text-body border-opacity-100;
}

.tab-button--inactive {
  @apply text-text-inactive border-opacity-0;
}
</style>
