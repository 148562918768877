import Big from 'big.js';

Big.RM = 0;

const array = {
  methods: {
    filterArray(array, value, validKeys) {
      const lowerCasedValue = value.toLowerCase();

      return array.filter((item) => {
        let contains = false;
        
        validKeys.forEach((validKey) => {
          const keys = validKey.split('.');
          const value = keys.reduce((item, level) => item && item[level], item);

          if (value.toLowerCase().includes(lowerCasedValue)) {
            contains = true;
          }
        });

        return contains;
      });
    },
    sortArrayOnProperty({ array, property, ascending = true }) {
      const newArray = [...array];

      if (ascending) {
        return newArray.sort((a, b) => {
          if (!a[property] && a[property] !== 0) {return -1;}

          if (isNaN(a[property]) || isNaN(b[property])) {
            if (a[property] < b[property]) {
              return -1;
            }
            
            if (a[property] > b[property]) {
              return 1;
            }

            return 0;
          }

          return Big(a[property]).cmp(Big(b[property]));
        });
      }

      return newArray.sort((a, b) => {
        if (!b[property] && b[property] !== 0) {return -1;}

        if (isNaN(a[property]) || isNaN(b[property])) {
          if (a[property] > b[property]) {
            return -1;
          }
  
          if (a[property] < b[property]) {
            return 1;
          }
  
          return 0;
        }

        return Big(b[property]).cmp(Big(a[property]));
      });
    },
  },
};

export default array;
