<template>
  <li
    v-if="isTransactionReady"
    class="transaction-row last:border-b-0"
  >
    <router-link
      :to="singleTransactionLink"
      class="transaction-grid"
    >    
      <div class="transaction-grid-item">
        <h4 class="subheadline-small">
          {{ transaction.name }}
        </h4>
        
        <p>
          {{ transaction.description }}
        </p>
      </div>
    
      <div class="transaction-grid-item">
        <p class="body-text-x-large" :class="[transaction.type.class]">
          {{ transaction.type.label }}
        </p> 
      </div>

      <div class="transaction-grid-item">
        <p>
          {{ transaction.date }}
        </p> 
      </div>

      <div class="transaction-grid-item">
        <p>
          {{ transaction.assetPrice }}
        </p> 
      </div>

      <div class="transaction-grid-item pr-s16">
        <p class="body-text-x-large">
          <PercentageBar
            v-if="!transaction.status.isPlainText"
            :percentage="transaction.status.percentage"
            :text="transaction.status.label"
          />

          <template v-else>
            {{ transaction.status.label }}
          </template>
        </p> 
      </div>

      <div class="transaction-grid-item">
        <p>
          {{ transaction.transaction }}
        </p> 
      </div>
    </router-link>
  </li>
</template>

<script>
import { PercentageBar } from '@/components/misc';
import TransactionsListItemMixin from '../transactions-list-item.mixin.js';

export default {
  name: 'TransactionsListItemRow',

  mixins: [TransactionsListItemMixin],

  components: { PercentageBar },

  props: {
    listItem: {
      type: Object,
      required: true,
    },

    itemType: {
      type: String,
      required: true,
    }
  },
};
</script>

<style scoped>
.transaction-row {
  @apply body-text-x-medium border-b border-border p-s16;
}

.transaction-grid-item {
  @apply flex flex-col justify-center;
  border-color: var(--colors-border-default);
}
</style>
