<template>
  <SkeletonLoading 
    v-if="isLoading"
    :height="skeletonHeight"
  />
  <div v-else-if="currentUserAmount > 0">
    <div class="flex justify-between items-center">
      <h3 class="headline-small">
        {{ $t('trade.balance') }}
      </h3>

      <ButtonV2
        v-if="hasOwnership"
        class="block lg:hidden"
        @onClick="onTakeCustody"
        :label="$tc('balance.take_custody')"
        size="small"
        testId="take-custody-btn"
        version="secondary"
      />
    </div>

    <div class="card-grid">
      <template v-for="(item) of values">
        <div
          v-if="item.isVisible"
          :key="item.id"
          class="flex flex-col wrap border-t border-border pt-s20 mt-s20"
        >
          <p class="text-text-inactive body-text-x-space">
            {{ item.title }}
          </p>
          <p
            :class="['mt-s4 body-text-large', item.specialClasses]"
          >
            {{ item.body }}
          </p>
        </div>
      </template>
    </div>

    <div class="card-grid border-t border-border pt-s20 mt-s20">
      <template v-for="(item) of profit">
        <div :key="item.id" class="flex flex-col wrap">
          <p class="text-text-inactive body-text-x-space">
            {{ item.title }}
          </p>
          <p :class="['mt-s4', item.specialClasses]">
            <span class="body-text-large">
              {{ item.priceChange }}{{ item.percentageChange }}
            </span>
          </p>
        </div>
      </template>
    </div>

    <ButtonV2
      v-if="hasOwnership"
      class="hidden mt-s20 lg:block"
      @onClick="onTakeCustody"
      :label="$tc('balance.take_custody')"
      size="small"
      testId="take-custody-btn-mobile"
      version="secondary"
      wide
    />
  </div>
</template>

<script>
import { ButtonV2 } from '@/components/misc';
import mountProfitLossObject from '@/modules/walletv2/utils/mountProfitLossObject';

export default {
  name: 'TradeBalance',
  components: {
    ButtonV2,
  },
  props: {
    asset: Object,
    isLoading:  {
      type: Boolean,
      default: false,
    },
  },
  
  computed: {
    balance() {
      try {
        return this.$big(this.currentUserAmount).times(this.pricePerToken);
      } catch (err) {
        return 0;
      }
    },

    currentUserAmount() {
      return this.lodashGet(this.asset, 'base.current_user_amount', 0);
    },

    currentUserCostBasis() {
      return this.lodashGet(this.asset, 'base.current_user_cost_basis', 0);
    },

    currentUserPercent() {
      try {
        return this.$big(this.currentUserAmount).times(100);
      } catch (err) {
        return 0;
      }
    },

    percentageChangeSincePurchased() {
      try {
        return this.$big(this.balance).minus(this.totalPurchased).times(100).div(this.totalPurchased);
      } catch (err) {
        return 0;
      }
    },

    priceChangeSincePurchased() {
      try {
        return this.$big(this.balance).minus(this.totalPurchased);
      } catch (err) {
        return 0;
      }
    },

    priceChangeColor() {
      return `text-text-${this.profitLoss.type}`;
    },

    priceChangePct() {
      return this.lodashGet(this.asset, 'price_change.change_pct', 0);
    },

    pricePerToken() {
      return this.lodashGet(this.asset, 'price_per_token', 0);
    },

    profit() {
      return [ 
        {
          id: 0,
          specialClasses: `${this.priceChangeSincePurchased.gte(0) ? 'text-text-positive' : 'text-text-negative'}`,
          title: this.$t('trade.pct_since_purchase'), 
          priceChange: this.numberFormat(this.priceChangeSincePurchased, 2, false, true),
          percentageChange: ` (${this.numberFormat(this.percentageChangeSincePurchased, 2, true)}%)`,
        },
        {
          id: 1,
          specialClasses: `text-text-${this.profitLoss.type}`,
          title: `${this.$t('trade.profit_loss')} ${this.timeframeLabel}`, 
          priceChange: this.numberFormat(this.profitLoss.value, 2, true, true),
          percentageChange: ` (${this.numberFormat(this.profitLoss.percentage, 2, true)}%)`,
        },
      ];
    },

    holdingChangeValue() {
      return this.$big(this.balance).times(this.priceChangePct).div(100).toNumber();
    },

    profitLoss() {
      return mountProfitLossObject(this.holdingChangeValue, this.priceChangePct);
    },

    skeletonHeight() {
      return this.isDrop ? '109px' : '194px';
    },

    timeframe() {
      return this.lodashGet(this.asset, 'price_change.timeframe', '_1D');
    },

    timeframeLabel() {
      return this.$t(`timeframes.${[this.timeframe || '_1W']}.long`);
    },

    totalPurchased() {
      try {
        return this.$big(this.currentUserAmount).times(this.currentUserCostBasis);
      } catch (err) {
        return 0;
      }
    },

    values() {
      return [
        {
          id: 0,
          title: this.$t('trade.balance'),
          body: this.numberFormat(this.balance, 2, false, true),
          isVisible: true,
        },
        {
          id: 1,
          title: this.$t('trade.percentage_owned'), 
          body: `~${this.numberFormat(this.currentUserPercent, 2, false, false)}%`,
          isVisible: true,
        },
        {
          id: 2,
          title: this.$t('trade.amount_owned'), 
          body: this.numberFormat(this.currentUserAmount, 6, false, false),
          isVisible: this.isDrop,
        },
        {
          id: 3,
          title: this.$t('trade.average_price'), 
          body: this.numberFormat(this.currentUserCostBasis, 2, false, true),
          isVisible: !this.isDrop,
        },
        {
          id: 4,
          title: this.$t('trade.total_purchased'), 
          body: this.numberFormat(this.totalPurchased, 2, false, true),
          isVisible: !this.isDrop,
        },
      ];
    },

    hasOwnership() {
      return this.currentUserPercent >= 100;
    }
  },

  methods: {
    onTakeCustody() {
      localStorage.setItem('takeCustodyAsset', JSON.stringify(this.asset));
      
      this.$router.push({ name: 'take-custody', params: { asset: this.asset } });
    },
  }
};
</script>

<style scoped>
.balance-learnmore{
  outline: 0;
}
.balance-learnmore:hover{
  @apply text-text-body;
}

.card-grid {
  @apply grid items-center;
  grid-template-columns: 29% 24% 24% 23%;
}

.balance-take-custody {
  @apply flex justify-between items-center pt-s16 mt-s16;
  border-top: 1px dashed #343434;
}

@screen xl {
  .card-grid {
    grid-template-columns: 50% 50%;
  }
}
</style>
