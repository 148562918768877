<template>
  <div class="flex justify-between font-bold xl:flex-col">

    <header>
      <BaseText v-bind="titleLoadingSettings">
        <Title :title="$t('navigation.wallet')" />
      </BaseText>

      <WalletBalanceResume
        :class="walletBalanceClass"
        :buyingPower="usdBalance"
        :totalBalance="userBalance"
      />
    </header>

    <div class="link-grid gap-s16">
      <ButtonV2
        class="body-text-x-large"
        version="tertiary"
        testId="btn-deposit"
        :label="$t('deposit.title')"
        @onClick="$emit('onTriggerAction', 'deposit')"
      />

      <ButtonV2
        class="body-text-x-large"
        version="tertiary"
        testId="btn-withdraw"
        :label="$t('withdraw.title')"
        @onClick="$emit('onTriggerAction', 'withdraw')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Title from '@/components/misc/Title';
import ButtonV2 from '@/stories/misc/ButtonV2';
import { BaseText } from '@/components/misc';
import { WalletBalanceResume } from '@/components/wallet';

export default {
  name: 'WalletBalanceHeader',

  components: {
    BaseText,
    ButtonV2,
    WalletBalanceResume,
    Title,
  },

  computed: {
    ...mapGetters('api', ['usdBalance']),

    titleLoadingSettings() {
      return {
        isLoading: this.globalIsLoading,
        loadingHeight: '34px',
        loadingWidth: '135px',
      };
    },

    walletBalanceClass() {
      return !this.isMobileDevice ? 'mt-s24' : 'mt-s12';
    },

    userBalance() {
      return this.$big(
        this.lodashGet(
          this.$store.state.api,
          'current_user.inventory_value_usd',
          0
        )
      ).toNumber();
    },
  },
};
</script>

<style scoped>
.link-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.link-grid--mobile {
  grid-template-columns: repeat(2, 1fr);
}

.link-grid--desktop {
  grid-template-columns: repeat(3, 1fr);
}
</style>