export default [
  {
    id: 'a81c1b1d-d5ce-42a4-8fcb-c15577b88b4e',
    description: 'Test Entry debit',
    credit: false,
    gems_amount: '12345.0000000000000000',
    date: '1654606371.444',
    __typename: 'UserRewardsHistoryEntry',
  },
  {
    id: '8b0bde43-fd14-4faa-b35f-aab84f6230ad',
    description: 'Test Entry credit',
    credit: true,
    gems_amount: '7056.0000000000000000',
    date: '1654606371.444',
    __typename: 'UserRewardsHistoryEntry',
  },
  {
    id: '8b0bde43-fa14-4faa-b35f-aab84f6230ad',
    description: 'Test Entry credit',
    credit: false,
    gems_amount: '7814.0000000000000000',
    date: '1654606371.444',
    __typename: 'UserRewardsHistoryEntry',
  },
  {
    id: '8b0bde43-fd14-4faa-b27f-aab84f6230ad',
    description: 'Test Entry credit',
    credit: true,
    gems_amount: '321.0000000000000000',
    date: '1654606371.444',
    __typename: 'UserRewardsHistoryEntry',
  },
];
