
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CurrentUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CurrentUser"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"account_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cost_basis"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"inventory_value_usd"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"kyc_level"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserKycState"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserKycState"},"directives":[]}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DibbsError"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DibbsError"},"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"permissions"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CurrentUserPermissions"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"ref_status"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RefStatus"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"user_info"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserInfo"},"directives":[]}]}}]}}],"loc":{"start":0,"end":572}};
    doc.loc.source = {"body":"#import \"@/graphql/fragments/CurrentUserPermissions.gql\"\n#import \"@/graphql/fragments/DibbsError.gql\"\n#import \"@/graphql/fragments/RefStatus.gql\"\n#import \"@/graphql/fragments/UserInfo.gql\"\n#import \"@/graphql/fragments/UserKycState.gql\"\n\nfragment CurrentUser on CurrentUser {\n  account_id\n  cost_basis\n  inventory_value_usd\n\n  kyc_level {\n    ... on UserKycState {\n      ...UserKycState\n    }\n\n    ... on DibbsError {\n      ...DibbsError\n    }\n  }\n\n  permissions {\n    ...CurrentUserPermissions\n  }\n\n  ref_status {\n    ...RefStatus\n  }\n\n  user_info {\n    ...UserInfo\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/CurrentUserPermissions.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/DibbsError.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/RefStatus.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/UserInfo.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/UserKycState.gql").definitions));


      module.exports = doc;
    
