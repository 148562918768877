<template>
  <div class="drops">
    <div
      v-if="!globalIsLoading && !drops.length"
      class="drops__header"
    >
      <div class="no-drops-found">
        <NoDropsDark v-if="currentTheme === 'dark'" />
        <NoDropsLight v-else />
        <div class="mt-s24 subheadline-medium">
          {{ $t('drops.no_drops_found') }}
        </div>
        <div class="mt-s8 body-text-large">
          {{ $t('drops.no_drops_found_description') }}
        </div>
        <ButtonV2
          class="mt-s20"
          @onClick="goToInternalPageGlobal('/market')"
          :label="$tc('drops.view_markets')"
          testId="btn-viewMarkets"
          size="medium"
        />
      </div>
    </div>

    <template v-else>
      <div class="drop-grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
        <DropsCard
          v-for="(drop, index) in dropsDisplay"
          class="drop-card"
          :key="index"
          :data="drop"
          :isLoading="globalIsLoading"
          :timeNow="timeNow"
          small
        />
      </div>
    </template>
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import { DropsCard } from '@/modules/drops';
import NoDropsDark from '@/assets/img/no-drops-dark.svg';
import NoDropsLight from '@/assets/img/no-drops-light.svg';

export default {
  name: 'DropsList',
  components: {
    ButtonV2,
    DropsCard,
    NoDropsDark,
    NoDropsLight,
  },

  computed: {
    dropsDisplay() {
      return this.drops.length ? this.drops : new Array(8).fill(0);
    },
  },

  props: {
    drops: {
      type: Array,
      default: () => [],
    },
    timeNow: Number,
  },
};
</script>

<style scoped>

.drops {
  @apply flex flex-wrap -m-s12 mt-s12;
}
.no-drops-found {
  @apply flex flex-col items-center text-center;
  max-width: 300px;
}

.drop-grid {
  @apply grid grid-cols-4 w-full gap-s16 mt-s16 ;
}

@screen lg {
  .drop-grid {
    @apply grid-cols-3;
  }

  .drops {
    @apply mx-0;
  }
}

@screen md {
  .drop-grid {
    @apply grid-cols-2 ;
  }
}

@screen sm {
  .drop-grid {
    @apply flex flex-col items-center;
  }

  .drop-card{
    @apply w-full;
  }
}

.drops__header {
  @apply flex justify-center items-center w-full mt-s64;
}


</style>
