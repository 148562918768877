
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserInfo"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserInfo"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"address_city"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"address_country"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"address_state"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"address_street"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"address_zip"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"birthday"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"email"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"email_verified"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"first_name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"phone"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sur_name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"twofa_enabled"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":205}};
    doc.loc.source = {"body":"fragment UserInfo on UserInfo {\n  address_city\n  address_country\n  address_state\n  address_street\n  address_zip\n  birthday\n  email\n  email_verified\n  first_name\n  name\n  phone\n  sur_name\n  twofa_enabled\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
