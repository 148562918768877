import Get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import router from '@/vue-router';

import ATTEMPT_LEVEL_UP_DOCUMENT from '@/graphql/mutations/AttemptLevelUpDocument.gql';
import ATTEMPT_LEVEL_UP_SSN from '@/graphql/mutations/AttemptLevelUpSSN.gql';
import CIRCLE_UPLOAD_PROOF_OF_BANK from '@/graphql/mutations/CircleUploadProofOfBank.gql';
import SOCURE_UPLOAD_PROOF_OF_ADDRESS from '@/graphql/mutations/SocureUploadProofOfAddress.gql';
import ATTEMPT_LEVEL_UP_KYC_LITE from '@/graphql/mutations/AttemptLevelUpKycLite.gql';
import KYC_LITE_STEPS from '@/enums/KycLiteSteps';
import KYC_LEVEL from '@/graphql/queries/GetKycLevel.gql';
import USER_PERMISSIONS from '@/graphql/queries/UserPermissions.gql';
import USER_INFO from '@/graphql/queries/UserInfo.gql';

const SET_USER = 'SET_USER';
const SET_USER_INFO = 'SET_USER_INFO';
const UPDATE_KYC_LEVEL = 'UPDATE_KYC_LEVEL';
const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
const UPDATE_BASIC_INFO = 'UPDATE_BASIC_INFO';
const UPDATE_KYC_LITE_STEP = 'UPDATE_KYC_LITE_STEP';
const UPDATE_PROOF_OF_BANKING_ID = 'UPDATE_PROOF_OF_BANKING_ID';
const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
const SET_KYC_COMPLETE = 'SET_KYC_COMPLETE';
const SET_FRACPACK_OPTIONS = 'SET_FRACPACK_OPTIONS';
const RESET_STORE = 'RESET_STORE';

const ROOT = { root: true };

const defaultState = {
  proof_of_banking_id: '',
  account_id: '',
  cost_basis: {},
  packs: {},
  ref_status: {},
  user_info: {},
  kyc_status: '',
  kycLiteCurrentStep: KYC_LITE_STEPS.COUNTRY,
  kycLevel: {},
  kycComplete: null,
  fracpack_options: [],
  fracpack_chosen: false,
  userPermissions: {
    deposit: null, 
    withdraw: null, 
    sell_with_dibbs: null
  },
  transaction_history: []
};

export default {
  namespaced: true,
  state: {
    ...defaultState,
  },
  mutations: {
    [ SET_USER ]: (state, payload) => {
      state.account_id = payload.account_id;
      state.cost_basis = payload.cost_basis;
      state.packs = payload.packs;
      state.ref_status = payload.ref_status;
      state.user_info = payload.user_info;
      state.kycLevel = payload.kyc_level;
      state.transaction_history = payload.transaction_history;
    },
    [SET_USER_INFO]: (state, payload) => {
      state.user_info = {
        ...state.user_info,
        first_name:payload.first_name,
        sur_name:payload.sur_name,
        address_street:payload.address_street,
        address_complement:payload.address_complement,
        address_city:payload.address_city,
        address_state:payload.address_state,
        address_zip:payload.address_zip,
        address_country:payload.address_country,
        birthday:payload.birthday,
        phone:payload.phone 
      };
    },
    [ UPDATE_ADDRESS ]: (state, payload) => {
      state.user_info = {
        ...state.user_info,
         address_country: payload.addressCountry,
         address_street: payload.addressStreet,
         address_complement: payload.addressComplement,
         address_city: payload.addressCity,
         address_state: payload.addressState, 
         address_zip: payload.addressZip
      };
    },
    [ UPDATE_BASIC_INFO ]: (state, payload) => {
      const adjustedBirthday = payload.birthday.toISOString().substring(0, 10);
      const adjustedPhone = `+1${payload.phone.replace( /\D/g, '')}`;

      state.user_info = {
        ...state.user_info,
         first_name: payload.firstName,
         sur_name: payload.surName,
         phone: adjustedPhone,
         birthday: adjustedBirthday,
      };
    },
    [ UPDATE_KYC_LITE_STEP ]: (state, payload) => {
      state.kycLiteCurrentStep = payload;
    },
    [ UPDATE_PROOF_OF_BANKING_ID ] (state, payload) {
      state.proof_of_banking_id = payload;
    },
    [ UPDATE_KYC_LEVEL ] (state, payload) {
      state.kycLevel = payload;
    },
    [ SET_USER_PERMISSIONS ] (state, payload) {
      state.userPermissions = payload;
    },
    [ SET_KYC_COMPLETE ] (state, payload) {
      state.kycComplete = payload;
    },
    [SET_FRACPACK_OPTIONS ] (state, payload) {
      state.fracpack_options = payload;
    },
    [ RESET_STORE ]: (state) => {
      Object.assign(state, defaultState);
    },
  },
  getters: {
    getUserEmail(state) {
      return Get(state, 'user_info.email', '');
    },
    getUserFullName(state) {
      return state.user_info.first_name ? `${Get(state, 'user_info.first_name', '')} ${Get(state, 'user_info.sur_name', '')}` : '';
    },
    getRequiredProofOfBankingId(state){
      return state.proof_of_banking_id;
    },
    getUserPermissions(state){
      return state.userPermissions;
    },
    getKycLevelId(state){
      return state.kycLevel.id;
    },
    getHistoryForDeposit(state){
      if(isEmpty(state.transaction_history)) {
        return [];
      }
      return state.transaction_history.filter(item => item.depositType);
    },
    getHistoryForWithdrawal(state){
      if(isEmpty(state.transaction_history)) {
        return [];
      }
      return state.transaction_history.filter(item => item.withdrawType);
    },
    getUserBasicInfo(state){
      if (!state.user_info) {
        return {
          firstName: '',
          surName: '',
          phone: '',
          birthday: '',
          email: ''
        };
      }

      const {
        first_name,
        sur_name,
        phone,
        birthday,
        email
      } = state.user_info;

      return {
        firstName: first_name || '',
        surName: sur_name || '',
        phone: phone || '',
        birthday: birthday || '',
        email: email || '',
      };
    },
    getUserInfo(state){ 
      const {
        first_name,
        sur_name,
        birthday,
        phone,
        address_country,
        address_street,
        address_complement,
        address_city,
        address_state,
        address_zip
      } = state.user_info;

      return {
        firstName: first_name || '',
        surName: sur_name || '',
        birthday: birthday || '',
        phone: phone || '',
        addressCountry: address_country || '',
        addressStreet: address_street || '',
        addressComplement: address_complement || '',
        addressCity: address_city || '',
        addressState: address_state || '', 
        addressZip: address_zip || '',
      };
    },
    getUserInformationAddress(state){
      if (!state.user_info) {
        return {
          addressCountry: '',
          addressStreet: '',
          addressComplement: '',
          addressCity: '',
          addressState: '', 
          addressZip: '',
        };
      }

      const {
        address_country,
        address_street,
        address_complement,
        address_city,
        address_state,
        address_zip
      } = state.user_info;

      return {
        addressCountry: address_country,
        addressStreet: address_street,
        addressComplement: address_complement,
        addressCity: address_city,
        addressState: address_state, 
        addressZip: address_zip,
      };
    },
    isAuthenticated(_state, _getters, rootState) {
      return rootState.api.isAuthenticated;
    },
    isKycComplete(state) {
      return state.kycComplete;
    },
    getKycLevel(state) {
      return state.kycLevel;
    },
    getFracPackOptions(state) {
      return state.fracpack_options;
    },
    getKycLevelForAmplitude(state) {
      return {
        current_level_description: Get(state.kycLevel, 'current_level_value.description', ''),
        next_level_description: Get(state.kycLevel, 'next_level_value.description', ''),
        kyc_level_status: Get(state.kycLevel, 'kyc_level_status', '')
      };
    },
    isEmailVerified(_state, _getters, rootState) {
      return Get(rootState.api, 'current_user.user_info.email_verified', false);
    },
  },
  actions: {
    async postDocument({ commit, dispatch }, docId){
      const response = await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: ATTEMPT_LEVEL_UP_DOCUMENT,
          variables: {
            docv: docId
          }
        }
      }, { root: true });

      await dispatch('loadUserPermissions');

      commit(UPDATE_KYC_LEVEL, response.data.attempt_level_up);

      return response; 
    },

    async postKycLite({ commit, dispatch, state }) {
      try {
        const userInfo = {
          first_name: state.user_info.first_name,
          sur_name: state.user_info.sur_name,
          address_street: state.user_info.address_street,
          address_complement: state.user_info.address_complement,
          address_city: state.user_info.address_city,
          address_state: state.user_info.address_state,
          address_zip: state.user_info.address_zip,
          address_country: state.user_info.address_country,
          birthday: state.user_info.birthday,
          phone: state.user_info.phone,
        };

        const response = await dispatch('api/apolloClient', {
          type: 'mutate',
          options: {
            mutation: ATTEMPT_LEVEL_UP_KYC_LITE,
            variables: {
              userInfo
            }
          }
        }, ROOT);

        commit(UPDATE_KYC_LEVEL, response.data.attempt_level_up);
        await dispatch('loadUserPermissions');
        await dispatch('updateKycLiteStep', KYC_LITE_STEPS.COMPLETE);

        return response;
      } catch (err) {
        await dispatch('updateKycLiteStep', KYC_LITE_STEPS.BASIC);
        throw err;
      }
    },

    async postSSN({ commit, dispatch }, ssn) {
      try {
        const response = await dispatch('api/apolloClient', {
          type: 'mutate',
          options: {
            mutation: ATTEMPT_LEVEL_UP_SSN,
            variables: {
              ssn
            }
          }
        }, ROOT);
  
        commit(UPDATE_KYC_LEVEL, response.data.attempt_level_up);

        await dispatch('loadUserPermissions');

        return response;
      } catch (err) {
        await dispatch('ui/showError', err, ROOT);
      }
    },
    
    doKycLite({ commit, state }) {
      switch(state.kycLiteCurrentStep) {
        case KYC_LITE_STEPS.COUNTRY:
          commit('ui/showModal', { currentModal: 'KycCountry' }, ROOT);
          break;
        case KYC_LITE_STEPS.BASIC:
          commit('ui/showModal', { currentModal: 'KycBasicInfo' }, ROOT);
          break;
        case KYC_LITE_STEPS.ADDRESS:
          commit('ui/showModal', { currentModal: 'KycAddress' }, ROOT);
          break;
        case KYC_LITE_STEPS.COMPLETE:
          commit('ui/showModal', { currentModal: 'KycStatus' }, ROOT);
          break;
        default: break;
      }
    },
    
    updateModule({ commit }, payload){
      commit(SET_USER, payload);
    },
    
    updateAddress({ commit }, payload){
      commit(UPDATE_ADDRESS, payload);
    },
    
    updateBasicInfo({ commit, dispatch }, payload){
      commit(UPDATE_BASIC_INFO, payload);
      dispatch('updateKycLiteStep', KYC_LITE_STEPS.ADDRESS);
    },
    
    updateCountry({ commit, dispatch }, payload) {
      commit(UPDATE_ADDRESS, payload);
      dispatch('updateKycLiteStep', KYC_LITE_STEPS.BASIC);
    },
    
    updateKycLiteStep({ commit, dispatch }, payload){
      commit(UPDATE_KYC_LITE_STEP, payload);
      dispatch('doKycLite');
    },
    
    updateKycStatus({ commit }, payload){
      commit(SET_KYC_COMPLETE, payload);
    },
    
    async uploadProofOfAdressFile({ dispatch }, file) {
      return dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: SOCURE_UPLOAD_PROOF_OF_ADDRESS,
          variables: { file }
        }
      }, ROOT);
    },
    
    async uploadProofOfBankFile({ dispatch }, {
      id,
      bank_document, 
      selfie_with_document
    }) {
      return dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CIRCLE_UPLOAD_PROOF_OF_BANK,
          variables: { 
            id,
            bankDocument: bank_document,
            selfieWithDocument: selfie_with_document
           }
        }
      }, ROOT);
    }, 

    updateProofOfBankId({ commit }, payload){
      commit(UPDATE_PROOF_OF_BANKING_ID, payload);
    },
    
    async loadKycLevel({ dispatch, commit }){
      try {
        const response = await dispatch('api/apolloClient', {
          type: 'query',
          options: {
            query: KYC_LEVEL,
            fetchPolicy: 'no-cache',
          }
        }, { root: true });

        if (response.data) {
          commit(UPDATE_KYC_LEVEL, response.data.current_user.kyc_level);
        }
      } catch(err) {
        await dispatch('ui/showError', err, { root: true });
      }
    },
   
    async loadUserPermissions({ dispatch, commit }){
      try {
        const response = await dispatch('api/apolloClient', {
          type: 'query',
          options: {
            query: USER_PERMISSIONS,
            fetchPolicy: 'no-cache',
          }
        }, { root: true });

        if (response.data) {
          commit(SET_USER_PERMISSIONS, response.data.current_user.permissions);
        }
      } catch(err) {
        await dispatch('ui/showError', err, { root: true });
      }
    },

    async loadUserInfo({ dispatch, commit }){
      try {
        const response = await dispatch('api/apolloClient', {
          type: 'query',
          options: {
            query: USER_INFO,
            fetchPolicy: 'no-cache',
          }
        }, { root: true });

        if (response.data) {
          commit(SET_USER_INFO, response.data.current_user.user_info);
        }
      } catch(err) {
        await dispatch('ui/showError', err, { root: true });
      }
    },

    async updateKycComplete({ commit, dispatch, state }) {
      try {
        await dispatch('loadUserPermissions');
        
        commit(SET_KYC_COMPLETE, Get(state, 'userPermissions.deposit', false));
      } catch (err) {
        if (Get(err, 'graphQLErrors[0].message', '') === 'invalid_auth_token') {
          return;
        }
        await dispatch('ui/showError', err, ROOT);
      }
    },

    async requiresKycComplete({ commit, dispatch, rootState, state, getters }) {
      if (!getters.isAuthenticated) {
        await router.push('/login');
        commit('ui/hideModal', null, ROOT);
        throw new Error('not_authorized');
      }

      await dispatch('updateKycComplete');

      const isEmailVerified = Get(rootState, 'api.current_user.user_info.email_verified', false);

      if (!isEmailVerified) {
        commit('ui/showModal', { currentModal: 'EmailNotVerified' }, ROOT);
        throw new Error('please_verify_your_email');
      }

      if (!Get(state, 'userPermissions.deposit', false)) {
        commit('ui/showModal', { currentModal:'KycStatus' }, ROOT);
        throw new Error('user_not_kyced');
      }
    },

    resetStore({ commit }){
      commit(RESET_STORE);
    },
  }
};
