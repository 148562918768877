<template>
  <div>
    <template v-if="globalIsLoading">
      <SkeletonLoading borderRadius="20px" width="100%" :height="bannerHeight" />
    </template>
    <template v-else>
      <section class="fp-banner" :class="bannerClasses" @click="goToPack">
        <div class="fp-banner-bg" />
        <div class="fp-banner-inner">
          <div class="fp-banner-image">
            <FracPackImage
              :image="pack.image"
              :glowColor="pack.color_glow_hex"  
              :glowWidth="70"
              :glowHeight="70"
              :glowBlur="20"
              :imageHeight="107" 
              :imageWidth="72" 
            />
          </div>
          <div class="fp-banner-content pl-n-4xl md:pl-n-2xl">
            
            <h2 class="text-n-4xl font-bold md:text-n-2xl">
              {{ pack.name }}
            </h2>
            <p class="text-n-2xl md:text-n-sm">
              {{ $t('frac_pack.banner_call', {cards: pack.cards_per_pack}) }}
            </p>
            <div class="fp-banner-available text-n-2xl md:text-n-md">
              {{ remaining_supply }}
            </div>
            <div class="fp-banner-mobile-cta mt-n-xl" v-if="isMobileDevice">
              <ButtonV2
                :inactive="!hasSupply"
                testId="btn__buyPack"
                size="small"
                version="primary"
                :label="$t('frac_pack.buy_pack')"
                @click="goToPack"
              />
            </div>
          </div>
          <div v-if="hasSupply" class="fp-banner-button">
            <Arrow class="w-n-2xl fill-current rotate-180 md:hidden"/>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
  import Arrow from '@/assets/icons/chevron_left.svg';
  import FracPackImage from '@/components/fracpack/FracPackImage';
  import ButtonV2 from '@/stories/misc/ButtonV2';

  export const DEFAULT_PACK = {
    id: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
    id_hr: 'example-pack',
    name: 'Example Pack',
    fraction_per_card: '0.0010000000000000',
    color_glow_hex: '#74DBB5',
    cards_per_pack: 5,
    image: 'https://img.dibbs.io/frac_pack_std.png',
    initial_supply: 100,
    price: '20.0000000000000000',
    remaining_supply: 99,
    sale_start_time: '1630419636',
    sale_end_time: '1638372039'
  };

  export default {
    name: 'FracPackBanner',

    components: {
      Arrow,
      FracPackImage,
      ButtonV2,
    },

    props: {
      pack: {
        type: Object,
        default: () => { return DEFAULT_PACK; },
        validate: (item) => {
          const a = Object.keys(item).sort();
          const b = Object.keys(DEFAULT_PACK).sort();
          return JSON.stringify(a) === JSON.stringify(b);
        }
      }
    },

    computed: {
      bannerClasses() {
        return {
          'mobile': this.isMobileDevice,
          'cursor-pointer': this.hasSupply,
        };
      },

      bannerHeight() {
        return this.isMobileDevice ? '176px' : '140px';
      },

      hasSupply() {
        return this.pack.remaining_supply > 0;
      },

      remaining_supply() {
        return this.hasSupply
          ? `${ this.$t('frac_pack.packs_available') } ${this.pack.remaining_supply}/${this.pack.initial_supply}`
          : this.$t('frac_pack.sold_out');
      },
    },

    methods: {
      goToPack() {
        if (this.hasSupply) {
          this.$router.push('/frac-pack/'+ this.pack.id_hr).catch(()=>{});
        }
      }
    }
  };
</script>

<style scoped>
  .fp-banner {
    @apply relative rounded-24 box-border overflow-hidden;
    border: solid 1px var(--colors-background-icon);
    height: 140px;
    z-index: 1;
  }
  .fp-banner-bg {
    @apply absolute inset-0;
    background-color: var(--colors-background-icon);
    opacity: .5;
    z-index: 2;
  }
  .fp-banner-inner {
    @apply relative flex content-center;
    height: 100%;
    z-index: 3;
  }
  .fp-banner-image {
    @apply flex justify-center items-center;
    width: 146px;
    height: 100%;
  }
  .fp-banner-content {
    @apply flex flex-col justify-center flex-1 align-middle;
  }
  .fp-banner-available {
    @apply font-semibold;
  }
  .fp-banner-button {
    @apply flex justify-start items-center;
    width: 45px;
  }
  .fp-banner-button svg {
    height: 32px;
  }
  
  /* Mobile */
  .fp-banner.mobile {
    height: 176px;
  }
  .mobile .fp-banner-inner {
    @apply flex-row-reverse;
    position: relative;
  }
  .mobile .fp-banner-image {
    max-width: 25vw;
  }
  .mobile .fp-banner-button {
    display: none;
  }

</style>
