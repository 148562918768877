// Import gql queries
import ManageSessionsQuery from '@/graphql/queries/ManageSessions.gql';
import DefaultQuery from '@/graphql/queries/DefaultQuery.gql';
import DropsQuery from '@/graphql/queries/Drops.gql';
import FracPackQuery from '@/graphql/queries/FracPack.gql';
import IdentityQuery from '@/graphql/queries/Identity.gql';

// Define routes
const routes = [
  { path: '/captcha',                          component: () => import ('@/views/Captcha'),                name: 'captcha',              meta: { template: 4 }},
  { path: '/change-password',                  component: () => import ('@/views/ChangePassword'),         name: 'change-password',      meta: { template: 1, requiresAuth: true }},
  { path: '/consignment-agreement',            component: () => import ('@/views/ConsignmentAgreement'),   name: 'consignment-agreement',meta: { template: 4 }},
  { path: '/drops',                            component: () => import ('@/views/Drops'),                  name: 'drops',                meta: { template: 1, query: DropsQuery, bypassLoading: true  }},
  { path: '/fee-schedule',                     component: () => import ('@/views/FeeSchedule'),            name: 'fee-schedule',         meta: { template: 4 }},
  { path: '/frac-pack/:id_hr',                 component: () => import ('@/views/FracPack'),               name: 'frac-pack',            meta: { template: 1, query: FracPackQuery, bypassLoading: true, variable: 'id_hr' }},
  { path: '/identity',                         component: () => import ('@/views/Identity'),               name: 'identity',             meta: { template: 1, query: IdentityQuery, requiresAuth: true }},
  { path: '/login',                            component: () => import ('@/views/Login'),                  name: 'login',                meta: { template: 3, query: DefaultQuery }},
  { path: '/manage-sessions',                  component: () => import ('@/views/ManageSessions'),         name: 'manage-sessions',      meta: { template: 1, query: ManageSessionsQuery, bypassLoading: true, }},
  { path: '/promotions/frac-pack',             component: () => import ('@/views/FracPackCode'),           name: 'promotions-frac-pack', meta: { template: 1, query: DefaultQuery, requiresAuth: true, bypassLoading: true, }},
  { path: '/settings',                         component: () => import ('@/views/Security'),               name: 'settings',             meta: { template: 1, query: DefaultQuery, requiresAuth: true }},
  { path: '/setup-2fa',                        component: () => import ('@/views/Security'),               name: 'setup-2fa',            meta: { template: 1, query: DefaultQuery, requiresAuth: true }},
  { path: '/sign-up',                          component: () => import ('@/views/SignUp'),                 name: 'sign-up',              meta: { template: 3, query: DefaultQuery }},
  { path: '/sign-up/:invite',                  component: () => import ('@/views/SignUp'),                 name: 'sign-up-invite',       meta: { template: 3, query: DefaultQuery }},
  { path: '/support',                          component: () => import ('@/views/Support'),                name: 'support',              meta: { template: 1, query: DefaultQuery, }},
  { path: '/support/chat',                     component: () => import ('@/views/Support'),                name: 'support-chat',         meta: { template: 1, query: DefaultQuery, }},
  { path: '/transaction/:id',                  component: () => import ('@/views/Transaction'),            name: 'transaction',          meta: { template: 1, bypassLoading: true, requiresAuth: true }},
  { path: '/delete',                           component: () => import ('@/views/UserDelete/index.vue'),   name: 'delete-confirmation',  meta: { template: 1, query: DefaultQuery, requiresAuth: true }},
];

if (process.env.VUE_APP_PLAID_ENV === 'sandbox') {
  routes.push(
    { path: '/dev', component: () => import ('@/views/Dev'), name: 'dev', meta: { template: 3, requiresAuth: true }},
  );
}

export default routes;
