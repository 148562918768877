export const emitBranchEvent = async (eventName, variables) => {
  if (window.branch) {
    try {
      await window.branch.logEvent(eventName, { ...variables });

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(`[Branch ${eventName}] event emitted ${variables ? `with variables: ${JSON.stringify(variables)}` : ''}`);
      }
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(err.message);
      }
    }
  }
};

export const setBranchUserProps = async ({ user }) => {
  if (window.branch) {
    await window.branch.setIdentity(user.account_id);
  }
};

export const unsetBranchUserProps = async () => {
  if (window.branch) {
    await window.branch.logout();
  }
};
