import i18n from '@/translations/i18n';

export default [
  {
    tab: 'active_orders',
    label: i18n.t('wallet.history.tabs.active_orders.title'),
    active: true,
    noResultData: {
      title: i18n.t('wallet.history.tabs.active_orders.no_result.title'),
      message: i18n.t('wallet.history.tabs.active_orders.no_result.message'),
      btnLabel: i18n.t('wallet.history.tabs.active_orders.no_result.btnLabel'),
      btnLink: '/market',
    },
    filters: [
      {
        tab: 'active_orders_all',
        label: i18n.t('wallet.history.tabs.active_orders.filters.all'),
      },
      {
        tab: 'active_orders_buy',
        label: i18n.t('wallet.history.tabs.active_orders.filters.buy'),
      },
      {
        tab: 'active_orders_sell',
        label: i18n.t('wallet.history.tabs.active_orders.filters.sell'),
      },
    ],
    transactionsList: {
      headers: {
        item: i18n.t('wallet.history.tabs.active_orders.results.headers.item'),
        type: i18n.t('wallet.history.tabs.active_orders.results.headers.type'),
        date: i18n.t('wallet.history.tabs.active_orders.results.headers.date'),
        assetPrice: i18n.t('wallet.history.tabs.active_orders.results.headers.asset_price'),
        status: i18n.t('wallet.history.tabs.active_orders.results.headers.status'),
        transaction: i18n.t('wallet.history.tabs.active_orders.results.headers.transaction'),
      },
    }
  },
  {
    tab: 'transaction_history',
    label: i18n.t('wallet.history.tabs.transaction_history.title'),
    active: true,
    noResultData: {
      title: i18n.t('wallet.history.tabs.transaction_history.no_result.title'),
      message: i18n.t('wallet.history.tabs.transaction_history.no_result.message'),
      btnLabel: i18n.t('wallet.history.tabs.transaction_history.no_result.btnLabel'),
      btnLink: '',
    },
    filters: [
      {
        tab: 'transaction_history_all',
        label: i18n.t('wallet.history.tabs.transaction_history.filters.all'),
      },
      {
        tab: 'transaction_history_orders',
        label: i18n.t('wallet.history.tabs.transaction_history.filters.orders'),
      },
      {
        tab: 'transaction_history_transfers',
        label: i18n.t('wallet.history.tabs.transaction_history.filters.transfers'),
      },
    ],
    transactionsList: {
      headers: {
        item: i18n.t('wallet.history.tabs.transaction_history.results.headers.item'),
        type: i18n.t('wallet.history.tabs.transaction_history.results.headers.type'),
        date: i18n.t('wallet.history.tabs.transaction_history.results.headers.date'),
        asset_price: i18n.t('wallet.history.tabs.active_orders.results.headers.asset_price'),
        status: i18n.t('wallet.history.tabs.transaction_history.results.headers.status'),
        transaction: i18n.t('wallet.history.tabs.transaction_history.results.headers.transaction'),
      },
    },
  },
  {
    tab: 'listings',
    label: i18n.t('wallet.history.tabs.listings.title'),
    active: false,
    filters: [],
    transactionsList: {
      headers: {},
    },
  },
  {
    tab: 'offers',
    label: i18n.t('wallet.history.tabs.offers.title'),
    active: false,
    filter: [],
    transactionsList: {
      headers: {},
    },
  },
];