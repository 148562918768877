import HOLDINGS_ASSETS_QUERY from '@/modules/holdings/queries/HoldingsAsset.gql';
import HOLDINGS_PACKS_QUERY from '@/modules/holdings/queries/HoldingsPacks.gql';
import HOLDINGS_WATCHLIST_QUERY from '@/modules/holdings/queries/HoldingsWatchlist.gql';

const handleFetchHoldings = async (
  dispatch,
  commit,
  getters,
  rootGetters,
  response,
  type
) => {
  const {
    assets,
    packs,
    watch_lists: watchlist,
  } = response.data.current_user;

  let requestLength;

  switch (type) {
    case 'assets':
      await dispatch('asset/updateUserAssets', assets, ROOT);

      requestLength = rootGetters['asset/getUserAssets'].length;

      await commit('setAssets', [
        ...getters.getAssets,
        ...rootGetters['asset/getUserAssets']
      ]);
      break;
    case 'packs':
      await dispatch('asset/updateUserPacks', packs, ROOT);

      requestLength = rootGetters['asset/getUserPacks'].length;

      await commit('setPacks', [
        ...getters.getPacks,
        ...rootGetters['asset/getUserPacks'],
      ]);
      break;
    case 'watchlist':
      await dispatch('asset/updateUserWatchlist', watchlist, ROOT);

      requestLength = rootGetters['asset/getUserWatchList'].length;

      await commit('setWatchlist', [
        ...getters.getWatchlist,
        ...rootGetters['asset/getUserWatchList'],
      ]);
      break;
  }

  return requestLength;
};

const ROOT = { root: true };

export default {
  updatePaginationOffset({ commit }, payload) {
    commit('setPaginationOffset', payload);
  },

  resetState({ commit }) {
    commit('clear');
  },

  async fetchHoldings(
    { commit, dispatch, getters, rootGetters },
    { type, sort }
  ) {
    try {
      const QUERIES = {
        assets: HOLDINGS_ASSETS_QUERY,
        watchlist: HOLDINGS_WATCHLIST_QUERY,
        packs: HOLDINGS_PACKS_QUERY,
      };

      const response = await dispatch('api/apolloClient', {
        type: 'query',
        options: {
          query: QUERIES[type],
          fetchPolicy: 'no-cache',
          variables: {
            sort,
            offset: getters.getPagination.offset,
            count: getters.getPagination.count,
          },
        }
      }, ROOT);

      const requestLength = await handleFetchHoldings(
        dispatch,
        commit,
        getters,
        rootGetters,
        response,
        type
      );

      const endOfData = getters.getPagination.count > requestLength;
      if (endOfData) {
        commit('setPaginationFlag', true);
      }

      return response;
    } catch (e) {
      throw e;
    }
  },
};
