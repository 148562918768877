<template>
  <section class="tab-selector">
    <div class="tab-selector__primary-list">
      <div class="tab-selector__primary-list-item"
        v-for="(item, i) in tabsList"
        :data-testid="`primary-tab:${item}`"
        :key="i"
        :class="{
          ...default_classes, 
          'selected': primary_selected_tab === item
        }"
        @click="handleClick(item)"
      >
        {{ $t(`navigation.${item}`) }}
      </div>
    </div>

    <div v-if="secondaryTabsList" class="tab-selector__secondary-list">
      <div class="tab-selector__secondary-list-item"
        v-for="(item, i) in secondaryTabsList"
        :data-testid="`secondary-tab:${item}`"
        :key="i"
        :class="{
          'selected': secondary_selected_tab === item
        }"
        @click="handleClick(item, false)"
      >
        {{ item }}
      </div>
    </div>

    <slot />
  </section>
</template>

<script>
import { DEFAULT_SIZES } from './index';

export default {
  name: 'TabSelector',
  props: {
    size: {
      type: String,
      default: 'large',
      validator: (str) => DEFAULT_SIZES.includes(str),
    },
    tabsList: Array,
    secondaryTabsList: Array,
    selectedTab: String,
    secondarySelectedTab: String,
  },
  data() {
    return {
      primary_selected_tab: this.selectedTab,
      secondary_selected_tab: this.secondarySelectedTab,
    };
  },
  computed: {
    default_classes() {
      return {
        [this.text_style]: true,
      };
    },
    text_style() {
      switch (this.size) {
        case 'medium': return 'subheadline-x-small';
        case 'large': return 'subheadline-x-small start-xl:headline-small';
        default: return 'subheadline-x-small start-xl:headline-small';
      }
    }
  },
  watch: {
    selectedTab(val, oldVal) {
      if(val !== oldVal) {
        this.primary_selected_tab = val;
      }
    },
    secondarySelectedTab(val, oldVal) {
      if(val !== oldVal) {
        this.secondary_selected_tab = val;
      }
    },
  },
  methods: {
    handleClick(selectedTab, is_primary = true) {
      if(is_primary) {
        this.primary_selected_tab = selectedTab;
        this.$emit('tabPrimary', selectedTab);
      } else {
        this.secondary_selected_tab = selectedTab;
        this.$emit('tabSecondary', selectedTab);
      }
      this.$emit('tabAction', selectedTab, is_primary);
    },
  },
};
</script>

<style scoped>
.tab-selector {
  @apply flex justify-between items-center border-b border-border;
}
.tab-selector__primary-list {
  @apply flex overflow-x-scroll;
  white-space: nowrap;
  max-width: calc(100vw - 45px);
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.tab-selector__primary-list::-webkit-scrollbar {
  display: none;
}

.tab-selector__primary-list-item {
  @apply text-text-inactive leading-loose mr-n-3xl cursor-pointer;
  color: rgb(var(--colors-text-inactive));
}

.tab-selector__secondary-list {
  @apply flex;
}

.tab-selector__secondary-list-item {
  @apply flex items-center font-bold text-xl20 cursor-pointer mx-2-2;
  color: rgb(var(--colors-text-inactive));
  opacity: .2;
}


/* Modifiers */
.tab-selector__primary-list-item.selected {
  @apply text-text-body;
  border-bottom: 4px solid rgba(var(--colors-border-active-nav), 1);
  transition: color 250ms;
}
.tab-selector__secondary-list-item.selected {
  opacity: 1;
  color: var(--colors-text-body);
}

</style>
