<template>
  <router-link :to="linkTo" :class="classes">
    {{ label }}
  </router-link>
</template>

<script>
export default {
  name: 'NavbarButton',

  props: {
    linkTo: String,
    label: String,
    selected: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    borderColor() {
      return this.selected ? 'border-border-active-nav' : 'border-transparent';
    },

    classes() {
      return {
        [this.borderColor]: true,
        ['border-b-2']: true,
        ['cursor-pointer']: true,
        ['default-transition']: true,
        ['font-semibold']: true,
        ['flex']: true,
        ['h-n-8xl']: true,
        ['hover:opacity-80']: true,
        ['items-center']: true,
        ['leading-md']: true,
        ['text-n-lg']: true,
        ['text-text-body']: true,
        ['w-fit-content']: true,
      };
    },
  },
};
</script>
