import VueRouter from 'vue-router';
import routes from './routes';

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    to.meta.fromHistory = savedPosition !== null;

    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    }

    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.fullPath.includes('//')) {
    router.replace(to.fullPath.replace('//', '/')).then(() => next());
  } else {
    next();
  }
});

export default router;
