import store from '@/store';
import copyToClipboard from '@/utils/clipboard';

const ui = {
  data: {
    hostname: window.location.hostname,
  },
  computed: {
    currentTheme() {
      return store.state.ui.currentTheme || 'light';
    },
    isMobileDevice() {
      return store.state.ui.windowWidth < 1200;
    },
    isMediumMobileDevice() {
      return store.state.ui.windowWidth < 992;
    },
    isSmallMobileDevice() {
      return store.state.ui.windowWidth < 768;
    },
    isTinyMobileDevice() {
      return store.state.ui.windowWidth < 576;
    },
    isNanoMobileDevice() {
      return store.state.ui.windowWidth < 375;
    },
    windowHeight() {
      return store.state.ui.windowHeight;
    },
    windowWidth() {
      return store.state.ui.windowWidth;
    },
  },
  methods: {
    getAssetImage(path) {
      return require('@/assets/img/' + path);
    },

    goToExternalPageGlobal(page) {
      window.open(page, '_blank', 'noopener,noreferrer');
    },

    goToInternalPageGlobal(page) {
      this.$router.push(page).catch(()=>{});
    },

    nativeAppPostMessage(data) {
      let iOSSucceeded = true;
      let flutterSucceeded = true;
      try {
        window.webkit.messageHandlers.iOSDismissCommunticationHandler.postMessage(data);
      } catch (err) {
        iOSSucceeded = false;
      }
      try {
        window.flutter_inappwebview.callHandler('nativeAppDismissCommunicationHandler', data);
      } catch (err) {
        flutterSucceeded = false;
      }
      return iOSSucceeded || flutterSucceeded; // if one of those succeeded, then native app message succeeded
    },

    scrollToTop() {
      window.scrollTo(0,0);
    },

    switchTheme(theme) {
      if (this.$store.state.ui.currentTheme === theme) {
        return;
      }

      localStorage.setItem('theme', theme);

      this.$store.commit('ui/setState', { stateName: 'currentTheme', stateData: theme });

      switch (theme) {
        case 'dark':
          document.documentElement.classList.remove(...document.documentElement.classList);
          document.documentElement.classList.add('dark');
          break;
        default:
          document.documentElement.classList.remove(...document.documentElement.classList);
          this.$store.commit('ui/setState', { stateName: 'currentTheme', stateData: 'light' });
      }
    },

    async clipboardCopy(text) {
      text = Array.isArray(text) ? text.join(', ') : text;
      try {
        await copyToClipboard(text);
        await this.showSuccess(this.$t('copied_to_clipboard'));
      } catch (err) {
        await this.showError(err);
      }
    },
  },
};

export default ui;
