import Vue from 'vue';
import Vuex from 'vuex';

import api from '@/modules/api';
import { AssetModule as asset } from '@/modules/asset';
import banner from '@/modules/banner';
import { CategoriesModule as categories } from '@/modules/categories';
import { CollectionsModule as collections } from '@/modules/collections';
import { DropModule as drops } from '@/modules/drops';
import events from '@/modules/events';
import { FracpackModule as fracpack } from '@/modules/fracpack';
import { holdings } from '@/modules/holdings/store';
import { PackModule as pack } from '@/modules/pack';
import { RewardModule as rewards } from '@/modules/rewards';
import { TopicModule as topic } from '@/modules/topic';
import { TradeModule as trade } from '@/modules/trade';
import ui from '@/modules/ui';
import { UserModule as user } from '@/modules/user';
import { WalletModule as wallet } from '@/modules/walletv2';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    api,
    asset,
    banner,
    categories,
    collections,
    drops,
    events,
    fracpack,
    holdings,
    pack,
    rewards,
    topic,
    trade,
    ui,
    user,
    wallet,
  },
});
