<template>
  <section class="no-result">
    <div class="text-center">

      <template>
        <slot
          v-if="$slots.icon"
          name="icon"
        />
        
        <component 
          v-else
          :is="iconComponent"
          class="m-auto"
        />
      </template>
      
      
      <h2 class="no-result_title">
        {{ title }}
      </h2>

      <p class="no-result_message">
        {{ message }}
      </p>

      <ButtonV2
        v-if="btnLabel"
        class="capitalize m-auto"
        testId="btn-primary"
        size="medium"
        :label="btnLabel"
        @onClick="onClickTrigger"
        version="primary"
      />
    </div>
  </section>
</template>


<script>
import { IconNoResultDark, IconNoResultLight } from '@/assets/icons';
import ButtonV2 from '@/stories/misc/ButtonV2.vue';

export default {
  name: 'NoResult',

  components: {
    ButtonV2,
    IconNoResultDark,
    IconNoResultLight,
  },

  computed: {
    iconComponent(){
      return this.currentTheme === 'dark' ? 'IconNoResultDark' : 'IconNoResultLight';
    },
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    message: {
      type: String,
      required: true,
    },

    btnLabel: String,

    btnLink: String,
  },

  methods: {
    onClickTrigger() {
      if (this.btnLink) {
        this.$router.push(`/${ this.btnLink }`);
        return;
      }

      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.no-result {
  @apply flex justify-center m-auto mt-s48;
  max-width: 300px;
}

.no-result_title {
  @apply mt-s24 subheadline-medium text-text-inactive-2;
}

.no-result_message {
  @apply mt-s8 mb-s24 body-text-large text-text-inactive;
}
</style>