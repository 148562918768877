import Vue from 'vue';
import Get from 'lodash.get';
import isMobile from 'ismobilejs';

import { GetTooltips } from '@/graphql/queries';
import { AVAILABLE_COMPONENTS } from '@/components/misc/ToastMessage';

import { showError as showErrorMessage } from '@/utils/error-handler';

const SET_IS_OVERLAY_LOADING = 'SET_IS_OVERLAY_LOADING';

export const DEFAULT_STATE = {
  currentModal: null,
  currentModalProps: null,
  currentTheme: 'light',
  initialLoading: true,
  isOverlayLoading: false,
  is_mobile: {},
  lastValidPage: null,
  loading: false,
  promptContent: {},
  promptOpened: false,
  sidebarSelectedItem: null,

  toastData: {
    type: 'success',
    message: '',
    component: '',
    dismiss: false,
  },
  toastOpen: false,
  viewedToasts: {
    // [toastComponent]: new Date()
  },
  tooltips: {},
  windowHeight: null,
  windowWidth: null,

  hasSeenAnnouncement: false,
};

export default {
  namespaced: true,

  state: { ...DEFAULT_STATE },

  actions: {
    // APP
    setIsMobile({ commit }, user_agent) {
      const is_mobile = isMobile(user_agent);
      commit('SET_IS_MOBILE', is_mobile);
    },

    setIsOverlayLoading({ commit }, payload) {
      commit(SET_IS_OVERLAY_LOADING, payload);
    },

    startLoading({ commit }) {
      commit('setState', {
        stateName: 'loading',
        stateData: true
      });
    },

    finishLoading({ commit }) {
      commit('setState', {
        stateName: 'loading',
        stateData: false
      });
    },

    async showError({ dispatch }, err) {
      await showErrorMessage(dispatch, err);
    },

    // PROMPT
    showPrompt({ commit }, payload) {
      const data = { promptOpened: true, promptContent: payload };
      commit('SET_PROMPT', data);
    },
    hidePrompt({ commit }) {
      const data = { promptOpened: false, promptContent: {} };
      commit('SET_PROMPT', data);
    },
    async promptMethod({ dispatch }, { component, method, payload = null }) {
      try {
        const action = await component[method](payload);
        return {
          result: action,
          error: null
        };
      } catch (err) {
        return {
          result: null,
          error: err
        };
      } finally {
        dispatch('hidePrompt');
      }
    },

    // MODAL
    showModal({ commit }, payload) {
      const data = { promptOpened: true, promptContent: payload };
      commit('SET_MODAL', data);
    },
    setModalProps({ commit, state }, payload) {
      commit('SET_MODAL_PROPS', {
        ...state.currentModalProps,
        ...payload
      });
    },
    hideModal({ commit }) {
      const data = { promptOpened: false, promptContent: {} };
      commit('SET_MODAL', data);
    },
    async modalMethod({ dispatch }, { component, method, payload = null }) {
      try {
        const action = await component[method](payload);
        return {
          result: action,
          error: null
        };
      } catch (err) {
        return {
          result: null,
          error: err
        };
      } finally {
        dispatch('hideModal');
      }
    },

    // Toast
    showToast({ commit }, { toastData, waitInDays = 0 }) {
      let canShow = true;
      if (toastData.component) {
        const viewedToasts = localStorage.getItem('viewedToasts');
        const viewedToastsObj = viewedToasts ? JSON.parse(viewedToasts) : {};
        if (toastData.component in viewedToastsObj) {
          const today = new Date();
          const lastSeen = new Date(viewedToastsObj[toastData.component]);
          const limitDate = lastSeen.setDate(lastSeen.getDate() + waitInDays);
          canShow = limitDate < today;
        }
      }

      if (canShow) {
        commit('SET_TOAST_DATA', { ...toastData });
        commit('SET_STATE', { stateName: 'toastOpen', stateData: true });
      }

    },

    hideToast({ commit }) {
      commit('SET_TOAST_DATA', {
        type: 'success',
        message: '',
        component: '',
        dismiss: false,
      });
      commit('SET_STATE', { stateName: 'toastOpen', stateData: false });
    },

    reclaimToast({ commit }, toastComponent) {
      const viewedToasts = localStorage.getItem('viewedToasts');
      const viewedToastsObj = viewedToasts ? JSON.parse(viewedToasts) : {};
      if (toastComponent in viewedToastsObj) {
        commit('REMOVE_VIEWED_TOASTS', toastComponent);
      }
    },

    dismissToast({ commit, dispatch }, toastComponent) {
      dispatch('hideToast');
      commit('ADD_VIEWED_TOASTS', toastComponent);
    },

    // Tooltip
    async getTooltips({ commit }, { apolloApiCall, showError, variables }) {
      try {
        const response = await apolloApiCall({
          query: GetTooltips,
          variables: { ...variables },
          fetchPolicy: 'no-cache',
        });
        commit('SET_TOOLTIPS', Get(response.data, 'get_tooltips', {}));
        const tooltip = Get(response.data, 'get_tooltips[0]', {});

        commit('showPrompt', {
          type: 'tooltip',
          tooltipIndex: 0,
          title: tooltip.title,
          message: tooltip.body,
        });
      } catch (err) {
        showError(err);
      }
    },

    // STATE
    setState({ commit }, payload) {
      commit('SET_STATE', payload);
    },

    toggleAnnouncement({ commit }) {
      commit('SET_ANNOUNCEMENT');
    }
  },

  mutations: {
    //... new codestyle pattern
    SET_IS_MOBILE(state, payload) {
      state.is_mobile = payload;
    },

    [SET_IS_OVERLAY_LOADING](state, payload) {
      state.isOverlayLoading = payload;
    },

    SET_PROMPT(state, { promptOpened = false, promptContent = {} }) {
      try {
        state.promptOpened = promptOpened;
        state.promptContent = promptContent;
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
      }
    },

    SET_MODAL(state, { currentModal, currentModalProps }) {
      state.currentModal = currentModal;
      state.currentModalProps = currentModalProps;
    },

    SET_MODAL_PROPS(state, currentModalProps) {
      state.currentModalProps = currentModalProps;
    },

    SET_STATE(state, { stateName, stateData }) {
      try {
        Vue.set(state, stateName, stateData);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    },

    SET_TOOLTIPS(state, payload) {
      state.tooltips = payload;
    },

    SET_TOAST_DATA(state, payload) {
      state.toastData.type = payload.type || 'success';
      state.toastData.dismiss = !!payload.dismiss;
      if (payload.message) {
        state.toastData.message = payload.message;
        state.toastData.component = '';
      } else if (payload.component) {
        state.toastData.component = payload.component;
        state.toastData.message = '';
      }
    },

    ADD_VIEWED_TOASTS(state, toastComponent) {
      if (!toastComponent && !AVAILABLE_COMPONENTS.includes(toastComponent)) { return; }

      const storedViewedToasts = localStorage.getItem('viewedToasts');
      const storedViewedToastsObj = storedViewedToasts ? JSON.parse(storedViewedToasts) : {};

      storedViewedToastsObj[toastComponent] = new Date();
      state.viewedToasts[toastComponent] = new Date();
      localStorage.setItem('viewedToasts', JSON.stringify(state.viewedToasts));
    },

    REMOVE_VIEWED_TOASTS(state, toastComponent) {
      if (!toastComponent && !AVAILABLE_COMPONENTS.includes(toastComponent)) { return; }

      const storedViewedToasts = localStorage.getItem('viewedToasts');
      const storedViewedToastsObj = storedViewedToasts ? JSON.parse(storedViewedToasts) : {};

      if (toastComponent in storedViewedToastsObj) {
        delete storedViewedToastsObj[toastComponent];
      }

      localStorage.setItem('viewedToasts', JSON.stringify(storedViewedToastsObj));
      state.viewedToasts = storedViewedToastsObj;
    },

    SET_VIEWED_TOAST(state, toastComponent) {
      state.viewedToasts[toastComponent] = new Date();
    },

    // ... WIP! legacy migrating 
    setState(state, payload) {
      Vue.set(state, payload.stateName, payload.stateData);
    },
    showPrompt(state, payload) {
      state.promptOpened = true;
      state.promptContent = payload;
    },
    hidePrompt(state) {
      state.promptOpened = false;
      state.promptContent = {};
    },

    // ... legacy, not migrated yet.
    showModal(state, payload) {
      state.currentModal = payload.currentModal;
      state.currentModalProps = payload.currentModalProps;
    },
    hideModal(state) {
      state.currentModal = null;
      state.currentModalProps = null;
    },
    setWindowDimensions(state, payload) {
      state.windowHeight = payload.windowHeight;
      state.windowWidth = payload.windowWidth;
    },

    SET_ANNOUNCEMENT(state) {
      state.hasSeenAnnouncement = !state.hasSeenAnnouncement;
    }
  },
};
