<template>
  <CentralizedSection :aria-label="$t(topicSettings.title)">
    <template slot="header">
      <div class="flex justify-center lg:mb-s32 mb-s64">
        <div class="topic-header">
          <h2 :class="titleClasses">
            {{ $t(topicSettings.title) }}
          </h2>
          <p v-if="topicSettings.subtitle" class="body-text-x-space">
            {{ $t(topicSettings.subtitle) }}
          </p>
        </div>
      </div>
    </template>

    <div class="grid grid-cols-4 self-center gap-s48 mt-s20 only-lg:grid-cols-2 md:grid-cols-1">
      <template
        v-for="(n, index) in topicSettings.topics.length"
      >
        <TopicCard
          v-bind="topicSettings.topics[index]"
          :key="index"
        />
      </template>
    </div>

    <div v-if="topicSettings.buttonLabel" class="flex justify-center mt-s64">
      <ButtonV2
        @onClick="$emit('onButtonClick')"
        :label="$tc(topicSettings.buttonLabel)"
        testId="btn-topicSettings"
        size="medium"
      />
    </div>
  </CentralizedSection>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import { CentralizedSection } from '@/components/misc';
import { topicSettings, TopicCard } from '@/modules/topic';

export default {
  name: 'TopicSection',

  components: {
    ButtonV2,
    CentralizedSection,
    TopicCard,
  },
  computed: {
    titleClasses(){
      return {
        'text-center': true,
        'headline-large': !this.isMobileDevice,
        'headline-small': this.isMobileDevice,
      };
    }
  },
  props: {
    topicSettings: {
      type: Object,
      default: () => (topicSettings),
    },
  },

};
</script>

<style scoped>
  .topic-header {
    @apply gap-s16 flex flex-col items-center text-justify;
  }
</style>
