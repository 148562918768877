export const SET_COLLECTION_POPULAR = 'SET_COLLECTION_POPULAR';
export const SET_COLLECTION_TOP_GAINERS = 'SET_COLLECTION_TOP_GAINERS';
export const SET_COLLECTION_TOP_LOSERS = 'SET_COLLECTION_TOP_LOSERS';

export const COLLECTIONS_POPULAR = 'COLLECTIONS_POPULAR';
export const COLLECTIONS_TOP_GAINERS = 'COLLECTIONS_TOP_GAINERS';
export const COLLECTIONS_TOP_LOSERS = 'COLLECTIONS_TOP_LOSERS';

export const collectionSettings = {
  data: [],
  isLoading: true,
  options: [],
  onTabChange: () => {},
  tab: '',
};
