<template>
  <SkeletonLoading v-if="globalIsLoading" class="feature-container mt-n-3xl" height="105px" borderRadius="25px"/>
  <div v-else @click="$emit('onClick')" class="feature-container hover:opacity-90">
    <div>
      <div class="text-n-2xl font-semibold">
        {{ title }}
      </div>
      <div class="mt-n-sm text-n-md font-medium">
        {{ subtitle }}
      </div>
    </div>
    <font-awesome-icon icon="chevron-down" class="rotate-270 ml-n-3xl text-text-body"/>
  </div>
</template>

<script>
export default {
  name: 'GoToFeature',
  props: {
    title: String,
    subtitle: String,
  }
};
</script>

<style scoped>
  .feature-container {
    @apply flex
    justify-between
    items-center
    px-n-4xl
    py-n-4xl
    bg-background-primary
    border
    border-border
    rounded-24
    shadow-card
    cursor-pointer;
  }
</style>
