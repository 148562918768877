<template>
  <SkeletonLoading 
    v-if="isLoading"
    :width="skeletonWidth" 
    :height="skeletonHeight" />
  <component
    v-else
    :class="cardClasses"
    :is="componentType"
    :to="componentType === 'router-link' && to">
      <slot name="image" />
      <slot name="header" />
      <slot />
  </component>
</template>

<script lang="js">
  export default {
    name: 'Card',
    props: {
      background: {
        type: String,
        default: 'bg-background-primary'
      },
      componentType: {
        type: String,
        default: 'section'
      },
      to: [String, Object],
      isLoading: {
        type: Boolean,
        default: () => false
      },
      skeletonLoading: {
        type: Object,
        required: false,
        validator: (value) => {
          if (value.height || value.width) {
            return true;
          }
          return false;
        }
      }
    },
    computed: {
      cardClasses(){
        return ['rounded-16 border border-border', this.background];
      },
      skeletonWidth(){
        return this.skeletonLoading && this.skeletonLoading.width || '100%';
      },
      skeletonHeight(){
        return this.skeletonLoading && this.skeletonLoading.height || '190px';
      }
    }
  };
</script>
