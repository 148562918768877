import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client';
import { InMemoryCache } from '@apollo/client';

if (!process || process.env.NODE_ENV !== 'test') {
  // Install the vue plugin
  Vue.use(VueApollo);
}

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'https://staging-api.hovuu.com';

// WebSocket endpoint
const wsEndpoint = process.env.VUE_APP_GRAPHQL_WSS || 'wss://staging-api.hovuu.com';

// Files URL root
export const filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));

Vue.prototype.$filesRoot = filesRoot;

const customCache = new InMemoryCache({
  possibleTypes: {
    'CircleDepositMethods': ['CircleAch', 'CircleCreditCard', 'CircleWire'],
  },
});

// Config
export const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  cache: customCache,

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions,
});

apolloClient.wsClient = wsClient;

export {
  apolloClient,
  wsClient
};

// Create vue apollo provider
export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      // fetchPolicy: 'cache-and-network',
    },
  },
  errorHandler (error) {
    // eslint-disable-next-line no-console
    console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message);
  },
});

// Manually call this when user log in
export async function onLogin (apolloProvider, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloProvider.wsClient) {restartWebsockets(apolloProvider.wsClient);}
  try {
    const client = apolloProvider.getClient();
    await client.stop();
    await client.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout (apolloProvider) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloProvider.wsClient) {restartWebsockets(apolloProvider.wsClient);}
  try {
    const client = apolloProvider.getClient();
    await client.stop();
    await client.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}

export function getTokenName() {
  return AUTH_TOKEN;
}
