
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FracPackCard"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FracPackCard"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fracpack_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"asset_price"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"asset"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Asset"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"current_user_amount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"market_cap_usd"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"associated_players"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"id_hr"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":237}};
    doc.loc.source = {"body":"#import \"@/graphql/fragments/Asset.gql\"\n\nfragment FracPackCard on FracPackCard {\n  id\n  fracpack_id\n  asset_price\n  asset {\n    ...Asset\n    current_user_amount\n    market_cap_usd\n    associated_players {\n      id\n      id_hr\n    }\n  }\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/Asset.gql").definitions));


      module.exports = doc;
    
