<template>
  <transition name="toast-animation">
    <section
      class="toast-container"
      :class="containerClasses"
      data-testid="toast-container"
      v-if="open"
      @click="handleClick"
    >
      <div class="toast-content" :class="{'--with-component': hasComponent}">

        <!-- With component -->
        <template v-if="hasComponent">
          <component
            transition="toast-animation"
            ref="toast-component"
            data-testid="toast-component"
            :is="component"
          />
        </template>

        <!-- With Message -->
        <template v-else>
          <font-awesome-icon role="icon" class="toast-icon mr-n-2xl" :icon="icon"/>
          <div v-html="markdownMessage" />
        </template>

        <!-- Is dismissable -->
        <button
          v-if="dismiss"
          class="dismiss-btn"
          data-testid="toast-dismiss-btn"
          @click="handleDismiss"
        >
          <i aria-hidden="true">
            <IconClose style="height: 14px;" />
          </i>
        </button>
      </div>
    </section>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';
import { markdownToHTML } from '@/utils/markdown';

import IconClose from '@/assets/icons/close.svg';
import ToastComponentExample from '@/components/misc/ToastMessage/ToastComponentExample.vue';

import { 
  AVAILABLE_TYPES, 
  AVAILABLE_COMPONENTS,
} from '@/components/misc/ToastMessage';

export default {
  name: 'ToastMessage',
  components: {
    'KycStatusBanner': () => import('@/modules/user/KycStatusBanner/index.vue'),
    IconClose,
    ToastComponentExample,
  },

  computed: {
    containerClasses() {
      return {
        [this.type]: true,
        'open': this.open,
        'dismiss': this.dismiss,
        'relative': this.dismiss,
        'fixed': !this.dismiss
      };
    },

    icon() {
      return this.type === 'success' ? 'check-circle' : 'exclamation-triangle';
    },

    markdownMessage() {
      return markdownToHTML(this.message);
    },
    
    hasComponent() {
      return !!this.component;
    },
    
  },

  data() {
    return {
      expiresAt: null,
    };
  },
  
  methods: {
    ...mapActions('ui', ['hideToast', 'dismissToast']),
    handleExpiration() {
      if(this.dismiss) {return;}
      this.expiresAt = Date.now() + this.expiresAfter;
      setTimeout(() => {
        if (!this.dismiss && this.open && Date.now() >= this.expiresAt) {
          this.handleClose();
        }
      }, this.expiresAfter);
    },
    handleClick(){
      if(!this.dismiss){
        this.handleClose();
      }
    },
    handleClose() {
      this.expiresAt = null;
      this.$emit('hideToast');
      this.hideToast();
    },
    handleDismiss() {
      if(this.hasComponent) {
        this.$emit('dismissToast');
        this.dismissToast(this.component);
      }
      this.handleClose();
    },
  },

  props: {
    message: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
      default: false,
    },
    expiresAfter: {
      type: Number,
      default: 3000,
    },
    type: {
      type: String,
      validator: (value) => AVAILABLE_TYPES.includes(value)
    },
    component: {
      type: String,
      validate: (value) => AVAILABLE_COMPONENTS.includes(value)
    },
    dismiss: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    open(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.handleExpiration();
      }
    },

    message() {
      if (this.expiresAt) {
        this.handleExpiration();
      }
    }
  },
};
</script>

<style scoped>
  .toast-container {
    @apply
      w-full
      overflow-hidden
      flex
      justify-center
      items-center
      font-semibold
      text-n-sm
      text-text-body
      left-0
      z-toast
    ;
  }

  .dismiss-btn {
    @apply 
      outline-none 
      fill-current
      text-text-body
      absolute
      top-0
      right-0
      mt-s20
      mr-s16
    ;
  }

  .toast-content {
    @apply 
      flex
      items-center
      justify-center
      px-s16
      py-s8
      overflow-hidden
    ;
    max-width: 1200px;
    min-height: 56px;
  }
  
  .toast-content.--with-component {
    @apply py-s0;
  }

  .open {
    @apply border-t border-transparent;
  }

  .toast-icon {
    height: 20px;
    width: 20px;
  }

  .success .toast-icon {
    @apply text-text-active-2;
  }

  .error .toast-icon {
    @apply text-text-negative;
  }

  .success {
    @apply 
      bg-background-positive-text
      border-border-active-nav
    ;
  }

  .error {
    @apply 
      bg-background-negative-text
      border-text-negative
    ;
  }

  .toast-animation-enter-active, .toast-animation-leave-active {
    transition: all 500ms;
    overflow: hidden;
    max-height: 120px;
    opacity: 1;
  }
  .toast-animation-enter, .toast-animation-leave-to {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
  }
</style>
