const api = {
  methods: {
    async apolloApiCall(data) {
      let result;

      // Call respective $apollo function
      if (data.mutation) {
        result = await this.$apollo.mutate(data);
      } else if (data.query) {
        result = await this.$apollo.query(data);
      }

      // Iterate over result data, checking for DibbsErrors
      Object.entries(result.data).find(([, resultData]) => {
        if (resultData['__typename'] === 'DibbsError') {
          let errorData = new Error();
          errorData = {...errorData, ...resultData};
          throw errorData;
        }
      });

      return result;
    },
  }
};

export default api;
