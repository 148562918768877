<template>
  <Card
    v-bind="cardProps"
    :class="cardClasses">
      <div
        slot="image"
        class="categories-image-holder"
      >
        <ImageResize class="object-cover" :src="data.img" type="home_category_card" />
      </div>
      <h3 slot="header" class="subheadline-large text-center capitalize py-s24 px-20">
        {{ name }} 
      </h3>
  </Card>
</template>

<script>
import { Card, ImageResize } from '@/components/misc';

export default {
  name: 'CategoryCard',
  components: {
    Card,
    ImageResize,
  },
  props: {
    ...Card.props,
    data: Object,
    background: String,
    isLoading: {
      type: Boolean,
      default: false,
    },
    skeletonLoading: {
      type: Object,
      default: () => ({
        height : '325px',
      })
    }
  },
  computed: {
    cardClasses(){
      return {
        'categories-card': true,
        'categories-card--mobile': this.isMobileDevice,
        'categories-card--desktop': !this.isMobileDevice
      };
    },
    cardProps(){
      if (this.$props) {
        const cardPropsExpected = ['background', 'isLoading', 'skeletonLoading'];
        const props = Object.entries(this.$props).filter(item => cardPropsExpected.includes(item[0]));

        return {
          ...Object.fromEntries(props),
          componentType: 'router-link',
          to: this.link
        };
      }
      return {};
    },
    image(){
      return !this.isLoading ? this.data.img : '';
    },
    name(){
      return !this.isLoading ? this.data.name : '';
    },
    link(){
      return !this.isLoading ? this.data.link : '';
    },
  }
};
</script>

<style scoped>
.categories-card {
  @apply flex flex-col overflow-hidden;
}

.categories-card--mobile{
  max-width: 320px;
}

.categories-card--desktop{
  max-width: 380px;
}

.categories-image-holder{
  @apply flex justify-center border-border border-b overflow-hidden;
  height: 253px;
}
</style>
