<template>
  <div class="navbar-container sticky">
    <!-- Desktop version -->
    <div v-if="!isMediumMobileDevice" class="navbar">
      <div class="flex items-center">
        <!-- Logo -->
        <MainLogo class="flex items-center mr-n-5xl" :darkMode="currentTheme === 'dark'"/>

        <!-- Buttons -->
        <div class="flex items-center">
          <NavbarButton v-for="(item) in desktopNavbar"
            v-bind:key="item.label"
            :data-cy="'navbar-' + item.name"
            :data-testid="'navbar-' + item.name"
            :label="$tc(item.label)"
            :linkTo="item.to"
            :selected="$route.name === item.name"
            class="mx-s24"
          />
        </div>
      </div>

      <div v-if="!isLoading" class="flex justify-center items-center">
        <template v-if="$route.path !== '/login' && !$route.path.includes('/sign-up')">
          <!-- Reward Navbar -->
          <RewardNavbar />

          <!-- Search Field -->
          <div @click="showModal('GlobalSearch')" class="ml-s16 cursor-pointer default-transition hover:opacity-80">
            <IconSearch class="w-s24 h-s24 fill-current mt-n-sm"/>
          </div>
        </template>

        <!-- Profile Dropdown -->
        <div v-if="isAuthenticated()" class="flex justify-center items-center">
          <NavbarDropdown
            class="ml-s32"
            @handleAction="handleAction"
            :dropdownItems="desktopItems"
          />
        </div>

        <!-- Login/register buttons -->
        <div v-else class="flex justify-center items-center">
          <ButtonV2 v-if="$route.path !== '/login'"
            @onClick="$router.push('/login')"
            :label="$t('navigation.login')"
            testId="btn-login"
            class="ml-n-3xl"
            size="medium"
          />
          <ButtonV2
            v-if="!$route.path.includes('/sign-up')"
            @onClick="$router.push('/sign-up')"
            :label="$t('navigation.signup')"
            testId="btn-signup"
            :version="$route.path !== '/login' ? 'secondary' : 'primary'"
            class="ml-n-xl"
            size="medium"
          />
        </div>
      </div>
    </div>

    <!-- Mobile version -->
    <div v-else-if="isMediumMobileDevice" class="navbar flex justify-between flex-grow">
      <!-- Logo Area -->
      <MainLogo class="flex items-center mr-n-5xl" :darkMode="currentTheme === 'dark'"/>

      <div v-if="!isLoading" class="flex items-center">

        <template v-if="$route.path !== '/login' && !$route.path.includes('/sign-up')">
          <!-- Reward Navbar -->
          <RewardNavbar />

          <!-- Search Field -->
          <IconSearch class="w-s24 h-s24 fill-current" @click="showModal('GlobalSearch')"/>
        </template>

        <!-- Profile Dropdown -->
        <div v-if="isAuthenticated()" class="flex items-center">
          <div @click="showModal('MobileMenu')">
            <Avatar class="ml-s16"/>
          </div>
        </div>

        <!-- Auth buttons -->
        <div v-else class="flex items-center whitespace-no-wrap">
          <ButtonV2 v-if="$route.path !== '/login'"
            data-cy="navbar-button-login"
            data-testid="navbar-button-login"
            @onClick="$router.push('/login')"
            :label="$t('navigation.login')"
            class="ml-n-md"
            size="medium"
          />
          <ButtonV2
            data-cy="navbar-button-signup"
            data-testid="navbar-button-signup"
            v-if="!$route.path.includes('/sign-up') && (!isTinyMobileDevice || $route.path === '/login')"
            @onClick="$router.push('/sign-up')"
            :label="$t('navigation.signup')"
            :version="$route.path !== '/login' ? 'secondary' : 'primary'"
            class="ml-n-md"
            size="medium"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/misc/Avatar.vue';
import ButtonV2 from '@/stories/misc/ButtonV2.vue';
import IconSearch from '@/assets/icons/search.svg';
import MainLogo from '@/stories/misc/MainLogo.vue';
import NavbarButton from '@/stories/navbar/NavbarButton.vue';
import NavbarDropdown from '@/components/navbar/NavbarDropdown.vue';
import RewardNavbar from '@/modules/rewards/RewardNavbar';

import Menu from './Menu.js';

export default {
  name: 'Navbar',
  extends: Menu,
  components: {
    Avatar,
    ButtonV2,
    IconSearch,
    MainLogo,
    NavbarButton,
    NavbarDropdown,
    RewardNavbar,
  },

  computed: {
    isLoading() {
      return this.$store.state.ui.initialLoading;
    },
  }
};
</script>

<style scoped>
  .navbar-container {
    @apply 
      bg-background-primary 
      border-b 
      border-border 
      flex 
      h-n-8xl 
      justify-center 
      top-0
      w-full 
      z-overlay
    ;
  }

  .navbar-container .navbar {
    @apply flex justify-between w-full px-n-3xl;
    max-width: 1242px;
  }
</style>
