<template>
  <div>
    <IconButton v-if="!showText"
      data-cy="share-button"
      data-testid="share-button"
      @onClick="openShareContainer"
      :color="color"
      icon="share-alt"
      variation="plain"
    />
    <ButtonV2 v-else
      data-cy="share-button"
      data-testid="share-button"
      testId="btn-share-button"
      @onClick="openShareContainer"
      :label="customLabel || $tc('share.share')"
      size="medium"
      wide
    />
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import { IconButton } from '@/components/misc';

export default {
  name: 'Share',
  components: {
    ButtonV2,
    IconButton,
  },

  props: {
    color: {
      type: String,
      default: 'text-text-active-2',
    },
    customLabel: {
      type: String,
      default: null
    },
    showText: {
      type: Boolean,
    },
    shareUrl: {
      type: String,
    },
    shareTitle: {
      type: String,
    },
    shareMessage: {
      type: String,
    },
  },

  methods: {
    openShareContainer() {
      if (navigator.share && this.isMobileDevice) {
        navigator.share({
          title: this.shareTitle || this.$t('share.share_title'),
          url: this.shareUrl || `https://${this.hostname}${this.$route.path}`,
          text: this.shareMessage || this.$t('share.share_message'),
        }).then(() => {
          this.$emit('amplitudeEvent');
        });
      } else {
        this.$emit('amplitudeEvent');
        this.showModal('Share', {
          shareUrl: this.shareUrl || `https://${this.hostname}${this.$route.path}`,
          shareTitle: this.shareTitle || this.$t('share.share_title'),
          shareMessage: this.shareMessage || this.$t('share.share_message'),
        });
      }
    }
  }
};
</script>
