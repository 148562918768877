
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EstimateSell"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"EstimateSell"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"average_card_price_sold"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"card_fraction_sold"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"estimate_row_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fee"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"instant_asset_spent"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"instant_money_received"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"instant_money_fee"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"inventory_percentage_sold"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"is_invalid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"post_sale_card_price"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"price_change"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"price_impact"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"usd_amount_received"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":296}};
    doc.loc.source = {"body":"fragment EstimateSell on EstimateSell {\n  average_card_price_sold\n  card_fraction_sold\n  estimate_row_id\n  fee\n  instant_asset_spent\n  instant_money_received\n  instant_money_fee\n  inventory_percentage_sold\n  is_invalid\n  post_sale_card_price\n  price_change\n  price_impact\n  usd_amount_received\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
