<template>
  <div class="stats-card" :class="[backgroundClass]">
    <div class="body-text-x-large">{{ label }}</div>
    <div class="stats-card-value">{{ value }}</div>
  </div>
</template>

<script>
export const AVAILABLE_BACKGROUNDS = ['none', 'primary', 'secondary', 'tertiary'];

export default {
  name: 'StatsCard',
  components: {

  },

  computed: {
    backgroundClass() {
      return !this.background ? '' : `stats-card-background--${this.background}`;
    },
  },

  props: {
    background: {
      type: String,
      default: 'none',
      validator: (value) => AVAILABLE_BACKGROUNDS.includes(value)
    },

    label: {
      type: String,
      required: true
    },

    value: {
      type: String,
      required: true
    },
  },
};
</script>

<style scoped>
  .stats-card {
    @apply
      flex
      flex-col
      items-center
      justify-center
      border
      border-border
      rounded-16
      px-s24
      w-full;
    max-width: 340px;
    height: 168px;
  }

  .stats-card-value {
    @apply mt-s20;
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
  }

  .stats-card-background--primary {
    background: url(~@/assets/img/drops-bg3.webp);
  }
  .stats-card-background--secondary {
    background: url(~@/assets/img/drops-bg2.webp);
  }
  .stats-card-background--tertiary {
    background: url(~@/assets/img/drops-bg1.webp);
  }
</style>
