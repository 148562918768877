const DEFAULT_STATE = {
  pagination: {
    count: 50,
    offset: 0,
    endOfList: false,
  },
  assets: [],
  packs: [],
  watchlist: [],
};

const state = { ...DEFAULT_STATE };

export {
  DEFAULT_STATE,
  state,
};
