import { sellWithDibbsSection, valuePropositionSection } from '@/modules/topic';

export default {
  namespaced: true,
  state: {
    sellWithDibbsSection,
    valuePropositionSection,
  },
  getters: {
    getSellWithDibbsSection(state) {
      return state.sellWithDibbsSection;
    },
    getValuePropositionSection(state) {
      return state.valuePropositionSection;
    },
  },
};
