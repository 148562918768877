export { default as TabSelector } from './TabSelector.vue';

export const DEFAULT_SIZES = ['medium', 'large'];

export const SAMPLE_DATA = {
  size: DEFAULT_SIZES[0],
  tabsList: ['buy_card', 'sell_card'],
  secondaryTabsList: ['1D', '1W', '1M', 'ALL'],
  selectedTab: 'buy_card',
  secondarySelectedTab: '1W',
};
