import { IconCircleDollar } from '@/assets/icons';
import CirclePaymentType from '@/enums/CirclePaymentType.js';

import { mountProfitLoss } from './utils';

export const RESET_USDC_DEPOSIT = 'RESET_USDC_DEPOSIT';
export const SET_CHART_VALUES = 'SET_CHART_VALUES';
export const SET_CIRCLE_PUBLIC_KEY = 'SET_CIRCLE_PUBLIC_KEY';
export const SET_CIRCLE_REQUEST_ACH_SIGNATURE = 'SET_CIRCLE_REQUEST_ACH_SIGNATURE';
export const SET_DEPOSIT_AMOUNT = 'SET_DEPOSIT_AMOUNT';
export const SET_LINKED_DEPOSIT_METHODS = 'SET_LINKED_DEPOSIT_METHODS';
export const SET_LINKED_WITHDRAWAL_METHODS = 'SET_LINKED_WITHDRAWAL_METHODS';
export const SET_ONGOING_DEPOSIT_DATA = 'SET_ONGOING_DEPOSIT_DATA';
export const SET_ONGOING_WITHDRAW_DATA = 'SET_ONGOING_WITHDRAW_DATA';
export const SET_PLAID_TOKEN = 'SET_PLAID_TOKEN';
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const SET_USDC_CHAINS = 'SET_USDC_CHAINS';
export const SET_USDC_DEPOSIT_ADDRESS = 'SET_USDC_DEPOSIT_ADDRESS';
export const SET_USDC_DEPOSIT_CHAIN = 'SET_USDC_DEPOSIT_CHAIN';
export const SET_USDC_WITHDRAW_ADDRESS = 'SET_USDC_WITHDRAW_ADDRESS';
export const SET_USDC_WITHDRAW_ADDRESS_TAG = 'SET_USDC_WITHDRAW_ADDRESS_TAG';
export const SET_USDC_WITHDRAW_AMOUNT = 'SET_USDC_WITHDRAW_AMOUNT';
export const SET_USDC_WITHDRAW_CHAIN = 'SET_USDC_WITHDRAW_CHAIN';
export const SET_WITHDRAW_AMOUNT = 'SET_WITHDRAW_AMOUNT';
export const SET_LAST_WITHDRAW_ID = 'SET_LAST_WITHDRAW_ID';
export const SET_DEPOSIT_LIMITS = 'SET_DEPOSIT_LIMITS';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_SINGLE_TRANSACTION = 'SET_SINGLE_TRANSACTION';
export const SET_INVENTORY_CHANGE = 'SET_INVENTORY_CHANGE';
export const SET_TRANSACTIONS_HISTORY_ACTIVE_ORDERS = 'SET_TRANSACTIONS_ACTIVE_ORDERS';
export const SET_TRANSACTIONS_HISTORY_ALL = 'SET_TRANSACTIONS_HISTORY';
export const RESET_TRANSACTION_HISTORY = 'CLEAR_TRANSACTION_HISTORY';
export const SET_PROFIT_LOSS = 'SET_PROFIT_LOSS';

export default {
  [SET_CHART_VALUES](state, payload) {
    state.balanceChartValues = payload;
  },
  [SET_PLAID_TOKEN](state, payload) {
    state.currentPlaidToken = payload;
  },
  [SET_CIRCLE_PUBLIC_KEY](state, payload) {
    state.currentCirclePublicKey = payload;
  },
  [SET_TRANSACTION](state, payload) {
    state.transaction = payload;
  },
  [SET_LINKED_DEPOSIT_METHODS](state, payload) {
    state.linkedDepositMethods = payload;
  },
  [SET_LINKED_WITHDRAWAL_METHODS](state, payload) {
    state.linkedWithdrawalMethods = payload;
  },
  [SET_ONGOING_DEPOSIT_DATA](state, payload) {
    state.ongoingDepositData = { ...state.ongoingDepositData, ...payload };
  },
  [SET_ONGOING_WITHDRAW_DATA](state, payload) {
    state.ongoingWithdrawData = payload;
  },
  [SET_DEPOSIT_AMOUNT](state, amount) {
    state.currentDepositAmount = amount;
  },
  [RESET_USDC_DEPOSIT](state) {
    state.selected_deposit_chain = null;
    state.circle_create_chain_deposit_address = null;
  },
  [SET_USDC_CHAINS](state, { chains, isDeposit }) {
    state[isDeposit ? 'circle_available_deposit_chains' : 'circle_available_withdrawal_chains'] = chains;
  },
  [SET_USDC_DEPOSIT_ADDRESS](state, created_deposit_address) {
    state.circle_create_chain_deposit_address = created_deposit_address;
  },
  [SET_USDC_DEPOSIT_CHAIN](state, chain) {
    state.selected_deposit_chain = chain;
  },
  [SET_USDC_WITHDRAW_CHAIN](state, chain) {
    state.selected_withdraw_chain = chain;
  },
  [SET_USDC_WITHDRAW_ADDRESS](state, address) {
    state.withdraw_wallet_address = address;
  },
  [SET_USDC_WITHDRAW_ADDRESS_TAG](state, tag) {
    state.withdraw_wallet_address_tag = tag;
  },
  [SET_WITHDRAW_AMOUNT](state, amount) {
    state.currentWithdrawAmount = amount;
  },
  [SET_DEPOSIT_LIMITS](state, payload) {
    const currentDepositLimits = payload.deposit_limits;
    const initialWithdrawLimit = 500;
    const initialDepositLimit = 10000;
    let mappedRemaining = [
      {
        type: 'account_limit',
        values: [
          {
            type: 'total',
            amount: !payload.permissions.withdraw ? initialWithdrawLimit : initialDepositLimit,
          },
        ],
      },
    ];

    //Map remaining limits
    for (let depositLimit of currentDepositLimits) {
      let obj = {
        type: '',
        values: [],
      };

      obj.type = depositLimit.type;
      Object.keys(depositLimit.remaining).forEach(function (key) {
        let value = {};
        value.type = key;
        value.amount = depositLimit.remaining[key];

        if (key !== '__typename' && key !== 'min') {
          key === 'single'
            ? (obj.values = [value, ...obj.values]) // put single (single transaction ) at the beginning
            : obj.values.push(value);
        }
      });
      mappedRemaining.push(obj);
    }

    state.depositLimits = {
      mappedRemaining: mappedRemaining,
      all: currentDepositLimits,
    };
  },
  [SET_PAYMENT_METHODS](state, payload) {
    const depositLimits = payload.deposit_limits;
    const permissions = payload.permissions;
    const depositMethods = [];
    const withdrawalMethods = [];

    for (let method of depositLimits) {
      if (CirclePaymentType.ACH === method.type) {
        const achData = { icon: 'university', isRecommended: true, type: CirclePaymentType.ACH, title: CirclePaymentType.ACH, enabled: method.enabled };
        depositMethods.push({ ...achData, fee: method.deposit_fee, funds: 'funds_available_instantly' });
        withdrawalMethods.push({ ...achData, fee: method.withdraw_fee, funds: 'funds_available_3_5_business_days' });
      } else if (CirclePaymentType.CARD === method.type) {
        const cardData = { icon: 'credit-card', isRecommended: false, type: CirclePaymentType.CARD, title: CirclePaymentType.CARD, enabled: method.enabled };
        depositMethods.push({ ...cardData, fee: method.withdraw_fee, funds: 'funds_available_instantly' });
      } else if (CirclePaymentType.WIRE === method.type) {
        const wireData = { icon: 'exchange-alt', isRecommended: false, type: CirclePaymentType.WIRE, title: CirclePaymentType.WIRE, enabled: method.enabled };
        depositMethods.push({ ...wireData, fee: method.deposit_fee, funds: 'funds_available_1_3_business_days' });
        withdrawalMethods.push({ ...wireData, fee: method.withdraw_fee, funds: 'funds_available_3_5_business_days' });
      } else {
        const blockchainData = { icon: IconCircleDollar, isRecommended: false, type: CirclePaymentType.BLOCKCHAIN, title: CirclePaymentType.BLOCKCHAIN, enabled: method.enabled };
        depositMethods.push({ ...blockchainData, fee: method.deposit_fee, funds: 'funds_available_instantly' });
        withdrawalMethods.push({ ...blockchainData, fee: method.withdraw_fee, funds: 'funds_available_3_5_business_days' });
      }
    }

    state.transferenceMethods.deposit = {
      limit: 0,
      methods: depositMethods,
    };

    state.transferenceMethods.withdraw = {
      limit: 0,
      methods: withdrawalMethods,
    };

    state.userPermissions = { ...state.userPermissions, withdraw: permissions.withdraw };
  },
  [SET_LAST_WITHDRAW_ID](state, payload) {
    state.lastWithdrawalId = payload;
  },

  [SET_SINGLE_TRANSACTION](state, payload) {
    state.transactions.single = payload;
  },

  [SET_INVENTORY_CHANGE](state, payload) {
    state.inventoryChange = payload;
  },


  [SET_TRANSACTIONS_HISTORY_ACTIVE_ORDERS](state, payload) {
    state.transactions.active_orders = [
      ...state.transactions.active_orders,
      ...payload,
    ];
  },

  [SET_TRANSACTIONS_HISTORY_ALL](state, payload) {
    state.transactions.all = [
      ...state.transactions.all,
      ...payload,
    ];
  },

  [RESET_TRANSACTION_HISTORY](state) {
    state.transactions = {
      all: [],
      active_orders: [],
      single: {},
    };
  },

  [SET_PROFIT_LOSS](state, payload) {
    const oldest = payload[0].close;
    const newest = payload[payload.length - 1].close;
    state.profitLoss.overall = mountProfitLoss(newest, oldest);
  },
};
