<template>
  <picture
    :style="[ maxHeight ]"
    class="picture"
    ref="squareImage"
  >
    <aside
      :class="coverProps.classes"
      :style="[ coverProps.styles ]"
    />

    <ImageResize
      v-bind="imageProps"
      class="front"
    />
  </picture>
</template>

<script>
import { getCdnImage } from '@/utils/formatters';
import ImageResize from '../ImageResize/ImageResize';

export default {
  name: 'SquareImage',
  
  components: {
    ImageResize,
  },

  props: {
    image: {
      type: String,
      required: true,
    },

    coverClasses: String,

    imageResizeProps: {
      alt: {
        type: String,
        default: 'Image of an asset',
      },
      classes: String,
      onImageLoad: Function,
      assetType: String,
    },
  },

  data() {
    return {
      maxWidth: 100,
    };
  },

  computed: {
    imageProps() {
      let props = {
        src: this.image,
        style: [ this.maxHeight ],
        class: [ 'front '],
      };

      if (this.imageResizeProps) {
        const { classes, assetType } = this.imageResizeProps;

        props = {
          ...props,
          ...this.imageResizeProps,
          type: assetType,
          class: [ 
            classes,
            props.class,
          ],
        };
      }

      return props;
    },

    backgroundImage() {
      const image = getCdnImage(
        this.image, 
        { width: this.coverWidth }
      );

      return {
        backgroundImage: `url(${ image })`
      };
    },

    coverProps() {
      return {
        styles: {
          ...this.backgroundImage,
          ...this.maxHeight,
          'max-width': `${ this.maxWidth }px`,
        },

        classes: [
          'cover',
          this.coverClasses,
        ],
      };
    },

    maxHeight() {
      return {
        'max-height': `${ this.maxWidth }px`,
      };
    },
  },

  mounted() {
    this.updateMaxWidth();
  },

  updated() {
    this.updateMaxWidth();
  },

  methods: {
    updateMaxWidth() {
      this.maxWidth = this.$refs.squareImage.clientWidth;
    }
  }
};
</script>

<style scoped>
.picture {
  @apply flex relative content-center justify-center w-full;
}

.cover {
  @apply
    absolute
    bg-center
    bg-cover
    min-w-full
    min-h-full
    opacity-40
    rounded-tl-lg
    rounded-tr-lg
    rounded-bl-lg
    rounded-br-lg;
}

.front {
  @apply relative z-component h-full w-auto;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-tr-none {
  border-bottom-right-radius: 0;
}

.rounded-tl-none {
  border-bottom-left-radius: 0;
}
</style>
