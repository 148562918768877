import formatters from '@/mixins/formatters';

const mountPackList = (list) => {

  return list.map(item => {
    const isPack = item.variation === 'pack';

    const rawValues = isPack ? {
      img: item.img,
      campaign: item.campaign,
      assets: item.assets,
      currentPrice: 0,
      time_created: item.time_created,
      time_sent: item.time_sent,
    } : {
      img: item.definition.image,
      definition_id: item.definition_id,
      definition: item.definition,
      cards_inside: item.cards_inside,
      time_bought: item.time_bought,
      currentPrice: item.buy_price,
    };

    const readableValues = isPack ? {} : {
      currentPrice:  formatters.methods.numberFormat(item.buy_price, 2, false, true),
    };

    return {
      id: item.id,
      image: rawValues.img,
      type: item.type,
      variation: item.variation,
      rawValues: {
        ...rawValues,
        id: item.id,
        state: item.state,
      },
      readableValues
    };
  });

};

export default mountPackList;
