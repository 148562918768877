<script>
export default {
  name: 'toast-example',
};
</script>

<template>
  <section class="toast-example">
    <h2 class="subheadline-large">This is a example title</h2>
    <div class="body-text-large">
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia odit explicabo corporis praesentium labore.</p>
    </div>
  </section>
</template>

<style scoped>
.toast-example {
  @apply py-s20;
}
.toast-example a { 
  color: var(--colors-text-active-2);
}
</style>
