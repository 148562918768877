import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import Get from 'lodash.get';
import cloneDeep from 'lodash.clonedeep';

const lodash = {
  methods: {
    lodashCloneDeep(object) {
      return cloneDeep(object);
    },

    lodashIsEmpty(objectOrArray) {
      return isEmpty(objectOrArray);
    },

    lodashIsEqual(object, other) {
      return isEqual(object, other);
    },

    lodashGet(object, path, defaultValue) {
      return Get(object, path, defaultValue);
    },
  },
};

export default lodash;
