<template>
  <header>
      <PrimaryTabs
        class="subheadline-large mb-s24"
        classes="primary-tabs-divider"
        :currentTab="currentTab"
        :tabList="tabsList"
        @tabChange="onTabChange($event, 'PRIMARY')"
      />

      <SecondaryTabs 
        class="mb-s32"
        :tabList="filtersList" 
        :currentTab="currentFilter" 
        @tabChange="onTabChange($event, 'SECONDARY')"
      />
  </header>
</template>

<script>
import {
  PrimaryTabs,
  SecondaryTabs,
} from '@/components/misc';

export default {
  name: 'TabsAndFilters',

  components: {
    PrimaryTabs,
    SecondaryTabs,
  },

  props: {
    tabsList: {
      type: Array,
      required: true,
    },

    currentTab: {
      type: String,
      required: true,
    },

    filtersList: {
      type: Array,
      required: true,
    },

    currentFilter: {
      type: String,
      required: true,
    },
  },

  methods: {
    onTabChange(tab, type) {
      this.$emit('onTabChange', {
        tab,
        type
      });
    }
  }
};
</script>