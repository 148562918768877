
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Drop"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Drop"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"id_hr"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"running"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"start_time"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"end_time"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"asset_value_usd"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"supply_left"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fee_fraction"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"max_current_user_can_spend"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":156}};
    doc.loc.source = {"body":"fragment Drop on Drop {\n  id\n  id_hr\n  running\n  type\n  start_time\n  end_time\n  asset_value_usd\n  supply_left\n  fee_fraction\n  max_current_user_can_spend\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
