import store from '@/store';
import { showError as showErrorMessage } from '@/utils/error-handler';

const messages = {
  methods: {
    showTotalBalanceInfo() {
      store.commit('ui/showPrompt', {
        type: 'single-action',
        title: this.$t('current_balance.total_balance_info'),
        message: `
          <div>
            <p>${this.$t('current_balance.total_balance_description_1')}</p>
            <p>${this.$t('current_balance.total_balance_description_2')}</p>
            <p>${this.$t('current_balance.total_balance_description_3')}</p>
          </div>
        `
      });
    },

    showSuccess: async function (successMessage) {
      await store.dispatch('ui/showToast', {
        toastData: { type: 'success', message: successMessage }
      });
    },

    showError: async function (err) {
      await showErrorMessage(store.dispatch, err);
    },

    showMessage: async function (message, type='success') {
      await store.dispatch('ui/showToast', {
        toastData: { type, message }
      });
    },
  },
};

export default messages;
