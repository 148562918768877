<template>
  <CentralizedSection class="categories-section">
    <template slot="header">
      <div class="flex justify-center lg:mb-s32 mb-s64">
        <h2 :class="titleClasses">
          {{$t('categories_section.title')}}
        </h2>
      </div>
    </template>

    <CarouselV2 :carousel-settings="carouselSettings">
      <template v-for="( chunk, chunkIndex ) in chunks">
        <Slide
          :data-testid="`category-${chunkIndex}`"
          :key="chunkIndex"
          class="flex justify-center gap-s24">
          <template v-for="( category, categoryIndex ) in chunk">
            <CategoryCard
              :key="categoryIndex"
              :isLoading="isLoading"
              :background="background"
              :data="category"
            />
          </template>
        </Slide>
      </template>
    </CarouselV2>

    <div v-if="!isMobileDevice" class="flex justify-center mt-s32">
      <ButtonV2 
        @onClick="() => goToInternalPageGlobal('/market/collections')"
        size="medium"
        testId="btn-viewMore"
        :label="$t('view_more')" />
    </div>

  </CentralizedSection>
</template>
<script>
import { Slide } from 'vue-carousel';

import ButtonV2 from '@/stories/misc/ButtonV2';
import { CarouselV2, CentralizedSection } from '@/components/misc';
import { CategoryCard } from '@/modules/categories';

export default {
  name: 'CategorySection',
  components: {
    ButtonV2,
    CategoryCard,
    CarouselV2,
    CentralizedSection,
    Slide
  },
  props: {
    background: {
      type: String,
      default: 'bg-background-page'
    },
    data: {
      type: Array,
      default: () => []
    },
    isLoading: Boolean,
  },
  computed: {
    carouselSettings(){
      return {
        slideAmount: this.chunks.length,
        perPage: 1,
      };
    },
    chunks(){
      if (this.isLoading) {
        return this.loadingList; 
      }
      return this.nestedList;
    },
    loadingList(){
      if (this.isSmallMobileDevice) {
        return [Array(1).fill({})]; 
      } else if (this.isMobileDevice) {
        return [Array(2).fill({})]; 
      } else {
        return [Array(3).fill({})]; 
      }
    },
    nestedList(){
      const data = [...this.data].splice(0, 3);
      return data.reduce((acc, item, index) => { 
        let nestedArray;
        if (this.isSmallMobileDevice) {
         nestedArray = Math.floor(index / 1);
        } else if (this.isMobileDevice) {
          nestedArray = Math.floor(index / 2);
        } else {
          nestedArray = Math.floor(index / 3);
        }

        if(!acc[nestedArray]) {
          acc[nestedArray] = [];
        }

        acc[nestedArray].push(item);

        return acc;
      }, []);
    },
    titleClasses(){
      return {
        'headline-large': !this.isMobileDevice,
        'headline-small': this.isMobileDevice,
      };
    },
  }
};
</script>

<style scoped></style>
