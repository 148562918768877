<template>
  <BaseLayout>
    <div class="inner w-full">
      <slot/>
    </div>
    <Footer class="mt-s48"/>
  </BaseLayout>
</template>

<script>
import Footer from '@/components/footer/Footer.vue';
import BaseLayout from './BaseLayout.vue';

export default {
  name: 'LayoutCentralized',
  components: {
    BaseLayout,
    Footer,
  }
};
</script>

<style scoped>
  .inner {
    min-height: calc(100vh - 455px);
  }
</style>
