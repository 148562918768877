
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CircleWithdrawal"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CircleWithdrawal"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"amount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"chain"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"address"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"chain"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"fee"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"withdrawType"},"name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"withdrawStatus"},"name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"created_at"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"updated_at"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":178}};
    doc.loc.source = {"body":"fragment CircleWithdrawal on CircleWithdrawal {\n  id\n  amount\n  chain {\n    address\n    chain\n  }\n  fee\n  withdrawType: type\n  withdrawStatus: status\n  created_at\n  updated_at\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
