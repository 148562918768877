
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Asset"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Asset"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ticker"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"short_name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"long_name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"img_front"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"img_back"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"img_alt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"price_usd"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"supply"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"associated_players"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Player"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sport"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"detailed_description"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":264}};
    doc.loc.source = {"body":"#import \"@/graphql/fragments/Player.gql\"\n\nfragment Asset on Asset {\n  id\n  ticker\n  name\n  short_name\n  long_name\n  img_front\n  img_back\n  img_alt\n  price_usd\n  supply\n  associated_players {\n    ...Player,\n    sport {\n      name\n    }\n  }\n  detailed_description\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/Player.gql").definitions));


      module.exports = doc;
    
