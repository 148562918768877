<template>
  <Card v-bind="cardProps" :class="cardClasses" @click.native="goToInternalPageGlobal(`/collection/${data.link}`)">
    <div
      slot="image"
      class="collection-image-holder">
      <ImageResize class="collection-image" :src="data.img" type="home_collection_card" />
    </div>
    <div v-if="!isLoading" slot="header" class="flex flex-col flex-1 py-s20 px-s16">
      <div class="subheadline-small pb-s8">
        {{ data.short_name || data.name }}
      </div>
      <div class="flex w-full items-center justify-between gap-s16">
        <div class="flex flex-col">
          <span class="body-text-x-small text-text-inactive">
            {{ $t('collection_section.collection_price') }}
          </span>
          <p class="body-text-x-large">
            {{ data.valueText }}
          </p>
        </div>
        <div class="flex flex-col" v-if="data.priceChange.rawValue !== 0">
          <span class="body-text-x-small text-text-inactive">
            {{ priceChangeTimeframe }}
          </span>
          <p :class="priceChangeClasses">
            {{ data.priceChange.text }} ({{ data.priceChange.percentText }})
          </p>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import { Card, ImageResize } from '@/components/misc';

export default {
  name: 'CollectionsCard',
  components: {
    Card,
    ImageResize
  },

  props: {
    ...Card.props,
    background: String,
    isLoading: {
      type: Boolean,
      default: false,
    },
    data: Object,
    skeletonLoading: {
      type: Object,
      default: () => ({
        height : '311px',
        width : '250px',
      })
    }
  },

  computed: {
    cardClasses(){
      return {
        'collections-card flex flex-col overflow-hidden cursor-pointer': true,
      };
    },
    cardProps(){
      if (this.$props) {
        const cardPropsExpected = ['background', 'isLoading', 'skeletonLoading'];
        const props = Object.entries(this.$props).filter(item => cardPropsExpected.includes(item[0]));

        return Object.fromEntries(props);
      }
      return {};
    },
    priceChangeClasses(){
      return !this.isLoading && {
        'body-text-x-medium': true,
        [`text-text-${this.data.priceChange.colorType}`]: true,
      };
    },
    priceChangeTimeframe() {
      const priceChange = this.data.priceChange.timeframe;
      return this.$t(`market.change_timeframes.change${priceChange}`);
    },
  }
};
</script>

<style scoped>
.collections-card{
  width: 250px;
}

.collection-image-holder{
  @apply flex justify-center border-border border-b overflow-hidden;
}

.collection-image{
  object-fit: contain;
  object-position: bottom;
  height: 150px;
}
</style>
