<template>
  <section class="info-panel">
    <div class="info-panel__inner">
      <font-awesome-icon
        data-testid="icon"
        class="info-panel__icon"
        :class="customIconClasses"
        :icon="icon"
        v-if="has_icon"
      />
      <div>
        <div class="info-panel__title" v-if="has_title">
          {{ title }}
        </div>
        <div class="info-panel__content" v-if="has_content">
          <slot />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'InfoPanel',
  props: {
    title: String,
    icon: String,
    customIconClasses: String,
  },
  computed: {
    has_title() {
      return !!this.title;
    },
    has_content() {
      return !!this.$slots.default;
    },
    has_icon() {
      return !!this.icon;
    }
  },
};
</script>

<style scoped>
.info-panel {
  @apply 
    rounded-8 
    border
    border-border-info-panel
    relative
    overflow-hidden
  ;
}
.info-panel:before {
  @apply 
    absolute
    inset-0
    bg-background-positive-text
    opacity-50
  ;
  content: '';
  z-index: 1;
}
.info-panel__icon {
  @apply mr-n-xl self-center text-text-positive;
  font-size: 20px;
}
.info-panel__inner {
  @apply flex relative p-s16;
  z-index: 2;
}
.info-panel__title {
  @apply text-left font-bold text-n-md mb-n-sm;
}
.info-panel__content {
  @apply text-left text-n-sm font-medium leading-sm;
}
.info-panel__content a {
  @apply text-text-positive;
}
</style>
