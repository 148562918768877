<template>
  <section class="carousel">
    <div class="carousel__slider">
      <button 
        v-if="isLeftArrowVisible"
        class="carousel-button carousel-arrow-button left-0"
        :aria-label="$t('carousel.previous_button')"
        :disabled="isLeftArrowDisabled"
        @click="goToPreviousIndex"
      >
        <IconCarretLeft class="carousel-arrow" />
      </button>

      <SkeletonLoading 
        v-if="isLoading" 
        class="mx-n-xl" 
        :width="imageContainerStyle.width" 
        :height="imageContainerStyle.height"
      />

      <template
        v-for="(item, id) in values"
        v-else
      >
        <div
          v-show="id === currentIndex"
          :key="id"
          :aria-label="`carousel-image-${ id }`"
          class="carousel__image-resize"
        >
          <SquareImage v-bind="getSquareImageProps(item)" />

          <div 
            class="zoom-icon-container"
            @click="triggerAssetViewer(item)">
            <font-awesome-icon icon="search-plus"/>
          </div>
        </div>
      </template>

      <button 
        v-if="isRightArrowVisible"
        class="carousel-button carousel-arrow-button right-0" 
        :aria-label="$t('carousel.next_button')"
        :disabled="isRightArrowDisabled"
        @click="goToNextIndex"
      >
        <IconCarretRight class="carousel-arrow" />
      </button>

    </div>

    <div
      v-if="showDots"
      class="carousel__dots"
    >
      <SkeletonLoading
        v-if="isLoading"
        height="20px"
      />

      <template
        v-for="(item, id) in values"
        v-else
      >
        <button
          v-show="getShowButtonValidation(id)"
          :key="id"
          :aria-label="`${$t('carousel.go_to')}: ${id}`"
          :disabled="isLoading"
          :class="getButtonClasses(id)"
          @click="goToCarouselIndex(id)">
        </button>
      </template>
    </div>
  </section>
</template>

<script>
import { IconCarretLeft, IconCarretRight } from '@/assets/icons';
import SquareImage from '../SquareImage';
import { IMAGE_SIZES } from '@/components/misc/ImageResize';

export default {
  name: 'Carousel',

  components: {
    IconCarretLeft,
    IconCarretRight,
    SquareImage,
  },

  props: {
    imageType: {
      type: String,
      default: 'asset_card',
      validator: (item) => Boolean(IMAGE_SIZES[item]),
    },

    isLoading: Boolean,

    values: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      currentIndex: 0,
    };
  },

  computed: {
    showDots() {
      return this.values.length > 1;
    },

    isLeftArrowVisible(){
      return this.currentIndex > 0;
    },

    isRightArrowVisible(){
      return this.currentIndex < (this.values.length - 1);
    },

    isLeftArrowDisabled(){
      return this.currentIndex === 0 || this.isLoading;
    },

    isRightArrowDisabled(){
      return (this.currentIndex + 1) === this.values.length 
        || this.isLoading;
    },

    imageTypeSize() {
      return IMAGE_SIZES[this.imageType];
    },

    imageContainerStyle() {
      return {
        'width': this.imageType && this.imageTypeSize.width + 'px',
        'height': this.imageType && this.imageTypeSize.height + 'px',
      };
    },
  },

  methods: {
    goToCarouselIndex(id){
      this.currentIndex = id;
      this.$emit('carouselChanged', id);
    },

    goToPreviousIndex(){
      if (this.isLeftArrowDisabled) {
        return;
      }
      return this.goToCarouselIndex(this.currentIndex - 1);
    },

    goToNextIndex(){
      if (this.isRightArrowDisabled) {
        return;
      }
      return this.goToCarouselIndex(this.currentIndex + 1);
    },

    getSquareImageProps({ img_front }) {
      return {
        image: img_front,
        imageResizeProps: {
          assetType: this.imageType,
        }
      };
    },

    getShowButtonValidation(id) {
      return this.values.length > 1 
        && this.currentIndex <= (id + 3) 
        && this.currentIndex >= (id - 3);
    },

    getButtonClasses(id) {
      return [
        'carousel-button carousel-dot',
        id === this.currentIndex && 'carousel-dot--selected',
        id === (this.currentIndex - 1) && 'carousel-dot--close',
        id === (this.currentIndex + 1) && 'carousel-dot--close',
        id === (this.currentIndex - 2) && 'carousel-dot--far',
        id === (this.currentIndex + 2) && 'carousel-dot--far',
      ];
    },

    triggerAssetViewer({ img_front, img_back }) {
      const imgBack = img_back ? img_back : false;

      this.openAssetViewer(img_front, imgBack);
    }
  }
};
</script>

<style scoped>
.carousel {
  @apply flex flex-col gap-n-3xl w-full h-full;
}

.carousel__slider {
  @apply flex justify-center relative;
}

.carousel__image-resize {
  @apply w-full relative mx-n-xl;
  object-fit: contain;
  user-select: none;
}

.carousel__dots {
  @apply flex items-center justify-center gap-n-md;
  height: 6px;
}

.carousel-button {
  @apply cursor-pointer;
}

.carousel-button:disabled {
  @apply cursor-not-allowed;
}

.carousel-arrow-button {
  @apply absolute outline-none;
  transform: translateY(-50%);
  top: 50%;
}

.carousel-arrow-icon {
  height: 25px;
  width: 25px;
}

.carousel-dot{
  @apply bg-background-inactive rounded-16 outline-none bg-opacity-40;
  height: 6px;
  width: 6px;
}

.carousel-dot--far {
  @apply bg-opacity-60;
  height: 6px;
  width: 6px;
  transition: all 700ms;
}

.carousel-dot--close {
  @apply bg-opacity-70;
  height: 8px;
  width: 8px;
  transition: all 400ms;
}

.carousel-dot--selected{
  @apply bg-background-active-nav bg-opacity-100;
  height: 10px;
  width: 10px;
  transition: all 600ms;
}
</style>
