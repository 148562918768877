import formatters from '@/mixins/formatters';

import { 
  SET_COLLECTION_POPULAR,
  SET_COLLECTION_TOP_GAINERS,
  SET_COLLECTION_TOP_LOSERS,
  COLLECTIONS_POPULAR,
  COLLECTIONS_TOP_GAINERS,
  COLLECTIONS_TOP_LOSERS,
} from './types';

export default {
  namespaced: true,
  state: {
    COLLECTIONS_POPULAR: [],
    COLLECTIONS_TOP_GAINERS: [],
    COLLECTIONS_TOP_LOSERS: [],
  },
  mutations: {
    [ SET_COLLECTION_POPULAR ](state, payload){
      state.COLLECTIONS_POPULAR = payload;
    },
    [ SET_COLLECTION_TOP_GAINERS ](state, payload){
      state.COLLECTIONS_TOP_GAINERS = payload;
    },
    [ SET_COLLECTION_TOP_LOSERS ](state, payload){
      state.COLLECTIONS_TOP_LOSERS = payload;
    }
  },
  actions: {
    setCollectionByType({ commit }, payload){
      switch (payload.type) {
        case COLLECTIONS_POPULAR:
          commit(SET_COLLECTION_POPULAR, payload.data);
          break;
        case COLLECTIONS_TOP_GAINERS:
          commit(SET_COLLECTION_TOP_GAINERS, payload.data);
          break;
        case COLLECTIONS_TOP_LOSERS:
          commit(SET_COLLECTION_TOP_LOSERS, payload.data);
          break;
        default:
          throw Error('No collection type found');
      }
    }
  },
  getters: {
    getCollectionsAdapted() {
      return (value) =>
        value.map((item) => ({
        id: item.id,
        img: item.img,
        link: item.id_hr,
        name: item.name,
        short_name: item.short_name,
        priceChange: {
            colorType: item.price_change?.change_amount >= 0 ? 'positive' : 'negative',
            percentText: `${formatters.methods.numberFormat(item.price_change?.change_pct)}%`,
            text: formatters.methods.numberFormat(item.price_change?.change_amount, 2, true, true),
            rawValue: parseFloat(item.price_change?.change_amount),
            timeframe: item.price_change?.timeframe,
        },
        valueText: formatters.methods.numberFormat(item.total_value, 2, false, true),
      }));
    },
    getCollectionsPopular(state, getters){
      return getters.getCollectionsAdapted(state[COLLECTIONS_POPULAR]);
    },
    getCollectionsTopGainers(state, getters){
      return getters.getCollectionsAdapted(state[COLLECTIONS_TOP_GAINERS]);
    },
    getCollectionsTopLosers(state, getters){
      return getters.getCollectionsAdapted(state[COLLECTIONS_TOP_LOSERS]);
    }
  },
};
