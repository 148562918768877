<template>
  <div v-if="isComponentLoading">
    <SkeletonLoading
      width="96px"
      height="96px"
    />
  </div>
  <div v-else @click="onDateClick" data-testid="claim-day-container" class="claim-day" :class="claimDayClasses">
    <h1 class="subheadline-large">{{ $t('rewards.day') }} {{ dayNumber }}</h1>
    <div v-if="isClaimed" data-testid="test-checked-icon">
      <component
        :is="currentCheckmarkIcon"
        role="icon"
        class="claim-day__check"
      />
    </div>
    <div v-else class="claim-day__gems mt-s16 body-text-x-large">
      {{ $tc('rewards.gems', currentAmount) }}
    </div>
  </div>
</template>

<script>
import RewardState from '@/enums/RewardState';

export default {
  name: 'RewardClaimDayButton',
  components: {
    IconCheckedDark: () => import('@/assets/icons/checked_dark.svg'),
    IconCheckedLight: () => import('@/assets/icons/checked.svg'),
  },
  props: {
    day: {
      type: Object,
      required: true,
    },
    dayNumber: {
      type: Number,
      default: 1,
    },
    isComponentLoading: {
      type: Boolean,
      default: false,
    },
    isKycComplete: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    canBeClaimed() {
      return this.day.state === RewardState.CAN_BE_CLAIMED && this.isKycComplete;
    },

    currentCheckmarkIcon() {
      return 'IconChecked' + (this.currentTheme !== 'dark' ? 'Light' : 'Dark');
    },

    claimDayClasses() {
      return {
        'claim-day__can-be-claimed': this.canBeClaimed,
        'claim-day__claimed': this.isClaimed,
      };
    },

    currentAmount() {
      return this.$big(this.day.amount).toFixed(0);
    },

    isClaimed() {
      return this.day.state === RewardState.CLAIMED;
    },
  },

  methods: {
    onDateClick() {
      if (this.canBeClaimed) {
        this.$emit('onDateClick');
      }
    }
  }
};
</script>

<style scoped>
.claim-day {
  @apply flex flex-col items-center justify-around bg-background-primary text-text-inactive py-s16 rounded-16;
  min-width: 96px;
  height: 96px;
}

.claim-day__check {
  @apply mt-s8;
  width: 28px;
  height: 28px;
}

.claim-day__can-be-claimed {
  @apply border-2 bg-background-icon border-border-active-nav-2 text-text-body cursor-pointer;
}

.claim-day__claimed {
  @apply bg-background-icon text-text-body;
}
</style>
