
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserAsset"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserAsset"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"asset_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"amount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ticker"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"img_front"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"total_value"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":108}};
    doc.loc.source = {"body":"fragment UserAsset on UserAsset {\n  asset_id\n  amount\n  ticker\n  name\n  img_front\n  total_value\n  # pairs\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
