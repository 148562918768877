<template>
  <section class="custom-input" :data-testid="testId">
    <!-- Label Area -->
    <div
      v-if="hasLabelSlot"
      class="custom-input__label-container"
      :data-testid="`${testId}__input-label-container`"
    >
      <label :class="leftLabelClasses" :for="name">
        <slot />
      </label>
      <div :class="rightLabelClasses">
        <slot name="right-label" />
      </div>
    </div>

    <!-- Input Area -->
    <div @click="handleFocus()" :class="containerClasses">
      <input
        v-model="model"
        v-maska="mask"
        v-if="!isDoubleInput"
        :id="name"
        :class="inputClasses"
        :data-lpignore="lpIgnore"
        :autocomplete="autocomplete"
        :data-testid="`${testId}__custom-input`"
        :disabled="disabled"
        :inputmode="inputmode"
        :name="name"
        :placeholder="placeholder"
        ref="inputRef"
        :type="type"
        @blur="emitBlur"
        @focus="handleFocus"
        @maska="onMask"
      />
      <money
        v-else
        v-model="model"
        mas
        v-bind="filterType"
        :id="name"
        :class="inputClasses"
        :data-lpignore="lpIgnore"
        :autocomplete="autocomplete"
        :data-testid="`${testId}__custom-input`"
        :disabled="disabled"
        :inputmode="inputmode"
        :name="name"
        :placeholder="placeholder"
        ref="inputRef"
        :type="type"
        @blur="emitBlur"
        @focus="handleFocus"
      ></money>
      <div
        v-if="hasIconSlot"
        :class="iconClasses"
        :data-testid="`${testId}__icon`"
      >
        <slot name="icon" />
      </div>
    </div>

    <!-- Error Area -->
    <div v-if="errorField" :class="errorClasses">
      <div
        v-if="error.status"
        :data-testid="`${testId}__input-error-container`"
      >
        <slot name="error">
          {{ error.message || $t('errors.invalid_field') }}
        </slot>
      </div>
    </div>
  </section>
</template>

<script>
import { maska } from 'maska';
import { Money } from 'v-money';

export const AVAILABLE_FILTERS = ['money', 'percent', 'double','letter'];
export const AVAILABLE_TYPES = ['email', 'number', 'password', 'tel', 'text'];
export const AVAILABLE_VERSIONS = ['primary'];

export default {
  name: 'CustomInput',
  components: { Money },
  directives: { maska },
  props: {
    autocomplete: {
      type: String,
      default: 'off'
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: String,
      validator: (value) => AVAILABLE_FILTERS.includes(value),
    },
    errorField: {
      type: Boolean,
      default: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    initialFocus: {
      type: Boolean,
      default: false,
    },
    inputmode: {
      type: String,
    },
    mask: {
      type: String,
    },
    lpIgnore: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    testId: {
      type: String,
      default: 'unamed',
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => AVAILABLE_TYPES.includes(value),
    },
    value: {
      type: [String, Number],
    },
    version: {
      type: String,
      default: 'primary',
      validator: (value) => AVAILABLE_VERSIONS.includes(value),
    },
    masked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      oldValue: this.value,
      error: {
        status: false,
        message: '',
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },

    containerClasses() {
      return {
        ...this.sharedClasses,
        [this.borderRadius]: true,
        [this.paddingHorizontal]: true,
        ['border']: true,
        ['flex']: true,
        ['items-center']: true,
        ['justify-between']: true,
      };
    },

    inputClasses() {
      return {
        ...this.sharedClasses,
        [this.placeholderColor]: true,
        [this.textColor]: true,
        [this.textSize]: true,
        [this.textWeight]: true,
        ['border-t']: true,
        ['border-b']: true,
        ['no-arrows']: true,
      };
    },

    sharedClasses() {
      return {
        [this.backgroundColor]: true,
        [this.borderColor]: true,
        [this.cursor]: true,
        [this.inputHeight]: true,
        ['w-full']: true,
      };
    },

    backgroundColor() {
      return 'bg-background-primary';
    },

    borderColor() {
      return this.error.status ? 'border-border-negative' : 'border-border';
    },

    borderRadius() {
      return 'rounded-16';
    },

    cursor() {
      return this.disabled ? 'cursor-not-allowed' : 'cursor-text';
    },

    errorClasses() {
      return 'body-text-x-large text-text-negative text-left h-s20 mt-s4';
    },

    hasIconSlot() {
      return !!this.$slots.icon;
    },

    hasLabelSlot() {
      return !!this.$slots.default || !!this.$slots['right-label'];
    },

    iconClasses() {
      return 'ml-n-xl cursor-text fill-current';
    },

    inputHeight() {
      return 'h-n-6xl';
    },

    leftLabelClasses() {
      return 'subheadline-small';
    },

    paddingHorizontal() {
      return 'px-n-3xl';
    },

    placeholderColor() {
      return 'placeholder-text-inactive';
    },

    rightLabelClasses() {
      return 'body-text-medium';
    },

    textColor() {
      return 'text-text-body';
    },

    textSize() {
      return 'body-text-x-large';
    },

    textWeight() {
      return 'font-semibold';
    },

    isDoubleInput() {
      return this.filter === 'money' || this.filter === 'percent' || this.filter === 'double';
    },

    filterType() {
      let defaultFilter = {
        precision: 2,
        masked: this.masked,
        decimal: '.',
        thousands: ',',
      };
      switch (this.filter) {
        case 'money':
          return {
            prefix: '$',
            suffix: '',
            ...defaultFilter,
          };
          
        case 'percent':
          return {
            prefix: '',
            suffix: '%',
            ...defaultFilter,
          };
          
        case 'double':
          return {
            prefix: '',
            suffix: '',
            ...defaultFilter,
          };
          
        default:
          return false;
      }
    },
  },
  mounted() {
    if (this.initialFocus) {
      this.handleFocus();
    }
  },
  methods: {
    handleFocus() {
      // force input selection on focus
      if (this.isDoubleInput) {
        this.$refs.inputRef.$el.focus();
      } else {
        this.$refs.inputRef.focus();
      }
    },

    emitBlur(event) {
      const value = event.target.value;
      this.$emit('blur', value);
    },

    onMask(event) {
      this.$emit('maska', event.target.dataset.maskRawValue);
    },

    setError(error) {
      if (error === false) {
        this.error = { status: false, message: error };
      } else {
        this.error = {
          status: true,
          message: error || this.errorMessage,
        };
      }
    },
  },
  watch: {
    hasError(value) {
      this.error.status = value;
    },
    errorMessage(value) {
      this.error.message = value;
    },
  },
};
</script>

<style scoped>
.custom-input__label-container {
  @apply flex justify-between items-center mb-s8;
}

input {
  text-align: inherit;
}

/* reset autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-top: solid 1px var(--colors-border-default);
  border-bottom: solid 1px var(--colors-border-default);
  -webkit-text-fill-color: var(--colors-text-body);
  -webkit-box-shadow: 0 0 0px 1000px var(--colors-background-primary) inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
