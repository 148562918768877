<template>
  <div class="w-full">
    <BaseText tag="h1" size="headline-small start-xl:headline-large" position="text-left">
      {{ $t('rewards.rewards_history.title') }}
    </BaseText>

    <NoResult
      v-if="!isComponentLoading && !currentHistoryList.length"
      :title="$t('rewards.rewards_history.no_result.title')"
      :message="$t('rewards.rewards_history.no_result.message')"
    />

    <template v-else>
      <BaseText class="mt-s12 start-xl:mt-s16" tag="p" size="body-text-x-space">
        {{ $t('rewards.rewards_history.description') }}
      </BaseText>
      <BaseText class="mt-s40 pb-s16 start-xl:pb-s24" tag="h2" size="subheadline-large">
        {{ $t('rewards.rewards_history.sub_title') }}
      </BaseText>

      <div class="history-list-container">
        <template v-for="(item) in currentHistoryList">
          <RewardHistoryItem
            v-bind="item"
            :key="item.id"
            data-cy="history-item"
            data-testid="history-item"
            class="history-item"
            :isComponentLoading="isComponentLoading"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { BaseText, NoResult } from '@/components/misc';
import RewardHistoryItem from '@/modules/rewards/RewardHistoryItem';
import historyItems from '@/mocks/reward-history';

export default {
  name: 'RewardHistoryList',

  components: {
    BaseText,
    NoResult,
    RewardHistoryItem,
  },

  props: {
    historyList: {
      type: Array,
      required: true,
    },
    isComponentLoading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
  },

  computed: {
    currentHistoryList() {
      if (this.isComponentLoading) {
        return this.loadingList;
      }

      return this.historyList;
    },

    loadingList() {
      return historyItems;
    },
  }
};
</script>

<style scoped>
.history-list-container {
  max-height: 370px;
  overflow: auto;
}

.history-item:first-child {
  @apply -mt-s16;
}

@screen start-xl {
  .history-item:first-child {
    @apply -mt-s24;
  }
}
</style>
