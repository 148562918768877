<template>
  <SkeletonLoading 
    v-if="isLoading"
    height="206px"
  />
  <div v-else-if="showCardladder">
    <div class="flex justify-between items-center">
      <div class="flex justify-center items-center leading-none">
        <div class="headline-small">
          {{ $t('cardladder.title') }}
        </div>
      </div>
      <ButtonV2
        @onClick="openCardladderPage(seeAllUrl)"
        class="block lg:hidden"
        :label="$t('cardladder.see_all')"
        size="small"
        testId="btn-see-all"
        version="secondary"
      />
    </div>

    <div class="flex flex-col">
      <div class="grid grid-cols-4 lg:grid-cols-2 items-center">
        <template v-for="(item) of content">
          <div
            v-if="item.active"
            :key="item.id"
            class="flex flex-col border-t border-border pt-s20 mt-s20"
          >
            <p class="text-text-inactive body-text-x-space">
              {{ item.title }}
            </p>
            <p :class="['mt-s4 body-text-large', item.specialClasses]">
              {{ item.body }}
            </p>
          </div>
        </template>

        <div class="cardladder-icon">
          <p class="text-text-inactive body-text-x-space">
            {{ $t('cardladder.last_days') }} {{ $t('cardladder.data_provided') }}
          </p>
          <IconCardLadder class="h-s20 mt-s4 text-text-body fill-current" />
        </div>
      </div>

      <ButtonV2
        @onClick="openCardladderPage(seeAllUrl)"
        class="hidden mt-s20 lg:block"
        :label="$t('cardladder.see_all')"
        size="small"
        testId="btn-see-all-mobile"
        version="secondary"
        wide
      />
    </div>
  </div>
</template>

<script>
import { IconCardLadder } from '@/assets/icons';
import ButtonV2 from '@/stories/misc/ButtonV2';

export default {
  name: 'TradeCardLadder',
  components: {
    ButtonV2,
    IconCardLadder,
  },
  props: {
    cardladder: Object,
    isLoading:  {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    seeAllUrl() {
      return `${this.cardladder.cl_url}&ref=dibbs_web`;
    },
    showCardladder() {
      return this.lodashGet(this.cardladder, 'cl_url', false);
    },
    content(){
      return [
        {
          id: 0,
          title: this.$t('cardladder.last_price'), 
          body: `${this.numberFormat(this.lodashGet(this.cardladder, 'cl_market_value', 0), 2, false, true)}`,
          active: !!this.lodashGet(this.cardladder, 'cl_market_value', 0),
        },
        {
          id: 1,
          title: this.$t('cardladder.average_price'), 
          body: `${this.numberFormat(this.lodashGet(this.cardladder, 'cl_average_price', 0), 2, false, true)}`,
          active: !!this.lodashGet(this.cardladder, 'cl_average_price', 0),
        },
        {
          id: 2,
          title: this.$t('cardladder.highest_price'), 
          body: `${this.numberFormat(this.lodashGet(this.cardladder, 'cl_high_price', 0), 2, false, true)}`,
          active: !!this.lodashGet(this.cardladder, 'cl_high_price', 0),
        },
        {
          id: 3,
          title: this.$t('cardladder.lowest_price'), 
          body: `${this.numberFormat(this.lodashGet(this.cardladder, 'cl_low_price', 0), 2, false, true)}`,
          active: !!this.lodashGet(this.cardladder, 'cl_low_price', 0),
        },
        {
          id: 4,
          title: this.$t('cardladder.number_of_sales'), 
          body: `${this.lodashGet(this.cardladder, 'cl_num_sales', 0)}`,
          active: !!this.lodashGet(this.cardladder, 'cl_num_sales', 0),
        },
        {
          id: 5,
          title: this.$t('cardladder.population'), 
          body: `${this.lodashGet(this.cardladder, 'cl_population', 0)}`,
          active: !!this.lodashGet(this.cardladder, 'cl_population', 0),
        },
      ];
    },
  },
  methods: {
    async openCardladderPage(page) {
      this.goToExternalPageGlobal(page);
      await this.$store.dispatch('events/track', {
        event: 'CARD_LADDER_VIEWED',
      });
    },
    openHelpDialog() {
      this.showModal('CardLadderHelp');
    },
  }
};
</script>

<style scoped>
  .cardladder-container {
    @apply flex-1 bg-background-primary rounded-16 border border-border;
  }

  .cardladder-icon {
    @apply flex flex-col justify-start items-start border-t border-border pt-s20 mt-s20;
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 2;
  }

  @screen lg {
    .cardladder-icon {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 4;
      grid-row-end: 4;
    }
  }
</style>
