import DROP_STATUS from '@/enums/DropStatus';

export const setDropStatus = (drop, timeNow) => {
  const rules = {
    [DROP_STATUS.LIVE]: [
      timeNow >= drop.start_time,
      timeNow <= drop.end_time,
      drop.supply !== 100
    ],
    [DROP_STATUS.LIVE_IN]: [
      timeNow < drop.start_time,
      drop.supply !== 100
    ],
    [DROP_STATUS.SOLD_OUT]: [
      timeNow < drop.end_time,
      drop.supply === 100
    ],
    [DROP_STATUS.ENDED]: [
      timeNow > drop.end_time,
    ]
  };

  const result = Object.entries(rules).flatMap(item => {
    if (item[1].every(value => value)) {
     return item[0];
    }
  }).filter(item => item)[0];

  return result || DROP_STATUS.ENDED;
};
