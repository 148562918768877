import router from '@/vue-router';
import HomeQuery from '@/graphql/queries/Home.gql';

router.addRoute({ 
  component: () => import('@/views/Home'),
  name: 'home',
  path: '/',
  meta: {
    bypassLoading: true,
    template: 2,
    query: HomeQuery
  }
});

router.addRoute({ 
  component: () => import('@/views/Home'),
  name: 'home-redirect',
  path: '*',
  meta: {
    bypassLoading: true,
    template: 2,
    query: HomeQuery
  }
});
