<template>
  <div class="carousel-container">
    <Carousel :key="settings.mouseDrag" v-bind="settings" v-model="currentItemIndex">
      <slot />
    </Carousel>

    <SkeletonLoading
      v-if="settings.isLoading"
      class="self-center"
      width="158px"
      height="20px"
    />
    <div v-else class="flex items-center justify-center gap-s8">
      <div class="flex justify-center w-s20 h-s20">
        <button 
          v-if="hasPagination && isLeftArrowVisible"
          class="carousel-button text-text-positive"
          :aria-label="$t('carousel.previous_button')"
          @click="changeCarouselItem(currentItemIndex - 1)">
          <font-awesome-icon icon="chevron-left" />
        </button>
      </div>
      <div v-if="hasPagination" class="flex justify-center items-center gap-s16 w-s102">
        <template v-for="(_, id) in pagination">
          <button
            v-if="showButton(id)"
            :key="id"
            :aria-label="`${$t('carousel.go_to')}: ${id}`"
            :class="currentButtonClasses(id)"
            @click="changeCarouselItem(id)">
          </button>
        </template>
      </div>
      <div class="flex justify-center w-s20 h-s20">
        <button 
          v-if="hasPagination && isRightArrowVisible"
          class="carousel-button text-text-positive"
          :aria-label="$t('carousel.next_button')"
          @click="changeCarouselItem(currentItemIndex + 1)">
          <font-awesome-icon icon="chevron-right" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel } from 'vue-carousel';
import { carouselSettings } from './types';

export default {
  name: 'CarouselV2',
  components: {
    Carousel
  },
  props: {
    carouselSettings: {
      type: Object,
      default: () => (carouselSettings)
    },
  },
  data: () =>({
    currentItemIndex: 0,
  }),
  computed: {
    currentButtonClasses(){
      return (id) => ({
       'carousel-button carousel-dot': true,
       'carousel-dot--selected': id === this.currentItemIndex,
       'carousel-dot--close': id === (this.currentItemIndex - 1) || id === (this.currentItemIndex + 1),
       'carousel-dot--far': id === (this.currentItemIndex - 2) || id === (this.currentItemIndex + 2),
      });
    },
    isLeftArrowVisible(){
      return this.currentItemIndex > 0;
    },
    isRightArrowVisible(){
      return this.currentItemIndex < (this.pagination - 1);
    },
    pagination(){
      return Math.ceil(this.carouselSettings.slideAmount / this.carouselSettings.perPage);
    },
    hasPagination(){
      return this.pagination > 1;
    },
    settings(){
      return {
        ...this.carouselSettings,
        navigateTo: this.currentItemIndex,
        paginationEnabled: false,
        mouseDrag: this.hasPagination,
        touchDrag: this.hasPagination
      };
    },
    showButton() {
      return (id) => {
        switch (this.currentItemIndex) {
          case 0:
          case this.pagination - 1:
            return this.currentItemIndex <= (id + 4) && this.currentItemIndex >= (id - 4);
          case 1:
          case this.pagination - 2:
            return this.currentItemIndex <= (id + 3) && this.currentItemIndex >= (id - 3);
          default:
            return this.currentItemIndex <= (id + 2) && this.currentItemIndex >= (id - 2);
        }
      };
    }
  },
  methods: {
    changeCarouselItem(index) {
      this.currentItemIndex = index;
    }
  }
};
</script>
<style scoped>
.carousel-container {
  @apply flex flex-col gap-s32;
}

.carousel-button {
  @apply cursor-pointer outline-none;
}

.carousel-button:disabled {
  @apply cursor-not-allowed;
}

.carousel-arrow-icon {
  height: 25px;
  width: 25px;
}

.carousel-dot{
  @apply bg-background-inactive rounded-16 outline-none bg-opacity-40;
  height: 6px;
  width: 6px;
}

.carousel-dot--far {
  @apply bg-opacity-60;
  height: 6px;
  width: 6px;
  transition: all 700ms;
}

.carousel-dot--close {
  @apply bg-opacity-70;
  height: 8px;
  width: 8px;
  transition: all 400ms;
}

.carousel-dot--selected{
  @apply bg-background-active-nav bg-opacity-100;
  height: 10px;
  width: 10px;
  transition: all 600ms;
}

.w-s102 {
  width: 102px;
}
</style>
