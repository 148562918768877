import Vue from 'vue';
import amplitude from 'amplitude-js';
import Get from 'lodash.get';

const vue = new Vue();

export const emitAmplitudeEvent = async (eventName, variables) => {
  if (vue.$amplitude) {
    try {
      await vue.$amplitude.logEvent(eventName, variables);
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(`[Amplitude ${eventName}] event emitted ${variables ? `with variables: ${JSON.stringify(variables)}` : ''}`);
      }
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(err.message);
      }
    }
  }
};

export const setAmplitudeGuestProps = ({ currentTheme, eventProperties, isMobileDevice }) => {
  const identify = new amplitude.Identify()
    .set(eventProperties.PLATFORM, !isMobileDevice ? 'web desktop' : 'web mobile')
    .set(eventProperties.COLOR_THEME, currentTheme);
  vue.$amplitude.identify(identify);
};

export const setAmplitudeUserProps = ({ eventProperties, user, isMobileDevice, currentTheme }) => {
  const today = new Date();
  const todayString = `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`;
  const identify = new amplitude.Identify()
    .set(eventProperties.IS_TWO_FACTOR_AUTH_ENABLED, Get(user, 'user_info.twofa_enabled'))
    .set(eventProperties.REFERRAL_CODE, Get(user, 'ref_status.ref_code'))
    .set(eventProperties.NUMBER_OF_REFERRALS, Get(user, 'ref_status.num_referred'))
    .set(eventProperties.REFERRAL_LEVEL, Get(user, 'ref_status.rank'))
    .setOnce(eventProperties.SIGNED_UP_DATE, todayString)
    .set(eventProperties.PLATFORM, !isMobileDevice ? 'web desktop' : 'web mobile')
    .set(eventProperties.COLOR_THEME, currentTheme);

  vue.$amplitude.setUserId(user.account_id);
  vue.$amplitude.identify(identify);
};

export const unsetAmplitudeUserProps = () => {
  vue.$amplitude.setUserId(null);
  vue.$amplitude.clearUserProperties();
};
