<template>
  <div v-if="globalIsLoading">
    <SkeletonLoading height="44px" borderRadius="25px"/>
  </div>
  <div v-else class="font-bold">
    <div
      class="review-order"
      :class="{ open: showingReviewOrder }"
      data-testid="frac_pack_purchase"
    >
      <div>
        {{ $t('review_order') }}:
      </div>

      <div class="mt-n-2xl flex justify-between leading-md">
        <div class="font-medium text-n-md">{{ $t('wallet.balance') }}:</div>
        <div class="text-n-lg">{{ numberFormat(balance, 2, false, true) }}</div>
      </div>

      <div class="mt-n-xl mb-n-4xl flex justify-between leading-md">
        <div class="font-medium text-n-md">{{ name }}:</div>
        <div class="text-n-lg">{{ numberFormat(price, 2, false, true) }}</div>
      </div>
    </div>

    <ButtonV2
      @onClick="handleButtonAction"
      :label="showingReviewOrder ? $t('confirm_purchase') : $t('buy_now')"
      size="medium"
      testId="btn-buy"
      :inactive="isLoading || !hasSupply"
      :loading="isLoading"
      wide
    />
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import { mapGetters } from 'vuex';

export default {
  name: 'FracPackPurchase',
  components: {
    ButtonV2,
  },

  computed: {
    ...mapGetters('fracpack', [
      'fracpackTrackingData'
    ]),
  },

  methods: {
    handleButtonAction() {
      if (this.showingReviewOrder) {
        this.$emit('buyPack');
        this.$store.dispatch('events/track', {
          event: 'FRAC_PACK_CHECKOUT_SUBMITTED',
          variables: {
            ...this.fracpackTrackingData,
          },
        });
        return;
      }

      this.$emit('showReviewOrder');
      this.$store.dispatch('events/track', {
        event: 'FRAC_PACK_CHECKOUT_VIEWED',
        variables: {
          ...this.fracpackTrackingData,
        },
      });
    }
  },

  props: {
    balance: Number,
    name: String,
    price: Number,
    showingReviewOrder: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasSupply: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
  .review-order {
    @apply text-n-2xl leading-xl overflow-hidden;
    transition: all 500ms;
    max-height: 0;
  }

  .review-order.open {
    max-height: 200px;
  }
</style>
