<template>
  <div class="flex justify-center w-full">
    <div
      data-testid="bg-rewards-page-gradient"
      data-cy="bg-rewards-page-gradient"
      class="bg-rewards-page-gradient"
    />
    <div
      data-testid="bg-rewards-page"
      data-cy="bg-rewards-page"
      class="bg-rewards-page"
    />
  </div>
</template>

<script>
export default {
  name: 'RewardTopBackground',
};
</script>

<style scoped>
.bg-rewards-page {
  @apply absolute w-full;
  background: url('~@/assets/img/bg-rewards-page.png');
  mix-blend-mode: overlay;
  height: 198px;
}
.dark .bg-rewards-page {
  mix-blend-mode: soft-light;
}
.bg-rewards-page-gradient {
  @apply absolute w-full;
  background: linear-gradient(180.91deg, rgba(116, 219, 181, 0.68) 3.6%, rgba(116, 219, 181, 0.5) 27.06%, rgba(116, 219, 181, 0.25) 49.47%, rgba(116, 219, 181, 0) 81.78%);
  height: 200px;
}
</style>
