import Get from 'lodash.get';
import formatters from '@/mixins/formatters';
import CirclePaymentType from '@/enums/CirclePaymentType';
import { mountProfitLoss, sortMethodsByWeight } from './utils';

export default {
  getBalanceChart(state) {
    return Get(state, 'balanceChartValues', []);
  },
  getAllChartRanges(state) {
    return state.balanceChartRanges;
  },
  getWithdrawalId(state) {
    return state.lastWithdrawalId;
  },
  getChartRange(state) {
    return (value) => state.balanceChartRanges[value];
  },
  getBalance(_state, _getters, rootState) {
    const buyingPower = Number(Get(rootState.api, 'current_user.usd_balance.amount', 0));
    const costBasis = Number(Get(rootState.api, 'current_user.cost_basis', 0));
    const totalBalance = Number(Get(rootState.api, 'current_user.inventory_value_usd', 0));

    return {
      buyingPower,
      costBasis,
      totalBalance,
      holdings: totalBalance - buyingPower,
    };
  },
  getDepositMethods(state) {
    return sortMethodsByWeight(state.transferenceMethods.deposit);
  },
  getWithdrawalMethods(state) {
    return sortMethodsByWeight(state.transferenceMethods.withdraw);
  },
  getLimitByType(state) {
    return (type) => {
      const limitAsNumber = Get(state.transferenceMethods[type], 'limit', 0);
      return formatters.methods.numberFormat(limitAsNumber, 2, false, true);
    };
  },
  getNetCost(_state, getters) {
    return formatters.methods.numberFormat(getters.getBalance.costBasis, 2, false, true);
  },
  getBuyingPower(_state, getters) {
    return formatters.methods.numberFormat(getters.getBalance.buyingPower, 2, false, true);
  },
  getHoldings(_state, getters) {
    return formatters.methods.numberFormat(getters.getBalance.holdings, 2, false, true);
  },
  getActiveOrders(_state, _getters, rootState) {
    return Get(rootState.api, 'current_user.orders_active', []);
  },
  getProfitLoss24Hrs(state) {
    return {
      type: state.profitLoss.overall.type,
      value: formatters.methods.numberFormat(state.profitLoss.overall.value, 2, true, true),
      percentage: `${formatters.methods.numberFormat(state.profitLoss.overall.percentage, 2, true, false)}%`,
    };
  },
  getProfitLossOverall(_state, getters) {
    const balance = getters.getBalance;
    const result = mountProfitLoss(balance.totalBalance, balance.costBasis);

    return {
      changeType: result.type,
      changeValue: formatters.methods.numberFormat(result.value, 2, true, true),
      changePercentage: `${formatters.methods.numberFormat(result.percentage, 2, true, false)}%`,
    };
  },
  getDepositLimits(state) {
    return state.depositLimits;
  },
  getDepositLimitsByMethodType(_state, getters) {
    return (methodType) => {
      return getters.getDepositLimits.all.find(method => method.type === methodType);
    };
  },
  getPaymentMinimumAmount(_state, getters) {
    return (transactionType, methodType) => {
      if (transactionType === 'deposit') {
        return parseFloat(getters.getDepositLimitsByMethodType(methodType).total.min);
      }

      // withdraw min not available from backend yet, thus, hardcoded
      if (transactionType === 'withdraw') {
        switch (methodType) {
          case CirclePaymentType.ACH: return 2;
          case CirclePaymentType.WIRE: return 15;
          default: return 0;
        }
      }
    };
  },
  getPaymentFee(_state, getters) {
    return (transactionType, methodType) => {
      return parseFloat(getters.getDepositLimitsByMethodType(methodType)[`${transactionType}_fee`]);
    };
  },

  getTransactionsActiveOrders(state) {
    return state.transactions.active_orders;
  },

  getTransactionsHistory(state) {
    return state.transactions.all;
  },

  getSingleTransaction(state) {
    return state.transactions.single;
  },

  getChainSpecifications: (state) => ({ isDeposit }) => {
    return state[isDeposit ? 'circle_available_deposit_chains' : 'circle_available_withdrawal_chains'];
  },

  getChainNames: (_state, getters) => ({ isDeposit }) => {
    return getters.getChainSpecifications({ isDeposit }).map(chainSpecification => chainSpecification.name);
  },

  getSelectedChainSpecification: (state, getters) => ({ isDeposit }) => {
    const selectedChain = state[isDeposit ? 'selected_deposit_chain' : 'selected_withdraw_chain'];
    return getters.getChainSpecifications({ isDeposit }).find(chainSpecification => chainSpecification.chain === selectedChain);
  },

  getSelectedChainName: (_state, getters) => ({ isDeposit }) => {
    return Get(getters.getSelectedChainSpecification({ isDeposit }), 'name', null);
  },

  getInventoryChange(state) {
    return state.inventoryChange;
  },
};
