<template>
  <div class="flex justify-center w-full">
    <div class="flex-container w-full mt-s48 z-10">
      <img v-if="currentTheme === 'light'" data-testid="gem-icon" class="gem-icon" src="@/assets/icons/gem-light.png" />
      <img v-else data-testid="gem-icon" class="gem-icon" src="@/assets/icons/gem-dark.png" />

      <div v-if="isComponentLoading" class="gem-description w-full">
        <SkeletonLoading
          :height="!isTinyMobileDevice ? '100px' : '120px'"
        />
      </div>
      <div v-else class="relative">
        <div class="flex-container" :class="{ 'opacity-0': !neverClaimedBefore }">
          <h1 class="headline-small mt-s12 self-start start-sm:self-center">
            {{ $t('rewards.enable_gems') }}
          </h1>
          <p class="gem-description mt-s8 body-text-large mb-s12">
            {{ $t('rewards.complete_your_trading') }}
          </p>
        </div>
        <div class="flex-container w-full absolute top-0" :class="{ 'opacity-0': neverClaimedBefore }">
          <h1 class="gem-balance">
            {{ balance }}
          </h1>
          <p class="gem-description mt-s8 body-text-x-medium">
            {{ $t('rewards.gems_balance') }}
          </p>
          <div data-testid="subheadline" class="subheadline-medium mt-s16">
            {{ subheadline }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'RewardHeader',

  data() {
    return {
      timeNowInterval: null,
      timeNow: new Date().getTime(),
    };
  },

  mounted() {
    this.timeNowInterval = setInterval(() => {
      this.timeNow = new Date().getTime();
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.timeNowInterval);
  },

  props: {
    balance: {
      type: Number,
      required: true,
    },
    canClaimDayNumber: {
      type: Number,
      required: true,
    },
    isComponentLoading: {
      type: Boolean,
      default: false,
    },
    neverClaimedBefore: {
      type: Boolean,
      required: true,
    },
    nextUpcomingDayNumber: {
      type: Number,
      required: true,
    },
    nextClaimStart: {
      type: Number,
      required: true,
    },
    nextClaimEnd: {
      type: Number,
      required: true,
    },
  },

  computed: {
    currentTime(){
      return DateTime.fromMillis(this.timeNow || 0);
    },

    dateReadable(){
      return (DateTimeA, DateTimeB) => {
        if (DateTimeA.ts === 0) {
          return '';
        }

        const duration = DateTimeA.diff(DateTimeB, ['days', 'hours', 'minutes', 'seconds']);

        if (duration.toFormat('ss') < 0) {
          return '00:00:00';
        }

        if (duration.days < 1) {
          return duration.toFormat('hh:mm:ss');
        }

        if (duration.days === 1) {
          return this.$t('drops.a_day');
        }

        return DateTimeA.toRelative({base: DateTimeB, locale: 'en-US'}).replace(/in/g, '');
      };
    },

    nextClaimEndTime() {
      return this.dateReadable(DateTime.fromMillis(this.nextClaimEnd), this.currentTime);
    },

    nextClaimStartTime() {
      return this.dateReadable(DateTime.fromMillis(this.nextClaimStart), this.currentTime);
    },

    subheadline() {
      return this.canClaimDayNumber > 0 ?
        `${this.$t('rewards.day_ends_in', {
          dayNumber: this.canClaimDayNumber,
          time: this.nextClaimEndTime,
        })}` :
        `${this.$t('rewards.come_back_in', {
          dayNumber: this.nextUpcomingDayNumber,
          time: this.nextClaimStartTime,
        })}`;
    }
  },
  watch: {
    nextClaimEndTime() {
      if (this.canClaimDayNumber > 0 && this.nextClaimEndTime === '00:00:00') {
        setTimeout(() => {
          this.$emit('onRefreshRewardsState');
        }, 2000);
      }
    },

    nextClaimStartTime() {
      if (this.canClaimDayNumber <= 0 && this.nextClaimStartTime === '00:00:00') {
        setTimeout(() => {
          this.$emit('onRefreshRewardsState');
        }, 2000);
      }
    }
  }
};
</script>

<style scoped>
.flex-container {
  @apply flex flex-col justify-center items-center;
}
.gem-icon {
  @apply mb-s24;
  height: 156px;
  width: 129px;
}
.gem-balance {
  font-size: 48px;
  line-height: 36px;
  font-weight: 700;
}
.gem-description {
  max-width: 396px;
}
</style>
