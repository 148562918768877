<template>
  <div class="order-confirmed">
    <div class="flex justify-between items-center font-bold leading-2xl">
      <div :class="titleClasses" role="title">{{ $t(title) }}</div>
      <CheckCircle class="h-s40 w-s40" />
    </div>

    <div class="mt-n-4xl">
      <div
        v-for="(n, index) in orderConfirmed.length"
        v-bind:key="index"
        class="order-confirmed-content"
      >
        <div class="order-confirmed-label">{{ $t(orderConfirmed[index].label) }}:</div>
        <div class="order-confirmed-value">{{ formattedValue(index) }}</div>
      </div>
    </div>

    <ButtonV2
      @onClick="$emit('onDone')"
      testId="btn-continue"
      class="mt-s24"
      :label="$t(doneLabel || 'done')"
      size="medium"
      version="secondary"
      wide
    />

    <ButtonV2
      @onClick="$emit('onContinue')"
      testId="btn-continue"
      class="mt-s12"
      :label="$t(continueLabel || 'balance_short.view_orders')"
      size="medium"
      wide
    />
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import CheckCircle from '@/assets/icons/check_circle.svg';

export default {
  name: 'OrderConfirmed',

  components: {
    ButtonV2,
    CheckCircle,
  },

  methods: {
    formattedValue(index) {
      switch (this.orderConfirmed[index].type) {
        case 'currency':
          return this.numberFormat(this.orderConfirmed[index].value, 2, false, true);
        case 'fraction':
          return `~${this.numberFormat(this.$big(this.orderConfirmed[index].value).times(100))}%`;
        default:
          return this.orderConfirmed[index].value;
      }
    },
  },

  props: {
    continueLabel: String,
    doneLabel: String,
    orderConfirmed: Array,
    smallTitle: Boolean,
    title: String,
  },

  computed: {
    titleClasses() {
      return {
        'order-confirmed-title': !this.smallTitle,
        'order-confirmed-title--small': this.smallTitle,
      };
    },
  },

  mounted() {
    this.$emit('onViewed');
  },
};
</script>

<style scoped>
.order-confirmed {
  @apply flex flex-col w-full;
}

.order-confirmed-content {
  @apply flex justify-between items-center leading-md mb-n-2xl;
}

.order-confirmed-content:last-of-type {
  @apply mb-n-0;
}

.order-confirmed-label {
  @apply text-n-lg font-medium;
}

.order-confirmed-title {
  @apply text-n-4xl;
}

.order-confirmed-title--small {
  @apply text-n-3xl;
}

.order-confirmed-value {
  @apply text-n-2xl font-bold;
}
</style>
