import FracpackState from '@/enums/FracpackState';
import PackState from '@/enums/PackState';
import { mountAssetList, mountPackList } from '@/modules/asset/utils';

export default {
  getCurrentPair(state){
    return state.currentPair;
  },
  getTrendingAssets(state){
    return mountAssetList(state.PAIRS_TRENDING);
  },
  getNewArrivalsAssets(state){
    return mountAssetList(state.PAIRS_NEW_ARRIVALS);
  },
  getCardladderDiscountedAssets(state){
    return mountAssetList(state.PAIRS_CARDLADDER_DISCOUNTED);
  },
  getUserWatchList(state){
    return mountAssetList(state.watchlist);
  },
  getUserAssets(state) {
    return mountAssetList(state.assets);
  },
  getUserPacks(state) {
    return mountPackList(state.packs.filter(( pack ) => pack.state !== PackState.ROLLED));
  },
  getUserFracPacks(state) {
    return mountPackList(state.fracpacks.filter(( fracpack ) => fracpack.state !== FracpackState.FINALIZED));
  },
};
