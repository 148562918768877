
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RewardsState"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RewardsState"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"daily_claims"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"days"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"state"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"amount"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"next_claim_start"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"next_claim_end"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"in_streak"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"balance"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"never_claimed_before"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":192}};
    doc.loc.source = {"body":"fragment RewardsState on RewardsState {\n  daily_claims {\n    days {\n      state\n      amount\n    }\n    next_claim_start\n    next_claim_end\n    in_streak\n  }\n  balance\n  never_claimed_before\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
