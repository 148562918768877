<template>
  <div :class="`avatar-container ${large ? 'large-container' : ''}`">
    <IconAvatarWhite v-if="currentTheme === 'dark'" :class="`avatar-image ${large ? 'large-image' : ''}`"/>
    <IconAvatarBlack v-else :class="`avatar-image ${large ? 'large-image' : ''}`"/>
  </div>
</template>

<script>
import IconAvatarWhite from '@/assets/icons/avatar_white.svg';
import IconAvatarBlack from '@/assets/icons/avatar_black.svg';

export default {
  name: 'Avatar',
  components: {
    IconAvatarWhite,
    IconAvatarBlack,
  },
  props: {
    large: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style scoped>
  .avatar-container {
    @apply rounded-full;
  }
  .avatar-image {
    @apply rounded-full object-contain;
    width: 36px;
    height: 36px;
  }
  .large-container {
    padding: 4px;
  }
  .large-image {
    width: 62px;
    height: 62px;
  }
</style>
