<template>
  <section class="pack-video">
    <video
      name="pack"
      ref="video"
      :autoplay="false"
      :controls="false"
      :disablePictureInPicture="true"
      :height="height"
      :width="width"
      @onTimeUpdate="playVideo"
    >
      <source :src="src" type="video/mp4" />
    </video>
  </section>
</template>

<script>

export default {
  name: 'PackVideo',

  props: {
    src: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '375px',
    },
    height: {
      type: String,
      default: '666px',
    },
  },

  methods: {
    loadVideo() {
      this.$refs.video.load();
      this.addListeners();
      this.playVideo();
    },

    playVideo() {
      this.$refs.video.play();
    },
    
    addListeners() {
      this.$refs.video.addEventListener('ended', this.emitVideoEnd);
    },

    removeListeners() {
      this.$refs.video.removeEventListener('ended', this.emitVideoEnd);
    },

    emitVideoEnd() {
      this.$emit('onVideoEnd');
    },
  },

  
  mounted() {
    this.loadVideo();
  },

  beforeDestroy() {
    this.removeListeners();
  },
};
</script>
