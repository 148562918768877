<template>
  <div @click="$emit('onClick')" :class="classes">
    <font-awesome-icon role="icon" :icon="[iconStyle, icon]"/>
  </div>
</template>

<script>
export const AVAILABLE_VARIATIONS = ['outlined', 'plain'];
export const AVAILABLE_STYLES = ['far', 'fas', 'fab'];

export default {
  name: 'IconButton',
  props: {
    color: {
      type: String,
      default: 'text-text-active-2',
    },
    icon: {
      type: String,
      required: true,
    },
    iconStyle: {
      type: String,
      default: 'fas',
      validator: (value) => AVAILABLE_STYLES.includes(value)
    },
    variation: {
      type: String,
      default: 'outlined',
      validator: (value) => AVAILABLE_VARIATIONS.includes(value)
    },
  },
  computed: {
    classes(){
      return {
        'flex justify-center items-center': true,
        'icon-button--outlined hover:opacity-80': this.variation === 'outlined',
        'icon-button--plain': this.variation === 'plain',
        [this.color]: true,
      };
    }
  }
};
</script>

<style scoped>
  .icon-button--outlined {
    @apply
      bg-background-icon
      border
      border-text-active-2
      rounded-full
      flex-shrink-0
      flex
      items-center
      justify-center
      cursor-pointer;
    width: 32px;
    height: 32px;
  }

  .icon-button--plain {
    @apply cursor-pointer;
  }
</style>
