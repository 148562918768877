import {
  SET_PAIRS,
  SET_CURRENT_PAIR,
  SET_USER_ASSETS,
  SET_USER_PACKS,
  SET_USER_FRACPACKS,
  SET_USER_WATCHLIST
} from './constants';

import Get from 'lodash.get';

export default {
  [ SET_PAIRS ](state, payload){
    state[payload.type] = payload.data;
  },
  [ SET_CURRENT_PAIR ](state, payload){
    state.currentPair = payload;
  },
  [ SET_USER_ASSETS ](state, payload){
    state.assets = payload.map(item => {
      const isDrop = !Get(item, 'base.pair', false) && Get(item, 'base.drops', []).length > 0;

      return {
        ...item,
        type: isDrop ? 'HoldingDrop' : 'HoldingAsset',
      };
    });
  },
  [ SET_USER_PACKS ](state, payload){
    state.packs = payload.map(item => ({ ...item, type: 'HoldingPack', variation: 'pack' }));
  },
  [ SET_USER_FRACPACKS ](state, payload){
    state.fracpacks = payload.map(item => ({ ...item, type: 'HoldingPack', variation: 'fracpack' }));
  },
  [ SET_USER_WATCHLIST ](state, payload){
    state.watchlist = payload.flatMap(item => item.assets).map(item => ({ ...item, type: 'HoldingAsset' }));
  }
};
