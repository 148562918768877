<template>
  <div ref="chart" class="w-full" />
</template>

<script>
import { mapState } from 'vuex';
import {createChart} from 'lightweight-charts';

export default {
  name: 'Chart',
  props: {
    chartdata: Array,
    chartSize: Number,
    chartOptions: Object,
    chartTimeLabel: {
      type: String,
      default: 'time',
    },
    chartValueLabel: {
      type: String,
      default: 'value',
    },
    excludeLeftZeros: Boolean,
    showPrice: Boolean,
    showTime: Boolean,
  },

  data() {
    const sortedChart = this.sortArrayOnProperty({array: this.chartdata, property: this.chartTimeLabel});

    if (this.excludeLeftZeros) {
      const firstValue = sortedChart.findIndex(data => data[this.chartValueLabel] !== 0);

      if (firstValue !== -1) {
        const chartValues = sortedChart.slice(firstValue, sortedChart.length);
        const sorttedValueChart = this.sortArrayOnProperty({array: chartValues, property: this.chartValueLabel});

        return {
          minValue: sorttedValueChart[0][this.chartValueLabel],
          maxValue: sorttedValueChart[sorttedValueChart.length-1][this.chartValueLabel],
          chartValues: [...chartValues],
        };
      }
    }

    const valueChart = this.sortArrayOnProperty({array: this.chartdata, property: this.chartValueLabel});

    return {
      minValue: valueChart[0][this.chartValueLabel],
      maxValue: valueChart[valueChart.length-1][this.chartValueLabel],
      chartValues: [...sortedChart],
    };
  },

  computed: {
    ...mapState('ui', ['currentTheme']),
    chartColor() {
      const lastValue = this.$big(this.chartValues[this.chartValues.length-1][this.chartValueLabel]).round(2);
      const firstValue = this.$big(this.chartValues[0][this.chartValueLabel]).round(2);
      const dibbs_green = this.currentTheme === 'light' ? '#218665' : '#42DDB3';
      return lastValue.gte(firstValue) ? dibbs_green : '#CF3E5A';
    },
  },

  methods: {
    autoscaleInfoProvider() {
      let min = this.$big(this.minValue).round(2);
      let max = this.$big(this.maxValue).round(2);

      if (min.eq(max)) {
        min = min.minus(10);
        max = max.plus(10);
      }

      return {
        priceRange: {
          minValue: min,
          maxValue: max,
        },
        margins: {
          above: 10,
          below: 10,
        },
      };
    },
  },

  watch: {
    chartdata() {
      const sortedChart = this.sortArrayOnProperty({array: this.chartdata, property: this.chartTimeLabel});

      if (this.excludeLeftZeros) {
      const firstValue = sortedChart.findIndex(data => data[this.chartValueLabel] !== 0);

        if (firstValue !== -1) {
          const chartValues = sortedChart.slice(firstValue, sortedChart.length);
          const sorttedValueChart = this.sortArrayOnProperty({array: chartValues, property: this.chartValueLabel});
          this.minValue = sorttedValueChart[0][this.chartValueLabel];
          this.maxValue = sorttedValueChart[sorttedValueChart.length-1][this.chartValueLabel];
          this.chartValues = [...chartValues];
          return;
        }
      }
      const valueChart = this.sortArrayOnProperty({array: this.chartdata, property: this.chartValueLabel});
      
      this.minValue = valueChart[0][this.chartValueLabel];
      this.maxValue = valueChart[valueChart.length-1][this.chartValueLabel];
      this.chartValues = [...sortedChart];
    },
    chartValues() {
      this.chart.removeSeries(this.lineSeries);
      this.lineSeries = this.chart.addLineSeries({
        color: this.chartColor,
        crosshairMarkerVisible: this.showTime,
        priceLineVisible: this.showTime,
        autoscaleInfoProvider: this.autoscaleInfoProvider,
      });

      const chartData = this.chartValues.map(data => ({
        time: parseInt(data[this.chartTimeLabel]),
        value: this.$big(data[this.chartValueLabel]).round(2),
      }));

      this.lineSeries.setData(chartData);

      this.chart.timeScale().fitContent();
    },
    chartSize() {
      this.chart.resize(this.chartSize, this.$refs.chart.offsetHeight);
      this.chart.timeScale().fitContent();
    },
    windowWidth() {
      this.chart.resize(this.chartSize || this.$refs.chart.offsetWidth, this.$refs.chart.offsetHeight);

      this.chart.timeScale().fitContent();
    },
    currentTheme() {
      this.chartValues = [...this.chartValues];
    },
  },
  mounted() {
    const options = {
      width: this.$refs.chart.offsetWidth,
      height: this.$refs.chart.offsetHeight,
      leftPriceScale: {
        visible: false,
      },
      rightPriceScale: {
        borderVisible: false,
        visible: this.showPrice,
        timeVisible: this.showPrice,
      },
      handleScroll : false,
      handleScale : false,
      layout: {
        backgroundColor: 'transparent',
        textColor: 'transparent',
      },
      timeScale: {
        borderVisible: false,
        visible: this.showTime,
        timeVisible: this.showTime,
      },
      localization: {
        timeFormatter: function (businessDayOrTimestamp) {
          const hoveredData = this.chartValues.find(data => Number(data.time) === Number(businessDayOrTimestamp));
          return `${this.$moment(businessDayOrTimestamp).format('LL - LT')} - ${this.numberFormat(hoveredData.close, 2, false, true)}`;
        }.bind(this),
      },
      grid: {
        horzLines: false,
        vertLines: false
      },
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false
        },
        vertLine: {
          visible: this.showTime,
          labelVisible: this.showTime,
        }
      },
      ...this.chartOptions,
    };

    this.chart = createChart(this.$refs.chart, {
      ...options,
    });

    this.chart.resize(this.$refs.chart.offsetWidth, this.$refs.chart.offsetHeight);

    this.lineSeries = this.chart.addLineSeries({
      color: this.chartColor,
      crosshairMarkerVisible: this.showTime,
      priceLineVisible: this.showTime,
      autoscaleInfoProvider: this.autoscaleInfoProvider,
    });

    const chartData = this.chartValues.map(data => ({
      time: parseInt(data[this.chartTimeLabel]),
      value: this.$big(data[this.chartValueLabel]).round(2),
    }));
    this.lineSeries.setData(chartData);

    this.chart.timeScale().fitContent();

    this.chart.subscribeCrosshairMove((param) => {
      if (param === undefined || param.time === undefined) {
        return this.$emit('chartSelectedValue', null);
      }

      const hoveredData = this.chartValues.find(data => data.time == param.time);

      if (hoveredData) {
        return this.$emit('chartSelectedValue', hoveredData[this.chartValueLabel]);
      }
    });

    this.chart.subscribeClick((param) => {
      if (param === undefined || param.time === undefined) {
        return this.$emit('chartSelectedValue', null);
      }

			const clickedData = this.chartValues.find(data => data.time == param.time);

      if (clickedData) {
        return this.$emit('chartSelectedValue', clickedData[this.chartValueLabel]);
      }
		});
  }
};
</script>
