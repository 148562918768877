
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Order"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Order"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"maker_order_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"amount_executed"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"amount_remaining"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"average_price"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"received"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fee"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":115}};
    doc.loc.source = {"body":"fragment Order on Order {\n  maker_order_id\n  amount_executed\n  amount_remaining\n  average_price\n  received\n  fee\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
