import Big from 'big.js';
import Get from 'lodash.get';
import i18n from '@/translations/i18n';
import isEmpty from 'lodash.isempty';

import FracpackState from '@/enums/FracpackState';

import FracPackRoll from '@/graphql/mutations/FracPackRoll.gql';
import FracPackDecide from '@/graphql/mutations/FracPackDecide.gql';

Big.RM = 0;

const RESET_FRACPACK = 'RESET_FRACPACK';
const SET_CARDS_INSIDE = 'SET_CARDS_INSIDE';
const SET_CURRENT_CARD_INDEX = 'SET_CURRENT_CARD_INDEX';
const SET_CURRENT_FRACPACK = 'SET_CURRENT_FRACPACK';
const SET_FRACPACK_DECISION = 'SET_FRACPACK_DECISION';
const SET_LOADING = 'SET_LOADING';

export const DEFAULT_STATE = {
  isLoading: false,
  currentFracpack: {
    id: null,
    cards_inside: [],
  },
  currentCardIndex: 0,
  fracpackDecision: [],
};

export default {
  namespaced: true,
  
  state: {
    ...DEFAULT_STATE
  },

  actions: {
    beforeDecide({ commit, dispatch }, { fracpackDecision }) {
      // set store data
      commit(SET_FRACPACK_DECISION, fracpackDecision);
      
      // Set prompt message
      const count = {
        sell: fracpackDecision.filter(item => item.sell === true).length,
        keep: fracpackDecision.filter(item => item.sell === false).length,
        total: fracpackDecision.length
      };
      let message;
      if (count.sell === count.total) {
        message = i18n.t('frac_pack.confirm_sell_all');
      } else if (count.keep === count.total) {
        message = i18n.t('frac_pack.confirm_keep_all');
      } else {
        message = i18n.t('frac_pack.confirm_sell_keep', count);
      }
      
      // Show prompt and set event emmiter
      commit('ui/showPrompt', {
        actions: {
          submit: () => {
            dispatch('fracpackDecide');
          },
        },
        title: i18n.t('frac_pack.confirm_checkout'),
        message,
      }, {root: true});
    },

    async fracpackDecide({ commit, dispatch, state }) {
      try {
        await dispatch('api/apolloClient', {
          type: 'mutate',
          options: {
            mutation: FracPackDecide,
            variables: {
              fracpack_id: state.currentFracpack.id,
              decisions: state.fracpackDecision
            }
          },
        }, { root: true });

        await dispatch('asset/updateFracPackState', {
          fracpackId: state.currentFracpack.id,
          fracpackState: FracpackState.FINALIZED,
        }, { root: true });
      } catch (err) {
        await dispatch('ui/showError', err, { root: true });
      } finally {
        commit('ui/hidePrompt', null, { root: true } );
        commit('ui/hideModal', null, { root: true } );
        await dispatch('resetFracpack');
      }
    },

    resetFracpack({ commit }) {
      commit(RESET_FRACPACK);
    },

    async rollFracpack({ dispatch, state }){
      await dispatch('setLoading', true);
      try {
        const response = await dispatch('api/apolloClient', {
          type: 'mutate',
          options: {
            mutation: FracPackRoll,
            variables: {
              fracpack_id: state.currentFracpack.id,
            },
          },
        }, { root: true });

        const cards_inside = Get(response.data, 'fracpack_roll_fracpack.cards_inside', []);

        await dispatch('setCardsInside', cards_inside);

        await dispatch('asset/updateFracPackState', {
          fracpackId: state.currentFracpack.id,
          fracpackState: FracpackState.OPENED,
        }, { root: true });
      } finally {
        await dispatch('setLoading', false);
      }
    },

    setCardsInside({ commit }, payload) {
      commit(SET_CARDS_INSIDE, payload);
    },

    setCurrentFracpack({ commit }, payload) {
      commit(SET_CURRENT_FRACPACK, payload);
      commit(SET_CURRENT_CARD_INDEX, 0);
    },

    showCardsModal({ state, commit }) {
      const cardsInside = Get(state, 'currentFracpack.cards_inside', []);

      if (cardsInside.length) {
        const card = cardsInside[state.currentCardIndex];
        commit('ui/showModal', {
          currentModal: 'OpenPackModal',
          currentModalProps: {
            imgFront: card.asset.img_front,
            name: card.asset.name,
            price: card.asset_price,
            marketCap: card.asset.price_usd,
            isLastCard: state.currentCardIndex === cardsInside.length - 1,
            isFirstCard: state.currentCardIndex === 0,
            noPadding: true,
            isClosable: false,
          }
        }, {root: true});
      }
    },

    setLoading({ commit }, payload) {      
      commit(SET_LOADING, Boolean(payload));
    },

    showFracpackReview({ state, commit }) {
      commit('ui/showModal', {
        currentModal: 'ReviewFracpackModal',
        currentModalProps: {
          cards: state.currentFracpack.cards_inside,
          noPadding: true,
          isClosable: false,
        }
      }, {root: true});
    },

    showNextCard({ state, dispatch, commit }) {
      if(state.currentCardIndex < state.currentFracpack.cards_inside.length - 1) {
        commit(SET_CURRENT_CARD_INDEX, state.currentCardIndex + 1);
        dispatch('showCardsModal');
      } 
    },

    showPreviousCard({state, dispatch, commit}) {
      if(state.currentCardIndex > 0 ) {
        commit(SET_CURRENT_CARD_INDEX, state.currentCardIndex - 1);
        dispatch('showCardsModal');
      } 
    },
  },

  getters: {
    currentFracpackDefinition(state) {
      return Get(state, 'currentFracpack.definition', {});
    },

    fracpackAssetTrackingData(state, getters) {
      const currentCard = state.currentFracpack.cards_inside[state.currentCardIndex];

      const asset = Get(currentCard, 'asset', {});
      const associatedPlayers = Get(asset, 'associated_players[0]', {});

      return {
        id: asset.id,
        name: asset.name,
        ticker: asset.ticker,
        price: new Big(asset.price_usd).toFixed(2),
        sale_price: new Big(currentCard.asset_price).toFixed(2),
        player_id: associatedPlayers.id,
        player_id_hr: associatedPlayers.id_hr,
        frac_pack_id: getters.currentFracpackDefinition.id,
      };
    },

    fracpackTrackingData(state, getters) {
      const fracpackDefinition = getters.currentFracpackDefinition;

      if (isEmpty(fracpackDefinition)) {
        return {};
      }

      return {
        id: fracpackDefinition.id,
        id_hr: fracpackDefinition.id_hr,
        price: new Big(fracpackDefinition.price).toFixed(2),
        base_supply: fracpackDefinition.initial_supply,
        winnable_assets: fracpackDefinition.possible_assets.length,
        total_assets: fracpackDefinition.cards_per_pack,
      };
    },
  },

  mutations: {
    [ RESET_FRACPACK ] (state) {
      state.isLoading = DEFAULT_STATE.isLoading;
      state.currentFracpack = DEFAULT_STATE.currentFracpack;
      state.currentCardIndex = DEFAULT_STATE.currentCardIndex;
      state.fracpackDecision = DEFAULT_STATE.fracpackDecision;
    },
    [ SET_CARDS_INSIDE ] (state, payload) {
      state.currentFracpack.cards_inside = payload;
    },
    [ SET_CURRENT_CARD_INDEX ] (state, payload) {
      state.currentCardIndex = payload;
    },
    [ SET_CURRENT_FRACPACK ] (state, payload) {
      state.currentFracpack = payload;
    },
    [ SET_FRACPACK_DECISION ] (state, payload) {
      state.fracpackDecision = payload;
    },
    [ SET_LOADING ] (state, payload) {
      state.isLoading = !!payload;
    },
  },
};
