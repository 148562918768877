<template>
  <Collapse :customClasses="collapseClasses">
    <div
      class="text-left headline-small"
      slot="title"
    >
      {{ $t('drops.what_are_drops') }}
    </div>

    <div class="body-text-x-space">
      <p>{{ $t('drops.specific_drop_a_drop_is') }}</p>
      <br />
      <p>{{ $t('drops.once_the_initial') }}</p>
    </div>
  </Collapse>
</template>

<script>
import Collapse from '@/components/misc/Collapse/Collapse';

export default {
  name: 'DropsWhatAre',
  components: {
    Collapse,
  },
  computed: {
    collapseClasses() {
      return {
        button: 'border-b pb-s20',
      };
    },
  },
};
</script>
