<template>
  <section class="asset-display">
    <!-- Background -->
    <SkeletonLoading
      v-if="isLoading"
      width="578px"
      height="606px"
      borderRadius="16px"
    />

    <!-- Asset -->
    <div v-else class="asset-display__asset">
      <div class="flex start-lg:hidden">
        <div class="icon-container-left">
          <IconButton
            data-cy="back-icon"
            data-testid="back-icon"
            color="text-text-body"
            icon="chevron-left"
            variation="plain"
            @onClick="$emit('onGoBack')"
          />
        </div>

        <div class="icon-container" :class="{ 'mr-s48': showFavoritesButton }">
          <Share
            :shareMessage="$tc('share.check_out', { name: player })"
            color="text-text-body"
            @amplitudeEvent="$emit('onShareEvent')"
          />
        </div>

        <div v-if="showFavoritesButton" class="icon-container">
          <IconButton
            :iconStyle="isOnFavoritesList ? 'fas' : 'far'"
            data-cy="favorite-icon"
            data-testid="favorite-icon"
            color="text-text-body"
            icon="star"
            variation="plain"
            @onClick="$emit('onToggleFavorite')"
          />
        </div>
      </div>

        <SquareImage
          v-bind="assetImgProps"
          data-testid="asset-display-asset"
          data-cy="asset-display-asset"
          @click.native="toggleImageBrowser"
        />
    </div>

    <div
      v-if="isMediumMobileDevice"
      class="asset-display__shadow"
    />

    <ImageBrowser
      v-if="showImageBrowser && imageList.length"
      :image-list="imageList"
      @close="toggleImageBrowser"
    />
  </section>
</template>

<script>
import {
  IconButton,
  ImageBrowser,
  SquareImage,
  Share,
} from '@/components/misc';

export default {
  name: 'AssetDisplay',

  components: {
    ImageBrowser,
    IconButton,
    SquareImage,
    Share,
  },

  props: {
    alt: String,

    imgBack: String,

    imgBg: String,

    imgFront: String,

    isLoading: Boolean,

    isOnFavoritesList: Boolean,

    player: String,

    serial: String,

    individualAssets: {
      type: Array,
      default: () => [],
    },

    showFavoritesButton: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showImageBrowser: false,
    };
  },

  computed: {
    imageList() {
      const imageList = [];
      
      this.individualAssets.forEach(asset => {
        imageList.push(asset.img_front);
        imageList.push(asset.img_back);
      });

      return imageList;
    },

    assetImgProps() {
      const assetType = this.isMediumMobileDevice
        ? 'asset_detail_mobile'
        : 'asset_detail';

      return {
        class: {
          'cursor-pointer': this.imageList.length, 
          hide: this.isLoading
        },
        image: this.imgFront,
        imageResizeProps: {
          alt: `Asset Display ${this.player}`,
          assetType,
        }
      };
    }
  },

  methods: {
    toggleImageBrowser() {
      this.showImageBrowser = !this.showImageBrowser;
    }
  }
};
</script>

<style scoped>
.asset-display {
  @apply relative rounded-16 border border-border overflow-hidden;
  width: 100%;
}

.is-screen-md-down .asset-display {
  @apply rounded-none;
}

.asset-display__shadow {
  @apply w-full absolute bottom-0 left-0;
  width: 100%;
  height: 64px;
  z-index: 22;
  pointer-events: none;
  background:
    linear-gradient(180deg, rgba(249, 249, 249, 0) 1%, #F9F9F9 99%),
    linear-gradient(180deg, rgba(249, 249, 249, 0) 1%, #F9F9F9 99%);
}

.dark .asset-display__shadow {
  background:
    linear-gradient(180deg, rgba(0, 0, 0, 0) 1%, #000000 99%),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 1%, #000000 99%);
}

.asset-display__asset {
  @apply flex justify-center items-center w-full h-full relative;
  z-index: 21;
}

.icon-container {
  @apply
    absolute
    flex
    items-center
    justify-center
    rounded-full
    cursor-pointer
    fill-current
    bg-background-primary
    w-s32
    h-s32
    opacity-80;
  transition: all 200ms;
  top: 20px;
  right: 20px;
  z-index: calc(theme('zIndex.component') + 1);
}

.icon-container-left {
  @apply
    absolute
    z-10
    flex
    items-center
    justify-center
    rounded-full
    cursor-pointer
    fill-current
    bg-background-primary
    w-s32
    h-s32
    opacity-80;
  transition: all 200ms;
  top: 20px;
  left: 20px;
}

.icon-container:hover {
  @apply opacity-60;
  transition: all 200ms;
}

@screen md {
  .asset-display {
    @apply rounded-none border-none;
  }
}
</style>
