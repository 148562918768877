import Get from 'lodash.get';

export default function sortMethodsByWeight(state) {
  const weightByMethodType = {
    ach: 0,
    card: 1,
    wire: 2,
    blockchain: 3,
  };

  return Get(state, 'methods', []).map(item => {
    return {
      ...item,
      weight: weightByMethodType[item.type]
    };
  }).sort((a,b) => {
    if (a.weight < b.weight) {
     return -1; 
    }

    if (a.weight > b.weight) {
     return 1; 
    }
    
    return 0; 
  });
}
