<template>
  <div 
    class="rewards-navbar-layout" 
    @click="$emit('onRewardClick')" 
    :class="hasReward" 
    data-testid="gem-icon-container"
  >
    <img :src="currentIcon" alt="gem-icon" width="62px" height="62px" :role="`gem-icon-${currentState}`" />
  </div>
</template>

<script>
export default {
  name: 'RewardNavbarLayout',
  
  props: {
    hasReward: {
      type: Boolean,
      required: true,
    },
  },
  
  data() {
    return {
      icons: {
        dark: {
          active: 'gem-dark-active',
          inactive: 'gem-dark-inactive',
        },
        light: {
          active: 'gem-light-active',
          inactive: 'gem-light-inactive',
        },
      },
    };
  },
  
  computed: {
    currentIcon() {
      const format = '.png';
      const theme = this.currentTheme || 'light';
      const file = `${this.icons[theme][this.currentState]}${format}`;
      return require(`@/assets/img/${file}`);
    },

    currentState() {
      return this.hasReward ? 'active' : 'inactive';
    },
  },
};
</script>

<style scoped>
.rewards-navbar-layout {
  @apply inline-block relative cursor-pointer;
}
</style>
