<template>
  <SkeletonLoading 
    v-if="isLoading"
    height="117px"
  />
  <div v-else>
    <div class="flex justify-between border-b border-border pb-s20 mb-s20">
      <h3 class="headline-small">
        {{ $t('trade.vaulted_ids') }}
      </h3>
      <div
        v-if="isUserAuthenticated"
        class="lg:hidden block"
      >
        <ButtonV2 
          @onClick="openSellByDibbs"
          :label="$tc('stats_card.sell_card_button')"
          size="small"
          testId="btn-sell-card"
          version="secondary"
        />
      </div>
    </div>

    <div class="flex flex-col" role="serials">
      <div class="text-text-inactive body-text-x-space">
        {{ content.title }}
      </div>
      <div class="body-text-x-space">
        {{ content.body }}
      </div>
    </div>

    <div
      v-if="isUserAuthenticated"
      class="lg:block hidden mt-s20"
    >
      <ButtonV2 
        @onClick="openSellByDibbs"
        :label="$tc('stats_card.sell_card_button')"
        size="small"
        testId="btn-sell-card-mobile"
        version="secondary"
        wide
      />
    </div>

  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';

export default {
  name: 'TradeSerialNumbers',
  components: {
    ButtonV2,
  },
  props: {
    address: Object,
    cardName: String,
    email: String,
    marketCap: Number,
    supply: String,
    userName: String,
    individualAssets: Array,
    isUserAuthenticated: Boolean,
    isLoading: Boolean,
  },
  computed: {
    grade(){
      return this.cardName.match(/((PSA|BGS).+)/g)[0];
    },
    content(){
      const assets = this.individualAssets ? [...this.individualAssets] : [];
      const serials = assets.map(asset => asset.serial).join(', ');
      return {
        id: 0,
        title: this.$tc('trade.serial_numbers', assets.length),
        body: serials,
      };
    }
  },
  methods: {
    openSellByDibbs(){
      const { city, postalCode, state, street } = this.address;
      const url = new URL('https://7hkv6xgop3d.typeform.com/to/xAflDYNM');
      url.searchParams.append('email', this.email);
      url.searchParams.append('card_name', this.cardName);
      url.searchParams.append('grade', this.grade);
      url.searchParams.append('address', street);
      url.searchParams.append('city', city);
      url.searchParams.append('state', state);
      url.searchParams.append('zipCode', postalCode);
      url.searchParams.append('fullName', this.userName);
      this.goToExternalPageGlobal(url.toString());
      this.$store.dispatch('events/track', {
        event: 'SELLERS_FORM_VIEWED',
      });
    }
  }
};
</script>
