<template>
  <div class="flex items-start md:flex-col md:items-center w-full">
    <!-- Player image -->
    <div class="player-img-container">
      <img v-if="globalIsLoading" src="@/assets/img/player_skeleton.png" class="player-img" alt="player_image_loading"/>
      <ImageResize v-else :src="player.img" class="player-img" type="collection_info"/>
    </div>

    <!-- Player info -->
    <div class="flex flex-col flex-1 w-full ml-s40 md:ml-s0 md:border-t md:border-border md:pt-s12">
      <SkeletonLoading v-if="globalIsLoading" height="40px" width="480px" borderRadius="0" class="max-w-full"/>
      <div v-else class="flex justify-start items-center leading-none md:justify-center">
        <div class="headline-large mr-s24">{{player.name}}</div>
        <Share @amplitudeEvent="emitShareEvent" :shareMessage="$t('share.check_out', { name: `${player.name} card collection` })" />
      </div>

      <div v-if="player.team" class="flex justify-start items-center mt-s12 md:justify-center">
        <BaseText
          :isLoading="globalIsLoading"
          loadingHeight="24px"
          loadingWidth="220px"
          size="body-text-x-space"
        >
          {{ player.team.name }}
        </BaseText>
        <BaseText
          v-if="player.jersey_number"
          class="ml-s4"
          size="body-text-x-space"
        >
          - {{ player.jersey_number }}
        </BaseText>
      </div>

      <div class="flex mt-s16 md:flex-1">
        <div class="asset-price">
          <BaseText
            :isLoading="globalIsLoading"
            class="md:text-center"
            loadingHeight="24px"
            loadingWidth="150px"
            size="body-text-x-space"
          >
            {{ $tc('player.asset_price') }}
          </BaseText>
          <BaseText
            :isLoading="globalIsLoading"
            class="md:text-center"
            loadingHeight="24px"
            loadingWidth="150px"
            size="subheadline-x-medium"
          >
            {{ numberFormat(player.total_value, 2, false, true) }}
          </BaseText>
        </div>

        <div class="ml-s64 md:flex-1 md:ml-s0">
          <BaseText
            :isLoading="globalIsLoading"
            class="md:text-center"
            loadingHeight="24px"
            loadingWidth="150px"
            size="body-text-x-space"
          >
            {{ priceTimeframe }}
          </BaseText>
          <BaseText
            :class="isPriceChangePositive ? 'text-text-positive' : 'text-text-negative'"
            :isLoading="globalIsLoading"
            class="md:text-center"
            loadingHeight="24px"
            loadingWidth="150px"
            size="body-text-x-space"
          >
            {{ numberFormat(player.price_change.change_amount, 2, true, true) }}
            ({{ numberFormat(player.price_change.change_pct, 2, true) }}%)
          </BaseText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseText, ImageResize } from '@/components/misc';
import Share from '@/components/misc/Share';

export default {
  name: 'CollectionInfo',
  components: {
    BaseText,
    ImageResize,
    Share,
  },

  props: {
    player: Object,
  },

  methods: {
    emitShareEvent() {
      this.$emit('onEmitShareEvent');
    },
  },

  computed: {
    isPriceChangePositive() {
      try {
        return this.$big(this.player.player.price_change.change_amount).gte(0);
      } catch (err) {
        return true;
      }
    },
    priceTimeframe() {
      const playerTimeframe = this.player.price_change.timeframe;
      return this.$t('market.change_timeframes.change' + playerTimeframe);
    }
  },
};
</script>

<style scoped>
  .player-img-container {
    @apply flex justify-center overflow-hidden;
    height: 150px;
    width: 180px;
  }

  .player-img {
    @apply flex-shrink-0 overflow-hidden h-full w-auto;
    max-width: none;
  }

  .asset-price {
    width: 150px;
  }
</style>
