import router from '@/vue-router';

router.addRoute({
  component: () => import('@/views/TakeCustody'),
  name: 'take-custody',
  path: '/take-custody',
  meta: {
    bypassLoading: true,
    template: 2,
    requiresAuth: true,
  }
});

router.addRoute({
  component: () => import('@/views/TakeCustody/TakeCustodyOption'),
  name: 'take-custody-option',
  path: '/take-custody/:option',
  meta: {
    bypassLoading: true,
    template: 2,
    requiresAuth: true,
  }
});
