<template>
  <component
    :is="tag"
    :class="componentClasses"
  >
    <SkeletonLoading
      v-if="isLoading"
      v-bind="loadingOptions"
    />
    <slot v-else />
  </component>
</template>

<script>
export default {
  name: 'BaseText',
  props: {
    color: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'text-left',
      validator(value){
        return ['text-left', 'text-right', 'text-center', 'text-justify'].includes(value);
      }
    },
    size: {
      type: String,
      default: 'body-text-medium',
    },
    tag: {
      type: String,
      default: 'p',
    },
    isLoading: Boolean,
    loadingHeight: {
      type: String,
      default: '20px',
    },
    loadingWidth: {
      type: String,
      default: '100%',
    },
  },
  computed: {
    componentClasses(){
      return {
        [this.color]: true,
        [this.position]: true,
        [this.size]: true,
      };
    },
    loadingOptions(){
      return {
        height: this.loadingHeight,
        width: this.loadingWidth,
      };
    }
  }
};
</script>

<style scoped>
</style>
