import router from '@/vue-router';
import Trade from '@/graphql/queries/Trade.gql';

router.addRoute({
  component: () => import('@/views/Trade'),
  name: 'trade',
  path: '/trade/:pair',
  meta: {
    bypassLoading: true,
    query: Trade,
    template: 5,
    variable: 'pair',
  },
});

router.addRoute({
  component: () => import('@/views/Trade'),
  name: 'trade-action',
  path: '/trade/:pair/:action',
  meta: {
    bypassLoading: true,
    query: Trade,
    template: 5,
    variable: 'pair',
  },
});
