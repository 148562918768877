<template>
  <section>
    <div class="chart-container__pre-chart">
      <div class="upper-bound" v-text="formattedUpperBound" />
      <SecondaryTabs
        class="items-end"
        @tabChange="(value) => updateChartRange(value)"
        :currentTab="selectedChartOption"
        :tabList="tabList"
      />
    </div>

    <Chart
      v-if="!isLoading"
      :chartdata="chart"
      :chartOptions="chartOptions"
      chartValueLabel="close"
      excludeLeftZeros
      showTime
    />

    <div v-else class="chart-loading" :style="`height:${chartOptions.height}px`">
      <Loading version="v2" />
    </div>

    <div class="lower-bound" v-text="formattedLowerBound" />
  </section>
</template>

<script>
import { Loading, SecondaryTabs } from '@/components/misc';
import Chart from '@/components/misc/Chart';
import PAIR_CHART from '@/graphql/queries/PairChart.gql';
import TRADE_CHART from '@/graphql/subscriptions/TradeChart.gql';
import chartRanges from '@/utils/chart-ranges';
export default {
  name: 'TradeChartContainer',
  components: {
    Chart,
    Loading,
    SecondaryTabs,
  },
  data() {
    return {
      isLoading: true,
      chartSubscription: null,
      chart: null,
      upperBound: 0,
      lowerBound: 0,
      chartRanges: chartRanges,
      selectedChartOption: '1W',
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: (obj) => obj.__typename === 'Pair',
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {
          height: 375,
        };
      },
    },
    priceAlertLabel: String,
    isSkeletonLoading: Boolean,
    showSetPriceButton: Boolean,
    showTime: Boolean,
  },
  async mounted() {
    if (!this.isSkeletonLoading) {
      await this.loaded();
    }
  },
  computed: {
    assetSpecs() {
      return this.data.base.specs.reduce(
        (accumulatedSpecs, spec) => ({
          ...accumulatedSpecs,
          [`asset_${spec.key}`]: spec.value,
        }),
        {}
      );
    },
    tabList() {
      return this.chartRanges.map((item) => item.text);
    },
    formattedUpperBound() {
      return this.numberFormat(this.upperBound, 2, false, true);
    },
    formattedLowerBound() {
      return this.numberFormat(this.lowerBound, 2, false, true);
    },
  },
  watch: {
    chart() {
      if (this.chart) {
        const firstValue = this.chart.findIndex((data) => data['close'] !== 0);
        let chartValues = null;
        if (firstValue !== -1) {
          chartValues = this.chart.slice(firstValue, this.chart.length);
        }
        const values = chartValues.map((chartData) => chartData.close);
        this.upperBound = Math.max(...values);
        this.lowerBound = Math.min(...values);
      }
    },
    data() {
      this.chart = this.sanitizeChartData(this.data.chart);
    },
    isSkeletonLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.loaded();
        });
      }
    },
  },
  methods: {
    loaded() {
      this.chart = this.sanitizeChartData(this.data.chart);
      this.$emit('onUpdateChartRange', this.chartRanges[2]);
      this.isLoading = false;
    },
    async updateChartRange(option) {
      if (this.selectedChartOption !== option) {
        this.selectedChartOption = option;

        const chartOption = this.chartRanges.find((chart) => chart.text === option);
        await this.loadData(chartOption);
        this.$emit('onUpdateChartRange', chartOption);
      }
    },
    async loadData(chartOption) {
      try {
        this.isLoading = true;
        const response = await this.$apollo.query({
          query: PAIR_CHART,
          variables: {
            pair: this.$route.params.pair,
            chartEndTimeSeconds: parseInt(new Date().getTime() / 1000),
            window: chartOption.window,
            timeframe: chartOption.timeframe,
          },
        });
        const { chart } = response.data.pair;
        this.chart = this.sanitizeChartData(chart);
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },
    async handleChartSubscription() {
      if (this.chartSubscription) {
        this.chartSubscription.unsubscribe();
      }
      const chartSubscription = await this.$apollo.subscribe({
        query: TRADE_CHART,
        variables: {
          pair: this.$route.params.pair,
          resolution: 'min15',
        },
      });

      this.chartSubscription = chartSubscription.subscribe((response) => {
        const {
          pair_chart_change: { time, close },
        } = response.data;
        if (this.chart[this.chart.length - 1].time > time) {
          return;
        }
        if (this.chart[this.chart.length - 1].time < time) {
          this.chart = [
            ...this.chart,
            {
              time,
              close,
            },
          ];

          return this.chart;
        }
        this.chart[this.chart.length - 1].close = close;
        this.chart = [...this.chart];
      });
    },
  },
};
</script>

<style scoped>
.chart-container__pre-chart {
  @apply flex justify-between items-baseline border-0 border-dashed border-b-2 border-border-active-nav pb-s12 border-opacity-30;
}
.period-filter {
  @apply text-n-2xl cursor-pointer text-center text-text-body;
  width: 45px;
}
.selected {
  @apply text-text-body;
}
.upper-bound {
  @apply text-text-positive subheadline-x-small;
}
.lower-bound {
  @apply text-text-negative subheadline-x-small border-0 border-dashed border-t-2 border-border-negative border-opacity-30 py-s8;
}
.chart-loading {
  @apply relative;
}
</style>
