<template>
  <div class="border-border border-b py-s16 start-xl:py-s24">
    <div class="grid-container">
      <div>
        <BaseText
          color="text-border-inverted"
          size="body-text-medium"
          tag="p"
        >
          {{ $t('rewards.rewards_history.action') }}
        </BaseText>
        <BaseText
          class="mt-s4"
          :isLoading="isComponentLoading"
          size="body-text-x-large"
          tag="p"
        >
          {{ description }}
        </BaseText>
      </div>

      <div>
        <BaseText
          color="text-border-inverted"
          size="body-text-medium"
          tag="p"
        >
          {{ $t('rewards.rewards_history.gems') }}
        </BaseText>
        <BaseText
          class="mt-s4"
          :isLoading="isComponentLoading"
          size="body-text-x-large"
          tag="p"
        >
          {{ formattedGems }}
        </BaseText>
      </div>

      <div class="mr-s12">
        <BaseText
          color="text-border-inverted"
          size="body-text-medium"
          tag="p"
        >
          {{ $t('rewards.rewards_history.redeemed') }}
        </BaseText>
        <BaseText
          class="mt-s4"
          :isLoading="isComponentLoading"
          size="body-text-x-large"
          tag="p"
        >
          {{ formattedDate }}
        </BaseText>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { BaseText } from '@/components/misc';

import { formatDecimals } from '@/utils/formatters';

export default {
  name: 'RewardHistoryItem',
  components: {
    BaseText,
  },
  props: {
    credit: {
      type: Boolean,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    gems_amount: {
      type: String,
      required: true,
    },
    isComponentLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedDate() {
      return DateTime.fromSeconds(Number(this.date)).setLocale('en-US').toLocaleString();
    },

    formattedGems() {
      return `${this.credit ? '+' : '-'}${formatDecimals(this.gems_amount)}`;
    },
  },
};
</script>

<style scoped>
.redeemed-on-text {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.grid-container {
  @apply grid gap-s12;
  grid-template-columns: 1fr .45fr .45fr;
}

@screen start-xl {
  .grid-container {
    grid-template-columns: 1fr .3fr .3fr;
  }
}
</style>
