<template>
  <input
    ref="input"
    :class="inputClasses"
    v-bind="inputProps"
    v-model="inputValue"
  />
</template>

<script>
export default {
  name: 'BasicInput',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      default: 'text'
    },
  },
  computed: {
    inputValue: {
      get(){
        return this.value;
      },
      set(event){
        this.$emit('input', event);
      }
    },
    inputClasses() {
      return {
        'p-s16 border-border bg-background-primary rounded-16 no-arrows': true,
        'body-text-x-large': true,
        'text-text-body': true,
        'placeholder-text-inactive': true,
      };
    },
    inputProps(){
      return {
        ...this.$attrs,
        type: this.type,
      };
    }
  },
};
</script>

<style scoped>
</style>
