<template>
  <div class="wallet-balance-resume">
    <BaseText v-bind="skeletonLoadingSettings">
      <div class="wallet-balance__block">
        <div class="font-bold flex text-n-sm text-text-inactive" @click="showTotalBalanceInfo()">
          {{ $t('current_balance.total_balance') }}

          <IconInfo class="wallet-balance_icon-info default-transition hover:opacity-60"/>
        </div>

        <div class="text-n-3xl font-bold mt-2px md:text-n-xl">
          <AnimatedNumber :number="totalBalance" currency/>
        </div>
      </div>
    </BaseText>

    <BaseText v-bind="skeletonLoadingSettings">
      <div class="wallet-balance__block ml-n-4xl md:ml-none">
        <div class="font-bold flex text-n-sm text-text-inactive">
          {{ $t('current_balance.holdings') }}
        </div>

        <div class="font-semibold text-n-xl md:text-n-lg">
          {{ formattedHoldings }}
        </div>
      </div>
    </BaseText>

    <BaseText v-bind="skeletonLoadingSettings">
      <div class="wallet-balance__block ml-n-4xl md:ml-none">
        <div class="font-bold flex text-n-sm text-text-inactive">
          {{ $t('current_balance.buying_power') }}
        </div>

        <div class="font-semibold text-n-xl md:text-n-lg">
          {{ formattedBuyingPower }}
        </div>
      </div>
    </BaseText>

  </div>
</template>

<script>
import { IconInfo } from '@/assets/icons';
import AnimatedNumber from '@/components/misc/AnimatedNumber';
import { BaseText } from '@/components/misc';

export default {
  name: 'WalletBalanceResume',

  components: {
    AnimatedNumber,
    BaseText,
    IconInfo,
  },

  props: {
    buyingPower: {
      type: Number,
      default: 0
    },

    totalBalance: {
      type: Number,
      default: 0
    },
  },

  computed: {
    skeletonLoadingSettings() {
      return {
        isLoading: this.globalIsLoading,
        loadingHeight: '36px',
        loadingWidth: '130px',
      };
    },

    formattedBuyingPower(){
      return this.numberFormat(this.buyingPower, 2, false, true);
    },

    formattedHoldings(){
      return this.numberFormat(this.$big(this.totalBalance).minus(this.buyingPower), 2, false, true);
    },
  }
};
</script>

<style scoped>
.wallet-balance-resume {
  @apply flex w-full;
  min-height: 75px;
}

@media (max-width: 767px) {
  .wallet-balance-resume {
    @apply justify-between py-s16 border-border mb-s24 border-b;
  }
}

.wallet-balance_icon-info {
  @apply w-s12 h-s12 ml-s4 fill-current cursor-pointer;
}

.wallet-balance__block{
  @apply flex flex-col;
}

.info-container {
  @apply
    ml-n-sm
    flex
    justify-center
    items-center
    font-semibold
    rounded-16
    cursor-pointer
    border
    border-text-inactive;
  font-size: 9px;
  width: 12px;
  height: 12px;
}
</style>
