import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/translations/en.json';
import ptBr from '@/translations/pt-br.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    ptBr,
  },
});

export default i18n;
