<template>
  <Transition>
    <div 
      v-if="visible"
      class="autocomplete-container">
      <slot />
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'Autocompletion',
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style scoped>
.autocomplete-container {
  @apply absolute border-border border flex flex-col w-full rounded-b-16 bg-background-primary overflow-hidden;
  z-index: 1;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
