<template>
  <div class="profile-container" v-click-outside="closeDropdown">
    <DropdownList
      :dropdownItems="dropdownItems"
      customClasses="navbar-dropdown"
      @onDropdownAction="onDropdownAction"
    >
      <div class="cursor-pointer text-sm12 text-text-body" @click="toggle" slot="dropdown-button">
        <div class="flex mt-2px">
          <div class="mr-1-2">
            <Avatar/>
          </div>
          <div class="flex flex-col justify-center">
            <div class="profile-dropdown-text font-medium">
              {{ $t('welcome_back') }}
            </div>
            <div class="profile-dropdown-text font-bold">
              {{ identityName }}
            </div>
          </div>
        </div>
      </div>
      <div class="theme-container" slot="footer">
        {{$t('navigation.theme')}}:
        <div class="flex justify-end items-center flex-1 ml-s8">
          <div @click="switchTheme('dark')" class="theme-circle" :class="darkThemeClasses">
          </div>
          <div @click="switchTheme('light')" class="ml-s8 theme-circle" :class="lightThemeClasses">
          </div>
        </div>
      </div>
    </DropdownList>
  </div>
</template>

<script>
import Avatar from '@/components/misc/Avatar.vue';
import { DropdownList } from '@/components/dropdown';

export default {
  name: 'NavbarDropdown',
  props: {
    label: String,
    dropdownItems: Array,
  },

  components: {
    Avatar,
    DropdownList,
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    darkThemeClasses() {
      return {
        'border-2 bg-background-page': this.currentTheme === 'dark',
        'bg-text-body': this.currentTheme !== 'dark',
      };
    },

    identityName() {
      return this.lodashGet(this.$store.state.api, 'current_user.user_info.email', '');
    },

    lightThemeClasses() {
      return {
        'border-2 bg-background-page': this.currentTheme === 'light',
        'bg-text-body': this.currentTheme !== 'light',
      };
    },
  },

  methods: {
    async toggle() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        await this.$store.dispatch('events/track', {
          event: 'ACCOUNT_VIEWED',
        });
      }
    },

    onDropdownAction(payload) {
      this.$emit('handleAction', payload);
    },

    closeDropdown() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
  .profile-container {
    @apply flex flex-col justify-center relative;
  }

  .theme-container {
    @apply flex justify-between items-center text-text-body font-medium py-s12;
    width: 128px;
  }
</style>
