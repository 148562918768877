
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CircleWithdrawalReceipt"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CircleWithdrawalReceipt"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"withdrawal_description"},"name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"withdrawal_type"},"name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"withdrawal_created_at"},"name":{"kind":"Name","value":"created_at"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"withdrawal_status"},"name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"withdrawal_amount"},"name":{"kind":"Name","value":"amount"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"withdrawal_transaction_id"},"name":{"kind":"Name","value":"transaction_id"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":262}};
    doc.loc.source = {"body":"fragment CircleWithdrawalReceipt on CircleWithdrawalReceipt {\n  withdrawal_description: description\n  withdrawal_type: type\n  withdrawal_created_at: created_at\n  withdrawal_status: status\n  withdrawal_amount: amount\n  withdrawal_transaction_id: transaction_id\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
