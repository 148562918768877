<template>
  <div data-testid="line-separator" class="my-s32 w-full uppercase relative border-b border-text-body flex justify-center">
    <div v-if="label" class="separator body-text-large">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'LineSeparator',
  props: {
    label: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
  .separator {
    @apply absolute bg-background-page p-s12;
    top: -21px;
  }
</style>
