import Get from 'lodash.get';
import Big from 'big.js';

Big.RM = 0;

import {
  BuyEstimateV2,
  LimitPresets,
  MarketBuyPresets,
  MarketSellPresets,
  Orderbook,
  SellEstimateV2,
} from '@/graphql/queries';

import { BuyV2, SellV2 } from '@/graphql/mutations';

export const DEFAULT_MODES = {
  BUY: 'BUY',
  SELL: 'SELL',
};

export const DEFAULT_TYPES = {
  INSTANT: 'INSTANT',
  LIMIT: 'LIMIT',
};

export const DEFAULT_STATE = {
  trade_mode: DEFAULT_MODES.BUY,
  trade_type: DEFAULT_TYPES.INSTANT,

  // Component inputs models
  selected_buy_preset: {},
  selected_sell_preset: {},

  // API responses
  limit_buy_presets: [],
  limit_sell_presets: [],
  market_buy_presets: [],
  market_sell_presets: [],
  buy_estimate_v2: {},
  sell_estimate_v2: {},
  buy_v2: {},
  sell_v2: {},

  // Conditionals
  isTradeLoading: false,
  show_review_order: false,
  show_info_prompt: false,
  show_trade_confirmation: false,
};

export default {
  namespaced: true,

  state: {
    ...DEFAULT_STATE,
  },

  actions: {
    reset({ commit }, payload) {
      commit('RESET_STATE', payload);
    },

    setIsLoading({ commit }, payload) {
      commit('SET_IS_LOADING', payload);
    },

    changeMode({ commit }, payload = '') {
      const mode = payload.toUpperCase();
      if (Object.values(DEFAULT_MODES).includes(mode)) {
        commit('SET_MODE', mode);
      }
    },

    changeType({ commit }, payload = '') {
      const type = payload.toUpperCase();
      if (Object.values(DEFAULT_TYPES).includes(type)) {
        commit('SET_TYPE', type);
      }
    },

    async getBuyPresets({ commit }, { apolloApiCall, showError, variables }) {
      try {
        const response = await apolloApiCall({
          query: MarketBuyPresets,
          variables: { ...variables },
          fetchPolicy: 'no-cache',
        });
        commit(
          'SET_BUY_PRESETS',
          Get(response.data, 'market_buy_presets.array', [])
        );
        return response;
      } catch (err) {
        showError(err);
      }
    },

    async getSellPresets({ commit }, { apolloApiCall, showError, variables }) {
      try {
        const response = await apolloApiCall({
          query: MarketSellPresets,
          variables: { ...variables },
          fetchPolicy: 'no-cache',
        });
        commit(
          'SET_SELL_PRESETS',
          Get(response.data, 'market_sell_presets.array', [])
        );
        return response;
      } catch (err) {
        showError(err);
      }
    },

    async getLimitPresets({ commit }, { apolloApiCall, showError }) {
      try {
        const response = await apolloApiCall({
          query: LimitPresets,
          fetchPolicy: 'no-cache',
        });
        commit('SET_LIMIT_PRESETS', {
          limit_buy_presets: Get(response.data, 'limit_buy_presets', []),
          limit_sell_presets: Get(response.data, 'limit_sell_presets', []),
        });
      } catch (err) {
        showError(err);
      }
    },

    async getBuyEstimate({ commit }, { apolloApiCall, showError, variables }) {
      try {
        const response = await apolloApiCall({
          query: BuyEstimateV2,
          variables: { ...variables },
          fetchPolicy: 'no-cache',
        });
        commit('SET_BUY_ESTIMATE', Get(response.data, 'buy_estimate_v2', {}));
      } catch (err) {
        showError(err);
      }
    },

    async getSellEstimate({ commit }, { apolloApiCall, showError, variables }) {
      try {
        const response = await apolloApiCall({
          query: SellEstimateV2,
          variables: { ...variables },
          fetchPolicy: 'no-cache',
        });
        commit('SET_SELL_ESTIMATE', Get(response.data, 'sell_estimate_v2', {}));
      } catch (err) {
        showError(err);
      }
    },

    async buyV2({ commit }, { apolloApiCall, showError, variables }) {
      try {
        const response = await apolloApiCall({
          mutation: BuyV2,
          variables: { ...variables },
        });
        commit('SET_BUY_V2', Get(response.data, 'buy_v2', {}));
      } catch (err) {
        await showError(err);
      } 
    },

    async getOrderbook({ commit, dispatch, rootState }, { pair, option }) {
      try {
        const response = await dispatch('api/apolloClient', {
          type: 'query',
          options: {
            query: Orderbook,
            variables: {
              pair,
              orderbookDecimals: -option,
            },
            fetchPolicy: 'no-cache',
          }
        }, { root: true });

        const updatedPair = { ...rootState.api['pair'] };
        updatedPair.orderbook = response.data.pair.orderbook;

        commit('api/setState', {
          stateName: 'pair',
          stateData: updatedPair,
        }, { root: true });
      } catch (err) {
        throw err;
      }
    },

    async sellV2({ commit }, { apolloApiCall, showError, variables }) {
      try {
        const response = await apolloApiCall({
          mutation: SellV2,
          variables: { ...variables },
        });
        commit('SET_SELL_V2', Get(response.data, 'sell_v2', {}));
      } catch (err) {
        showError(err);
      } 
    },

    selectBuyPreset({ commit }, preset) {
      commit('SET_SELECTED_BUY_PRESET', preset);
    },

    selectSellPreset({ commit }, preset) {
      commit('SET_SELECTED_SELL_PRESET', preset);
    },

    setBuyEstimate({ commit }, payload) {
      commit('SET_BUY_ESTIMATE', payload);
    },

    setSellEstimate({ commit }, payload) {
      commit('SET_SELL_ESTIMATE', payload);
    },

    showReviewOrder({ commit }, payload) {
      commit('SET_SHOW_REVIEW_ORDER', payload);
    },
  },

  mutations: {
    RESET_STATE(state, preserve) {
      const preservedValues = preserve ? { 
        trade_mode         : state.trade_mode          || DEFAULT_STATE['trade_mode'],
        trade_type         : state.trade_type          || DEFAULT_STATE['trade_type'],
        market_buy_presets : state.market_buy_presets  || DEFAULT_STATE['market_buy_presets'],
        market_sell_presets: state.market_sell_presets || DEFAULT_STATE['market_sell_presets'],
        limit_buy_presets  : state.limit_buy_presets   || DEFAULT_STATE['limit_buy_presets'],
        limit_sell_presets : state.limit_sell_presets  || DEFAULT_STATE['limit_sell_presets'],
      } : {};
      Object.assign(state, DEFAULT_STATE, preservedValues);
    },
    SET_IS_LOADING(state, payload) {
      state.isTradeLoading = payload;
    },
    SET_MODE(state, payload) {
      state.trade_mode = payload;
    },
    SET_TYPE(state, payload) {
      state.trade_type = payload;
    },
    SET_BUY_PRESETS(state, payload) {
      state.market_buy_presets = payload;
    },
    SET_SELECTED_BUY_PRESET(state, payload) {
      state.selected_buy_preset = payload;
    },
    SET_SELL_PRESETS(state, payload) {
      state.market_sell_presets = payload;
    },
    SET_SELECTED_SELL_PRESET(state, payload) {
      state.selected_sell_preset = payload;
    },
    SET_LIMIT_PRESETS(state, payload) {
      state.limit_buy_presets = payload.limit_buy_presets;
      state.limit_sell_presets = payload.limit_sell_presets;
    },
    SET_BUY_ESTIMATE(state, payload) {
      const valid_amount = payload.usd_amount_spent;
      state.buy_estimate_v2 = valid_amount ? payload : {};
    },
    SET_SELL_ESTIMATE(state, payload) {
      state.sell_estimate_v2 = payload;
    },
    SET_BUY_V2(state, payload) {
      state.buy_v2 = payload;
      state.show_review_order = false;
      state.show_trade_confirmation = true;
    },
    SET_SELL_V2(state, payload) {
      state.sell_v2 = payload;
      state.show_review_order = false;
      state.show_trade_confirmation = true;
    },
    SET_SHOW_REVIEW_ORDER(state, payload) {
      state.show_review_order = Boolean(payload);
    },
  },

  getters: {
    orderConfirmedInfo(state, _, rootState) {
      if (state.trade_mode === DEFAULT_MODES.BUY) {
        if (state.trade_type === DEFAULT_TYPES.INSTANT) {
          // Trade -> Instant Buy
          return {
            orderConfirmed: [
              {
                label: 'trade.total_purchase',
                type: 'currency',
                value: Get(state, 'buy_estimate_v2.usd_amount_spent', '0'),
              },
              {
                label: 'trade.quantity_purchase',
                type: 'fraction',
                value: Get(state, 'buy_estimate_v2.card_fraction_purchased', '0'),
              },
            ],
            title: 'trade.buy_confirmed',
          };
        } else {
          // Trade -> Limit Buy
          return {
            orderConfirmed: [
              {
                label: 'trade.total_order',
                type: 'currency',
                value: Get(state, 'buy_estimate_v2.usd_amount_spent', '0'),
              },
              {
                label: 'trade.quantity_order',
                type: 'fraction',
                value: Get(state, 'buy_estimate_v2.card_fraction_purchased', '0'),
              },
              {
                label: 'trade.card_price',
                type: 'currency',
                value: Get(state, 'buy_estimate_v2.average_card_price_purchase', '0'),
              },
            ],
            title: 'trade.limit_order_confirmed',
            smallTitle: true,
          };
        }
      } else {
        if (state.trade_type === DEFAULT_TYPES.INSTANT) {
          // Trade -> Instant Sell
          return {
            orderConfirmed: [
              {
                label: 'trade.total_sold',
                type: 'currency',
                value: Get(state, 'sell_estimate_v2.usd_amount_received', '0'),
              },
              {
                label: 'trade.quantity_sold',
                type: 'fraction',
                value: Get(state, 'sell_estimate_v2.inventory_percentage_sold', '0'),
              },
            ],
            title: 'trade.sale_confirmed',
          };
        } else {
          // Trade -> Limit Sell
          return {
            orderConfirmed: [
              {
                label: 'trade.total_order',
                type: 'currency',
                value: Get(state, 'sell_estimate_v2.usd_amount_received', '0'),
              },
              {
                label: 'trade.quantity_order',
                type: 'fraction',
                value: Big(Get(state, 'sell_estimate_v2.card_fraction_sold', '0')).div(Get(rootState, 'api.pair.base.current_user_amount', '1')),
              },
              {
                label: 'trade.card_price',
                type: 'currency',
                value: Get(state, 'sell_estimate_v2.average_card_price_sold', '0'),
              },
            ],
            title: 'trade.limit_order_confirmed',
            smallTitle: true,
          };
        }
      }
    },
    reviewOrderInfo(state, _, rootState) {
      if (state.trade_mode === DEFAULT_MODES.BUY) {
        if (state.trade_type === DEFAULT_TYPES.INSTANT) {
          // Trade -> Instant Buy
          return {
            reviewOrder: [
              {
                label: 'trade.balance',
                type: 'currency',
                value: Get(rootState, 'api.current_user.usd_balance.amount', '0'),
              },
              {
                label: 'trade.average_card_price',
                tooltip: 'average_asset_price',
                type: 'currency',
                value: Get(state, 'buy_estimate_v2.average_card_price_purchase', '0'),
              },
              {
                label: 'trade.quantity_purchased',
                type: 'fraction',
                value: Get(state, 'buy_estimate_v2.card_fraction_purchased', '0'),
              },
              {
                label: 'trade.purchase_amount',
                type: 'currency',
                value: Big(
                  Get(state, 'buy_estimate_v2.usd_amount_spent', '0')
                ).minus(
                  Big(Get(state, 'buy_estimate_v2.fee', '0')).toFixed(2, 1)
                ).toFixed(2),
              },
              {
                label: 'trade.fee',
                type: 'currency',
                value: Big(
                  Get(state, 'buy_estimate_v2.fee', '0')
                ).toFixed(2, 1),
              },
            ],
            total: {
              label: 'trade.total_purchase',
              value: Get(state, 'buy_estimate_v2.usd_amount_spent', '0'),
            },
            continueLabel: 'buy_now',
          };
        } else {
          // Trade -> Limit Buy
          return {
            reviewOrder: [
              {
                label: 'trade.balance',
                type: 'currency',
                value: Get(rootState, 'api.current_user.usd_balance.amount', '0'),
              },
              {
                label: 'trade.quantity_ordered',
                type: 'fraction',
                value: Get(state, 'buy_estimate_v2.card_fraction_purchased', '0'),
              },
              {
                label: 'trade.card_price',
                type: 'currency',
                value: Get(state, 'buy_estimate_v2.average_card_price_purchase', '0'),
              },
              {
                label: 'trade.limit_order_amount',
                type: 'currency',
                value: Big(
                  Get(state, 'buy_estimate_v2.usd_amount_spent', '0')
                ).minus(
                  Big(Get(state, 'buy_estimate_v2.fee', '0')).toFixed(2, 1)
                ).toFixed(2),
              },
              {
                label: 'trade.fee',
                type: 'currency',
                value: Big(
                  Get(state, 'buy_estimate_v2.fee', '0')
                ).toFixed(2, 1),
              },
            ],
            total: {
              label: 'trade.total_purchase',
              value: Get(state, 'buy_estimate_v2.usd_amount_spent', '0'),
            },
            continueLabel: 'trade.set_limit_order',
          };
        }
      } else {
        if (state.trade_type === DEFAULT_TYPES.INSTANT) {
          // Trade -> Instant Sell
          return {
            reviewOrder: [
              {
                label: 'trade.average_card_price',
                tooltip: 'average_asset_price',
                type: 'currency',
                value: Get(state, 'sell_estimate_v2.average_card_price_sold', '0'),
              },
              {
                label: 'trade.quantity_sold',
                type: 'fraction',
                value: Get(state, 'sell_estimate_v2.inventory_percentage_sold', '0'),
              },
              {
                label: 'trade.sell_amount',
                type: 'currency',
                value: Big(
                  Get(state, 'sell_estimate_v2.usd_amount_received', '0')
                ).minus(
                  Big(Get(state, 'sell_estimate_v2.fee', '0')).toFixed(2, 1)
                ).toFixed(2),
              },
              {
                label: 'trade.fee',
                type: 'currency',
                value: Big(
                  Get(state, 'sell_estimate_v2.fee', '0')
                ).toFixed(2, 1),
              },
            ],
            total: {
              label: 'trade.total_sold',
              value: Get(state, 'sell_estimate_v2.usd_amount_received', '0'),
            },
            continueLabel: 'sell_now',
          };
        } else {
          // Trade -> Limit Sell
          return {
            reviewOrder: [
              {
                label: 'trade.quantity_to_sell',
                type: 'fraction',
                value: Big(Get(state, 'sell_estimate_v2.card_fraction_sold', '0')).div(Get(rootState, 'api.pair.base.current_user_amount', '1')),
              },
              {
                label: 'trade.card_price',
                type: 'currency',
                value: Get(state, 'sell_estimate_v2.average_card_price_sold', '0'),
              },
              {
                label: 'trade.limit_order_amount',
                type: 'currency',
                value: Big(
                  Get(state, 'sell_estimate_v2.usd_amount_received', '0')
                ).minus(
                  Big(Get(state, 'sell_estimate_v2.fee', '0')).toFixed(2, 1)
                ).toFixed(2),
              },
              {
                label: 'trade.fee',
                type: 'currency',
                value: Big(
                  Get(state, 'sell_estimate_v2.fee', '0')
                ).toFixed(2, 1),
              },
            ],
            total: {
              label: 'trade.total_to_sell',
              value: Get(state, 'sell_estimate_v2.usd_amount_received', '0'),
            },
            continueLabel: 'trade.set_limit_order',
          };
        }
      }
    },
  },
};
