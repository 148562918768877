import router from '@/vue-router';

router.addRoute({
  component: () => import('@/views/SweepstakesTerms'),
  name: 'sweepstakes-terms',
  path: '/sweepstakes-terms',
  meta: {
    template: 4,
  },
});
