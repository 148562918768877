<template>
  <Card
    v-bind="cardProps"
    :class="cardClasses"
    @click.native="goToInternalPageGlobal(data.to)"
  >
    <div class="asset-image">
      <SquareImage v-bind="assetImgProps" />
    </div>

    <div class="asset-info">
      <div class="asset-title">
        <h4 class="subheadline-x-medium text-center">
          {{ data.collectionName }}
        </h4>

        <span
          class="body-text-medium text-center truncate mt-s4"
          :title="data.title"
        >
          {{ assetTitle }}
        </span>
      </div>

      <div class="flex flex-col text-center" v-if="!isLoading">
        <div class="grid grid-cols-2">
          <div class="flex flex-col">
            <h5 class="body-text-medium text-text-inactive">
              {{ $t('trade.asset_price') }}
            </h5>

            <p class="subheadline-x-small">
              {{ currentPrice }}
            </p>
          </div>

          <div class="flex flex-col">
            <h5 class="body-text-medium text-text-inactive">
              {{ priceChangeTimeframe }}
            </h5>

            <p :class="priceChangeClasses">
              {{ priceChange.percentage }}
            </p>
          </div>
        </div>

        <CardLadderDescription
          v-if="hasCardLadder"
          descriptionText="drops.card_ladder_avg_price_small"
          :value="cardLadderValue"
        />
      </div>
    </div>
  </Card>
</template>

<script>
import {
  Card,
  CardLadderDescription,
  SquareImage,
} from '@/components/misc';

export default {
  name: 'AssetCardV2',

  components: {
    Card,
    CardLadderDescription,
    SquareImage,
  },

  props: {
    ...Card.props,

    data: Object,

    background: {
      default: 'bg-background-page',
    },

    skeletonLoading: {
      type: Object,
      default: () => ({
        height: '220px',
        width: '100%',
      }),
    },
  },

  computed: {
    assetImgProps() {
      return {
        image: this.data.image,
        coverClasses: 'rounded-bl-none rounded-br-none',
        imageResizeProps: {
          alt: this.data.image_alt,
          assetType: 'collection_asset_card',
          classes: '',
        },
      };
    },
      
    assetTitle() {
      return this.data.short_name || this.data.title;
    },

    priceChangeTimeframe() {
      const priceChange = this.priceChange.timeframe;

      return this.$t(`market.change_timeframes.change${ priceChange }`);
    },

    cardClasses() {
      return {
        'asset-card cursor-pointer': true,
      };
    },

    cardProps() {
      let cardProps = {};

      if (this.$props) {
        const cardPropsExpected = [
          'background',
          'isLoading',
          'skeletonLoading',
        ];

        const props = Object
          .entries(this.$props)
          .filter((item) => cardPropsExpected.includes(item[0]));

        cardProps = Object.fromEntries(props);
      }

      return cardProps;
    },

    cardLadderValue() {
      return !this.isLoading && this.data.cardladder.value;
    },

    currentPrice() {
      return !this.isLoading && this.data.readableValues.currentPrice;
    },

    hasCardLadder() {
      return !this.isLoading && this.data.cardladder.active;
    },

    priceChange() {
      const { priceChangeDynamic } = this.data.readableValues;

      return !this.isLoading && priceChangeDynamic;
    },

    priceChangeClasses() {
      return (
        !this.isLoading && {
          'body-text-large': true,
          [`text-text-${this.priceChange.type}`]: true,
        }
      );
    },
  },
};
</script>

<style scoped>
.asset-card {
  @apply flex flex-col justify-center items-center;
}

.asset-info {
  @apply grid p-s16 gap-s12;
  grid-template-rows: min-content 1fr min-content;
}

.asset-title {
  @apply flex flex-col truncate;
}

.asset-image {
  @apply w-full;
}

@screen start-xl {
  .asset-card {
    max-width: 230px;
  }
}
</style>
