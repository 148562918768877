import {
  initialDepositMethods,
  initialWithdrawMethods
} from '@/modules/walletv2/transferMethods';

export default {

  // Account
  currentCirclePublicKey: null,
  currentPlaidToken: null,
  userPermissions: null,

  // Balance
  balanceChartValues: [],
  balanceChartRanges: {
    '1D': {
      window: 96,
      resolution: 'min15',
      timeframe: '_15m'
    },
    '1W': {
      window: 168,
      resolution: 'hour1',
      timeframe: '_1h'
    },
    '1M': {
      window: 30,
      resolution: 'hour6',
      timeframe: '_1D'
    },
    '1Y': {
      window: 57,
      resolution: 'week1',
      timeframe: '_1W'
    },
  },

  // Profit / Loss
  profitLoss: {
    overall: {
      type: 'positive',
      value: 0,
      percentage: 0,
    },
  },

  // Transaction
  transferenceMethods: {
    deposit: {
      limit: 0,
      methods: [...initialDepositMethods],
    },
    withdraw: {
      limit: 0,
      methods: [...initialWithdrawMethods],
    }
  },

  // Transaction: Deposit
  selected_deposit_chain: null,
  circle_available_deposit_chains: [],
  circle_create_chain_deposit_address: null,
  currentDepositAmount: null,
  linkedDepositMethods: null,
  ongoingDepositData: null,
  depositLimits: {
    mappedRemaining: null,
    all: null
  },

  // Transaction: Withdraw
  selected_withdraw_chain: null,
  circle_available_withdrawal_chains: [],
  linkedWithdrawalMethods: null,
  ongoingWithdrawData: null,
  currentWithdrawAmount: null,
  withdraw_wallet_address: null,
  withdraw_wallet_address_tag: null,
  lastWithdrawalId: null,

  // Transaction: History
  transactions: {
    all: [],
    active_orders: [],
    single: {},
  },

  // Inventory Change
  inventoryChange: null
};
