<template>
  <div class="loading-container" data-testid="loading">
    <div v-if="version === 'v1'">
      <div class="hollow-dots-spinner">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

    <div v-if="version === 'v2'">
      <div :class="`circles-to-rhombuses-spinner ${smallLoading ? 'small' : ''}`">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    </div>

    <div v-if="version === 'v3'">
      <MainLogoDark v-if="currentTheme === 'dark'" class="h-n-8xl pulsate"/>
      <MainLogoLight v-else class="h-n-8xl pulsate"/>
    </div>

    <div v-if="version === 'v4'" class="w-full h-full flex justify-center items-center">
      <svg class="rotating-spinner w-full h-full" viewBox="0 0 50 50">
        <circle class="rotating-spinner__path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
    </div>
  </div>
</template>

<script>
import MainLogoDark from '@/assets/img/logo_main.svg';
import MainLogoLight from '@/assets/img/logo_main_light.svg';

export const AVAILABLE_LOADERS = ['v1', 'v2', 'v3', 'v4'];

export default {
  name: 'Loading',
  components: {
    MainLogoDark,
    MainLogoLight,
  },

  props: {
    version: {
      type: String,
      default: 'v2',
      validator: (value) => AVAILABLE_LOADERS.includes(value)
    },

    smallLoading: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<style scoped>
  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  /* V1 - hollow-dots-spinner */
  .hollow-dots-spinner, .hollow-dots-spinner * {
    box-sizing: border-box;
  }
  .hollow-dots-spinner {
    height: 15px;
    width: calc(30px * 3);
  }
  .hollow-dots-spinner .dot {
    width: 15px;
    height: 15px;
    margin: 0 calc(15px / 2);
    border: calc(15px / 5) solid;
    border-radius: 50%;
    float: left;
    transform: scale(0);
    animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
  }
  .hollow-dots-spinner .dot:nth-child(1) {
    animation-delay: calc(300ms * 1);
  }
  .hollow-dots-spinner .dot:nth-child(2) {
    animation-delay: calc(300ms * 2);
  }
  .hollow-dots-spinner .dot:nth-child(3) {
    animation-delay: calc(300ms * 3);

  }
  @keyframes hollow-dots-spinner-animation {
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  /* V2 - circles-to-rhombuses-spinner */
  .circles-to-rhombuses-spinner, .circles-to-rhombuses-spinner * {
    box-sizing: border-box;
  }
  .circles-to-rhombuses-spinner {
    height: 15px;
    width: calc( (15px + 15px * 1.125) * 3);
    display: flex;
    align-items: center;
    justify-content: center
  }
  .circles-to-rhombuses-spinner .circle {
    height: 15px;
    width: 15px;
    margin-left: calc(15px * 1.125);
    transform: rotate(45deg);
    border-radius: 10%;
    border: 3px solid;
    overflow: hidden;
    background: transparent;

    animation: circles-to-rhombuses-animation 1200ms linear infinite;
  }
  .circles-to-rhombuses-spinner.small {
    height: 10px;
    width: calc( (10px + 10px * 1.125) * 3);
  }
  .circles-to-rhombuses-spinner.small .circle {
    height: 10px;
    width: 10px;
    margin-left: calc(10px * 1.125);
    border: 2px solid;
  }
  .circles-to-rhombuses-spinner .circle:nth-child(1) {
    animation-delay: calc(150ms * 1);
    margin-left: 0
  }
  .circles-to-rhombuses-spinner .circle:nth-child(2) {
    animation-delay: calc(150ms * 2);
  }
  .circles-to-rhombuses-spinner .circle:nth-child(3) {
    animation-delay: calc(150ms * 3);
  }
  @keyframes circles-to-rhombuses-animation {
    0% {
      border-radius: 10%;
    }

    17.5% {
      border-radius: 10%;
    }

    50% {
      border-radius: 100%;
    }


    93.5% {
      border-radius: 10%;
    }

    100% {
      border-radius: 10%;
    }
  }
  @keyframes circles-to-rhombuses-background-animation {
    50% {
      opacity: 0.4;
    }
  }

  .pulsate {
    animation: pulsate-animation 1600ms infinite;
  }
  @keyframes pulsate-animation {
    0% {
      transform: scale(0.85);
    }

    70% {
      transform: scale(1.3);
    }

    100% {
      transform: scale(0.85);
    }
  }

  /* V4 - rotating spinner */
  .rotating-spinner {
    @apply fill-current;
    animation: spinnerRotationAnimation 2s linear infinite;
  }

  .rotating-spinner__path {
    @apply stroke-current;
    stroke-linecap: square;
    animation: spinnerDashAnimation 1.5s ease-in-out infinite;
  }

  @keyframes spinnerRotationAnimation {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinnerDashAnimation {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
</style>
