import Vue from 'vue';
import Big from 'big.js';
import Get from 'lodash.get';
import cloneDeep from 'lodash.clonedeep';
import { apolloClient } from '@/vue-apollo';

import arrayMixin from '@/mixins/array';
import countries from '@/utils/countries.json';

Big.RM = 0;

const EXTENSIBLE_API_STATES = ['current_user', 'drops'];

export default {
  namespaced: true,
  state: { // you just need to add here states you want to have an initial value - otherwise, just dynamically add with setState
    isAuthenticated: false,
    authenticationToken: null,
    user: {},
    drops: [],
  },
  mutations: {
    setLoginData(state, payload) {
      const data = payload.data;
      state.isAuthenticated = true;
      state.authenticationToken = data.token;
      state.user = data.user;
      state.current_user = data.user;

      // This is just so we know if the user is email verified right upon login, without having to wait for the page data to load
      if (!state.current_user.user_info) {
        const userInfo = {};
        userInfo.email_verified = data.user.email_verified;
        state.current_user.user_info = userInfo;
      }
    },
    unsetLoginData(state) {
      state.isAuthenticated = false;
      state.authenticationToken = null;
      state.user = {};
      state.current_user = {};
    },
    setState(state, payload) {
      const isExtensible = EXTENSIBLE_API_STATES.indexOf(payload.stateName) > -1;
      const data = isExtensible ? cloneDeep(payload.stateData) : payload.stateData;
      
      Vue.delete(state, payload.stateName);
      Vue.set(state, payload.stateName, data);
    },
    removeCanceledOrder(state, payload) {
      if (state['current_user'] && state['current_user']['orders_active']) {
        let updatedOrdersHistory = state['current_user']['orders_history'] || [];
        updatedOrdersHistory.push(state['current_user']['orders_active'][payload]);
        Vue.set(state['current_user'], 'orders_history', updatedOrdersHistory);
        Vue.delete(state['current_user']['orders_active'], payload);
      }
    },
    updateUserBalance(state, payload) {
      if (state['current_user'] && state['current_user']['usd_balance']) {
        const updatedValue = Big(state['current_user']['usd_balance']['amount']).plus(Big(payload)).toString();
        Vue.set(state['current_user']['usd_balance'], 'amount', updatedValue);
      }
    },
    updateActiveDropRemaining(state, payload) {
      Vue.set(state['drops'][payload.index], 'supply_left', payload.supply_left);
    },

    setUserEmailStatus(state, payload) {
      const hasUserInfoKey = state['current_user'] && state['current_user']['user_info'];

      if (hasUserInfoKey) {
        Vue.set(state['current_user']['user_info'], 'email_verified', !!payload);
      }
    },
  },

  actions: {
    async apolloClient(_, { type, options }) {
      const response = await apolloClient[type](options);

      Object.entries(response.data).find(([, resultData]) => {
        if (resultData['__typename'] === 'DibbsError') {
          let errorData = new Error();
          errorData = { ...errorData, ...resultData };
          throw errorData;
        }
      });

      return response;
    },
    // This action receives a view/page query as a parameter, retrieve the data and update the respective states
    async refreshPageData({ commit }, { $apollo, query, variables }) {
      try {
        const response = await $apollo.query({
          query,
          variables: { ...variables },
          fetchPolicy: 'no-cache',
        });

        // Set state data
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log('----------------------------------------');
        }
        for (let [key, value] of Object.entries(response.data)) {
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.log('STATE REFRESHED', key, value);
          }
          await commit('setState', { stateName: key, stateData: value });
        }
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log('Error refreshing data - ', err.message);
        }
      }
    },

    updateDropRemaining({ commit, state }, dropData) {
      const drop = Get(state, 'drop', null);

      if (drop && drop.id === dropData.drop_id) {
        commit('setState', { stateName: 'drop', stateData: { ...drop, supply_left: dropData.remaining } });
      }

      const drops = Get(state, 'drops', []);

      const dropIndex = drops.findIndex((drop) => drop.id === dropData.drop_id);

      if (dropIndex !== -1) {
        commit('updateActiveDropRemaining', { index: dropIndex, supply_left: dropData.remaining });
      }
    },

    updatePtStatus({ commit, state }, payload) {
      commit('setState', { stateName: 'current_user', stateData: Object.assign({ ...state.current_user }, payload.current_user) });
      commit('setState', { stateName: 'available_kyc_countries', stateData: payload.available_kyc_countries });
      commit('setState', { stateName: 'get_deposit_limits_and_fees', stateData: payload.get_deposit_limits_and_fees });
      commit('setState', { stateName: 'get_withdrawal_limits_and_fees', stateData: payload.get_withdrawal_limits_and_fees });
    },

    updateUserEmailStatus({ commit }, payload) {
      commit('setUserEmailStatus', payload);
    },
  },

  getters: {
    getCurrentUser(state) {
      return Get(state, 'current_user', {});
    },
    getCurrentUserInformation(state) {
      return Get(state, 'current_user.user_info', {});
    },
    getCurrentAddressCountry(state) {
      return Get(state, 'current_user.user_info.address_country', 'US');
    },
    getUserIdentificationSummary(state) {
      const userInformation = Get(state, 'current_user.user_info', {});
      return {
        country: userInformation.address_country && countries.find((country) => country.code === userInformation.address_country),
        name: userInformation.name,
        phone: userInformation.phone,
        birthday: userInformation.birthday,
        address: {
          street: userInformation.address_street,
          city: userInformation.address_city,
          state: userInformation.address_state,
          postalCode: userInformation.address_zip,
        },
        taxId: userInformation.tax_id,
      };
    },
    getPairAssetImages(state) {
      if (!Get(state, 'pair.base.individual_assets', []).length) {
        return [{
          img_front: Get(state, 'pair.base.img_front', ''),
          img_back: Get(state, 'pair.base.img_back', ''),
        }];
      }
      return Get(state, 'pair.base.individual_assets', []);
    },
    activeOrders(state) {
      return Get(state, 'current_user.orders_active', []);
    },
    activeOrdersLength(state) {
      return Get(state, 'current_user.orders.length', 0);
    },
    cancelledOrders(state) {
      const cancelledOrders = Get(state, 'current_user.orders_cancelled', []);

      return arrayMixin.methods.sortArrayOnProperty({ array: cancelledOrders, property: 'trade_time', ascending: false });
    },
    completedOrders(state) {
      const completedOrders = Get(state, 'current_user.orders_completed', []);

      const userFracpacks = Get(state, 'current_user.fracpacks', []);

      const fracpackWithTradeTime = userFracpacks.map((fracpack) => ({
        ...fracpack,
        trade_time: fracpack.time_bought,
        type: 'BUY',
      }));

      const orders = [...completedOrders, ...fracpackWithTradeTime];

      return arrayMixin.methods.sortArrayOnProperty({ array: orders, property: 'trade_time', ascending: false });
    },
    firstFracPack(state) {
      return Get(state, 'fracpacks[0]', {});
    },
    userAssetsWithValue(state) {
      const userAssetsWithValue = Get(state, 'current_user.assets', []);
      return userAssetsWithValue.filter((asset) => Big(asset.total_value).gte(Big(0.01)));
    },
    userAssetsWithoutDollar(state, getters) {
      const usdAssetIndex = getters.userAssetsWithValue.findIndex((asset) => asset.ticker === 'USD');
      const assets = [...getters.userAssetsWithValue];

      if (usdAssetIndex !== -1) {
        assets.splice(usdAssetIndex, 1);
      }

      return arrayMixin.methods.sortArrayOnProperty({ array: assets, property: 'total_value', ascending: false });
    },
    userFracPacks(state) {
      const fracpacks = Get(state, 'current_user.fracpacks', []);
      return fracpacks.filter((fracpack) => fracpack.state !== 'finalized');
    },
    userHoldings(state, getters) {
      const usdAssetIndex = getters.userAssetsWithValue.findIndex((asset) => asset.ticker === 'USD');
      const assets = [...getters.userAssetsWithValue];
      let usdAsset;

      if (usdAssetIndex !== -1) {
        usdAsset = assets.splice(usdAssetIndex, 1);
      }

      if (usdAsset) {
        return [...usdAsset, ...getters.userPlayers];
      }

      return getters.userPlayers;
    },
    userPacks(state) {
      return Get(state, 'current_user.packs', []).map(item => ({ ...item, type: 'pack' } ));
    },
    userPlayers(state, getters) {
      const players = getters.userAssetsWithValue.reduce((accum, asset) => {
        const player = { ...asset };

        if (player.ticker === 'USD') {
          return accum;
        }

        const playerFound = accum.findIndex((array) => {
          const arrayAsset = Get(array, 'base.associated_players[0].id', null);
          const playerAsset = Get(player, 'base.associated_players[0].id', null);
          if (arrayAsset && playerAsset) {
            return array.base['associated_players'][0].id === player.base['associated_players'][0].id;
          }
          return false;
        });

        const isDrop = !Get(player, 'base.pair', false) && Get(player, 'base.drops', []).length > 0;
        const playerAsset = {
          name: player.name,
          img_front: player.img_front,
          amount: player.amount,
          ticker: player.ticker,
          total_value: player.total_value,
          price_change_24h_pct: Get(player, 'base.pair.price_change_24h_pct', 0),
          dropAmountSold: isDrop ? Big(1).minus(Get(player, 'base.drops[0].supply_left', 1)).times(100) : undefined,
          routerLink: isDrop ? `/drop/${Get(player, 'base.drops[0].id')}` : `/trade/${player.ticker}-USD`,
          isDrop,
        };

        if (playerFound !== -1) {
          accum[playerFound].assets.push(playerAsset);
          accum[playerFound].total_player_value = accum[playerFound].total_player_value.plus(player.total_value);

          return accum;
        }

        player.assets = [];
        player.assets.push(playerAsset);
        player.showCards = false;
        player.total_player_value = Big(player.total_value);

        return [...accum, player];
      }, []);

      return arrayMixin.methods.sortArrayOnProperty({
        array: players,
        property: 'total_player_value',
        ascending: false
      });
    },
    userWatchlistAssets: (state) => (listName = 'favorites') => {
      const allWatchLists = Get(state, 'current_user.watch_lists', []);
      const watchList = allWatchLists.find((watchList) => watchList.name === listName);
      return Get(watchList, 'assets', []);
    },
    usdBalance(state) {
      return Number(Get(state, 'current_user.usd_balance.amount', 0));
    }
  },
};
