import { IconCircleDollar } from '@/assets/icons';
import CirclePaymentType from '@/enums/CirclePaymentType.js';

export const initialDepositMethods = [
  {
    icon: 'university',
    isRecommended: true,
    fee: 'No Fee',
    funds: 'Funds Available Instantly.',
    title: 'Link Bank Account',
    type: CirclePaymentType.ACH
  },
  {
    icon: 'credit-card',
    fee: 'No Fee',
    funds: 'Funds Available Instantly.',
    title: 'Credit/Debit Card',
    isRecommended: false,
    type: CirclePaymentType.CARD
  },
  {
    icon: 'exchange-alt',
    fee: '$15 Fee',
    funds: 'Funds Available  in 3 - 5 Business Days',
    title: 'Wire Transfer',
    isRecommended: false,
    type: CirclePaymentType.WIRE
  },
  {
    icon: IconCircleDollar,
    fee: 'No Fee',
    funds: 'Funds Available Instantly.',
    title: 'Send USDC',
    isRecommended: false,
    type: CirclePaymentType.BLOCKCHAIN
  }];

export const initialWithdrawMethods = [
  {
    icon: 'university',
    isRecommended: true,
    fee: 'No Fee',
    funds: 'Funds Available Instantly.',
    title: 'Link Bank Account',
    type: CirclePaymentType.ACH
  },
  {
    icon: 'exchange-alt',
    fee: '$15 Fee',
    funds: 'Funds Available in 3 - 5 Business Days',
    title: 'Wire Transfer',
    isRecommended: false,
    type: CirclePaymentType.WIRE
  },
  {
    icon: IconCircleDollar,
    fee: 'No Fee',
    funds: 'Funds Available Instantly.',
    title: 'Send USDC',
    isRecommended: false,
    type: CirclePaymentType.BLOCKCHAIN
},];
