import showdown from 'showdown';
const converter = new showdown.Converter();
export const markdownToHTML = (markdown) => converter.makeHtml(markdown);

/* How to use it

// ... in your i18n entries:
{
  "some_entry": "Some text with **markdown**. This is a [Link](https://www.google.com)"",
}

// ...inside component's script:
{
  const formatedText = markdownToHTML(this.$t('some_entry'));
  console.log(formatedText); // => "<p>Some text with <strong>markdown</strong>. This is a <a href="https://www.google.com">Link</a></p>"
}

// ...inside template:
<div v-html="formatedText"></div>
*/
