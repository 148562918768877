<template>
  <label class="switch">
    <input v-bind="$attrs" :data-testid="testId" type="checkbox" data-lpignore="true" :checked="checked" @change="$emit('update:checked', $event.target.checked)" :disabled="disabled">
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    testId: {
      type: String,
      default: null
    },
    checked: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<style scoped>
  /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 31px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  @apply border-2 flex;
  background: #999;
  border-color: #999;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  @apply bg-white;
  position: absolute;
  content: "";
  height: 27px;
  width: 27px;
  top: 0px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  
  @apply bg-background-active-nav border-border-active-nav;
}

input:focus + .slider {
  /* box-shadow: 0 0 0 #41DCB2; */
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 35px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
