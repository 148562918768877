import router from '@/vue-router';

router.addRoute({
  component: () => import('@/views/PrivacyPolicy'),
  name: 'privacy-policy',
  path: '/privacy-policy',
  meta: {
    template: 4,
  },
});
