<template>
  <section class="percentage-bar">
    <div v-if="isLoading" class="w-full flex" :style="{height}">
      <SkeletonLoading />
    </div>

    <div v-else class="percentage-bar-wrapper" :style="{height}">
      <div class="percentage-bar-background" :style="percentageBarStyle"></div>

      <div class="percentage-bar-label">
        {{text}}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PercentageBar',

  props: {
    isLoading: {
      type: Boolean,
      default: false
    },

    percentage: {
      type: Number,
      required: true
    },

    text: {
      type: String,
      required: false,
      default: ''
    },

    height: {
      type: String,
      default: '18px',
    }
  },

  computed: {
    percentageBarStyle(){
      return {
        width: `${this.percentage}%`
      };
    }
  }
};
</script>

<style scoped>
.percentage-bar-wrapper {
  @apply w-full relative rounded-full border border-border bg-background-page overflow-hidden;
}

.percentage-bar-background {
  @apply h-full rounded-full bg-background-positive-text;
}

.percentage-bar-label {
  @apply h-full absolute top-0 w-full text-n-xs font-bold flex justify-center items-center
}
</style>
