import router from '@/vue-router';
import MarketQuery from '@/graphql/queries/Market.gql';

router.addRoute({ 
  component: () => import('@/views/Market'),
  name: 'market',
  path: '/market',
  meta: {
    template: 2,
    query: MarketQuery,
    bypassLoading: true,
  }
});

router.addRoute({ 
  component: () => import('@/views/Market'),
  name: 'market-type',
  path: '/market/:type',
  meta: {
    template: 2,
    query: MarketQuery,
    bypassLoading: true,
  }
});

router.addRoute({ 
  component: () => import('@/views/Market'),
  name: 'market-sport-id',
  path: '/market/:sport_id_hr/:type',
  meta: {
    template: 2,
    query: MarketQuery,
    bypassLoading: true,
  }
});

router.addRoute({ 
  name: 'markets',
  path: '/markets',
  redirect: '/market',
});

router.addRoute({ 
  name: 'markets-type',
  path: '/markets/:type',
  redirect: '/market/:type',
});

router.addRoute({ 
  name: 'markets-sport-id',
  path: '/markets/:sport_id_hr/:type',
  redirect: '/market/:sport_id_hr/:type',
});
