<template> 
  <section class="fracpack-banner-background lg:-mx-s16 lg:px-s20">
    <div class="fracpack-banner-content flex lg:flex-col lg:px-s20 lg:py-s40">
      <div class="flex justify-center items-center not-mobile:py-s64 not-mobile:mr-s64 lg:mb-s24 relative">
        <BackgroundGlow class="fracpack-image-glow" filter="blur(100px)" width="60%" height="30%" />
        <img class="fracpack-image" :style="imageStyle" src="@/assets/img/fracpacks.webp" alt="fracpacks" width="501" height="374" />
      </div>
      <div class="flex flex-col justify-center relative">
        <h4 :class="headingClasses">{{$t('fracpack.home.title')}}</h4>
        <p class="body-text-large mt-s16">{{$t('fracpack.home.description')}}</p>
        <div class="flex mt-s24 lg:justify-center">
          <ButtonV2
            @onClick="goToExternalPageGlobal('/login')"
            :label="$tc('fracpack.home.button')"
            version="primary"
            testId="btn-home"
            size="medium" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { BackgroundGlow } from '@/components/misc';
import ButtonV2 from '@/stories/misc/ButtonV2';

export default {
  name: 'FracPackHomeSection',
  components: {
    BackgroundGlow,
    ButtonV2,
  },
  computed: {
    imageStyle() {
      return {
        'max-width': this.isMobileDevice ? '100%' : 'fit-content'
      };
    },
    headingClasses(){
      return {
        'headline-large': !this.isMobileDevice,
        'headline-small': this.isMobileDevice,
      };
    },
  }
};
</script>

<style scoped>
.fracpack-banner-background {
  @apply flex justify-center items-center overflow-hidden relative;
}

.fracpack-banner-background:before {
  content: '';
  background: url("~@/assets/img/fracpack-banner-home-bg.webp");
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0;
  background-size: cover;
}

.fracpack-image {
  @apply relative;
  z-index: 10;
}

.fracpack-image-glow{
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.fracpack-banner-content{
  max-width: 950px;
}
</style>
