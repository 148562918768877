<template>
  <footer class="w-full">
    <div class="flex justify-center">

      <div :class="footerContentClasses">
        <div class="flex sm:justify-center sm:w-full">
          <MainLogo :darkMode="currentTheme === 'dark'" />
        </div>
        <div class="flex lg:justify-between w-full gap-s16">
          <template v-for="section in links">
            <div :key="section.title" class="lg:w-auto w-full">
              <h5 class="subheadline-small mb-s16">
                {{section.title}}
              </h5>
              <ul class="flex flex-col gap-s12">
                <template v-for="link in section.values">
                  <li
                    v-if="link.active"
                    class="body-text-medium"
                    :key="link.label">
                    <router-link v-if="link.internalTo" :to="link.internalTo" role="link">
                      {{link.label}}
                    </router-link>
                    <a v-else-if="link.to" :href="link.to" rel="noopener noreferrer">
                      {{link.label}}
                    </a>
                    <button
                      v-else-if="link.onClick"
                      class="body-text-medium footer-button"
                      @click="link.onClick">
                      {{link.label}}
                    </button>
                  </li>
                </template>
              </ul>
            </div>
          </template>
        </div>

        <div class="flex flex-col w-full gap-s16">
          <h5 class="subheadline-small">
            {{$t('footer.join_our_community_section.title')}}
          </h5>
          <div class="flex sm:flex-no-wrap lg:flex-wrap gap-s24">
            <template v-for="item in icons">
              <IconButton 
                :key="item.type"
                class="text-n-3xl"
                icon-style="fab"
                :icon="item.type"
                variation="plain"
                @onClick="goToExternalPageGlobal(item.to)"
              />
            </template>
          </div>
        </div>
      </div>

    </div>

    <div class="flex border-t border-border justify-center py-s16 w-full">
      <p class="body-text-medium">
        {{$t('copyright', { year })}}
      </p>
    </div>

  </footer>
</template>
<script>
import IconButton from '@/components/misc/IconButton/IconButton';
import MainLogo from '@/stories/misc/MainLogo';

export default {
  name: 'Footer',
  components: {
    IconButton,
    MainLogo
  },

  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    footerContentClasses(){
      return {
        'footer-content-holder--desktop py-s48 gap-s40': !this.isMobileDevice,
        'footer-content-holder--medium py-s48 px-s20 gap-s40': !this.isSmallMobileDevice && this.isMobileDevice,
        'footer-content-holder--small p-s48 gap-s40': this.isSmallMobileDevice,
      };
    },

    links(){
      return [
        {
          title: this.$t('footer.company_section.title'),
          values: [
            { label: this.$t('footer.company_section.about_us'), internalTo: '/about-us', active: true },
            { label: this.$t('footer.company_section.jobs'), to: 'https://jobs.dibbs.io/ ', active: true },
            { label: this.$t('footer.company_section.feedback'), onClick: this.openFeedbackModal, active: true },
            { label: this.$t('footer.company_section.terms'), internalTo: '/user-agreement', active: true },
            { label: this.$t('footer.company_section.privacy'), internalTo: '/privacy-policy', active: true },
          ]
        },
        {
          title: this.$t('footer.learn_dibbs_section.title'),
          values: [
            { label: this.$t('footer.learn_dibbs_section.dibbs_academy'), to: 'https://blog.dibbs.io/', active: true },
            { label: this.$t('footer.learn_dibbs_section.press'), to: 'mailto:press@dibbs.io', active: true },
            { label: this.$t('footer.learn_dibbs_section.support'), to: 'https://help.dibbs.io/', active: true },
            { label: this.$t('footer.learn_dibbs_section.faq'), to: 'https://help.dibbs.io/en/collections/3166834-basics', active: true },
            { label: this.$t('footer.learn_dibbs_section.partnership'), to: 'https://dibbs.io/partnerships', active: true },
          ]
        }
      ];
    },

    icons(){
      return [
        {
          type: 'discord',
          to: 'https://discord.gg/bUwf3wF8ZM'
        },
        {
          type: 'twitter',
          to: 'https://twitter.com/dibbs_io'
        },
        {
          type: 'instagram',
          to: 'https://www.instagram.com/dibbs.io'
        },
        {
          type: 'facebook-f',
          to: 'https://www.facebook.com/dibbs.io'
        },
        {
          type: 'twitch',
          to: 'https://www.twitch.tv/dibbsio'
        },
      ];
    },
  },

  methods: {
    openFeedbackModal(){
      if (this.isAuthenticated()) {
        return this.showModal('SubmitFeedback');
      }
      return this.goToInternalPageGlobal('/login');
    }
  },
};
</script>
<style scoped>
footer {
  @apply bg-background-primary border-t border-border flex flex-col;
}

.footer-content-holder--desktop {
  display: grid;
  grid-template-columns: 150px 1fr .7fr 1fr;
  max-width: 1239px;
  width: 100%;
}

.footer-content-holder--medium {
  display: grid;
  grid-template-columns: min-content repeat(2, 1fr) min-content;
  width: 100%;
}

.footer-content-holder--small {
  display: grid;
  /* TODO: Revert this change when get free cash is released */
  grid-template-rows: min-content 1fr min-content;
  width: 100%;
}

.footer-button {
  outline: 0;
}
</style>
