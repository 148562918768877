import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faCaretDown,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faCreditCard,
  faDollarSign,
  faEdit,
  faExchangeAlt,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faSearchPlus,
  faShareAlt,
  faStar,
  faSync,
  faTrashAlt,
  faUniversity,
  faEnvelope,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendar as faCalendarRegular,
  faCircle as faCircle,
  faStar as faStarRegular,
  faCheckCircle as faCheckCircleRegular,
} from '@fortawesome/free-regular-svg-icons';

import {
  faApple as faAppleBrand,
  faDiscord,
  faGoogle as faGoogleBrand,
  faInstagram,
  faFacebookF,
  faTwitch,
  faTwitter,
  faFacebook,
  faCcVisa,
  faCcMastercard,
} from '@fortawesome/free-brands-svg-icons';

const faIcons = {
  faAppleBrand,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faCheckCircleRegular,
  faCircle,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faCreditCard,
  faDollarSign,
  faDiscord,
  faEdit,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faGoogleBrand,
  faInfoCircle,
  faInstagram,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faFacebookF,
  faCalendarRegular,
  faSearchPlus,
  faSync,
  faShareAlt,
  faUniversity,
  faExchangeAlt,
  faStar,
  faStarRegular,
  faTrashAlt,
  faTwitch,
  faTwitter,
  faFacebook,
  faEnvelope,
  faTimes,
  faCcVisa,
  faCcMastercard,
};

library.add(faIcons);
Vue.component('font-awesome-icon', FontAwesomeIcon);

export default faIcons;
