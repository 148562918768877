import store from '@/store';

const modals = {
  methods: {
    showModal: function (currentModal, currentModalProps) {
      store.commit('ui/showModal', { currentModal, currentModalProps });
    },
    hideModal: function () {
      store.commit('ui/hideModal');
    },
    openAssetViewer(imgFront, imgBack) {
      this.showModal('AssetViewer', {imgFront, imgBack});
    },
  },
};

export default modals;
