<template>
  <span>{{numberFormat(displayNumber, decimalPlaces, signal, currency)}}</span>
</template>

<script>

export default {
  name: 'AnimatedNumber',
  data () {
    return {
      displayNumber: this.$big(0),
      bigNumber: this.$big(this.number),
      counter: false
    };
  },
  props: {
    number: {
      type: [Number, String],
      default: 0,
    },
    decimalPlaces: {
      type: Number,
      default: 2,
    },
    signal: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    number () {
      this.bigNumber = this.$big(this.number);
      this.animateNumber();
    },
  },

  mounted() {
    this.animateNumber();
  },

  methods: {
    animateNumber() {
      clearInterval(this.counter);
      if (this.bigNumber.eq(this.displayNumber)) {
        return;
      }
      this.counter = setInterval(function () {
        if (this.bigNumber.minus(this.displayNumber).abs().gte(0.05)) {
          const change = (this.bigNumber.minus(this.displayNumber)).div(5);
          this.displayNumber = this.displayNumber.plus(change);
        } else {
          this.displayNumber = this.bigNumber;
          clearInterval(this.counter);
        }
      }.bind(this), 20);
    }
  }
};
</script>

<style scoped>

</style>