<template>
  <div v-if="globalIsLoading && !title">
    <SkeletonLoading width="100%" :height="loadingHeight" />
  </div>
  <div v-else>
    <div class="headline-medium">
      {{ title }}
    </div>
    <div class="subheadline-x-medium">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    loadingHeight: {
      type: String,
      default: '49px'
    },
    title: String,
    subtitle: String,
  }
};
</script>

<style scoped>

</style>
