<template>
  <section class="mobile-bottom-menu">
    <router-link
      v-for="menuItem in mobileNavbar"
      :key="'mobile-sidebar-' + menuItem.key"
      :data-cy="menuItem.key"
      :data-test-i="menuItem.key"
      role="link"
      :to="menuItem.to"
      :name="menuItem.home"
      class="menu-item"
      :class="{ selected: menuItem.name === sidebarSelectedItem }"
    >
      <div class="flex justify-between items-center flex-col">
        <component :is="menuItem.icon" class="menu-icon"/>
        <span class="body-text-x-small mt-s4">{{ $tc(menuItem.label) }}</span>
      </div>
    </router-link>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import {
  IconHome,
  IconDrops,
  IconHoldings,
  IconMarket,
  IconWallet,
} from '@/assets/icons';

import Menu from '../Menu.js';

export default {
  name: 'MobileBottomMenu',
  extends: Menu,
  components: {
    IconHome,
    IconDrops,
    IconHoldings,
    IconMarket,
    IconWallet,
  },

  computed: {
    ...mapState('ui', [
      'sidebarSelectedItem',
    ]),
  }
};
</script>

<style scoped>
  .mobile-bottom-menu {
    @apply flex justify-around items-center h-17 w-full border-t border-border bg-background-primary fixed bottom-0;
    z-index: 20;
  }
  .menu-item {
    @apply flex justify-start cursor-pointer font-semibold opacity-30;
    transition: opacity 250ms;
  }
  .selected {
    @apply opacity-100;
  }
  .menu-icon {
    width: 28px;
    height: 28px;
  }
</style>
