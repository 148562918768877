<template>
  <div>
    <router-link v-if="!dropdownItem.action" class="dropdown-item hover:opacity-80" :to="dropdownItem.linkTo">
      <component class="dropdown-icon" :class="[dropdownItem.iconClasses]" v-if="dropdownItem.icon" :is="dropdownItem.icon"></component>
      <span class="whitespace-no-wrap dropdown-text subheadline-small">{{dropdownItem.label}}</span>
      <font-awesome-icon v-if="dropdownItem.faIcon" class="ml-s4" role="icon" :icon="['fas', dropdownItem.faIcon]"></font-awesome-icon>
    </router-link>
    <span v-else class="dropdown-item hover:opacity-80" @click="action">
      <component class="dropdown-icon" :class="[dropdownItem.iconClasses]" v-if="dropdownItem.icon" :is="dropdownItem.icon"></component>
      <span class="whitespace-no-wrap dropdown-text subheadline-small">{{dropdownItem.label}}</span>
      <font-awesome-icon v-if="dropdownItem.faIcon" class="ml-s4" role="icon" :icon="['fas', dropdownItem.faIcon]"></font-awesome-icon>
    </span>
  </div>
</template>

<script>
import Settings from '@/assets/icons/settings.svg';
import Identity from '@/assets/icons/identity_v2.svg';
import ReferFriends from '@/assets/icons/refer_friends.svg';
import GetFracPack from '@/assets/icons/get_frack_pack.svg';
import SellCard from '@/assets/icons/sell_with_dibbs.svg';
import Support from '@/assets/icons/support_v2.svg';
import Logout from '@/assets/icons/logout_v2.svg';
import Feedback from '@/assets/icons/feedback_v2.svg';

export default {
  name: 'DropdownItem',
  props: {
    dropdownItem: Object,
    selectedItem: Object,
  },
  components: {
    Feedback,
    GetFracPack,
    Identity,
    Logout,
    ReferFriends,
    SellCard,
    Settings,
    Support,
  },
  methods: {
    async action() {
      this.$emit('onDropdownAction', this.dropdownItem);
    },
  },
};
</script>

<style scoped>
  .dropdown-item {
    @apply flex items-center py-s12 cursor-pointer;
  }

  .dropdown-icon {
    @apply object-contain;
    margin-right: 18px;
    height: 28px;
    width: 28px;
    fill: var(--colors-text-active-2);
  }

  i {
    background-size: 20px 20px;
  }

  .dropdown-text {
    @apply text-text-body;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
  }
</style>
