import { v4 as uuidv4 } from 'uuid';
const SIFT = window._sift = window._sift || [];
const VueSift = {};

const ENTRIES = {
  SET_ACCOUNT: '_setAccount',
  SET_USER: '_setUserId',
  SET_SESSION: '_setSessionId',
  TRACK_PAGEVIEW: '_trackPageview'
};

VueSift.install = (Vue) => {

  Vue.prototype.$sift = {
    loadScript() {
      let e = document.createElement('script');
      e.src = 'https://cdn.sift.com/s.js';
      document.body.appendChild(e);
    },

    setData(key, value) {
      if(!key) {return;}
      SIFT.push([key, value]);
    },
    
    setAccount() {
      this.setData(ENTRIES.SET_ACCOUNT, process.env.VUE_APP_SIFT_BEACON_KEY);
    },

    setUser(user_id) {
      const user = user_id || '';
      this.setData(ENTRIES.SET_USER, user);
    },

    setSession() {
      this.setData(ENTRIES.SET_SESSION, uuidv4());
    },

    setPageview() {
      SIFT.push([ENTRIES.TRACK_PAGEVIEW]);
    },

    start() {
      this.setAccount();
      this.setSession();
      this.setUser();
      this.loadScript();
    }
  };
};

export default VueSift;
