import Get from 'lodash.get';

import CURRENT_USER_ASSETS from '@/graphql/queries/CurrentUserAssets.gql';
import WATCHLIST_REMOVE_ASSET from '@/graphql/mutations/WatchlistRemoveAsset.gql';

import {
  SET_PAIRS,
  SET_CURRENT_PAIR,
  SET_USER_ASSETS,
  SET_USER_PACKS,
  SET_USER_FRACPACKS,
  SET_USER_WATCHLIST
} from './constants';

const ROOT = { root: true };

export default {
  setCurrentPair({ commit }, payload){
    commit(SET_CURRENT_PAIR, payload);
  },
  setPairs({ commit }, payload){
    commit(SET_PAIRS, payload);
  },
  updateUserAssets({ commit }, payload){
    commit(SET_USER_ASSETS, payload);
  },
  updateUserPacks({ commit }, payload){
    commit(SET_USER_PACKS, payload);
  },
  updateUserFracPacks({ commit }, payload){
    commit(SET_USER_FRACPACKS, payload);
  },
  updatePackState({ dispatch, state }, { packId, packState }) {
    const packs = [...state.packs];
    const packIndex = packs.findIndex((pack) => pack.id === packId);

    if (packIndex === -1) {
      return;
    }

    packs[packIndex] = {
      ...packs[packIndex],
      state: packState,
    };
    dispatch('updateUserPacks', packs);
  },
  updateFracPackState({ dispatch, state }, { fracpackId, fracpackState }) {
    const fracpacks = [...state.fracpacks];
    const fracpackIndex = fracpacks.findIndex((fracpack) => fracpack.id === fracpackId);

    if (fracpackIndex === -1) {
      return;
    }

    fracpacks[fracpackIndex] = {
      ...fracpacks[fracpackIndex],
      state: fracpackState,
    };
    dispatch('updateUserFracPacks', fracpacks);
  },

  updateUserWatchlist({ commit }, payload){
    commit(SET_USER_WATCHLIST, payload);
  },

  async removeFromWatchlist({ state, commit, dispatch }, id){
    await dispatch('api/apolloClient', {
      mutation: WATCHLIST_REMOVE_ASSET,
      variables: {
        asset_id: id,
        list_name: 'favorites',
      },
    });

    const updateWatchlist = state.watchlist.filter(item => item.id === id);

    commit(SET_USER_WATCHLIST, updateWatchlist);
  },

  async fetchUserAssets({ state, dispatch }, { count, offset, sort, }) {
    const response = await dispatch('api/apolloClient', {
      type: 'query',
      options: {
        query: CURRENT_USER_ASSETS,
        variables: {
          count,
          offset,
          sort,
        }
      }
    }, ROOT);

    const fetchedAssets = Get(response, 'data.current_user.assets', []);

    const assets = [...state.assets, ...fetchedAssets];

    dispatch('updateUserAssets', assets);
  }
};
