<template>
  <transition name="banner-animation">
    <div
      v-if="banners.length"
      class="banner-container xl:p-s16"
      @mouseover="isHovering = true"
      @mouseleave="isHovering = false"
      :style="{ height: isMobileDevice ? 'auto' : '88px' }"
    >
      <transition name="banner-animation-slide" mode="out-in">
        <div class="flex items-center" v-bind:key="currentBanner.name">
          <div class="banner-icon-container mr-s24 md:mr-s16" :class="{ 'banner-icon-container-mobile': isMobileDevice }">
            <component :is="currentBanner.icon" class="absolute"/>
          </div>

          <div>
            <div :class="isMobileDevice ? 'subheadline-small' : 'subheadline-large'">
              {{ $t(currentBanner.title) }}
            </div>
            <div :class="isMobileDevice ? 'body-text-medium' : 'body-text-large'">
              {{$t(currentBanner.subTitle)}}
              <span class="text-text-active-2 underline link-text hover:opacity-70" @click="actionUrlClicked()">
                {{$t(currentBanner.actionLabel)}}.
              </span>
            </div>
          </div>
        </div>
      </transition>

      <div
        data-testid="button-close"
        class="banner-close-container link-text hover:opacity-50"
        :class="{ 'banner-close-container-mobile': isMobileDevice }"
        @click="close(null)"
      >
        <IconClose class="banner-close fill-current" />
      </div>
    </div>
  </transition>
</template>

<script>
import { BackgroundGlow } from '@/components/misc';
import { IconClose } from '@/assets/icons';

export default {
  name: 'Banner',
  components: {
    BackgroundGlow,
    IconClose
  },

  computed: {
    currentBanner() {
      if (!this.banners[this.currentBannerIndex]) {
        this.nextBanner();
      }
      return this.banners[this.currentBannerIndex];
    },
  },

  data: function () {
    return {
      currentBannerIndex: 0,
      expiresAt: null,
      isHovering: false,
    };
  },

  methods: {
    async actionUrlClicked(){
      await this.$store.dispatch('events/track', {
        event: this.currentBanner.amplitudeEvent,
      });
      this.goToExternalPageGlobal(this.currentBanner.actionUrl);
      this.close(this.currentBanner.name);
    },

    close(name = null) {
      if (!name) {
        this.$emit('closeAllBanners');
      } else {
        this.$emit('closeBanner', name);
      }
    },

    handleExpiration() {
      this.expiresAt = Date.now() + this.expiresAfter;
      setTimeout(() => {
        // todo investigate this logic -- it is not working properly on storybook at least
        if (Date.now() >= this.expiresAt && !this.isHovering) {
          this.nextBanner();
        }
        this.handleExpiration();
      }, this.expiresAfter);
    },

    nextBanner() {
      if (this.currentBannerIndex + 1 >= this.banners.length) {
        this.currentBannerIndex = 0;
      } else {
        this.currentBannerIndex = this.currentBannerIndex + 1;
      }
    }
  },

  mounted() {
    this.handleExpiration();
  },

  props: {
    banners: Array,
    expiresAfter: {
      type: Number,
      default: 6000,
    },
  },
};
</script>

<style scoped>
  .banner-container {
    @apply
      relative
      flex
      justify-center
      items-center
      bg-background-positive-text
      border-t
      border-border-active-nav;
  }

  .banner-icon-container {
    @apply
      relative
      flex
      justify-center
      items-center
      flex-shrink-0;
    height: 48px;
    width: 48px;
  }

  .banner-icon-container-mobile {
    height: 38px;
    width: 38px;
  }

  .banner-close-container {
    @apply absolute;
    top: 17px;
    right: 17px;
  }

  .banner-close-container-mobile {
    top: 12px;
    right: 12px;
  }

  .banner-close {
    width: 14px;
    height: 14px;
  }

  .banner-animation-enter-active, .banner-animation-leave-active {
    transition: all 500ms;
    overflow: hidden;
    max-height: 120px;
  }
  .banner-animation-enter, .banner-animation-leave-to {
    @apply py-0;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
  }

  .banner-animation-slide-enter-active {
    transition: all 600ms ease-out;
  }
  .banner-animation-slide-leave-active {
    transition: all 300ms ease-in;
  }
  .banner-animation-slide-enter {
    opacity: 0;
    transform: translateX(-35%);
  }
  .banner-animation-slide-leave-to {
    opacity: 0;
    transform: translateX(35%);
  }
</style>
