import Get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import cloneDeep from 'lodash.clonedeep';
import encryptCircleData from '@/utils/encrypt-circle-data';
import CirclePaymentType from '@/enums/CirclePaymentType';
import TransactionHistory from '@/enums/TransactionHistory';

import CIRCLE_AVAILABLE_DEPOSIT_CHAINS from '@/graphql/queries/CircleAvailableDepositChains.gql';
import CIRCLE_CREATE_ACH_DEPOSIT_METHOD from '@/graphql/mutations/CircleCreateAchDepositMethod.gql';
import CIRCLE_CREATE_CHAIN_DEPOSIT_ADDRESS from '@/graphql/mutations/CircleCreateChainDepositAddress.gql';
import CIRCLE_CREATE_CREDIT_CARD from '@/graphql/mutations/CircleCreateCreditCard.gql';
import CIRCLE_CREATE_LINK_TOKEN from '@/graphql/mutations/CircleCreateLinkToken.gql';
import CIRCLE_CREATE_USA_WIRE from '@/graphql/mutations/CircleCreateUsaWire.gql';
import CIRCLE_FINISH_CARD_DEPOSIT from '@/graphql/mutations/CircleFinishCardDeposit.gql';
import CIRCLE_GET_PUBLIC_KEY from '@/graphql/mutations/CircleGetPublicKey.gql';
import CIRCLE_INITIATE_ACH_DEPOSIT from '@/graphql/mutations/CircleInitiateAchDeposit.gql';
import CIRCLE_INITIATE_CREDIT_CARD_DEPOSIT from '@/graphql/mutations/CircleInitiateCreditCardDeposit.gql';
import CIRCLE_REQUEST_ACH_SIGNATURE from '@/graphql/mutations/CircleRequestAchSignature.gql';
import CIRCLE_SIGN_ACH_SIGNATURE from '@/graphql/mutations/CircleSignAchSignature.gql';
import CIRCLE_AVAILABLE_WITHDRAWAL_CHAINS from '@/graphql/queries/CircleAvailableWithdrawalChains.gql';
import CIRCLE_WITHDRAWAL_ACH from '@/graphql/mutations/CircleWithdrawalAch.gql';
import CIRCLE_WITHDRAWAL_CHAIN from '@/graphql/mutations/CircleWithdrawalChain.gql';
import CIRCLE_WITHDRAWAL_RESEND_AUTHORIZATION_EMAIL from '@/graphql/mutations/CircleWithdrawalResendAuthorizationEmail.gql';
import CIRCLE_WITHDRAWAL_WIRE from '@/graphql/mutations/CircleWithdrawalWire.gql';
import DEPOSIT_LIMITS from '@/graphql/queries/DepositLimits.gql';
import DEPOSIT_METHODS from '@/graphql/queries/DepositMethods.gql';
import GET_WIRE_INSTRUCTIONS from '@/graphql/queries/GetWireInstructions.gql';
import WITHDRAWAL_METHODS from '@/graphql/queries/GetCircleWithdrawalMethods.gql';
import SINGLE_TRANSACTION_HISTORY from '@/graphql/queries/TransactionHistory/SingleTransactionHistory.gql';
import CANCEL_ORDER from '@/graphql/mutations/CancelOrder.gql';

import {
  RESET_USDC_DEPOSIT,
  SET_CHART_VALUES,
  SET_CIRCLE_PUBLIC_KEY,
  SET_DEPOSIT_AMOUNT,
  SET_LINKED_DEPOSIT_METHODS,
  SET_LINKED_WITHDRAWAL_METHODS,
  SET_ONGOING_DEPOSIT_DATA,
  SET_ONGOING_WITHDRAW_DATA,
  SET_PLAID_TOKEN,
  SET_USDC_CHAINS,
  SET_USDC_DEPOSIT_ADDRESS,
  SET_USDC_DEPOSIT_CHAIN,
  SET_USDC_WITHDRAW_ADDRESS,
  SET_USDC_WITHDRAW_ADDRESS_TAG,
  SET_USDC_WITHDRAW_CHAIN,
  SET_WITHDRAW_AMOUNT,
  SET_DEPOSIT_LIMITS,
  SET_PAYMENT_METHODS,
  SET_LAST_WITHDRAW_ID,
  SET_PROFIT_LOSS,
  RESET_TRANSACTION_HISTORY,
  SET_SINGLE_TRANSACTION,
  SET_INVENTORY_CHANGE
} from './mutations';

const ROOT = { root: true };

export default {
  setBalanceChart({ commit }, payload) {
    commit(SET_CHART_VALUES, payload);
  },

  setInventoryChange({ commit }, payload) {
    commit(SET_INVENTORY_CHANGE, payload);
  },

  setProfitLoss({ commit }, payload) {
    commit(SET_PROFIT_LOSS, payload);
  },

  async createCreditCard({ state, dispatch }, { cardHolderName, card, monthExpire, yearExpire, }) {
    try {
      await dispatch('circleGetPublicKey');

      if (isEmpty(state.currentCirclePublicKey)) { return; }

      const { public_key, key_id: keyId } = state.currentCirclePublicKey;
      const encryptedCardData = await encryptCircleData(public_key, card);

      await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CIRCLE_CREATE_CREDIT_CARD,
          variables: {
            cardHolderName,
            encryptedCardData,
            keyId,
            monthExpire,
            yearExpire,
          },
        }
      }, ROOT);
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
      throw err;
    }
  },

  async circleGetPublicKey({ commit, dispatch }) {
    const response = await dispatch('api/apolloClient', {
      type: 'mutate',
      options: {
        mutation: CIRCLE_GET_PUBLIC_KEY,
      }
    }, ROOT);

    commit(SET_CIRCLE_PUBLIC_KEY, response.data.circle_get_public_key);
  },

  async requestAchSignature({ commit, dispatch }, { achId, amount }) {
    const response = await dispatch('api/apolloClient', {
      type: 'mutate',
      options: {
        mutation: CIRCLE_REQUEST_ACH_SIGNATURE,
        variables: {
          ach_id: achId,
          deposit_amount: amount,
        },
      }
    }, ROOT);
    commit(SET_ONGOING_DEPOSIT_DATA, {
      requestAchSignature: response.data.circle_request_ach_signature_to_sign_v2,
    });
  },

  async handleAchDeposit({ state, commit, dispatch }) {
    try {
      await dispatch('signAchSignature');
      const response = await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CIRCLE_INITIATE_ACH_DEPOSIT,
          variables: {
            achId: Get(state, 'ongoingDepositData.methodData.id', ''),
            signatureId: Get(state, 'ongoingDepositData.requestAchSignature.id', ''),
            amount: Get(state, 'ongoingDepositData.amount', 0),
          }
        }
      }, ROOT);
      if (response.data) {
        const finishData = response.data.circle_initiate_ach_deposit;
        await commit('ui/showModal', {
          currentModal: 'WalletTransferSuccess',
          currentModalProps: {
            noPadding: true,
            isClosable: false,
            amount: finishData.amount,
            depositStatus: finishData.depositStatus,
            method: Get(state, 'ongoingDepositData.methodData', {}),
          },
        }, ROOT);
      }
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
      throw err;
    }
  },

  async openConfirmWithdrawal({ commit }, id) {
    await commit(SET_LAST_WITHDRAW_ID, id);
    await commit('ui/showModal', {
      currentModal: 'WalletResendWithdrawalConfirmation',
      currentModalProps: {
        noPadding: true,
        isClosable: false,
      },
    }, ROOT);
  },

  async handleAchWithdraw({ state, dispatch }) {
    try {
      const response = await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CIRCLE_WITHDRAWAL_ACH,
          variables: {
            achId: Get(state, 'ongoingWithdrawData.methodData.id', ''),
            amount: Get(state, 'ongoingWithdrawData.amount', 0),
          }
        }
      }, ROOT);

      if (response.data) {
        const finishData = response.data.circle_withdrawal_ach;
        dispatch('openConfirmWithdrawal', finishData.id);
      }
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
      throw err;
    }
  },

  async handleWireWithdraw({ state, dispatch }) {
    try {
      const response = await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CIRCLE_WITHDRAWAL_WIRE,
          variables: {
            wireId: Get(state, 'ongoingWithdrawData.methodData.id', ''),
            amount: Get(state, 'ongoingWithdrawData.amount', 0),
          }
        }
      }, ROOT);

      if (response.data) {
        const finishData = response.data.circle_withdrawal_wire;
        dispatch('openConfirmWithdrawal', finishData.id);
      }
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
      throw err;
    }
  },

  async signAchSignature({ state, dispatch }) {
    await dispatch('api/apolloClient', {
      type: 'mutate',
      options: {
        mutation: CIRCLE_SIGN_ACH_SIGNATURE,
        variables: {
          signatureId: Get(state, 'ongoingDepositData.requestAchSignature.id', ''),
        },
      }
    }, ROOT);
  },

  async retrieveLinkedDepositMethods({ commit, dispatch }) {
    try {
      const response = await dispatch('api/apolloClient', {
        type: 'query',
        options: {
          query: DEPOSIT_METHODS,
          fetchPolicy: 'no-cache',
        }
      }, ROOT);

      commit(SET_LINKED_DEPOSIT_METHODS, response.data.current_user.deposit_methods);

    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
      throw err;
    }
  },

  async retrieveLinkedWithdrawalMethods({ commit, dispatch }) {
    try {
      const response = await dispatch('api/apolloClient', {
        type: 'query',
        options: {
          query: WITHDRAWAL_METHODS,
          fetchPolicy: 'no-cache',
        }
      }, ROOT);
      commit(SET_LINKED_WITHDRAWAL_METHODS, response.data.current_user.withdrawal_methods);
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
      throw err;
    }
  },

  async finishCardDeposit({ commit, dispatch, state }, dataFrom3ds) {
    try {
      const {
        id: payment_id,
        succeeded: successful_3ds
      } = dataFrom3ds;

      const response = await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CIRCLE_FINISH_CARD_DEPOSIT,
          variables: {
            payment_id,
            successful_3ds,
          },
        }
      }, ROOT);

      if (response.data) {
        const finishData = response.data.finish_credit_card_deposit;
        await commit('ui/showModal', {
          currentModal: 'WalletTransferSuccess',
          currentModalProps: {
            noPadding: true,
            isClosable: false,
            amount: finishData.amount,
            depositStatus: finishData.depositStatus,
            method: Get(state, 'ongoingDepositData.methodData', {}),
          }
        }, ROOT);
      }
    } catch (err) {
      commit('ui/showModal', {
        currentModal: 'PaymentInterface',
        currentModalProps: { isDeposit: true },
      }, ROOT);
      dispatch('ui/showError', err, ROOT);
    }
  },

  async getTokenForPlaid({ commit, dispatch }) {
    try {
      const response = await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CIRCLE_CREATE_LINK_TOKEN,
        }
      }, ROOT);

      commit(SET_PLAID_TOKEN, response.data.circle_create_link_token.result);
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
    }
  },

  async handleCard3ds({ state, commit, dispatch }, cvv) {
    try {
      const ongoingDepositData = cloneDeep(state.ongoingDepositData);

      await dispatch('circleGetPublicKey');
      if (isEmpty(state.currentCirclePublicKey)) { return; }

      const { public_key, key_id } = state.currentCirclePublicKey;
      const encrypted_card_data = await encryptCircleData(public_key, { cvv });
      const { methodData, amount } = state.ongoingDepositData;

      const response = await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CIRCLE_INITIATE_CREDIT_CARD_DEPOSIT,
          variables: {
            card_id: methodData.id,
            encrypted_card_data,
            key_id,
            amount,
          },
        }
      }, ROOT);

      if (response.data) {
        ongoingDepositData.initResult = response.data.circle_initiate_credit_card_deposit;
        await commit(SET_ONGOING_DEPOSIT_DATA, ongoingDepositData);
        await commit('ui/showModal', { currentModal: 'Card3ds' }, ROOT);
      }
    } catch (err) {
      commit('ui/showModal', {
        currentModal: 'PaymentInterface',
        currentModalProps: { isDeposit: true },
      }, ROOT);
      dispatch('ui/showError', err, ROOT);
    }
  },

  async handleDepositFormSubmit({ commit, dispatch }, ongoingDepositData) {
    commit(SET_ONGOING_DEPOSIT_DATA, ongoingDepositData);
    try {
      switch (ongoingDepositData.methodData.type) {
        case CirclePaymentType.ACH:
          await dispatch('requestAchSignature', {
            achId: ongoingDepositData.methodData.id,
            amount: ongoingDepositData.amount,
          });
          commit('ui/showModal', {
            currentModal: 'AchOverview',
          }, ROOT);
          break;
        case CirclePaymentType.BLOCKCHAIN:
          commit('ui/showModal', {
            currentModal: 'DepositUsdc',
            currentModalProps: {
              amount: ongoingDepositData.amount,
            },
          }, ROOT);
          break;
        case CirclePaymentType.CARD:
          commit('ui/showModal', {
            currentModal: 'CardCcvConfirmation',
          }, ROOT);
          break;
        case CirclePaymentType.WIRE:
          commit('ui/showModal', {
            currentModal: 'WalletWireTransferDetails',
            currentModalProps: {
              wireId: ongoingDepositData.methodData.id,
            },
          }, ROOT);
          break;
        default:
          return;
      }
    } catch (err) {
      await dispatch('ui/hideModal', null, ROOT);
      await dispatch('ui/showError', err, ROOT);
    }
  },

  async handleWithdrawFormSubmit({ commit, dispatch }, ongoingWithdrawData) {
    commit(SET_ONGOING_WITHDRAW_DATA, ongoingWithdrawData);
    switch (ongoingWithdrawData.methodData.type) {
      case CirclePaymentType.ACH:
        await dispatch('handleAchWithdraw');
        break;
      case CirclePaymentType.BLOCKCHAIN:
        commit('ui/showModal', {
          currentModal: 'WithdrawUsdc',
          currentModalProps: {
            amount: ongoingWithdrawData.amount,
          },
        }, ROOT);
        break;
      case CirclePaymentType.WIRE:
        await dispatch('handleWireWithdraw');
        break;
      // todo implement other withdrawal methods
      default:
        return;
    }
  },

  async postCreateAchDepositMethod({ dispatch }, variables) {
    try {
      return dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CIRCLE_CREATE_ACH_DEPOSIT_METHOD,
          variables
        }
      }, ROOT);
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
      throw err;
    }
  },

  async postCreateUsaWire({ dispatch }, variables) {
    try {
      const response = await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CIRCLE_CREATE_USA_WIRE,
          variables
        }
      }, ROOT);

      if (response.data) {
        return response.data.circle_create_usa_wire;
      }
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
    }
  },

  async getWireInstructions({ dispatch }, variables) {
    try {
      const response = await dispatch('api/apolloClient', {
        type: 'query',
        options: {
          query: GET_WIRE_INSTRUCTIONS,
          variables
        }
      }, ROOT);

      if (response.data) {
        return response.data.get_wire_instructions;
      }
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
    }
  },

  async loadPaymentLimits({ dispatch, commit }) {
    try {
      const response = await dispatch('api/apolloClient', {
        type: 'query',
        options: {
          query: DEPOSIT_LIMITS
        }
      }, ROOT);

      if (response.data) {
        commit(SET_PAYMENT_METHODS, { deposit_limits: response.data.current_user.deposit_limits, permissions: response.data.current_user.permissions });
        commit(SET_DEPOSIT_LIMITS, { deposit_limits: response.data.current_user.deposit_limits, permissions: response.data.current_user.permissions });
      }
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
    }
  },

  setDepositAmount({ commit }, payload) {
    commit(SET_DEPOSIT_AMOUNT, payload);
  },

  setWithdrawAmount({ commit }, payload) {
    commit(SET_WITHDRAW_AMOUNT, payload);
  },

  async getUsdcChains({ dispatch, commit }, { isDeposit }) {
    try {
      const response = await dispatch('api/apolloClient', {
        type: 'query',
        options: {
          query: isDeposit ? CIRCLE_AVAILABLE_DEPOSIT_CHAINS : CIRCLE_AVAILABLE_WITHDRAWAL_CHAINS,
          variables: {},
        }
      }, ROOT);

      commit(SET_USDC_CHAINS, {
        chains: response.data[isDeposit ? 'circle_available_deposit_chains_v2' : 'circle_available_withdrawal_chains_v2'],
        isDeposit,
      });
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
    }
  },

  async createDepositAddress({ dispatch, commit }, chain) {
    try {
      const response = await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CIRCLE_CREATE_CHAIN_DEPOSIT_ADDRESS,
          variables: { chain },
        }
      }, ROOT);
      commit(SET_USDC_DEPOSIT_ADDRESS, response.data.circle_create_chain_deposit_address);
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
    }
  },

  setSelectedDepositChain({ commit, state, dispatch }, chain) {
    if (state.circle_available_deposit_chains.some(chainSpecification => chainSpecification.chain === chain)) {
      commit(SET_USDC_DEPOSIT_CHAIN, chain);
      dispatch('createDepositAddress', chain);
    }
  },

  setSelectedWithdrawChain({ commit, state }, chain) {
    if (state.circle_available_withdrawal_chains.some(chainSpecification => chainSpecification.chain === chain)) {
      commit(SET_USDC_WITHDRAW_CHAIN, chain);
    }
  },

  resetUsdcDeposit({ commit }) {
    commit(RESET_USDC_DEPOSIT);
  },

  setUsdcWithdrawAddress({ commit }, payload) {
    commit(SET_USDC_WITHDRAW_ADDRESS, payload);
  },

  setUsdcWithdrawAddressTag({ commit }, payload) {
    commit(SET_USDC_WITHDRAW_ADDRESS_TAG, payload);
  },

  resetUsdcWithdraw({ commit }) {
    commit(SET_USDC_WITHDRAW_CHAIN, null);
    commit(SET_USDC_WITHDRAW_ADDRESS, null);
    commit(SET_USDC_WITHDRAW_ADDRESS_TAG, null);
  },

  async handleUsdcWithdraw({ dispatch }, {
    address,
    chain,
    amount,
    addressTag
  }) {
    try {
      const response = await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CIRCLE_WITHDRAWAL_CHAIN,
          variables: {
            address,
            chain,
            amount,
            addressTag
          },
        }
      }, ROOT);

      const withdrawalId = Get(response, 'data.circle_withdrawal_chain.id', '');
      dispatch('openConfirmWithdrawal', withdrawalId);
      dispatch('resetUsdcWithdraw');
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
      throw err;
    }
  },
  async resendEmailToConfirmWithdrawal({ dispatch }, payload) {
    try {

      if (!payload.id) {
        throw Error('There is no previous id');
      }

      const response = await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CIRCLE_WITHDRAWAL_RESEND_AUTHORIZATION_EMAIL,
          variables: {
            withdrawalId: payload.id
          }
        }
      }, ROOT);

      if (response.data) {
        return response.data;
      }

    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
      throw err;
    }
  },

  async handlePaymentMethodRemoval({ dispatch }, payload) {
    try {
      const { methodId, mutation } = payload;

      await dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: mutation,
          variables: { methodId },
        }
      }, ROOT);
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
      throw err;
    }
  },

  async retrieveTransactions({ commit, dispatch }, payload) {
    try {
      const response = await dispatch('api/apolloClient', {
        type: 'query',
        options: {
          query: TransactionHistory[payload.type].query,
          variables: { ...payload },
          fetchPolicy: 'no-cache',
        }
      }, ROOT);

      const MUTATION = TransactionHistory[payload.type].mutation;
      commit(MUTATION, response.data.current_user.transaction_history);

      return response;
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
      throw err;
    }
  },

  resetTransactionHistory({ commit }) {
    commit(RESET_TRANSACTION_HISTORY);
  },

  async retrieveSingleTransaction({ commit, dispatch }, transaction_id) {
    try {
      const response = await dispatch('api/apolloClient', {
        type: 'query',
        options: {
          query: SINGLE_TRANSACTION_HISTORY,
          variables: { transaction_id },
          fetchPolicy: 'no-cache',
        }
      }, ROOT);

      commit(SET_SINGLE_TRANSACTION, response.data.current_user.single_transaction_history);

      return response;
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
      throw err;
    }
  },

  async cancelActiveOrder({ dispatch }, order_id) {
    try {
      return dispatch('api/apolloClient', {
        type: 'mutate',
        options: {
          mutation: CANCEL_ORDER,
          variables: { order_id },
          fetchPolicy: 'no-cache',
        }
      }, ROOT);
    } catch (err) {
      await dispatch('ui/showError', err, ROOT);
      throw err;
    }
  },
};
