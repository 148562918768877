
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CurrentUserReferral"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CurrentUserReferral"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referral_code"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"referral_link"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"number_of_referrals"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"usd_amount_rewarded"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"number_of_rewards"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"referrals"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Referral"},"directives":[]}]}}]}}],"loc":{"start":0,"end":230}};
    doc.loc.source = {"body":"#import \"@/graphql/fragments/Referral.gql\"\n\nfragment CurrentUserReferral on CurrentUserReferral {\n  referral_code\n  referral_link\n  number_of_referrals\n  usd_amount_rewarded\n  number_of_rewards\n  referrals {\n    ...Referral\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/Referral.gql").definitions));


      module.exports = doc;
    
