<template>
  <CentralizedSection class="download-section" :aria-label="$t('download_section.title')">
    <template slot="header">
      <h2 :class="titleClasses">
        {{$t('download_section.title')}}
      </h2>
    </template>
    <div class="flex md:flex-col justify-center mt-s16 gap-s24 md:px-s24 md:m-s24">
      <template v-if="isMobileDevice">
        <a class="flex justify-center" href="https://apps.apple.com/app/id1533182700" target="blank">
          <img
            class="download-image"
            src="~@/assets/img/download-app-store.png"
            :alt="$t('download_section.img_alt_apple')"
            width="192"
            height="65"
          />
        </a>
        <a class="flex justify-center" href="https://play.google.com/store/apps/details?id=com.collectibleholdings.exchange" target="blank">
          <img
            class="download-image"
            src="~@/assets/img/download-google-play.png"
            :alt="$t('download_section.img_alt_google')"
            width="192"
            height="58"
          />
        </a>
      </template>
      <img
        v-else
        class="download-image mt-s32"
        src="~@/assets/img/download-qr-code.png"
        :alt="$t('download_section.title')"
        width="120"
        height="120"
      />
    </div>
  </CentralizedSection>
</template>

<script>
import { CentralizedSection } from '@/components/misc';

export default {
  name: 'DownloadSection',
  components: {
    CentralizedSection,
  },
  computed: {
    titleClasses(){
      return {
        'text-center mb-s12': true,
        'headline-large': !this.isMobileDevice,
        'headline-small': this.isMobileDevice,
      };
    },
  },
};
</script>

<style scoped>
.dark .download-section {
  background: url(~@/assets/img/bg-download-section-2.webp);
  background-size: cover;
}
.download-section {
  background: url(~@/assets/img/bg-download-section-1.webp);
  background-size: cover;
  min-height: 336px;
}
</style>
