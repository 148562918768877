<template>
  <SkeletonLoading
    v-if="isComponentLoading"
    height="305px"
  />
  <div v-else class="shop-item">
    <FracPackImage
      class="mb-s12"
      :image="image"
      :imageHeight="153"
      :imageWidth="99"
      :glowColor="color"
      :glowHeight="75"
      :glowWidth="75"
      :glowBlur="20"
    />

    <div class="shop-item__title my-auto">
      {{ title }}
    </div>

    <div class="shop-item__cost">
      {{ $tc('rewards.gems', currentCost) }}
    </div>

    <ButtonV2
      @onClick="buyReward"
      class="mt-s12"
      :inactive="$big(balance).lt(cost)"
      :label="$t('rewards.buy_now')"
      testId="btn-buyNow"
      :size="isMobileDevice ? 'small' : 'medium'"
      wide
    />
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2.vue';
import { FracPackImage } from '@/components/fracpack';

export default {
  name: 'RewardShopItem',
  components: {
    ButtonV2,
    FracPackImage,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    balance: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: '#FB4DAD',
    },
    cost: {
      type: [String, Number],
      required: true,
    },
    isComponentLoading: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: 'https://img.dibbs.io/frac_pack_std.png',
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    buyReward() {
      this.$emit('onBuyReward', {
        ...this.$props,
      });
    },
  },
  computed: {
    currentCost() {
      return this.$big(this.cost).toFixed(0);
    }
  }
};
</script>

<style scoped>
.shop-item {
  @apply flex flex-col items-center bg-background-primary border border-border p-s20 rounded-16 flex-1;
  max-width: 180px;
}

.shop-item__title {
  @apply subheadline-x-small text-center;
}
.shop-item__cost {
  @apply mt-s4 text-text-active-2 body-text-x-medium;
}
</style>
