<template>
  <div class="flex flex-col mt-s32">
    <TabsAndFilters
      @onTabChange="onTabChange"
      :tabsList="tabsList"
      :currentTab="currentTab"
      :filtersList="tabsFiltersList"
      :currentFilter="currentFilter"
    />

    <template>
      <SkeletonLoading
        v-if="isLoading && !isInfiniteScrollLoading" 
        height="70px" 
        width="100%"
      />

      <TransactionsList
        v-else-if="showResults"
        :listHeaders="currentListHeaders"
        :listResults="currentListResults"
        :type="currentTab"
        ref="transactions"
      /> 

      <NoResult
        v-else-if="showNoResults"
        @click="onNoResultClick"
        :title="currentTabNoResult.title"
        :message="currentTabNoResult.message"
        :btnLabel="currentTabNoResult.btnLabel"
        :btnLink="currentTabNoResult.btnLink"
      />
    </template>
    
    <template>
      <div v-if="showManualTrigger" class="transactions-manual-trigger">      
        <ButtonV2
          :label="$t('wallet.history.transaction.show_more')"
          testId="btn-showMore"
          version="secondary"
          size="medium"
          @onClick="fetchTransactionsOnScroll"
        />
      </div>

      <div v-if="isInfiniteScrollLoading" class="mt-s48 relative">
        <Loading smallLoading />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Loading, NoResult } from '@/components/misc';
import InfiniteScroll from '@/mixins/infinite-scroll';
import ButtonV2 from '@/stories/misc/ButtonV2';

import TransactionsTabs from './transactions-tabs';
import TabsAndFilters from './TabsAndFilters';
import TransactionsList from './TransactionsList';

export default {
  name: 'WalletTransactions',

  mixins: [InfiniteScroll],

  props: {
    parentRoute: {
      type: String,
      required: true
    },
  },

  components: {
    ButtonV2,
    Loading,
    NoResult,
    TabsAndFilters,
    TransactionsList,
  },

  created() {
    this.setInitialPagination();
  },

  async mounted() {
    if (!this.isAuthenticated()) {
      return this.goToInternalPageGlobal('/login');
    }
    
    this.scrollToTop();

    if (this.$route.params.tab) {
      this.setInitialTab(this.$route.params.tab);
    }
    
    await this.resetTransactionHistory();
    await this.handleTabChangeFetch();

    if (this.$refs.transactions) {
      this.setInfiniteScrollRefHeight(this.$refs.transactions.$el.offsetHeight);

      this.setInfiniteScrollStatus(true);
    }
  },

  data() {
    return {
      currentTab: 'active_orders',
      currentFilter: 'active_orders_all',
      isLoading: true,
      pagination: null,
    };
  },

  computed: {
    ...mapGetters('wallet',
      [
        'getTransactionsActiveOrders',
        'getTransactionsHistory',
      ]
    ),

    noLoaders() {
      return !this.isLoading && !this.isInfiniteScrollLoading;
    },

    showManualTrigger() {      
      return this.noLoaders && this.needManualTrigger && this.showResults && this.isInfiniteScrollActive;
    },

    showResults() {
      return !!this.currentListResults.length;
    },

    showNoResults() {
      return this.noLoaders && !this.showResults;
    },

    tabsList() {
      return TransactionsTabs.filter(tab => tab.active);
    },

    tabsFiltersList() {
      return this.tabsList.find(tab => tab.tab === this.currentTab).filters;
    },

    selectedTab() {
      return this.tabsList.find(tab => tab.tab === this.currentTab);
    },

    currentTabNoResult() {
      return this.selectedTab.noResultData;
    },

    currentListHeaders() {
      return this.selectedTab.transactionsList.headers;
    },

    currentListResults() {
      return this.getCurrentListResults();
    },

    currentFilterLabel() {
      return this.currentFilter.split(`${ this.currentTab }_`)[1];
    }
  },

  watch: {
    async scrollTop() {
      if (this.userScrolledOverTrigger) {
        await this.fetchTransactionsOnScroll();
      }
    },

    currentListResults(list, oldList) {
      this.pagination.offset = list.length;

      if (list.length === oldList.length) {
        return this.setInfiniteScrollStatus(false);
      }

      this.setInfiniteScrollStatus(true);
    },
  },

  methods: {
    ...mapActions('wallet',
      [
        'retrieveTransactions',
        'resetTransactionHistory',
      ]
    ),

    setInitialTab(filterFromRoute) {
      const tabFilters = [];

      this.tabsList.forEach(tab => {
        tab.filters.forEach(filter => {
          tabFilters.push({
            tab: tab.tab,
            filter: filter.tab
          });
        });
      });

      const routeFilter = tabFilters.filter(tabFilter => tabFilter.filter === filterFromRoute)[0];
      
      this.currentTab = routeFilter.tab;
      this.currentFilter = routeFilter.filter;
    },

    async onTabChange({ tab, type }) {
      const newTab = this.getTab(tab, type);
      const currentTab = this.getCurrentTab(tab, type);

      if (currentTab !== newTab) {
        this.setInfiniteScrollStatus(true);
        this.setCurrentTab(newTab, type);
        this.emitEvents();
        this.setInitialPagination();

        await this.resetTransactionHistory();
        await this.handleTabChangeFetch();
      }
    },

    getTab(selectedTab, type) {
      let tabList = this.tabsList;

      if (type === 'SECONDARY') {
        tabList = this.tabsFiltersList;
      }

      return tabList.find(tab => tab.tab === selectedTab.tab).tab;
    },

    getCurrentTab(selectedTab, type) {
      let currentTab = this.currentTab;

      if (type === 'SECONDARY') {
        currentTab = this.currentFilter;
      }

      return currentTab;
    },

    getCurrentListResults() {
      let results;

      switch (this.currentTab) {
        case 'active_orders':
          results = this.getActiveOrdersData(this.currentFilterLabel);
          break;
        case 'transaction_history':
          results = this.getTransactionHistoryData(this.currentFilterLabel);
          break;
      }

      return results;
    },

    getActiveOrdersData(filter) {
      let data = this.getTransactionsActiveOrders;
      
      switch (filter) {
        case 'buy':
          data = data.filter(transaction => transaction.is_buy);
          break;
        case 'sell':
          data = data.filter(transaction => !transaction.is_buy);
          break;
      }

      return data;
    },

    getTransactionHistoryData(filter) {
      let data = this.getTransactionsHistory;
      
      switch (filter) {
        case 'orders':
          data = data.filter(transaction => {
            return ['UserOrderReceipt', 'DropReceipt', 'FracPackReceipt']
              .some(type => type === transaction.__typename);
          });
          break;
        case 'transfers':
          data = data.filter(transaction => {
            return ['CirclePaymentReceipt', 'CircleWithdrawalReceipt']
              .some(type => type === transaction.__typename);
          });
          break;
      }

      return data;
    },

    setCurrentTab(selectedTab, type) {
      if (type === 'PRIMARY') {
        this.currentTab = selectedTab;
        this.currentFilter = this.tabsFiltersList[0].tab;
        return;
      }

      this.currentFilter = selectedTab;
    },

    setInitialPagination() {
      this.pagination = {
        offset: 0,
        count: 10,
      };
    },

    async fetchTransactionsOnScroll() {
      this.setInfiniteScrollLoading(true);

      await this.handleTabChangeFetch();
      this.setInfiniteScrollRefHeight(this.$refs.transactions.$el.offsetHeight);

      this.setInfiniteScrollLoading(false);
    },

    async handleTabChangeFetch() {
      this.isLoading = true;

      switch (this.selectedTab.tab) {
        case 'active_orders':
          await this.fetchActiveOrders();
          break;
        case 'transaction_history':
          await this.fetchTransactionHistory();
          break;
      }

      this.isLoading = false;
    },

    async fetchActiveOrders() {
      const userOrderTransactionType = this.currentFilterLabel === 'all' 
        ? null 
        : this.currentFilterLabel;

      const payload = {
        type: 'ACTIVE_ORDERS',
        offset: this.pagination.offset,
        count: this.pagination.count,
        userOrderStatus: 'active',
        userOrderTransactionType,
        transactionHistoryFilter: 'orders'
      };

      await this.retrieveTransactions(payload);
    },

    async fetchTransactionHistory() {
      const transactionHistoryFilter = this.currentFilterLabel === 'all' 
        ? null 
        : this.currentFilterLabel;

      const payload = {
        type: 'ALL',
        offset: this.pagination.offset,
        count: this.pagination.count,
        userOrderStatus: null,
        userOrderTransactionType: null,
        transactionHistoryFilter
      };

      await this.retrieveTransactions(payload);
    },

    emitEvents() {
      const newTab = `/${this.parentRoute}/${ this.currentFilter }`;

      window.history.replaceState({}, null, newTab);

      this.$store.dispatch('events/track', {
        event: `${newTab.toUpperCase()}_HISTORY_VIEWED`,
      });
    },

    onNoResultClick() {
      if (this.currentTab === 'transaction_history') {
        this.showModal('PaymentInterface', { isDeposit: true });
      }
    }
  }
};
</script>

<style scoped>
.transactions-manual-trigger {
  @apply flex items-center justify-center mt-s48;
}
</style>
