<template>
  <button role="button" class="flex items-center border border-border-info-panel bg-background-positive-text bg-opacity-50 hover:bg-opacity-100 gap-s16 p-s12 rounded-16">
    <div class="flex">
      <template v-if="isFontAwesome">
        <div class="icon-container">
          <font-awesome-icon :data-testid="`fa-${icon}`" role="icon" :icon="icon" class="payment-icon" />
        </div>
      </template>
      <template v-else>
        <div class="icon-container">
          <component data-testid="custom-icon" :is="icon" class="custom-icon payment-icon" />
        </div>
      </template>
    </div>
    <div class="flex flex-col gap-s8 text-left flex-grow">
      <p class="body-text-x-large text-text-body">{{title}}</p>
      <p class="body-text-medium text-text-inactive">{{funds}}</p>
      <p class="body-text-medium text-text-positive">{{fee}}</p>
    </div>
    <div class="flex flex-col">
      <p v-if="isRecommended" class="bg-background-positive-text capitalize text-text-positive body-text-x-small not-mobile:body-text-medium py-s2 px-s8 rounded-4">{{$t('recommended')}}</p>
    </div>
  </button>
</template>

<script>
import { IconCircleDollar } from '@/assets/icons';

export default {
  name: 'PaymentTypeButton',
  components: {
    IconCircleDollar,
  },
  props: {
    icon: {
      type: [String, Object],
      required: true
    },
    isRecommended: {
      type: Boolean,
      default: false
    },
    fee: {
      type: String,
      required: true
    },
    funds: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    iconType(){
      return typeof this.icon === 'string' ? 'fa' : 'custom';
    },
    isFontAwesome(){
      return this.iconType === 'fa';
    }
  }
};
</script>

<style scoped>
.custom-icon {
  @apply fill-current;
  display: inline-block;
  height: 1em;
  overflow: visible;
}

.payment-icon {
  @apply text-text-positive text-n-2xl;
}

.icon-container {
  @apply flex p-s8;
  background: rgba(116, 219, 181, 0.50);
  border-radius: 50%;
}
</style>
