import Vue from 'vue';
import ExternalUrl from '@/enums/ExternalUrl';

export const navbarList = [
  {
    visibility: {
      desktop: true,
      mobile: true,
    },
    key: 'home',
    icon: 'IconHome',
    label: 'navigation.home',
    to: '/',
    name: 'home',
  },
  {
    visibility: {
      desktop: true,
      mobile: true,
    },
    key: 'drops',
    icon: 'IconDrops',
    label: 'navigation.drops',
    to: '/drops',
    name: 'drops',
  },
  {
    visibility: {
      desktop: true,
      mobile: true,
    },
    key: 'markets',
    icon: 'IconMarket',
    label: 'navigation.markets',
    to: '/market',
    name: 'market',
  },
  {
    visibility: {
      desktop: true,
      mobile: true,
    },
    key: 'holdings',
    icon: 'IconHoldings',
    label: 'navigation.holdings',
    to: '/holdings',
    name: 'holdings',
  },
  {
    visibility: {
      desktop: true,
      mobile: true,
    },
    key: 'wallet',
    icon: 'IconWallet',
    label: 'navigation.wallet',
    to: '/wallet',
    name: 'wallet',
  },
];

export default Vue.component('Menu', {
  name: 'Menu',

  computed: {
    navbarList() {
      return navbarList;
    },

    menuItems() {
      return [
        {
          id: 0,
          visibility: {
            desktop: true,
            mobile: true,
          },
          name: 'settings',
          label: this.$t('navigation.settings'),
          description: this.$t('navigation.mobile_menu.settings_description'),
          icon: 'Settings',
          linkTo: '/settings',
          action: 'redirect',
          enabled: true
        },
        {
          id: 1,
          visibility: {
            desktop: true,
            mobile: true,
          },
          name: 'identity',
          label: this.$t('navigation.identity'),
          description: this.$t('navigation.mobile_menu.identify_description'),
          icon: 'Identity',
          linkTo: '/identity',
          action: 'redirect',
          enabled: true
        },
        {
          id: 2,
          visibility: {
            desktop: true,
            mobile: true,
          },
          name: 'get_frack_pack',
          label: this.$t('navigation.get_frack_pack'),
          description: this.$t('navigation.mobile_menu.promotions_description'),
          icon: 'GetFracPack',
          linkTo: '/promotions/frac-pack',
          action: 'redirect',
          enabled: true
        },
        {
          id: 3,
          visibility: {
            desktop: true,
            mobile: true,
          },
          name: 'sell_with_dibbs',
          label: this.$t('navigation.sell_with_dibbs'),
          description: this.$t('navigation.mobile_menu.help_description'),
          icon: 'SellCard',
          action: 'sellCardRedirect',
          enabled: true
        },
        {
          id: 4,
          visibility: {
            desktop: true,
            mobile: true,
          },
          name: 'support',
          label: this.$t('navigation.support'),
          description: this.$t('navigation.mobile_menu.feedback_description'),
          icon: 'Support',
          linkTo: '/support',
          action: 'redirect',
          enabled: true
        },
        {
          id: 5,
          visibility: {
            desktop: true,
            mobile: true,
          },
          name: 'feedback',
          label: this.$t('navigation.feedback'),
          description: this.$t('navigation.mobile_menu.feedback_description'),
          icon: 'Feedback',
          action: 'feedback',
          enabled: true
        },
        {
          id: 6,
          visibility: {
            desktop: false,
            mobile: true,
          },
          name: 'disclosures',
          label: this.$t('navigation.disclosures'),
          description: this.$t('navigation.mobile_menu.disclosures_description'),
          icon: 'Disclosures',
          linkTo: '/user-agreement',
          action: 'redirect',
          enabled: true
        },
        {
          id: 7,
          visibility: {
            desktop: true,
            mobile: true,
          },
          name: 'logout',
          label: this.$t('navigation.logout'),
          icon: 'Logout',
          action: 'logout',
          enabled: true
        },
      ];
    },
    enabledItems(){
      return this.menuItems.filter(item => item.enabled);
    },
    desktopItems(){
      return this.enabledItems.filter(item => item.visibility.desktop);
    },
    mobileItems(){
      return this.enabledItems.filter(item => item.visibility.mobile);
    },
    desktopNavbar(){
      return this.navbarList.filter(item => item.visibility.desktop);
    },
    mobileNavbar(){
      return this.navbarList.filter(item => item.visibility.mobile);
    },
  },

  methods: {
    async handleAction(payload) {
      switch (payload.action) {
        case 'sellCardRedirect':
          await this.redirectSellCard();
          break;
        case 'logout':
          this.showModal('ConfirmLogout');
          break;
        case 'feedback':
          this.showModal('SubmitFeedback');
          break;
        default:
          this.goToPage(payload.linkTo);
      }
    },
    goToPage(page) {
      this.$router.push(page).catch(() => {});
      if (this.isMediumMobileDevice) {
        this.hideModal();
      }
    },
    async redirectSellCard(){
      await this.$store.dispatch('events/track', {
        event: 'SELLERS_LANDING_PAGE_VIEWED',
      });
      return this.goToExternalPageGlobal(ExternalUrl.SELL_WITH_DIBBS);
    }
  }
});
