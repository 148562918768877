<template>
  <div>
    <DatePicker
      v-model="model"
      v-bind="$props"
      class="w-full"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  name: 'Datepicker',
  extends: DatePicker,
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: [Date, String]
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },
  }
};
</script>

<style>
@import '~vue2-datepicker/index.css';

::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(1);
}

.mx-datepicker-popup {
  z-index: 41 !important;
}
.mx-datepicker {
  width: 100% !important;
}
.mx-datepicker > div > input {
  @apply flex items-center justify-between w-full border bg-background-primary border-border cursor-text h-s48 rounded-16 px-n-3xl text-text-body;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  outline: none;
  box-shadow: none;
}
.mx-datepicker > div > input:hover,
.mx-datepicker > div > input:active,
.mx-datepicker > div > input:focus {
  @apply border-border;
}
.mx-datepicker > div > i {
  @apply text-text-body;
}
.mx-datepicker > div > i:hover {
  opacity: 0.6;
}
</style>
