<template>
  <div class="flex flex-col gap-s16">

    <div class="flex items-end justify-between border-border border-b gap-s24 pb-s16">
      <SkeletonLoading v-if="isLoading" width="200px" height="54px" />

      <div v-else class="flex flex-col">
        <div class="body-text-x-medium uppercase flex items-center text-text-inactive gap-s4" @click="showTotalBalanceInfo()">
          {{ $t('wallet.total_balance') }}
          <IconInfo class="w-s16 h-s16 fill-current cursor-pointer default-transition hover:opacity-60" />
        </div>
        <p :class="chartValueClasses">
          {{ currentChartValue }}
        </p>
      </div>

      <SecondaryTabs 
        :currentTab="selectedTab"
        :tabList="filteredTabList"
        @tabChange="(value) => $emit('changeTab', value)" />
    </div>

    <div class="flex flex-col">
      <SkeletonLoading v-if="isLoading" height="159px" />

      <div v-else>
        <p :class="changeClasses">
          {{ changeValueText }} ({{ changePercentageText }})
        </p>
        <Chart
          @chartSelectedValue="chartSelectedValue"
          :chartdata="chart"
          :chartOptions="{height: 135}"
          chartValueLabel="close"
          showTime
          />
      </div>

    </div>

  </div>
</template>

<script>
import { IconInfo } from '@/assets/icons';
import { SecondaryTabs } from '@/components/misc';
import Chart from '@/components/misc/Chart';

export default {
  name: 'WalletChart',
  components: {
    Chart,
    IconInfo,
    SecondaryTabs,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    chart: {
      type: Array,
      default: () => ([])
    },    
    tabList: {
      type: Array,
      default: () => ([])
    },
    selectedTab: {
      type: String,
      default: '1W'
    }
  },
  data: () => ({
    chartValue: null,
  }),
  computed: {
    currentChartValue(){
      if (this.isLoading) {
        return 0;
      }

      const value = this.chartValue ? this.chartValue : this.chart[this.chart.length - 1].close;
      return this.numberFormat(value, 2, false, true);
    },
    changeValue() {
      if (this.isLoading) {
        return 0;
      }

      return this.$big(this.chart[this.chart.length - 1].close).minus(this.chart[0].close).toNumber();
    },
    changePercentage() {
      if (this.isLoading) {
        return 0;
      }

      return this.chart[0].close ? this.$big(this.chart[this.chart.length - 1].close).times(100).div(this.chart[0].close).minus(100).toNumber() : null;
    },
    changeValueText(){
      return this.numberFormat(this.changeValue, 2, true, true);
    },
    changePercentageText(){
      return `${this.numberFormat(this.changePercentage, 2, true, false)}%`;
    },
    changeClasses(){
      return {
        'subheadline-x-medium text-right': true,
        'text-text-positive': this.changeValue >= 0,
        'text-text-negative': this.changeValue < 0,
      };
    },
    chartValueClasses() {
      return {
        'headline-large': !this.isNanoMobileDevice,
        'headline-small': this.isNanoMobileDevice,
      };
    },
    filteredTabList(){
      return this.isMediumMobileDevice ? this.tabList.slice(0, 3) : this.tabList;
    }
  },
  methods: {
    chartSelectedValue(value) {
      this.chartValue = value;
    },
  }
};
</script>

<style scoped>
  .chart-loading {
    height: 200px;
  }
</style>
