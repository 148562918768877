import router from '@/vue-router';

router.addRoute({ 
  component: () => import ('@/views/DepositEncrypt'),
  name: 'deposit-encrypt',
  path: '/deposit/encrypt',
  meta: {
    template: 4,
  },
});
