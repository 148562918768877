export { default as ToastMessage } from './ToastMessage.vue';
export { default as ToastComponentExample } from './ToastComponentExample.vue';


export const AVAILABLE_TYPES = [
  'success', 
  'error'
];

export const AVAILABLE_COMPONENTS = [
  'ToastComponentExample',
  'KycStatusBanner',
];
