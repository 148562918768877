<template>
  <section class="open-pack-modal">
    <div class="open-pack-modal__bg" :style="`background-image: url(${ imgFront })`" />
    <div class="open-pack-modal__inner">
      
      <div class="open-pack-modal__card-img">
        <img :src="imgFront" :alt="name">
      </div>
      
      <h2 class="open-pack-modal__card-title">
        {{name}}
      </h2>
      
      <ul class="open-pack-modal__card-info">
        <li>
          <div>{{$t('frac_pack.card_quantity')}}: </div>
          <div><strong>{{ numberFormat(currentFracpackDefinition.fraction_per_card * 100, 2) }}%</strong></div>
        </li>
        <li>
          <div>{{$t('frac_pack.usd_value')}}: </div>
          <div><strong>{{ numberFormat(price, 2, false, true)}}</strong></div>
        </li>
        <li>
          <div>{{$t('frac_pack.card_price')}}: </div>
          <div><strong>{{ numberFormat(marketCap, 2, false, true)}}</strong></div>
        </li>
      </ul>
      <div class="open-pack-modal__controls">
        <ButtonV2
          class="flex-1 mr-n-md"
          :label="$t('frac_pack.back')"
          version="secondary"
          size="medium"
          testId="btn-back"
          v-if="!isFirstCard"
          @onClick="prevCard"
        />
        <ButtonV2
          :class="`flex-1 ${!isFirstCard? 'ml-n-md': ''}`"
          :label="$t('frac_pack.next')"
          size="medium"
          testId="btn-next"
          :wide="isFirstCard"
          v-if="!isLastCard"
          @onClick="nextCard"
        />
        <ButtonV2
          class="flex-1 ml-n-md"
          :label="$t('frac_pack.review')"
          testId="btn-review"
          size="medium"
          v-else
          @onClick="review"
        />
      </div>
    </div>
  </section>
</template>


<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import {mapGetters} from 'vuex';

export default {
  name: 'OpenPackModal',
  components: {
    ButtonV2
  },

  computed: {
    ...mapGetters('fracpack', [
      'fracpackAssetTrackingData',
      'currentFracpackDefinition'
    ]),
  },

  props: {
    imgFront   : { type: String, required: true },
    name       : { type: String, required: true },
    price      : { type: String, required: true },
    marketCap  : { type: String, required: true },
    isLastCard : { type: Boolean, default: false },
    isFirstCard: { type: Boolean, default: false }
  },

  methods: {
    prevCard() {
      this.$store.dispatch('fracpack/showPreviousCard');
      this.$store.dispatch('events/track', {
        event: 'FRAC_PACK_ASSET_VIEWED',
        variables: {
          ...this.fracpackAssetTrackingData,
        },
      });
    },
    nextCard() {
      this.$store.dispatch('fracpack/showNextCard');
      this.$store.dispatch('events/track', {
        event: 'FRAC_PACK_ASSET_VIEWED',
        variables: {
          ...this.fracpackAssetTrackingData,
        },
      });
    },
    review() {
      this.$store.dispatch('fracpack/showFracpackReview');
    }
  },

  mounted() {
    this.$store.dispatch('events/track', {
      event: 'FRAC_PACK_ASSET_VIEWED',
      variables: {
        ...this.fracpackAssetTrackingData,
      },
    });
  }
};
</script>

<style scoped>
.open-pack-modal {
  position: relative;
  /* max-width: 390px; */
  z-index: 0;
}
.is-screen-lg-up .open-pack-modal {
  width: 390px;
  height: 616px;
}
@media screen and (max-width: 500px) {
  .open-pack-modal {
    max-width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
  }
}
.open-pack-modal__bg {
  position: absolute;
  inset: 0;
  opacity: .15;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--colors-background-page);
  mix-blend-mode: multiply;
  z-index: 1;
}
.open-pack-modal__inner {
  @apply px-n-4xl py-n-5xl;
  position: relative;
  height: 100%;
  z-index: 2;
}
.open-pack-modal__card-img {
  @apply flex justify-center mb-n-4xl;
}
.open-pack-modal__card-img img {
  width: 150px;
}
.open-pack-modal__card-title {
  @apply text-n-2xl leading-xl font-bold mb-n-xl;
}
.open-pack-modal__card-info {
  @apply list-none mb-n-4xl;
}
.open-pack-modal__card-info li{
  @apply flex justify-between items-center py-n-md text-n-md font-medium;
  border-bottom: solid 1px;
  border-color: #00000040;
}
.dark .open-pack-modal__card-info li {
  border-color: #FFFFFF70;
}
.open-pack-modal__card-info li:last-of-type {
  border: none;
}
.open-pack-modal__card-info li strong {
  @apply text-n-xl font-semibold;
}
.open-pack-modal__controls {
  @apply flex justify-between;
  position: absolute;
  bottom: 24px;
  left: 24px;
  right: 24px;
}
</style>